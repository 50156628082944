import React, { useState, useEffect } from 'react'
import Header from '../../../component/Header'
import CustomCard from '../../../component/CustomeCard'
import CustomButton from '../../../component/Button/Button'
import CustomInput from '../../../component/CustomInput'
import Customselect from '../../../component/CustomSelect'
import './style.scss'
import SumbitModal from "../../../component/HrNewModuleComponent/Modal/SubmitRequisitionModal"
import { useStores } from '../../../stores'
import notify from '../../../shared/notify'
import Loader from '../../../component/helper/Loader/loader'
import { useNavigate } from "react-router-dom";
import Select from "react-select";

const AddNewRequisition = () => {
    const { crmStore } = useStores();
    const { mainStore } = useStores()
    const navigate = useNavigate()
    const candidateString = sessionStorage.getItem("candidate");
    const candidate = candidateString ? JSON.parse(candidateString) : null;
    const [isLoading, setIsLoading] = useState(false)
    const [businessList, setBusinessList] = useState([])
    const [selectedBusinessList, setSelectedBusinessList] = useState(candidate?.business_id)
    const [companyNameList, setCompanyNameList] = useState([])
    const [selectedCompanyName, setSelectedCompanyName] = useState("")
    const [plantList, setPlantList] = useState([])
    const [selectedPlant, setSelectedPlant] = useState("")
    const [locationList, setLocationList] = useState("")
    const [selectLocationName, setSelectLocationName] = useState("")
    const [dateOfRequisition, setDateOfRequisition] = useState(new Date().toISOString()?.split('T')[0])
    const [departmentList, setDeparmentList] = useState([])
    const [selectedDepartment, setSelectedDepartment] = useState(candidate?.department_id)
    const [designationList, setDesignationList] = useState([])
    const [designation, setDesignation] = useState('')
    const [age, setAge] = useState('')
    const [qualification, setQualification] = useState('')
    const [experience, setExperience] = useState('')
    const [subQualification, setSubQualification] = useState("")
    const [showSubQualification, setShowSubQualification] = useState(false)
    const [numOfPosition, setNumOfPosition] = useState('')
    const [positionName, setPositionName] = useState(null)
    const [empName, setEmpName] = useState("")
    const [empId, setEmpId] = useState('')
    const [currentCtc, setCurrentCtc] = useState('')
    const [existingManPower, setExistingManPower] = useState('')
    const [selectedCtcRange, setSelectedCtcRange] = useState('')
    const [showOther, setShowOther] = useState(false)
    const [other, setOther] = useState("")
    const [justification, setJustification] = useState("")
    const [jobTitle, setJobTitle] = useState('')
    const [aboutRole, setAboutRole] = useState("")
    const [keyResponsibilities, setKeyResponsibility] = useState('')
    const [yearOfExperience, setYearOfExperience] = useState("")
    const [educationQualification, setEducationQualification] = useState('')
    const [showSubmitModal, setShowSubmitModal] = useState(false)
    const [isSelectedTwo, setIsSelectedTwo] = useState(false)
    const [isSelectedReplacement, setIsSelectedReplacement] = useState(false)
    const [selectedTypeOfPosition, setSelectedTypeOfPosition] = useState("")
    const [typeOfPosition, setTypeOfPosition] = useState([
        { value: 'New pos within org chart', label: 'New pos within org chart' },
        { value: 'New pos outside org chart', label: 'New pos outside org chart' },
        { value: 'Replacement', label: 'Replacement' },
    ])

    const [ctcRangeList, setCtcRangeList] = useState([
        { value: "1-2 LPA", label: "1-2 LPA" },
        { value: "2-3 LPA", label: "2-3 LPA" },
        { value: "3-4 LPA", label: "3-4 LPA" },
        { value: "5-6 LPA", label: "5-6 LPA" },
        { value: "Others", label: "Others" },
    ])

    let educationList = [
        { value: "Primary education", label: "Primary education" },
        { value: "Secondary education or high school", label: "Secondary education or high school" },
        { value: "Diploma/Certificate", label: "Diploma/Certificate" },
        { value: "Vocational qualification", label: "Vocational qualification" },
        { value: "Bachelor's degree", label: "Bachelor's degree" },
        { value: "Master's degree", label: "Master's degree" },
        { value: "Doctorate or higher", label: "Doctorate or higher" },
        { value: "GED", label: "GED" },
    ]

    //   let expDropDown =[
    //     {value : "0-12 months" , label : "0-12 months"},
    //     {value : "1-2 years" , label : "1-2 years"},
    //     {value : "2-3 years" , label : "2-3 years"},
    //     {value : "3-4 years" , label : "3-4 years"},
    //     {value : "4-5 years" , label : "4-5 years"},
    //     {value : "5-6 years" , label : "5-6 years"},
    //     {value : "6-7 years" , label : "6-7 years"}, 
    //     {value : "7-8 years" , label : "7-8 years"},
    //     {value : "8-9 years" , label : "8-9 years"},
    //     {value : "9-10 years" , label : "9-10 years"},
    //   ]

    const [empList, setEmpList] = useState([])

    useEffect(() => {
        if (selectedCtcRange === "Others") {
            setShowOther(true)
        } else {
            setShowOther(false)
        }
    }, [selectedCtcRange])

    useEffect(() => {
        emp()
    }, [])

    useEffect(() => {
        if (qualification !== "") {
            setShowSubQualification(true)
        } else {
            setShowSubQualification(false)
        }
    }, [qualification])




    const emp = async () => {
        await crmStore.getEmployeeByNusiness(candidate?.business_id, (status, res) => {
            if (res?.meta?.status === "success") {
                // let data = [];
                // if (res.data.users) {
                //     res.data.users.forEach(user => {
                //         let obj = {
                //             empCode: user.emp_code,
                //             empName: user.name
                //         }
                //         data.push(obj);
                //     });
                // }
                // setEmpList(data)
                let data = [...res?.data?.users]
                const selectOptions = data.map((option) => ({
                    value: option.emp_code,
                    label: option.name,
                }));
                setEmpList(selectOptions);
            } else {
                notify(res?.meta?.message, "danger")
            }
        }, (error) => {
            notify(error?.response?.meta?.message, "danger")
        })
    }


    useEffect(() => {
        if (selectedTypeOfPosition === "New pos within org chart" || selectedTypeOfPosition === "New pos outside org chart") {
            setIsSelectedTwo(true)
            setIsSelectedReplacement(false)
        }
        if (selectedTypeOfPosition === "Replacement") {
            setIsSelectedReplacement(true)
            setIsSelectedTwo(false)
        }

    }, [selectedTypeOfPosition])


    const handleCreateRequisitionApi = async () => {

        if (selectedBusinessList === "" || selectedBusinessList === undefined) {
            return notify("please select business type", "danger")
        }
        if (dateOfRequisition === "" || dateOfRequisition === undefined || dateOfRequisition === null) {
            return notify("please select date of requisition", "danger")
        }
        if (selectedCompanyName === "" || selectedCompanyName === undefined || selectedCompanyName == null) {
            return notify("please select company name", "danger")
        }
        if (plantList === "" || plantList === undefined) {
            return notify("please select plant", "danger")
        }
        if (locationList === "" || locationList === undefined) {
            return notify("please select loaction", "danger")
        }
        if (departmentList === "" || departmentList === undefined) {
            return notify("please select department", "danger")
        }
        if (designationList === "" || designationList === undefined) {
            return notify("please select designation", "danger")
        }
        // if (age === "" || age === undefined) {
        //     return notify("please fill age", "danger")
        // }
        // if (qualification === "" || qualification === undefined) {
        //     return notify("please fill qualification", "danger")
        // }
        // if (experience === "" || experience === undefined) {
        //     return notify("please fill experience", "danger")
        // }
        if (typeOfPosition === "" || typeOfPosition === undefined) {
            return notify("please select type of position", "danger")
        }
        if (existingManPower === "" || existingManPower === undefined) {
            return notify("please fill existing ManPower ", "danger")
        }
        // if (ctcRangeList === "" || ctcRangeList === undefined) {
        //     return notify("please select ctc range ", "danger")
        // }
        if (justification === "" || justification === undefined) {
            return notify("please fill justification ", "danger")
        }
        if (jobTitle === "" || jobTitle === undefined) {
            return notify("please fill job title ", "danger")
        }
        if (aboutRole === "" || aboutRole === undefined) {
            return notify("please fill about the role ", "danger")
        }
        if (keyResponsibilities === "" || keyResponsibilities === undefined) {
            return notify("please fill key responsibilities ", "danger")
        }
        if (yearOfExperience === "" || yearOfExperience === undefined) {
            return notify("please fill year of experience ", "danger")
        }
        if (educationQualification === "" || educationQualification === undefined) {
            return notify("please fill education qualification ", "danger")
        }

        if (selectedTypeOfPosition === "Replacement" && (positionName === "" || empId === "")) {
            return notify("please fill all replacement fields ", "danger")
        }


        let payload = {
            business_id: selectedBusinessList !== undefined || selectedBusinessList !== " " || selectedBusinessList !== null ? selectedBusinessList : undefined,
            date_of_requisition: dateOfRequisition !== undefined || dateOfRequisition !== "" || dateOfRequisition !== null ? dateOfRequisition : undefined,
            company_id: selectedCompanyName !== undefined || selectedCompanyName !== "" || selectedCompanyName !== null ? selectedCompanyName : undefined,
            plant_id: selectedPlant !== undefined || selectedPlant !== "" || selectedPlant !== null ? selectedPlant : undefined,
            plant_location: locationList !== undefined || locationList !== "" || locationList !== null ? locationList[0] : undefined,
            department_id: selectedDepartment !== undefined || selectedDepartment !== "" || selectedDepartment !== null ? selectedDepartment : undefined,
            designation_id: designation !== undefined || designation !== null || designation !== "" ? designation : undefined,
            // person_age: age !== undefined || age !== "" || age !== null ? age : undefined,
            // person_qualification: qualification !== "" || qualification !== undefined || qualification !== null ? qualification : undefined,
            // person_experience: experience !== undefined || experience !== "" || experience !== null ? experience : undefined,
            position_type: selectedTypeOfPosition !== undefined || experience !== "" || selectedTypeOfPosition !== null ? selectedTypeOfPosition : undefined,
            existing_manpower: existingManPower !== undefined || existingManPower !== "" || existingManPower !== null ? existingManPower : undefined,
            budgeted_manpower: selectedCtcRange !== undefined || selectedCtcRange !== "" || selectedCtcRange !== null ? selectedCtcRange : undefined,
            justification: justification !== undefined || justification !== "" || justification !== null ? justification : undefined,
            job_title: jobTitle !== undefined !== jobTitle !== "" || jobTitle !== null ? jobTitle : undefined,
            about_role: aboutRole !== undefined !== aboutRole !== "" || aboutRole !== null ? aboutRole : undefined,
            key_responsibilites: keyResponsibilities !== undefined || keyResponsibilities !== "" || keyResponsibilities !== null ? keyResponsibilities : undefined,
            years_of_experience: yearOfExperience !== undefined || yearOfExperience !== "" || yearOfExperience !== null ? yearOfExperience : undefined,
            education_qualification: educationQualification !== undefined || educationQualification !== "" || educationQualification !== null ? educationQualification : undefined,
            // ...(positionName !== undefined && positionName !== null && positionName !== "" && { employee_name: positionName }),
            // ...(empId !== undefined && empId !== null && empId !== "" && { employee_id: empId }),
            // ...(currentCtc !== undefined && currentCtc !== null && currentCtc !== "" && { current_ctc: currentCtc }),
            // ...(numOfPosition !== undefined && numOfPosition !== null && numOfPosition !== "" && { number_of_new_position: numOfPosition }),
            ...(empName !== undefined && empName !== "NA" && empName !== "" && empName !== null && { employee_name: empName }),
            ...(empId !== undefined && empId !== "NA" && empId !== "" && empId !== null && { employee_id: empId }),
            ...(currentCtc !== undefined && currentCtc !== null && currentCtc !== "NA" && currentCtc !== "" && { current_ctc: currentCtc }),
            ...(numOfPosition !== undefined && numOfPosition !== "NA" && numOfPosition !== "" && numOfPosition !== null && { number_of_new_position: numOfPosition }),
            ...(other !== undefined && other !== "NA" && other !== "" && other !== null && { budgeted_manpower_others: other }),
            // ...(subQualification !== undefined && subQualification !== "NA" && subQualification !== "" && subQualification !== null && { qualification_subcategory: subQualification }),
        }
        callCreateRequisitionApi(payload)
    }



    const callCreateRequisitionApi = async (payload) => {
        setIsLoading(true)
        await crmStore.createRequisition(payload, (res) => {
            if (res?.data?.meta?.status === "success") {
                setShowSubmitModal(true)
                setIsLoading(false)
            } else {
                notify(res?.data?.meta?.message, "danger")
                setIsLoading(false)
            }
        },
            (error) => {
                notify(error?.response?.data?.meta?.message, "danger")
                setIsLoading(false)
            }
        );
    }

    useEffect(() => {
        handleBusinessDropDown()
        handleDepartment()
        handleDesignation()
        handleCompanyName()
    }, [])



    const handleBusinessDropDown = async () => {
        await crmStore.getAllBusinessList((status, res) => {
            if (res?.meta?.status === "success") {
                setBusinessList(res?.data)
            } else {
                console.log(res?.data?.meta?.message, "businessList")
            }
        })
    }

    const handleCompanyName = async () => {
        await mainStore.getEntity((status, res) => {
            if (res?.meta?.status === "success") {
                setCompanyNameList(res?.data?.entitites)
            } else {
                console.log(res?.data?.meta?.message, "businessList")
            }
        })
    }

    const handlePlantName = async (id) => {
        let payload = {
            company_id: id
        }
        await crmStore.getAllCompanyPlant(payload, (res) => {
            if (res?.data?.meta?.status === "success") {
                setPlantList(res?.data?.data)
            } else {
                console.log(res?.data?.meta?.message, "businessList")
            }
        })
    }

    const handlePlantLocation = async (id) => {
        let payload = {
            company_id: selectedCompanyName,
            plant_id: id,
        }
        await crmStore.getAllCompanyPlantLocation(payload, (res) => {
            if (res?.data?.meta?.status === "success") {
                let location = res?.data?.data.map((item) => {
                    setSelectLocationName(item?.location_id)
                    return item?.location
                })
                setLocationList(location)
            } else {
                console.log(res?.data?.meta?.message, "businessList")
            }
        })
    }
    const handleCompanyNameChange = (e) => {
        setSelectedCompanyName(e.target.value)
        handlePlantName(e.target.value)
    }

    const handlePlantNameChange = (e) => {
        setSelectedPlant(e.target.value)
        handlePlantLocation(e.target.value)
    }




    const handleDepartment = async () => {
        await crmStore.getDeparments((status, res) => {
            if (res?.meta?.status === "success") {
                setDeparmentList(res?.data)
            } else {
                console.log(res?.data?.meta?.message, "setDeparmentList")
            }
        })
    }


    const handleDesignation = async () => {
        await crmStore.getDesignations((status, res) => {
            if (res?.meta?.status === "success") {
                setDesignationList(res?.data)
            } else {
                console.log(res?.data?.meta?.message, "setDesignationList")
            }
        })
    }

    const handlePosition = (event) => {
        let data = event;
        setPositionName(data)
        setEmpId(data?.value)
        setEmpName(data?.label)
    }

    const handleMenuOpen = () => {
        setPositionName("")
    };


    return (
        <div className="main-container">
            {isLoading && <Loader />}
            <div className="right-view">
                <Header title={" ManPower Requisition Form,"} />
                <div className='row'>
                    <div className='col-md-12'>
                        <CustomCard
                            cardtitle={"Business"}
                            cardboldtitle={"Details"}
                            cardbody={
                                <>
                                    <img src={require("../../../assets/images/left-arrow.png")} className="back-btn-dashboard" onClick={() => navigate(-1)} />
                                    <div className='row'>
                                        <div className='col-md-3'>
                                            <Customselect
                                                label="Select Business Type"
                                                require={true}
                                                readOnly={true}
                                                value={selectedBusinessList}
                                                onChange={(e) => {
                                                    setSelectedBusinessList(e.target.value);
                                                }}
                                                optionview={businessList?.map((option) => {
                                                    return (
                                                        <option value={option.business_id}>{option.business_name}</option>
                                                    );
                                                })}
                                            />
                                        </div>
                                        <div className='col-md-3'>
                                            <Customselect
                                                label="Select Company Name"
                                                require={true}
                                                onChange={handleCompanyNameChange}
                                                optionview={companyNameList?.map((option) => {
                                                    return (
                                                        <option value={option?.entity_code}>{option?.name}</option>
                                                    );
                                                })}
                                            />
                                        </div>
                                        <div className='col-md-3'>
                                            <Customselect
                                                label="Select Plant"
                                                // require={true}
                                                onChange={handlePlantNameChange}
                                                optionview={plantList?.map((option) => {
                                                    return (
                                                        <option value={option.plant_id}>{option.plant_name}</option>
                                                    );
                                                })}
                                            />
                                        </div>
                                        <div className='col-md-3'>
                                            <CustomInput
                                                label="Select Location"
                                                // require={true}
                                                readOnly={true}
                                                value={locationList[0]}
                                                placeholder="Select Location"
                                            />
                                        </div>
                                        <div className='col-md-3'>
                                            <CustomInput
                                                label="Date of Requisition"
                                                require={true}
                                                type="date"
                                                placeholder='Date of Requisition'
                                                value={dateOfRequisition}
                                                readOnly={true}
                                            />
                                        </div>
                                        <div className='col-md-3'>
                                            <Customselect
                                                label="Department"
                                                require={true}
                                                readOnly={true}
                                                value={selectedDepartment}
                                                onChange={(e) => {
                                                    setSelectedDepartment(e.target.value);
                                                }}
                                                optionview={departmentList?.map((option) => {
                                                    return (
                                                        <option value={option?.department_id}>{option?.department_name}</option>
                                                    );
                                                })}
                                            />
                                        </div>
                                        <div className='col-md-3'>
                                            <Customselect
                                                label="Designation"
                                                require={true}
                                                onChange={(e) => {
                                                    setDesignation(e.target.value);
                                                }}
                                                optionview={designationList?.map((option) => {
                                                    return (
                                                        <option value={option?.designation_id}>{option?.designation_name}</option>
                                                    );
                                                })}
                                            />
                                        </div>
                                    </div>
                                </>}
                        />
                    </div>

                    {/* <div className='col-md-12'>
                        <CustomCard
                            cardtitle={'Person'}
                            cardboldtitle={'Specification'}
                            cardbody={
                                <div className='row'>
                                    <div className='col-md-3'>
                                        <CustomInput
                                            label="Age"
                                            placeholder="Age"
                                            require={true}
                                            value={age}
                                            onChange={(e) => setAge(e.target.value)}
                                        />
                                    </div>
                                    <div className='col-md-3'>
                                        <Customselect
                                            label="Qualification"
                                            placeholder="Qualification"
                                            require={true}
                                            value={qualification}
                                            onChange={(e) => setQualification(e.target.value)}
                                            optionview={educationList?.map((option) => {
                                                return (
                                                    <option value={option?.value}>{option?.label}</option>
                                                );
                                            })}
                                        />
                                    </div>
                                   {showSubQualification && <div className='col-md-3'>
                                        <CustomInput
                                            label="Sub Qualification"
                                            placeholder="Sub Qualification"
                                            require={true}
                                            value={subQualification}
                                            onChange={(e) => setSubQualification(e.target.value)}
                                        />
                                    </div>}
                                    <div className='col-md-3'>
                                        <Customselect
                                            label="Experience"
                                            placeholder="Experience"
                                            require={true}
                                            value={experience}
                                            onChange={(e) => setExperience(e.target.value)}
                                            // optionview={expDropDown?.map((option) => {
                                            //     return (
                                            //         <option value={option?.value}>{option?.label}</option>
                                            //     );
                                            // })}
                                            optionview={Array.from({ length: 49 }, (_, index) => {
                                                const years = index + 1;
                                                const label = years === 1 ? `${years} year` : `${years}-${years + 1} years`;
                                                return (
                                                  <option key={index} value={label}>
                                                    {index === 0 ? `0-12 months` : label}
                                                  </option>
                                                );
                                              })}
                                        />
                                    </div>
                                </div>
                            }
                        />
                    </div> */}


                    <div className='col-md-12'>
                        <CustomCard
                            cardtitle={'New'}
                            cardboldtitle={'Position Details'}
                            cardbody={
                                <div className='row'>
                                    <div className='col-md-3'>
                                        <Customselect
                                            label="Type of Position"
                                            require={true}
                                            onChange={(e) => {
                                                setSelectedTypeOfPosition(e.target.value);
                                            }}
                                            optionview={typeOfPosition?.map((option) => {
                                                return (
                                                    <option value={option.value}>{option.label}</option>
                                                );
                                            })}
                                        />
                                    </div>
                                    {isSelectedTwo && <div className='col-md-3'>
                                        <CustomInput
                                            label="Number of Positions"
                                            placeholder="Number of Positions"
                                            require={true}
                                            value={numOfPosition}
                                            onChange={(e) => setNumOfPosition(e.target.value)}
                                        />
                                    </div>}
                                    {
                                        isSelectedReplacement &&
                                        <>
                                            <div className='col-md-3 mt-2'>
                                                {/* <Customselect
                                                    label="Name"
                                                    placeholder="Name"
                                                    require={true}
                                                    value={positionName}
                                                    onChange={handlePosition}
                                                    optionview={empList?.map((option) => {
                                                        return (
                                                            <option value={option?.emp_code}>{option?.name}</option>
                                                        );
                                                    })}
                                                /> */}
                                                <label htmlFor="position-select">Name</label>
                                                <Select
                                                    label="Name"
                                                    value={positionName}
                                                    onChange={handlePosition}
                                                    options={empList}
                                                    isSearchable={true}
                                                    placeholder="Name"
                                                    onMenuOpen={handleMenuOpen}

                                                />
                                            </div>
                                            <div className='col-md-3'>
                                                <CustomInput
                                                    label="Employee Code"
                                                    placeholder="Employee Code"
                                                    require={true}
                                                    value={empId}
                                                    // onChange={(e) => setEmpId(e.target.value)}
                                                    readOnly={true}
                                                />
                                            </div>
                                            <div className='col-md-3'>
                                                <CustomInput
                                                    label="Current CTC"
                                                    placeholder="Current CTC"
                                                    // require={true}
                                                    value={currentCtc}
                                                    onChange={(e) => setCurrentCtc(e.target.value)}
                                                />
                                            </div>
                                        </>
                                    }
                                </div>
                            }
                        />
                    </div>

                    <div className='col-md-12'>
                        <CustomCard
                            cardtitle={'Manpower'}
                            cardboldtitle={'Details'}
                            cardbody={
                                <div className='row'>
                                    <div className='col-md-3'>
                                        <CustomInput
                                            label="Existing Manpower"
                                            placeholder="Existing Manpower"
                                            require={true}
                                            value={existingManPower}
                                            onChange={(e) => setExistingManPower(e.target.value)}
                                        />
                                    </div>
                                    <div className='col-md-3'>
                                        <Customselect
                                            label="CTC Range"
                                            require={true}
                                            onChange={(e) => {
                                                setSelectedCtcRange(e.target.value);
                                            }}
                                            optionview={ctcRangeList?.map((option) => {
                                                return (
                                                    <option value={option?.value}>{option?.label}</option>
                                                );
                                            })}
                                        />
                                    </div>
                                    {showOther &&
                                        <div className='col-md-3'>
                                            <CustomInput
                                                label="Other"
                                                placeholder="Other"
                                                require={true}
                                                value={other}
                                                onChange={(e) => setOther(e.target.value)}
                                            />
                                        </div>
                                    }

                                    <div className='col-md-11'>
                                        <p>Justification For New Position  <span style={{ color: "red" }}>*</span></p>
                                        <textarea
                                            cols="10"
                                            className="form-control p-3"
                                            type="text"
                                            placeholder="Justification For New Position"
                                            value={justification}
                                            onChange={(e) => setJustification(e.target.value)}
                                        />
                                    </div>
                                </div>
                            }
                        />
                    </div>

                    <div className='col-md-12'>
                        <CustomCard
                            cardtitle={'Job '}
                            cardboldtitle={'Description'}
                            cardbody={
                                <div className='row'>
                                    <div className='col-md-11'>
                                        <CustomInput
                                            label='Job Title'
                                            placeholder='Job Title'
                                            value={jobTitle}
                                            onChange={(e) => setJobTitle(e.target.value)}
                                        />
                                    </div>
                                    <div className='col-md-11'>
                                        <p>About the Role  <span style={{ color: "red" }}>*</span></p>
                                        <textarea
                                            cols="10"
                                            className="form-control p-3"
                                            type="text"
                                            placeholder="About the Role"
                                            value={aboutRole}
                                            onChange={(e) => setAboutRole(e.target.value)}
                                        />
                                    </div>
                                    <div className='col-md-11 mt-3'>
                                        <p>Key Responsibilities  <span style={{ color: "red" }}>*</span></p>
                                        <textarea
                                            cols="10"
                                            className="form-control p-3"
                                            type="text"
                                            placeholder="Key Responsibilities"
                                            value={keyResponsibilities}
                                            onChange={(e) => setKeyResponsibility(e.target.value)}
                                        />
                                    </div>
                                    <div className='col-md-3'>
                                        <Customselect
                                            label='Years of experience'
                                            placeholder="Years of experience"
                                            require={true}
                                            value={yearOfExperience}
                                            onChange={(e) => setYearOfExperience(e.target.value)}
                                            optionview={Array.from({ length: 49 }, (_, index) => {
                                                const years = index + 1;
                                                const label = years === 1 ? `${years} year` : `${years}-${years + 1} years`;
                                                return (
                                                    <option key={index} value={label}>
                                                        {index === 0 ? `0-12 months` : label}
                                                    </option>
                                                );
                                            })}
                                        />
                                    </div>
                                    <div className='col-md-11'>
                                        <CustomInput
                                            label='Education Qualification'
                                            placeholder="Education Qualification"
                                            require={true}
                                            value={educationQualification}
                                            onChange={(e) => setEducationQualification(e.target.value)}
                                        />
                                    </div>
                                </div>
                            }
                        />
                    </div>

                    <div className='col-md-12 text-end'>
                        <CustomButton children={"Submit"} style={"btn--approved--solid"} onClick={handleCreateRequisitionApi} />
                    </div>

                </div>

                <SumbitModal
                    show={showSubmitModal}
                    hide={() => {
                        setShowSubmitModal(false)
                        navigate('/manpower-requisition-dashboard')
                        sessionStorage.removeItem('currentManpowerPage');
                        sessionStorage.removeItem('pageManpowerLimit')
                    }}

                />
            </div>
        </div>
    )
}

export default AddNewRequisition
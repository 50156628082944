import React, { useState, useEffect } from 'react'
import Header from '../../../component/Header'
import CustomCard from '../../../component/CustomeCard'
import CustomButton from '../../../component/Button/Button'
import CustomInput from '../../../component/CustomInput'
import Customselect from '../../../component/CustomSelect'
import './style.scss'
import { useLocation, useNavigate } from "react-router-dom"
import SubmitRequisitionModal from '../../../component/HrNewModuleComponent/Modal/SubmitRequisitionModal'
import { useStores } from '../../../stores'
import notify from '../../../shared/notify'
import Loader from '../../../component/helper/Loader/loader'
import FormatDate from '../../../component/helper/formatDate/formateDate'
import ReactExport from "react-export-excel";
import Select from "react-select";



const ExcelFile = ReactExport.ExcelFile;
const ExcelSheet = ReactExport.ExcelFile.ExcelSheet;
const ExcelColumn = ReactExport.ExcelFile.ExcelColumn;

const EditManPowerRequisition = () => {
    const { crmStore } = useStores()
    const { mainStore } = useStores()
    const { state } = useLocation();
    const navigate = useNavigate()
    const [isLoading, setIsLoading] = useState(false)
    const [businessList, setBusinessList] = useState([])
    const [selectedBusinessList, setSelectedBusinessList] = useState(state?.item?.business?.business_id)
    const [companyNameList, setCompanyNameList] = useState([])
    const [selectedCompanyName, setSelectedCompanyName] = useState(state?.item?.company?.entity_code)
    const [plantList, setPlantList] = useState([])
    const [selectedPlant, setSelectedPlant] = useState(state?.item?.company_plants?.plant_id)
    const [locationList, setLocationList] = useState([])
    const [locationName, setLocationName] = useState(state?.item?.plant_location)
    const [dateOfRequisition, setDateOfRequisition] = useState(state?.item?.date_of_requisition?.split("T")[0])
    const [departmentList, setDeparmentList] = useState([])
    const [selectedDepartment, setSelectedDepartment] = useState(state?.item?.departments?.department_id)
    const [designationList, setDesignationList] = useState([])
    const [designation, setDesignation] = useState(state?.item?.designation?.designation_id)
    const [age, setAge] = useState(state?.item?.person_age)
    const [qualification, setQualification] = useState(state?.item?.person_qualification)
    const [experience, setExperience] = useState(state?.item?.person_experience)
    const [numOfPosition, setNumOfPosition] = useState(state?.item?.number_of_new_position)
    const [positionName, setPositionName] = useState(state?.item?.employee_name)
    const [empId, setEmpId] = useState(state?.item?.employee_id)
    const [currentCtc, setCurrentCtc] = useState(state?.item?.current_ctc)
    const [existingManPower, setExistingManPower] = useState(state?.item?.existing_manpower)
    const [selectedCtcRange, setSelectedCtcRange] = useState(state?.item?.budgeted_manpower)
    const [justification, setJustification] = useState(state?.item?.justification)
    const [jobTitle, setJobTitle] = useState(state?.item?.job_title)
    const [aboutRole, setAboutRole] = useState(state?.item?.about_role)
    const [keyResponsibilities, setKeyResponsibility] = useState(state?.item?.key_responsibilites)
    const [yearOfExperience, setYearOfExperience] = useState(state?.item?.years_of_experience)
    const [educationQualification, setEducationQualification] = useState(state?.item?.education_qualification)
    const [showSubmitModal, setShowSubmitNodal] = useState(false)
    const [selectedTypeOfPosition, setSelectedTypeOfPosition] = useState(state?.item?.position_type)
    const [isSelectedTwo, setIsSelectedTwo] = useState(false)
    const [isSelectedReplacement, setIsSelectedReplacement] = useState(false)
    const [others, setOthers] = useState(false)
    const [other, setOther] = useState(state?.item?.budgeted_manpower_others)
    const [subQualification, setSubQualification] = useState(state?.item?.qualification_subcategory)
    const [auditTrail, setAuditTrail] = useState([])
    const [ctcRangeList, setCtcRangeList] = useState([
        { value: "1-2 LPA", label: "1-2 LPA" },
        { value: "2-3 LPA", label: "2-3 LPA" },
        { value: "3-4 LPA", label: "3-4 LPA" },
        { value: "5-6 LPA", label: "5-6 LPA" },
        { value: "Others", label: "Others" },
    ])

    let educationList = [
        { value: "Primary education", label: "Primary education" },
        { value: "Secondary education or high school", label: "Secondary education or high school" },
        { value: "Diploma/Certificate", label: "Diploma/Certificate" },
        { value: "Vocational qualification", label: "Vocational qualification" },
        { value: "Bachelor's degree", label: "Bachelor's degree" },
        { value: "Master's degree", label: "Master's degree" },
        { value: "Doctorate or higher", label: "Doctorate or higher" },
        { value: "GED", label: "GED" },
    ]

    const [showAll, setShowAll] = useState(false);
    const [empList, setEmpList] = useState([])


    const emp = async () => {
        await crmStore.getEmployeeByNusiness(state?.item?.business?.business_id, (status, res) => {
            if (res?.meta?.status === "success") {
                // let data = [];
                // if (res.data.users) {
                //     res.data.users.forEach(user => {
                //         let obj = {
                //             empCode: user.emp_code,
                //             empName: user.name
                //         }
                //         data.push(obj);
                //     });
                // }
                // setEmpList(data)
                let data = [...res?.data?.users]
                const selectOptions = data.map((option) => ({
                    value: option.emp_code,
                    label: option.name,
                }));
                setEmpList(selectOptions);
                let finalres = selectOptions.find((ele) => ele.label == positionName)
                setPositionName(finalres)

            } else {
                notify(res?.meta?.message, "danger")
            }
        }, (error) => {
            notify(error?.response?.meta?.message, "danger")
        })
    }

    const handlePosition = (event) => {
        let data = event;
        setPositionName(data)
        setEmpId(data?.value)
    }

    const handleMenuOpen = () => {
        setPositionName("")
    };


    const toggleVisibility = () => {
        setShowAll(prevState => !prevState);
    };

    const dataToDisplay = showAll ? auditTrail : [auditTrail[auditTrail.length - 1]]



    useEffect(() => {
        fetchAuditTrail()
        emp
            ()
    }, [])

    const fetchAuditTrail = async () => {
        await crmStore.auditTailManpower(state?.item?.requisition_id, (status, res) => {
            if (res?.meta?.status == "success") {
                setAuditTrail(res?.data)
            } else {
                console.log(res?.meta?.message, "error");

            }
        }, (error) => {
            notify(error?.response?.meta?.message, "danger")
        })
    }



    const getFormattedDate = (dateString) => {
        const date = new Date(dateString);
        const year = date.getFullYear();
        const month = (date.getMonth() + 1).toString().padStart(2, "0");
        const day = date.getDate().toString().padStart(2, "0");
        return `${year}-${month}-${day}`;
    };

    const [typeOfPosition] = useState([
        { value: 'New pos within org chart', label: 'New pos within org chart' },
        { value: 'New pos outside org chart', label: 'New pos outside org chart' },
        { value: 'Replacement', label: 'Replacement' },
    ])

    useEffect(() => {
        if (selectedCtcRange === "Others") {
            setOthers(true)
        } else {
            setOthers(false)
        }
    }, [selectedCtcRange])


    useEffect(() => {
        if (selectedTypeOfPosition === "New pos within org chart" || selectedTypeOfPosition === "New pos outside org chart") {
            setIsSelectedTwo(true)
            setIsSelectedReplacement(false)
        }
        if (selectedTypeOfPosition === "Replacement") {
            setIsSelectedReplacement(true)
            setIsSelectedTwo(false)
        }

    }, [selectedTypeOfPosition])




    useEffect(() => {
        handleBusinessDropDown()
        handleDepartment()
        handleDesignation()
        handleCompanyName()
    }, [])



    const handleBusinessDropDown = async () => {
        await crmStore.getAllBusinessList((status, res) => {
            if (res?.meta?.status === "success") {
                setBusinessList(res?.data)
            } else {
                console.log(res?.data?.meta?.message, "businessList")
            }
        })
    }

    const handleCompanyName = async () => {
        await mainStore.getEntity((status, res) => {
            if (res?.meta?.status === "success") {
                setCompanyNameList(res?.data?.entitites)
            } else {
                console.log(res?.data?.meta?.message, "businessList")
            }
        })
    }

    const handlePlantName = async (id) => {
        let payload = {
            company_id: id
        }
        await crmStore.getAllCompanyPlant(payload, (res) => {
            if (res?.data?.meta?.status === "success") {
                setPlantList(res?.data?.data)
            } else {
                console.log(res?.data?.meta?.message, "businessList")
            }
        })
    }

    const handlePlantLocation = async (id) => {
        let payload = {
            company_id: selectedCompanyName,
            plant_id: id,
        }
        await crmStore.getAllCompanyPlantLocation(payload, (res) => {
            if (res?.data?.meta?.status === "success") {
                let location = res?.data?.data.map((item) => {
                    return item?.location
                })
                setLocationName(location)
            } else {
                console.log(res?.data?.meta?.message, "selectedCompanyName")
            }
        })
    }
    const handleCompanyNameChange = (e) => {
        setSelectedCompanyName(e.target.value)
        handlePlantName(e.target.value)
        setLocationName("")
    }

    const handlePlantNameChange = (e) => {
        setSelectedPlant(e.target.value)
        handlePlantLocation(e.target.value)
    }




    const handleDepartment = async () => {
        await crmStore.getDeparments((status, res) => {
            if (res?.meta?.status === "success") {
                setDeparmentList(res?.data)
            } else {
                console.log(res?.data?.meta?.message, "setDeparmentList")
            }
        })
    }
    const handleDesignation = async () => {
        await crmStore.getDesignations((status, res) => {
            if (res?.meta?.status === "success") {
                setDesignationList(res?.data)
            } else {
                console.log(res?.data?.meta?.message, "setDesignationList")
            }
        })
    }

    useEffect(() => {
        handlePlantName(selectedCompanyName)
        handlePlantLocation(selectedPlant)
    }, [])


    const updateManRequistion = async () => {
        let payload = {
            business_id: selectedBusinessList !== undefined || selectedBusinessList !== " " || selectedBusinessList !== null ? selectedBusinessList : undefined,
            date_of_requisition: dateOfRequisition !== undefined || dateOfRequisition !== "" || dateOfRequisition !== null ? dateOfRequisition : undefined,
            company_id: selectedCompanyName !== undefined || selectedCompanyName !== "" || selectedCompanyName !== null ? selectedCompanyName : undefined,
            plant_id: selectedPlant !== undefined || selectedPlant !== "" || selectedPlant !== null ? selectedPlant : undefined,
            plant_location: locationName !== undefined || locationName !== "" || locationName !== null ? locationName[0] : undefined,
            department_id: selectedDepartment !== undefined || selectedDepartment !== "" || selectedDepartment !== null ? selectedDepartment : undefined,
            designation_id: designation !== undefined || designation !== null || designation !== "" ? designation : undefined,
            // person_age: age !== undefined || age !== "" || age !== null ? age : undefined,
            // person_qualification: qualification !== "" || qualification !== undefined || qualification !== null ? qualification : undefined,
            // person_experience: experience !== undefined || experience !== "" || experience !== null ? experience : undefined,
            position_type: selectedTypeOfPosition !== undefined || experience !== "" || selectedTypeOfPosition !== null ? selectedTypeOfPosition : undefined,
            existing_manpower: existingManPower !== undefined || existingManPower !== "" || existingManPower !== null ? existingManPower : undefined,
            budgeted_manpower: selectedCtcRange !== undefined || selectedCtcRange !== "" || selectedCtcRange !== null ? selectedCtcRange : undefined,
            justification: justification !== undefined || justification !== "" || justification !== null ? justification : undefined,
            job_title: jobTitle !== undefined !== jobTitle !== "" || jobTitle !== null ? jobTitle : undefined,
            about_role: aboutRole !== undefined !== aboutRole !== "" || aboutRole !== null ? aboutRole : undefined,
            key_responsibilites: keyResponsibilities !== undefined || keyResponsibilities !== "" || keyResponsibilities !== null ? keyResponsibilities : undefined,
            years_of_experience: yearOfExperience !== undefined || yearOfExperience !== "" || yearOfExperience !== null ? yearOfExperience : undefined,
            education_qualification: educationQualification !== undefined || educationQualification !== "" || educationQualification !== null ? educationQualification : undefined,
            ...(positionName !== undefined && positionName !== "NA" && positionName !== "" && positionName !== null && { employee_name: positionName?.label }),
            ...(empId !== undefined && empId !== "NA" && empId !== "" && empId !== null && { employee_id: empId }),
            ...(currentCtc !== undefined && currentCtc !== null && currentCtc !== "NA" && currentCtc !== "" && { current_ctc: currentCtc }),
            ...(numOfPosition !== undefined && numOfPosition !== "NA" && numOfPosition !== "" && numOfPosition !== null && { number_of_new_position: numOfPosition }),
            ...(other !== undefined && other !== "NA" && other !== "" && other !== null && { budgeted_manpower_others: other }),
            // ...(subQualification !== undefined && subQualification !== "NA" && subQualification !== "" && subQualification !== null && { qualification_subcategory: subQualification }),
        }

        callUpdateApi(payload)
    }

    const callUpdateApi = async (payload) => {
        setIsLoading(true)
        await crmStore.updateManRequistion(state?.item?.requisition_id, payload, (res) => {
            if (res?.data?.meta?.status === "success") {
                setShowSubmitNodal(true)
                setIsLoading(false)
            } else {
                notify(res?.data?.meta?.message, "danger")
                setIsLoading(false)
            }
        }, (error) => {
            notify(error?.response?.data?.meta?.message, "danger")
            setIsLoading(false)
        })
    }


    return (
        <div className="main-container">
            {isLoading && <Loader />}
            <div className="right-view">
                <Header title={" ManPower Requisition Form,"} />
                <div className='row'>
                    <div className='col-md-12'>
                        <CustomCard
                            cardtitle={"Business"}
                            cardboldtitle={"Details"}
                            cardbody={
                                <>
                                    <img src={require("../../../assets/images/left-arrow.png")} className="back-btn-dashboard" onClick={() => navigate(-1)} />
                                    <div className='row'>
                                        <div className='col-md-3'>
                                            <Customselect
                                                label="Select Business Type"
                                                require={true}
                                                readOnly={true}
                                                value={selectedBusinessList}
                                                onChange={(e) => {
                                                    setSelectedBusinessList(e.target.value);
                                                }}
                                                optionview={businessList?.map((item) => {
                                                    return (
                                                        <option value={item?.business_id}>{item?.business_name}</option>
                                                    );
                                                })}
                                            />
                                        </div>
                                        <div className='col-md-3'>
                                            <Customselect
                                                label="Select Company Name"
                                                require={true}
                                                value={selectedCompanyName}
                                                onChange={handleCompanyNameChange}
                                                optionview={companyNameList?.map((item) => {
                                                    return (
                                                        <option value={item?.entity_code}>{item?.name}</option>
                                                    );
                                                })}
                                            />
                                        </div>
                                        <div className='col-md-3'>
                                            <Customselect
                                                label="Select Plant"
                                                value={selectedPlant}
                                                onChange={handlePlantNameChange}
                                                optionview={plantList?.map((item) => {
                                                    return (
                                                        <option value={item?.plant_id}>{item?.plant_name}</option>
                                                    );
                                                })}

                                            />
                                        </div>
                                        <div className='col-md-3'>
                                            <CustomInput
                                                label="Select Location"
                                                readOnly={true}
                                                value={locationName[0]}
                                                placeholder="Select Location"
                                            />
                                        </div>
                                        <div className='col-md-3'>
                                            <CustomInput
                                                label="Date of Requisition"
                                                require={true}
                                                type="date"
                                                placeholder='Date of Requisition'
                                                value={dateOfRequisition && getFormattedDate(dateOfRequisition)}
                                                readOnly={true}
                                            />
                                        </div>
                                        <div className='col-md-3'>
                                            <Customselect
                                                label="Department"
                                                require={true}
                                                readOnly={true}
                                                value={selectedDepartment}
                                                onChange={(e) => {
                                                    setSelectedDepartment(e.target.value);
                                                }}
                                                optionview={departmentList?.map((option) => {
                                                    return (
                                                        <option value={option?.department_id}>{option?.department_name}</option>
                                                    );
                                                })}
                                            />
                                        </div>
                                        <div className='col-md-3'>
                                            <Customselect
                                                label="Designation"
                                                require={true}
                                                value={designation}
                                                onChange={(e) => {
                                                    setDesignation(e.target.value);
                                                }}
                                                optionview={designationList?.map((option) => {
                                                    return (
                                                        <option value={option?.designation_id}>{option?.designation_name}</option>
                                                    );
                                                })}
                                            />
                                        </div>
                                    </div>
                                </>}
                        />
                    </div>

                    {/* <div className='col-md-12'>
                        <CustomCard
                            cardtitle={'Person'}
                            cardboldtitle={'Specification'}
                            cardbody={
                                <div className='row'>
                                    <div className='col-md-3'>
                                        <CustomInput
                                            label="Age"
                                            placeholder="Age"
                                            require={true}
                                            value={age}
                                            onChange={(e) => setAge(e.target.value)}
                                        />
                                    </div>
                                    <div className='col-md-3'>
                                        <Customselect
                                            label="Qualification"
                                            placeholder="Qualification"
                                            require={true}
                                            value={qualification}
                                            onChange={(e) => setQualification(e.target.value)}
                                            optionview={educationList?.map((option) => {
                                                return (
                                                    <option value={option?.value}>{option?.label}</option>
                                                );
                                            })}
                                        />
                                    </div>
                                    <div className='col-md-3'>
                                        <CustomInput
                                            label="Sub Qualification"
                                            placeholder="Sub Qualification"
                                            require={true}
                                            // readOnly={true}
                                            value={subQualification}
                                            onChange={(e) => setSubQualification(e.target.value)}
                                        />
                                    </div>
                                    <div className='col-md-3'>
                                        <Customselect
                                            label="Experience"
                                            placeholder="Experience"
                                            require={true}
                                            value={experience}
                                            onChange={(e) => setExperience(e.target.value)}
                                            optionview={Array.from({ length: 49 }, (_, index) => {
                                                const years = index + 1;
                                                const label = years === 1 ? `${years} year` : `${years}-${years + 1} years`;
                                                return (
                                                  <option key={index} value={label}>
                                                    {index === 0 ? `0-12 months` : label}
                                                  </option>
                                                );
                                              })}
                                        />
                                    </div>
                                </div>
                            }
                        />
                    </div> */}


                    <div className='col-md-12'>
                        <CustomCard
                            cardtitle={'New'}
                            cardboldtitle={'Position Details'}
                            cardbody={
                                <div className='row'>
                                    <div className='col-md-3'>
                                        <Customselect
                                            label="Type of Position"
                                            require={true}
                                            value={selectedTypeOfPosition}
                                            onChange={(e) => {
                                                setSelectedTypeOfPosition(e.target.value);
                                            }}
                                            optionview={typeOfPosition?.map((option) => {
                                                return (
                                                    <option value={option.value}>{option.label}</option>
                                                );
                                            })}
                                        />
                                    </div>
                                    {isSelectedTwo && <div className='col-md-3'>
                                        <CustomInput
                                            label="Number of Positions"
                                            placeholder="Number of Positions"
                                            require={true}
                                            value={numOfPosition}
                                            onChange={(e) => setNumOfPosition(e.target.value)}
                                        />
                                    </div>}
                                    {
                                        isSelectedReplacement &&
                                        <>
                                            <div className='col-md-3 mt-2'>
                                                {/* <CustomInput
                                                    label="Name"
                                                    placeholder="Name"
                                                    require={true}
                                                    value={positionName}
                                                    onChange={(e) => setPositionName(e.target.value)}
                                                /> */}
                                                <label htmlFor="position-select">Name</label>
                                                <Select
                                                    label="Name"
                                                    value={positionName}
                                                    onChange={handlePosition}
                                                    options={empList}
                                                    isSearchable={true}
                                                    placeholder="Name"
                                                    onMenuOpen={handleMenuOpen}

                                                />
                                            </div>
                                            <div className='col-md-3'>
                                                <CustomInput
                                                    label="Employee ID"
                                                    placeholder="Employee ID"
                                                    require={true}
                                                    value={empId}
                                                    readOnly={true}
                                                    onChange={(e) => setEmpId(e.target.value)}
                                                />
                                            </div>
                                            <div className='col-md-3'>
                                                <CustomInput
                                                    label="Current CTC"
                                                    placeholder="Current CTC"
                                                    // require={true}
                                                    value={currentCtc}
                                                    onChange={(e) => setCurrentCtc(e.target.value)}
                                                />
                                            </div>
                                        </>
                                    }
                                </div>
                            }
                        />
                    </div>

                    <div className='col-md-12'>
                        <CustomCard
                            cardtitle={'Manpower'}
                            cardboldtitle={'Details'}
                            cardbody={
                                <div className='row'>
                                    <div className='col-md-3'>
                                        <CustomInput
                                            label="Existing Manpower"
                                            placeholder="Existing Manpower"
                                            require={true}
                                            value={existingManPower}
                                            onChange={(e) => setExistingManPower(e.target.value)}
                                        />
                                    </div>
                                    <div className='col-md-3'>
                                        <Customselect
                                            label="CTC Range"
                                            require={true}
                                            value={selectedCtcRange}
                                            onChange={(e) => {
                                                setSelectedCtcRange(e.target.value);
                                            }}
                                            optionview={ctcRangeList?.map((option) => {
                                                return (
                                                    <option value={option?.value}>{option?.label}</option>
                                                );
                                            })}
                                        />
                                    </div>
                                    {others && <div className='col-md-3'>
                                        <CustomInput
                                            label="Others"
                                            placeholder="Others"
                                            require={true}
                                            value={other}
                                            onChange={(e) => setOther(e.target.value)}
                                        />
                                    </div>}

                                    <div className='col-md-11'>
                                        <p>Justification For New Position  <span style={{ color: "red" }}>*</span></p>
                                        <textarea
                                            cols="10"
                                            className="form-control p-3"
                                            type="text"
                                            placeholder="Justification For New Position"
                                            value={justification}
                                            onChange={(e) => setJustification(e.target.value)}
                                        />
                                    </div>
                                </div>
                            }
                        />
                    </div>

                    <div className='col-md-12'>
                        <CustomCard
                            cardtitle={'Job '}
                            cardboldtitle={'Description'}
                            cardbody={
                                <div className='row'>
                                    <div className='col-md-11'>
                                        <CustomInput
                                            label='Job Title'
                                            placeholder='Job Title'
                                            value={jobTitle}
                                            onChange={(e) => setJobTitle(e.target.value)}
                                        />
                                    </div>
                                    <div className='col-md-11'>
                                        <p>About the Role  <span style={{ color: "red" }}>*</span></p>
                                        <textarea
                                            cols="10"
                                            className="form-control p-3"
                                            type="text"
                                            placeholder="About the Role"
                                            value={aboutRole}
                                            onChange={(e) => setAboutRole(e.target.value)}
                                        />
                                    </div>
                                    <div className='col-md-11 mt-3'>
                                        <p>Key Responsibilities  <span style={{ color: "red" }}>*</span></p>
                                        <textarea
                                            cols="10"
                                            className="form-control p-3"
                                            type="text"
                                            placeholder="Key Responsibilities"
                                            value={keyResponsibilities}
                                            onChange={(e) => setKeyResponsibility(e.target.value)}
                                        />
                                    </div>
                                    {/* <div className='col-md-11 mt-3'>
                                        <CustomInput
                                            label='Years of experience'
                                            placeholder="Years of experience"
                                            require={true}
                                            value={yearOfExperience}
                                            onChange={(e) => setYearOfExperience(e.target.value)}
                                        />
                                    </div> */}
                                    <div className='col-md-3'>
                                        <Customselect
                                            label='Years of experience'
                                            placeholder="Years of experience"
                                            require={true}
                                            value={yearOfExperience}
                                            onChange={(e) => setYearOfExperience(e.target.value)}
                                            optionview={Array.from({ length: 49 }, (_, index) => {
                                                const years = index + 1;
                                                const label = years === 1 ? `${years} year` : `${years}-${years + 1} years`;
                                                return (
                                                    <option key={index} value={label}>
                                                        {index === 0 ? `0-12 months` : label}
                                                    </option>
                                                );
                                            })}
                                        />
                                    </div>
                                    <div className='col-md-11'>
                                        <CustomInput
                                            label='Education Qualification'
                                            placeholder="Education Qualification"
                                            require={true}
                                            value={educationQualification}
                                            onChange={(e) => setEducationQualification(e.target.value)}
                                        />
                                    </div>
                                </div>
                            }
                        />
                    </div>

                    <div className="col-md-12">
                        <CustomCard
                            cardtitle={"Audit"}
                            cardboldtitle={" trail"}
                            toprightview={
                                <>
                                    <CustomButton style={"me-3"} children={'Show All Pipeline'} onClick={toggleVisibility} />
                                    <button className="export-btn">
                                        <ExcelFile element={<div> <img width={20} className="me-2" src={require("../../../assets/images/excel.png")} />Export</div>}>
                                            <ExcelSheet data={auditTrail?.map((item, i) => ({
                                                ...item, index: i + 1, role: item?.role,
                                                created_at: item?.created_at && `${item.created_at?.split("T")[0]} ${item.created_at?.split("T")[1]?.split(".")[0].substring(0, 5)}`,
                                            }))} name="Sheet">
                                                <ExcelColumn label="Status Date & Time" value="created_at" />
                                                <ExcelColumn label="Name" value="userName" />
                                                <ExcelColumn label="Role" value="role" />
                                                <ExcelColumn label="Action Required" value="status" />
                                            </ExcelSheet>
                                        </ExcelFile>
                                    </button>
                                </>
                            }
                            cardbody={
                                <React.Fragment>
                                    <div class="table-responsive">
                                        <table class="table">
                                            <thead class="table-customlight">
                                                <tr>
                                                    <th className="auditTrail">Status Date & Time</th>
                                                    <th className="auditTrail">Name</th>
                                                    <th className="auditTrail">Role</th>
                                                    <th className="auditTrail">Status</th>
                                                    <th className="auditTrail">Comment</th>
                                                </tr>
                                            </thead>
                                            {dataToDisplay?.length > 0 && (
                                                <tbody>
                                                    {dataToDisplay?.map((item, i) => {
                                                        return (
                                                            <tr key={item?.requestNumber}>
                                                                <td scope="row"><FormatDate dateString={item?.created_at} /> </td>
                                                                <td>{item?.userName}</td>
                                                                <td>{item?.role}</td>
                                                                <td className="status-box"><span className="status"></span>{item?.status}</td>
                                                                <td>{item?.comment}</td>
                                                            </tr>)
                                                    })}
                                                </tbody>)}
                                        </table>
                                    </div>
                                </React.Fragment>
                            }
                        />
                    </div>

                    <div className='col-md-12 text-end'>
                        {/* <CustomButton style={"btn--outline me-3"} children={"Back"} onClick={() => navigate("/approver-requisition-dashboard")} /> */}
                        <CustomButton children={"Submit"} style={"btn--approved--solid"} onClick={updateManRequistion} />
                    </div>

                </div>
                < SubmitRequisitionModal
                    show={showSubmitModal}
                    hide={() => {
                        setShowSubmitNodal(false)
                        navigate("/manpower-requisition-dashboard")
                    }}
                />
            </div>
        </div>
    )
}

export default EditManPowerRequisition
import React, { useEffect, useState } from "react";
import Header from "../component/Header";
import RequestTab from "../component/Tabs/requestTab";
import notify from "../shared/notify";
import { useStores } from "../stores";
import BGRequest from "../component/ListingTable/bgListingTable";
import LCRequest from "../component/ListingTable/lcListingTable";
import Loader from "../component/helper/Loader/loader";

const ListingDashboard = () => {
    const { mainStore } = useStores()
    const [isLoading, setIsLoading] = useState(false);
    const [showLcRequest, setShowLcRequest] = useState(false);
    const [showBgRequest, setShowBgRequest] = useState(true);
    const [fetchedBgData, setFetchedBgData] = useState([])
    const [fetchedLcData, setFetchedLcData] = useState([]);
    const [fetchedbBgAllData, setFetchedBgAllData] = useState([]);
    const [fetchedbLcAllData, setFetchedLcAllData] = useState([]);


    const bgRequest = () => {
      fetchBgData()
        setShowBgRequest(true);
        setShowLcRequest(false);
    };
    const lcRequest = () => {
      fetchLcData()
        setShowBgRequest(false);
        setShowLcRequest(true);
    };

    const callBgApi =async(payload) =>{
        setIsLoading(true)
        await mainStore.bgListingData(payload,(response) => {
            if (response?.data?.meta?.status === "success") {
              const filteredData = response?.data?.data?.filteredData?.results
            //   .filter(item => item.isCompleted === true);
              setFetchedBgData(filteredData);
              setFetchedBgAllData(response?.data?.data)
              setIsLoading(false)
            } else {
              notify(response?.data?.meta?.message, "danger");
              setIsLoading(false)
            }
          },
          (error) => {
            notify(error?.response?.data?.meta?.message, "danger");
            setIsLoading(false)
          }
        );
      }

      const fetchBgData = async () => {
        setIsLoading(true)
        let payload = {
          limit: 10,
          filters: {},
          pageNumber: 1,
        };
        callBgApi(payload)
      };


      const callLcApi = async(payload) =>{
        setIsLoading(true)
        await mainStore.LcListingData(payload,(response) => {
            if (response?.data?.meta?.status === "success") {
              const filteredData = response?.data?.data?.filteredData?.results
            //   .filter(item => item.isCompleted === true);
              setFetchedLcData(filteredData);
              setFetchedLcAllData(response?.data?.data)
              setIsLoading(false)
            } else {
              notify(response?.data?.meta?.message, "danger");
              setIsLoading(false)
            }
          },
          (error) => {
            notify(error?.response?.data?.meta?.message, "danger");
            setIsLoading(false)
          }
        );
    
      }

      const fetchLcData = async () => {
        let payload = {
          limit: 10,
          filters: {},
          pageNumber: 1,
        };
        callLcApi(payload)
      };

      useEffect(()=>{
        fetchBgData()
        fetchLcData()
      },[])
  

    return (
        <div className="main-container dashboard-container">
            {isLoading && <Loader />}
            <div className="right-view">
                <Header title={"Listing Dashboard"} />
                <div className="container-fluid px-0">
                    <div className="row">
                        <div className="col-md-12">
                            <RequestTab
                                showLcRequest={showLcRequest}
                                showBgRequest={showBgRequest}
                                lcRequest={lcRequest}
                                bgRequest={bgRequest}
                            />
                        </div>
                        <div className="col-md-12 listing-table">
                            {showBgRequest && (
                                <BGRequest
                                    fetchedBgData={fetchedBgData}
                                    fetchBgAllData={fetchedbBgAllData}
                                    fetchBgData={fetchBgData}
                                    callApi={callBgApi}
                                />
                            )}
                            {showLcRequest && (
                                <LCRequest
                                    fetchedLcData={fetchedLcData}
                                    fetchLcAllData={fetchedbLcAllData}
                                    fetchLcData={fetchLcData}
                                    callLcApi={callLcApi}
                                />
                            )}
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default ListingDashboard
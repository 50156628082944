import React from "react";
import { Chart } from "react-google-charts";



const GraphChart = (props) => {
  const data = props.data.slice(1); 
  const allZero = data.every((item) => item.slice(1).every(value => value === 0));
  
  if (allZero) {
      return <div>No data available to display the chart</div>;
  }

  return (
    <Chart
      chartType="PieChart"
      data={props.data}
      options={props.options}
      width={"100%"}
      height={"320px"}
    />
  );
}

export default GraphChart;

import React, { useState } from "react";
import Modal from "react-bootstrap/Modal";
// import "./style.scss";
import cancellationImage from "../../../assets/images/rejected@2x.png"
import crossImage from "../../../assets/images/close (4).png"
import CustomButton from "../../Button/Button";


const RequisitionRejection = (props) => {

  

    return (
        <Modal show={props?.show} centered className="sucess-content">
            <Modal.Body className="sucess-modal">
                <img src={crossImage} alt="" className="cross-img" onClick={() => props.hide()} />
                <div className="moodal" >
                    <img src={cancellationImage} alt="" className="modal-img" />
                    <h5 className="modal-texts">OOPS!</h5>
                    <h5 className="mt-1 modal-text">You have reject the request</h5>
                    <div className="addName-model mt-3" style={{margin:"auto"}}>
                        <textarea
                            cols="10"
                            className="form-control  p-3"
                            type="text"
                            placeholder="Please add the comment......."
                            value={props?.rejectComment}
                            onChange={(e) => props?.handleRejectComment(e.target.value)}
                        />
                    </div>
                    <CustomButton
                        children={"Submit"}
                        style={"mt-3"}
                        onClick={props?.rejectForm}
                    />
                </div>
            </Modal.Body>
        </Modal>
    );
}
export default RequisitionRejection;

import React, { useState, useEffect } from 'react'
import Header from '../../../component/Header'
import CustomCard from '../../../component/CustomeCard'
import CustomInput from '../../../component/CustomInput'
import './style.scss'
import { useLocation, useNavigate } from "react-router-dom"
import FormatDate from '../../../component/helper/formatDate/formateDate'
import ReactExport from "react-export-excel";
import { useStores } from '../../../stores'
import notify from '../../../shared/notify'
import CustomButton from '../../../component/Button/Button'


const ExcelFile = ReactExport.ExcelFile;
const ExcelSheet = ReactExport.ExcelFile.ExcelSheet;
const ExcelColumn = ReactExport.ExcelFile.ExcelColumn;


const ViewManPowerRequisition = () => {
    const { crmStore } = useStores()
    const { state } = useLocation();
    const navigate = useNavigate()
    const [selectedBusinessList] = useState(state?.item?.business?.business_name)
    const [selectedCompanyName] = useState(state?.item?.company?.name)
    const [selectedPlant] = useState(state?.item?.company_plants?.plant_name)
    const [locationName] = useState(state?.item?.plant_location)
    const [dateOfRequisition] = useState(state?.item?.date_of_requisition?.split("T")[0])
    const [selectedDepartment] = useState(state?.item?.departments?.department_name)
    const [designation] = useState(state?.item?.designation?.designation_name)
    const [age] = useState(state?.item?.person_age)
    const [qualification] = useState(state?.item?.person_qualification)
    const [experience] = useState(state?.item?.person_experience)
    const [isSelectedTypeOfPosition] = useState(state?.item?.position_type)
    const [numOfPosition] = useState(state?.item?.number_of_new_position)
    const [positionName] = useState(state?.item?.employee_name)
    const [empId] = useState(state?.item?.employee_id)
    const [currentCtc] = useState(state?.item?.current_ctc)
    const [existingManPower] = useState(state?.item?.existing_manpower)
    const [selectedCtcRange] = useState(state?.item?.budgeted_manpower)
    const [justification] = useState(state?.item?.justification)
    const [jobTitle] = useState(state?.item?.job_title)
    const [aboutRole] = useState(state?.item?.about_role)
    const [keyResponsibilities] = useState(state?.item?.key_responsibilites)
    const [yearOfExperience] = useState(state?.item?.years_of_experience)
    const [educationQualification] = useState(state?.item?.education_qualification)
    const readOnly = true
    const [showOther, setShowOther] = useState(false)
    const [other, setOther] = useState(state?.item?.budgeted_manpower_others)
    const [auditTrail, setAuditTrail] = useState([])
    const [showAll, setShowAll] = useState(false);
    const[subQualification , setSubQualification] = useState(state?.item?.qualification_subcategory)


    useEffect(() => {
        fetchAuditTrail()
    }, [])

    const fetchAuditTrail = async () => {
        await crmStore.auditTailManpower(state?.item?.requisition_id, (status, res) => {
            if (res?.meta?.status == "success") {
                setAuditTrail(res?.data)
            } else {
                console.log(res?.meta?.message, "error");

            }
        }, (error) => {
            notify(error?.response?.meta?.message, "danger")
        })
    }


    const getFormattedDate = (dateString) => {
        const date = new Date(dateString);
        const year = date.getFullYear();
        const month = (date.getMonth() + 1).toString().padStart(2, "0");
        const day = date.getDate().toString().padStart(2, "0");
        return `${year}-${month}-${day}`;
    };

    useEffect(() => {
        if (selectedCtcRange === "Others") {
            setShowOther(true)
        } else {
            setShowOther(false)
        }
    }, [selectedCtcRange])

    const toggleVisibility = () => {
        setShowAll(prevState => !prevState);
    };

    const dataToDisplay = showAll ? auditTrail : [auditTrail[auditTrail.length - 1]]

    return (
        <div className="main-container">
            <div className="right-view">
                <Header title={" ManPower Requisition Form,"} />
                <div className='row'>
                    <div className='col-md-12'>
                        <CustomCard
                            cardtitle={"Business"}
                            cardboldtitle={"Details"}
                            cardbody={
                                <>
                             <img src={require("../../../assets/images/left-arrow.png")} className="back-btn-dashboard" onClick={() => navigate(-1)} />
                                <div className='row'>
                                    <div className='col-md-3'>
                                        <CustomInput
                                            label="Select Business Type"
                                            placeholder="Select Business Type"
                                            require={true}
                                            readOnly={true}
                                            value={selectedBusinessList}
                                        />
                                    </div>
                                    <div className='col-md-3'>
                                        <CustomInput
                                            label="Select Company Name"
                                            placeholder="Select Company Name"
                                            require={true}
                                            readOnly={true}
                                            value={selectedCompanyName}
                                        />
                                    </div>
                                    <div className='col-md-3'>
                                        <CustomInput
                                            label="Select Plant"
                                            placeholder="Select Plant"
                                            require={true}
                                            readOnly={true}
                                            value={selectedPlant}

                                        />
                                    </div>
                                    <div className='col-md-3'>
                                        <CustomInput
                                            label="Select Location"
                                            placeholder="Select Location"
                                            require={true}
                                            readOnly={true}
                                            value={locationName}
                                        />
                                    </div>
                                    <div className='col-md-3'>
                                        <CustomInput
                                            label="Date of Requisition"
                                            require={true}
                                            type="date"
                                            placeholder='Date of Requisition'
                                            value={dateOfRequisition && getFormattedDate(dateOfRequisition)}
                                            readOnly={true}
                                        />
                                    </div>
                                    <div className='col-md-3'>
                                        <CustomInput
                                            label="Department"
                                            placeholder="Department"
                                            require={true}
                                            readOnly={true}
                                            value={selectedDepartment}
                                        />
                                    </div>
                                    <div className='col-md-3'>
                                        <CustomInput
                                            label="Designation"
                                            placeholder="Designation"
                                            require={true}
                                            readOnly={true}
                                            value={designation}
                                        />
                                    </div>
                                </div>
                                </> }
                        />
                    </div>

                    {/* <div className='col-md-12'>
                        <CustomCard
                            cardtitle={'Person'}
                            cardboldtitle={'Specification'}
                            cardbody={
                                <div className='row'>
                                    <div className='col-md-3'>
                                        <CustomInput
                                            label="Age"
                                            placeholder="Age"
                                            require={true}
                                            readOnly={true}
                                            value={age}
                                        />
                                    </div>
                                    <div className='col-md-3'>
                                        <CustomInput
                                            label="Qualification"
                                            placeholder="Qualification"
                                            require={true}
                                            readOnly={true}
                                            value={qualification}
                                        />
                                    </div>
                                     <div className='col-md-3'>
                                        <CustomInput
                                            label="Sub Qualification"
                                            placeholder="Sub Qualification"
                                            require={true}
                                            readOnly={true}
                                            value={subQualification}
                                            onChange={(e) => setSubQualification(e.target.value)}
                                        />
                                    </div>
                                    <div className='col-md-3'>
                                        <CustomInput
                                            label="Experience"
                                            placeholder="Experience"
                                            require={true}
                                            readOnly={true}
                                            value={experience}
                                        />
                                    </div>
                                </div>
                            }
                        />
                    </div> */}


                    <div className='col-md-12'>
                        <CustomCard
                            cardtitle={'New'}
                            cardboldtitle={'Position Details'}
                            cardbody={
                                <div className='row'>
                                    <div className='col-md-3'>
                                        <CustomInput
                                            label="Type of Position"
                                            require={true}
                                            readOnly={true}
                                            value={isSelectedTypeOfPosition}
                                        />
                                    </div>
                                    {isSelectedTypeOfPosition !== "Replacement" && <div className='col-md-3'>
                                        <CustomInput
                                            label="Number of Positions"
                                            placeholder="Number of Positions"
                                            require={true}
                                            readOnly={true}
                                            value={numOfPosition}
                                        />
                                    </div>}
                                    {isSelectedTypeOfPosition === "Replacement" &&
                                        <>
                                            <div className='col-md-3'>
                                                <CustomInput
                                                    label="Name"
                                                    placeholder="Name"
                                                    require={true}
                                                    readOnly={true}
                                                    value={positionName}
                                                />
                                            </div>
                                            <div className='col-md-3'>
                                                <CustomInput
                                                    label="Employee ID"
                                                    placeholder="Employee ID"
                                                    require={true}
                                                    readOnly={true}
                                                    value={empId}
                                                />
                                            </div>
                                            <div className='col-md-3'>
                                                <CustomInput
                                                    label="Current CTC"
                                                    placeholder="Current CTC"
                                                    // require={true}
                                                    readOnly={true}
                                                    value={currentCtc}
                                                />
                                            </div>
                                        </>
                                    }
                                </div>
                            }
                        />
                    </div>

                    <div className='col-md-12'>
                        <CustomCard
                            cardtitle={'Manpower'}
                            cardboldtitle={'Details'}
                            cardbody={
                                <div className='row'>
                                    <div className='col-md-3'>
                                        <CustomInput
                                            label="Existing Manpower"
                                            placeholder="Existing Manpower"
                                            require={true}
                                            readOnly={true}
                                            value={existingManPower}
                                        />
                                    </div>
                                    <div className='col-md-3'>
                                        <CustomInput
                                            label="CTC Range"
                                            require={true}
                                            readOnly={true}
                                            placeholder="CTC Range"
                                            value={selectedCtcRange}
                                        />
                                    </div>

                                    {showOther && <div className='col-md-3'>
                                        <CustomInput
                                            label="Others"
                                            placeholder="Others"
                                            require={true}
                                            readOnly={true}
                                            value={other}
                                        />
                                    </div>}

                                    <div className='col-md-11'>
                                        <p>Justification For New Position  <span style={{ color: "red" }}>*</span></p>
                                        <textarea
                                            cols="10"
                                            className="form-control p-3"
                                            type="text"
                                            placeholder="Justification For New Position"
                                            readOnly
                                            style={{ backgroundColor: readOnly ? '#e3e3e3' : 'white' }}
                                            value={justification}
                                        />
                                    </div>
                                </div>
                            }
                        />
                    </div>

                    <div className='col-md-12'>
                        <CustomCard
                            cardtitle={'Job '}
                            cardboldtitle={'Description'}
                            cardbody={
                                <div className='row'>
                                    <div className='col-md-11'>
                                        <CustomInput
                                            label='Job Title'
                                            placeholder='Job Title'
                                            readOnly={true}
                                            value={jobTitle}
                                        />
                                    </div>
                                    <div className='col-md-11'>
                                        <p>About the Role  <span style={{ color: "red" }}>*</span></p>
                                        <textarea
                                            cols="10"
                                            className="form-control p-3"
                                            type="text"
                                            placeholder="About the Role"
                                            style={{ backgroundColor: readOnly ? '#e3e3e3' : 'white' }}
                                            value={aboutRole}
                                        />
                                    </div>
                                    <div className='col-md-11 mt-3'>
                                        <p>Key Responsibilities  <span style={{ color: "red" }}>*</span></p>
                                        <textarea
                                            cols="10"
                                            className="form-control p-3"
                                            type="text"
                                            placeholder="Key Responsibilities"
                                            style={{ backgroundColor: readOnly ? '#e3e3e3' : 'white' }}
                                            value={keyResponsibilities}
                                        />
                                    </div>
                                    <div className='col-md-11 mt-3'>
                                        <CustomInput
                                            label='Years of experience'
                                            placeholder="Years of experience"
                                            require={true}
                                            readOnly={true}
                                            value={yearOfExperience}
                                        />
                                    </div>
                                    <div className='col-md-11'>
                                        <CustomInput
                                            label='Education Qualification'
                                            placeholder="Education Qualification"
                                            require={true}
                                            readOnly={true}
                                            value={educationQualification}
                                        />
                                    </div>
                                </div>
                            }
                        />
                    </div>
                    <div className="col-md-12">
                        <CustomCard
                            cardtitle={"Audit"}
                            cardboldtitle={" trail"}
                            toprightview={
                                <><CustomButton style={"me-3"} children={ 'Show All Pipeline'} onClick={toggleVisibility} />
                                    <button className="export-btn">
                                        <ExcelFile element={<div> <img width={20} className="me-2" src={require("../../../assets/images/excel.png")} />Export</div>}>
                                            <ExcelSheet data={auditTrail?.map((item, i) => ({
                                                ...item, index: i + 1, role: item?.role,
                                                created_at: item?.created_at && `${item.created_at?.split("T")[0]} ${item.created_at?.split("T")[1]?.split(".")[0].substring(0, 5)}`,
                                            }))} name="Sheet">
                                                <ExcelColumn label="Status Date & Time" value="created_at" />
                                                <ExcelColumn label="Name" value="userName" />
                                                <ExcelColumn label="Role" value="role" />
                                                <ExcelColumn label="Action Required" value="status" />
                                            </ExcelSheet>
                                        </ExcelFile>
                                    </button>
                                </>
                            }
                            cardbody={
                                <React.Fragment>
                                    <div class="table-responsive">

                                        <table class="table">
                                            <thead class="table-customlight">
                                                <tr>
                                                    <th className="auditTrail">Status Date & Time</th>
                                                    <th className="auditTrail">Name</th>
                                                    <th className="auditTrail">Role</th>
                                                    <th className="auditTrail">Status</th>
                                                    <th className="auditTrail">Comment</th>
                                                </tr>
                                            </thead>
                                            {dataToDisplay?.length > 0 && (
                                                <tbody>
                                                    {dataToDisplay?.map((item, i) => {
                                                        return (
                                                            <tr key={item?.requestNumber}>
                                                                <td scope="row"><FormatDate dateString={item?.created_at} /> </td>
                                                                <td>{item?.userName}</td>
                                                                <td>{item?.role}</td>
                                                                <td className="status-box"><span className="status"></span>{item?.status}</td>
                                                                <td>{item?.comment}</td>
                                                            </tr>)
                                                    })}
                                                </tbody>)}
                                        </table>
                                    </div>
                                </React.Fragment>
                            }
                        />
                    </div>
                    {/* <div className='col-md-12 text-end'>
                    <CustomButton style={"btn--outline me-3"} children={"Back"} onClick={() => navigate("/approver-requisition-dashboard")} />
                    </div> */}
                </div>
            </div>
        </div>
    )
}

export default ViewManPowerRequisition
import React from "react";
import CustomCard from "../component/CustomeCard";
import CustomInput from "../component/CustomInput";

const ErrorPage = () => {
  return (
    <div className="main-container error-container">
      <div className="right-view" style={{marginTop:"30px"}}>
          <CustomCard
             cardboldtitle={""}
            cardbody={
              <>
                <h1>404 - Not Found</h1>
                <p>Sorry, the page you are looking for does not exist.</p>
              </>
            } />
        </div>
      </div>
  );
};

export default ErrorPage;
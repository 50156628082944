import React, { useEffect, useState } from 'react';
import Header from '../../component/Header';
import './style.scss';
import Loader from '../../component/helper/Loader/loader';
import notify from '../../shared/notify';
import { useStores } from '../../stores'
import moment from 'moment-timezone';
import ReactPaginate from 'react-paginate';
import CustomCard from '../../component/CustomeCard';
import 'font-awesome/css/font-awesome.min.css';
import { useLocation, useNavigate } from "react-router-dom"



function Notification() {

    const pageTitle = 'Notification';
    const { crmStore } = useStores()
    const userData = sessionStorage.getItem('candidate')
    const data = JSON.parse(userData)
    const [isLoading, setIsLoading] = useState(false)
    const [notificationData, setNotificationData] = useState([])

    const [totalNoOfPages, setTotalNoOfPages] = useState('');
    const [pageLimit, setPageLimit] = useState(10);
    const [count, setCount] = useState(0)
    const navigate = useNavigate()


    const fetchAllNotification = async () => {

        let payload = {
            "pageNumber": 1,
            "limit": pageLimit,
        }
        setIsLoading(true)
        await crmStore?.getNotifications(payload, (res) => {
            if (res?.data?.meta?.status == 'success') {
                setNotificationData(res?.data?.data?.filteredData?.results)
                setIsLoading(false)
                setTotalNoOfPages(res?.data?.data?.totalPages)
            }
        },(err) => {
                // notify(err?.response?.data?.meta?.message, 'danger')
                console.log(err?.response?.data?.meta?.message, 'danger')
                setIsLoading(false)
            })
    }
    const paginationNotification = async (index) => {

        let payload = {
            "pageNumber": index.selected + 1,
            "limit": pageLimit,

        }
        setIsLoading(true)
        await crmStore?.getNotifications(payload,
            (res) => {
                if (res?.data?.meta?.status == 'success') {
                    setNotificationData(res?.data?.data?.filteredData?.results)
                    setIsLoading(false)
                }
            },
            (err) => {
                notify(err?.data?.meta?.message, 'danger')
                console.log(err)
                setIsLoading(false)
            })
    }



    useEffect(() => {
        fetchAllNotification();
    }, [])

    const getAmOrPmFromISOString = (isoString) => {
        const istDateTime = moment.utc(isoString).tz('Asia/Kolkata').format('DD-MM-YYYY h:mm A');
        return istDateTime;
    }

    return (
        <div className="main-container">
            <div className="right-view">
            <div className="right-view1">
                <Header notification_no={count} pageTitle={pageTitle} />
                <div className='col-md-12'>
                    <CustomCard
                        cardtitle={"All"}
                        cardboldtitle={"Notification"}
                        cardbody={
                            <>
                            <img src={require("../../assets/images/left-arrow.png")} className="back-btn-dashboard" onClick={() => navigate(-1)} />
                            <div className='main_section_box'>
                                {isLoading && <Loader />}
                                <div className="container-fluid">
                                    <div className="contant_box notification_main_box">
                                        <div className="row">
                                            {notificationData?.length > 0 &&
                                                notificationData?.map((item, index) => {
                                                    if (data?.id == item.notify_to) {
                                                        return (
                                                            <div className="col-md-12" key={index}>
                                                                <div className="notification_box">
                                                                    <div className="notification_left_box">
                                                                        <div>
                                                                            <h3 className='notification_title'>{item.title}</h3>
                                                                            <p className='notification_text'>{item.body}</p>
                                                                        </div>
                                                                    </div>
                                                                    <div className="notification_right_box">
                                                                        <i className="fa fa-clock-o" aria-hidden="true"></i>
                                                                        <p className='notification_time'>{getAmOrPmFromISOString(item.created_at)}</p>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        )
                                                    }
                                                })
                                            }


                                        </div>

                                        {
                                            notificationData?.length > 0 &&
                                            <div>
                                                <ReactPaginate
                                                    // previousLabel={'Previous'}
                                                    // nextLabel={'Next'}
                                                    breakLabel={'...'}
                                                    pageCount={totalNoOfPages}
                                                    marginPagesDisplayed={3}
                                                    pageRangeDisplayed={3}
                                                    onPageChange={paginationNotification}
                                                    containerClassName={'pagination paginationBox justify-content-end'}
                                                    pageClassName={'page-item'}
                                                    pageLinkClassName={'page-link pageNumber'}
                                                    previousClassName={'page-item'}
                                                    previousLinkClassName={'page-link previosBtn'}
                                                    nextClassName={'page-item'}
                                                    nextLinkClassName={'page-link previosBtn'}
                                                    breakClassName={'page-item'}
                                                    breakLinkClassName={'page-link pageNumber'}
                                                    activeClassName={'active'}
                                                />
                                            </div>

                                        }
                                    </div>
                                </div>
                            </div>
                            </>
                        }
                    />
                </div>
                </div>
            </div>

        </div>

    )
}

export default Notification;

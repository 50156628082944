import logo from './logo.svg';
import './App.css';
import 'bootstrap/dist/css/bootstrap.min.css';
import AppRoutes from './routes/web';

function App() {
  const roleUrl = sessionStorage.getItem("selectedDashboard");
  switch (roleUrl) {
      case "/treasury-journey-dashboard":
          sessionStorage.setItem("roleName", "Treasury");
          break;
      case "/approver-journey-dashboard":
          sessionStorage.setItem("roleName", "Approver");
          break;
      case "/requestor-journey-dashboard":
          sessionStorage.setItem("roleName", "Requestor");
          break;
      default:
          // console.log("Unknown dashboard URL:", roleUrl);
          break;
  }
  return (
    <>
      <AppRoutes />
    </>
  );
}

export default App;

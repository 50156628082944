import React, { useState } from "react";
import Modal from "react-bootstrap/Modal";
import successImage from "../../../assets/images/checked@2x.png";
import crossImage from "../../../assets/images/close (4).png";
import CustomButton from "../../Button/Button";
import Customselect from "../../CustomSelect";

const ForwardRequisitionForm = ({ hide, show }) => {



    return (
        <Modal show={show} centered className="sucess-content">
            <Modal.Body className="sucess-modal">

                <div className="modal-container">
                    <img src={crossImage}  alt=""  className="cross-img"  onClick={() => hide()} />
                    <div className="row moodal">
                        <img src={successImage} alt="" className="modal-img" />
                        <h4 className="modal-heading p-2" style={{ textAlign: "center" }}>
                            You have approved the request
                        </h4>
                        
                        <div className="col-md-11 mt-4" >
                            <textarea
                                cols="7"
                                className="form-control approve-modal p-3"
                                type="text"
                                placeholder="Please add the comment......"
                            />
                        </div>

                        <div className='col-md-11 mt-4'>
                            <Customselect
                            />
                        </div>
                    </div>
                    <div className="col-md-12 text-center mb-3">
                        <CustomButton children={"Submit"} style={"btn--approved--solid me-3"} />
                    </div>
                    </div>
            </Modal.Body>
        </Modal>
    );
};

export default ForwardRequisitionForm;

import React, { useState, useEffect } from 'react'
import Header from '../../../component/Header'
import ReactPaginate from "react-paginate";
import './style.scss'
import { json, useLocation, useNavigate } from "react-router-dom"
import CustomCard from '../../../component/CustomeCard';
import CustomInput from '../../../component/CustomInput';
import CustomButton from '../../../component/Button/Button';
import Customselect from '../../../component/CustomSelect';
import SubmitRequisitionModal from '../../../component/HrNewModuleComponent/Modal/SubmitRequisitionModal';
import { useStores } from '../../../stores';
import notify from '../../../shared/notify';
import Loader from '../../../component/helper/Loader/loader';


const limit = [
    {
        option: 10,
        value: 10,
    },
    {
        option: 100,
        value: 100,
    },
    {
        option: 500,
        value: 500,
    },
    {
        option: 1000,
        value: 1000,
    },
];

const CandidateApplicantLink = () => {
    const { crmStore } = useStores()
    const candidateString = sessionStorage.getItem("candidate");
    const candidate = candidateString ? JSON.parse(candidateString) : null;
    const [page, setPage] = useState(1)
    const [pageLimit, setPageLimit] = useState(10)
    const navigate = useNavigate()
    const [submitModal, setSubmitModal] = useState(false)
    const [isLoading, setIsLoading] = useState(false)
    const [selectApplicationDate, setSelectedApplicationDate] = useState("")
    const [positionApplied, setPositionApplied] = useState("")
    const [departmentName, setDeparmentName] = useState(candidate?.department)
    const [location, setLocation] = useState("")
    const [fullName, setFullName] = useState(candidate?.full_name)
    const [fatherName, setFatherName] = useState("")
    const [EmailID, setEmailID] = useState(candidate?.email_id)
    const [mobileNumber, setMobileNumber] = useState(candidate?.contact)
    const [permanentAddress, setPermanentAddress] = useState("")
    const [presentAddress, setPresentAddress] = useState("")
    const [pinCode, setPinCode] = useState("")
    const [pinCodePresent, setPinCodePresent] = useState("")
    const [dateOfBirth, setDateOfBirth] = useState("")
    const [maritalStatus, setMaritalStatus] = useState("")
    const [academicGap, setAcademicGap] = useState("")
    const [numberOfBackLog, setNubmerOfBacklog] = useState("")
    const [totalExperience, setTotalExperience] = useState("")
    const [currentSalary, setCurrentSalary] = useState("")
    const [noticePeriod, setNoticePeriod] = useState("")
    const [expectedSalary, setExpectedSalary] = useState("")
    const [expectedDateOfJoining, setExpectedDateOfJoining] = useState("")


    let classList = [
        { id: "10th", name: "10th" },
        { id: "12th", name: "12th" },
        { id: "Graduation", name: "Graduation" },
        { id: "Post Graduation", name: "Post Graduation" },
    ]

    let qualificationType = [
        { id: "Part-time", name: "Part-time" },
        { id: "Regular", name: "Regular", }
    ]

    let maritalStatusList = [
        { id: "Married", name: "Married" },
        { id: "Unmarried", name: "Unmarried" },
    ]


    const [educationalDetails, setEducationalDetails] = useState([
        { class: "", course_name: "", school_college_name: "", board_university: "", education_type: "", year_of_passing: "", percentage_grade: "", },
    ])
    const [employmentDetails, setEmploymentDetails] = useState([
        { organization: '', designation: '', select_from_date: '', select_to_date: "", ctc: '', reason_for_leaving: '', },
    ]);

    const handleEducationChange = (index, field, value) => {
        const updatedDetail = [...educationalDetails]
        updatedDetail[index][field] = value;
        setEducationalDetails(updatedDetail)
    }

    const handleAddAnotherDetail = () => {
        setEducationalDetails([
            ...educationalDetails,
            {
                class: '',
                course_name: '',
                school_college_name: '',
                board_university: '',
                education_type: '',
                year_of_passing: '',
                percentage_grade: '',
            },
        ])

    }
    const   addEmploymentDetail = () => {
        setEmploymentDetails([
            ...employmentDetails,
            {
                organization: '',
                designation: '',
                selectFromDate: '',
                ctc: '',
                reason_for_leaving: '',
            },
        ]);
    };

    const handleInputChange = (index, field, value) => {
        const updatedDetails = [...employmentDetails];
        updatedDetails[index][field] = value;
        setEmploymentDetails(updatedDetails);
    };


    const removeEmploymentDetail = (index) => {
        const updatedDetails = employmentDetails.filter((_, i) => i !== index)
        setEmploymentDetails(updatedDetails);
    };
    const handleDeleteEducation = (index) => {
        const updatedDetails = educationalDetails.filter((_, i) => i !== index)
        setEducationalDetails(updatedDetails);
    };

    const clearCacheData = () => {
        sessionStorage.clear()
        if ("caches" in window) {
            caches.keys().then((names) => {
                names.forEach((name) => {
                    caches.delete(name);
                });
            });
        }
        window.location.reload();
    };


    const handleChnage = (e) => {
        setAcademicGap(e.target.value)
    }


    const handleSubmit = async (id) => {
        // if(candidate?.is_candidate_application_submitted == true){
        //     return notify("Form is already submitted" ,"danger")
        // }


        if (numberOfBackLog == undefined || numberOfBackLog == null || numberOfBackLog == "") {
            return notify("please enter number of backlogs", "danger")
        }
        if (academicGap == undefined || academicGap == null || academicGap == "") {
            return notify("please select academic gap.", "danger")
        }

        let payload = {
            application_date: selectApplicationDate,
            father_name: fatherName,
            permanent_address: permanentAddress,
            pin_code_permanent: pinCode,
            location: location,
            present_address: presentAddress,
            position_applied: positionApplied,
            pin_code_present: pinCodePresent,
            date_of_birth: dateOfBirth,
            maritial_status: maritalStatus,
            total_experience: totalExperience,
            current_salary: currentSalary,
            notice_period: noticePeriod,
            expected_salary: expectedSalary,
            expected_doj: expectedDateOfJoining,
            academic_gap: academicGap == "Yes" ? true : false,
            number_of_backlogs: numberOfBackLog,
            educational_qualification: educationalDetails,
            employment_details: employmentDetails,
        }
        setIsLoading(true)

        await crmStore.candidateForm(id, payload, (res) => {
            if (res?.data?.meta?.status === "success") {
                notify(res?.data?.meta?.message)
                setSubmitModal(true)
                setIsLoading(false)
            } else {
                notify(res?.data?.meta?.message, "danger")
                setSubmitModal(false)
                setIsLoading(false)
            }
        }, (error) => {
            notify(error?.response?.data?.meta?.message, "danger")
            setSubmitModal(false)
            setIsLoading(false)
        })
    }



    return (
        <div className="applicant-mainContainer">
            {isLoading && <Loader />}
            <div className="container">
                <div>
                    <h5 className='candidate-form'>Candidate Application Form,</h5>
                    <div className='heading-underline underline'></div>
                </div>
                <div className='row'>

                    <div className='col-md-12'>
                        <CustomCard
                            cardtitle={"Personal"}
                            cardboldtitle={"Details"}
                            cardbody={
                                <div className='row'>
                                    <div className='col-md-3'>
                                        <CustomInput
                                            label="Select Application Date*"
                                            require={true}
                                            placeholder='Select Application Date*'
                                            type='date'
                                            value={selectApplicationDate}
                                            onChange={(e) => setSelectedApplicationDate(e.target.value)}
                                        />
                                    </div>
                                    <div className='col-md-3'>
                                        <CustomInput
                                            label="Position Applied"
                                            require={true}
                                            placeholder='Position Applied'
                                            value={positionApplied}
                                            onChange={(e) => setPositionApplied(e.target.value)}
                                        />
                                    </div>
                                    <div className='col-md-3'>
                                        <CustomInput
                                            label="Department Name"
                                            require={true}
                                            placeholder='Department Name'
                                            value={departmentName}
                                            readOnly={true}
                                        />
                                    </div>
                                    <div className='col-md-3'>
                                        <CustomInput
                                            label="Location"
                                            require={true}
                                            placeholder='Location'
                                            value={location}
                                            onChange={(e) => setLocation(e.target.value)}
                                        />
                                    </div>
                                    <div className='col-md-3'>
                                        <CustomInput
                                            label="Full Name"
                                            require={true}
                                            placeholder='Full Name'
                                            value={fullName}
                                            readOnly={true}
                                            onChange={(e) => setFullName(e.target.value)}
                                        />
                                    </div>
                                    <div className='col-md-3'>
                                        <CustomInput
                                            label="Father’s Name"
                                            require={true}
                                            placeholder='Father’s Name'
                                            value={fatherName}
                                            onChange={(e) => setFatherName(e.target.value)}
                                        />
                                    </div>
                                    <div className='col-md-3'>
                                        <CustomInput
                                            label="Mobile Number"
                                            require={true}
                                            placeholder='Mobile Number'
                                            readOnly={true}
                                            value={mobileNumber}
                                            onChange={(e) => setMobileNumber(e.target.value)}
                                        />
                                    </div>
                                    <div className='col-md-3'>
                                        <CustomInput
                                            label="E-mail ID"
                                            require={true}
                                            placeholder='E-mail ID'
                                            readOnly={true}
                                            value={EmailID}
                                            onChange={(e) => setEmailID(e.target.value)}
                                        />
                                    </div>
                                    <div className='col-md-9'>
                                        <CustomInput
                                            label="Permanent Address"
                                            require={true}
                                            placeholder='Permanent Address'
                                            value={permanentAddress}
                                            onChange={(e) => setPermanentAddress(e.target.value)}
                                        />
                                    </div>
                                    <div className='col-md-3'>
                                        <CustomInput
                                            label="Permanent Pincode"
                                            require={true}
                                            placeholder='Permanent Pincode'
                                            value={pinCode}
                                            onChange={(e) => setPinCode(e.target.value)}
                                        />
                                    </div>
                                    <div className='col-md-9'>
                                        <CustomInput
                                            label="Present Address"
                                            require={true}
                                            placeholder='Present Address'
                                            value={presentAddress}
                                            onChange={(e) => setPresentAddress(e.target.value)}
                                        />
                                    </div>
                                    <div className='col-md-3'>
                                        <CustomInput
                                            label="Present PinCode"
                                            require={true}
                                            placeholder='Present PinCode'
                                            value={pinCodePresent}
                                            onChange={(e) => setPinCodePresent(e.target.value)}
                                        />
                                    </div>
                                    <div className='col-md-3'>
                                        <CustomInput
                                            label="DOB"
                                            require={true}
                                            type="date"
                                            placeholder='DOB'
                                            value={dateOfBirth}
                                            onChange={(e) => setDateOfBirth(e.target.value)}
                                        />
                                    </div>
                                    <div className='col-md-3'>
                                        <Customselect
                                            label="Marital Status"
                                            require={true}
                                            placeholder='Marital Status'
                                            value={maritalStatus}
                                            onChange={(e) => setMaritalStatus(e.target.value)}
                                            optionview={maritalStatusList?.map((option) => {
                                                return (
                                                    <option value={option?.id}>{option?.name}</option>
                                                );
                                            })}
                                        />
                                    </div>
                                </div>
                            }
                        />
                    </div>

                    <div className='col-md-12'>
                        <CustomCard
                            cardtitle={"Educational"}
                            cardboldtitle={"Details"}
                            cardbody={
                                <div className='row'>
                                    {educationalDetails?.map((item, index) => {
                                        let data = item?.class !== "Post Graduation" ? true : false

                                        return (

                                            <>
                                                <div className='col-md-3'>
                                                    <Customselect
                                                        label="Select Class Name"
                                                        require={data}
                                                        value={item?.class}
                                                        onChange={(e) => handleEducationChange(index, "class", e.target.value)}
                                                        optionview={classList?.map((option) => {
                                                            return (
                                                                <option value={option?.id}>{option?.name}</option>
                                                            );
                                                        })}
                                                    />
                                                </div>
                                                <div className='col-md-3'>
                                                    <CustomInput
                                                        label="Name of Course"
                                                        require={data}
                                                        placeholder='Name of Course'
                                                        value={item?.course_name}
                                                        onChange={(e) => handleEducationChange(index, "course_name", e.target.value)}
                                                    />
                                                </div>
                                                <div className='col-md-3'>
                                                    <CustomInput
                                                        label="Name of the Institute"
                                                        require={data}
                                                        placeholder='School/College/Institute Name'
                                                        value={item?.school_college_name}
                                                        onChange={(e) => handleEducationChange(index, "school_college_name", e.target.value)}
                                                    />
                                                </div>
                                                <div className='col-md-3'>
                                                    <CustomInput
                                                        label="Board/University Name"
                                                        require={data}
                                                        placeholder='Board/University Name'
                                                        value={item?.board_university}
                                                        onChange={(e) => handleEducationChange(index, "board_university", e.target.value)}
                                                    />
                                                </div>
                                                <div className='col-md-3'>
                                                    <Customselect
                                                        label="Select Regular/Part-time"
                                                        require={data}
                                                        value={item?.education_type}
                                                        onChange={(e) => handleEducationChange(index, "education_type", e.target.value)}
                                                        optionview={qualificationType?.map((option) => {
                                                            return (
                                                                <option value={option?.id}>{option?.name}</option>
                                                            );
                                                        })}
                                                    />
                                                </div>
                                                <div className='col-md-3'>
                                                    <CustomInput
                                                        label="Select Year of Passing"
                                                        require={data}
                                                        placeholder='Select Year of Passing'
                                                        type="date"
                                                        value={item?.year_of_passing}
                                                        onChange={(e) => handleEducationChange(index, "year_of_passing", e.target.value)}
                                                    />
                                                </div>
                                                <div className='col-md-3'>
                                                    <CustomInput
                                                        label="Percentage(%)/Grade"
                                                        require={data}
                                                        placeholder='Percentage(%)/Grade'
                                                        value={item?.percentage_grade}
                                                        onChange={(e) => handleEducationChange(index, "percentage_grade", e.target.value)}
                                                    />
                                                </div>
                                                {index === 0 && <div className='col-md-3'>

                                                </div>}
                                                {index > 0 && <div className='col-md-3 mt-4'>
                                                    <button className='btn btn-danger' onClick={() => handleDeleteEducation(index)} > Remove </button>
                                                </div>}

                                            </>
                                        )
                                    })}

                                    <div className='col-md-12 me-4' style={{ color: "red", cursor: "pointer" }} onClick={handleAddAnotherDetail}>
                                        <p className='mb-3 p-text'>  + Add another Educational Detail </p>

                                    </div>


                                    <div className='row'>
                                        <div className='col-md-3'>
                                            <div className='radio-type'>
                                                <h3>Academic Gap<span style={{ color: "red" }}>*</span></h3>
                                                <div className=' academic-box'>
                                                    <div className='radio-btnn'>
                                                        <input type='radio' value="Yes" name='Academic Gap' onChange={handleChnage} />
                                                        <label >Yes</label>
                                                    </div>
                                                    <div className='radio-btnn'>
                                                        <input type='radio' value="No" name='Academic Gap' onChange={handleChnage} />
                                                        <label >No</label>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>

                                        <div className='col-md-3'>
                                            <CustomInput
                                                label="Number of Backlogs, if any"
                                                require={true}
                                                placeholder='Number of Backlogs, if any'
                                                value={numberOfBackLog}
                                                onChange={(e) => setNubmerOfBacklog(e.target.value)}
                                            />
                                        </div>
                                    </div>
                                </div>
                            }
                        />
                    </div>


                    {employmentDetails.map((detail, index) => (
                        <div key={index} className='col-md-12'>
                            <CustomCard
                                cardtitle={"Employment"}
                                cardboldtitle={`Details ${index + 1}`}
                                cardbody={
                                    <div className='row'>
                                        <div className='col-md-3'>
                                            <CustomInput
                                                label="Organization Name"
                                                require={true}
                                                placeholder='Organization Name'
                                                value={detail.organization}
                                                onChange={(e) =>
                                                    handleInputChange(index, 'organization', e.target.value)
                                                }
                                            />
                                        </div>
                                        <div className='col-md-3'>
                                            <CustomInput
                                                label="Designation Name"
                                                require={true}
                                                placeholder='Designation Name'
                                                value={detail.designation}
                                                onChange={(e) =>
                                                    handleInputChange(index, 'designation', e.target.value)
                                                }
                                            />
                                        </div>
                                        <div className='col-md-3'>
                                            <CustomInput
                                                label="Select Start Date"
                                                require={true}
                                                placeholder='Select Start Date'
                                                type="date"
                                                value={detail.select_from_date}
                                                onChange={(e) =>
                                                    handleInputChange(index, 'select_from_date', e.target.value)
                                                }
                                            />
                                        </div>
                                        <div className='col-md-3'>
                                            <CustomInput
                                                label="Select End Date"
                                                require={true}
                                                placeholder='Select End Date'
                                                type="date"
                                                value={detail.select_to_date}
                                                onChange={(e) =>
                                                    handleInputChange(index, 'select_to_date', e.target.value)
                                                }
                                            />
                                        </div>
                                        <div className='col-md-3'>
                                            <CustomInput
                                                label="Current CTC"
                                                require={true}
                                                placeholder='Current CTC'
                                                value={detail.ctc}
                                                onChange={(e) =>
                                                    handleInputChange(index, 'ctc', e.target.value)
                                                }
                                            />
                                        </div>
                                        <div className='col-md-3'>
                                            <CustomInput
                                                label="Reason for Leaving"
                                                require={true}
                                                placeholder='Reason for Leaving'
                                                value={detail.reason_for_leaving}
                                                onChange={(e) =>
                                                    handleInputChange(index, 'reason_for_leaving', e.target.value)
                                                }
                                            />
                                        </div>

                                        {index > 0 && (
                                            <div className='col-md-12 mt-4 '>
                                                <button className='btn btn-danger' onClick={() => removeEmploymentDetail(index)} > Remove </button>
                                            </div>
                                        )}

                                    </div>
                                }
                            />
                        </div>
                    ))}


                    <div className='col-md-12 mt-3' style={{ color: "red", cursor: "pointer" }} onClick={addEmploymentDetail}>
                        <p className='mb-3 p-text'> + Add another Employment Detail </p>
                    </div>


                    <div className='col-md-12'>
                        <CustomCard
                            cardtitle={"Other"}
                            cardboldtitle={"Details"}
                            cardbody={
                                <div className='row'>
                                    <div className='col-md-3'>
                                        <CustomInput
                                            label="Total Experience"
                                            require={true}
                                            placeholder='Total Experience'
                                            value={totalExperience}
                                            onChange={(e) => setTotalExperience(e.target.value)}
                                        />
                                    </div>
                                    <div className='col-md-3'>
                                        <CustomInput
                                            label="Current Salary"
                                            require={true}
                                            placeholder='Current Salary'
                                            value={currentSalary}
                                            onChange={(e) => setCurrentSalary(e.target.value)}
                                        />
                                    </div>
                                    <div className='col-md-3'>
                                        <CustomInput
                                            label="Notice Period"
                                            require={true}
                                            placeholder='Notice Period'
                                            type="date"
                                            value={noticePeriod}
                                            onChange={(e) => setNoticePeriod(e.target.value)}
                                        />
                                    </div>
                                    <div className='col-md-3'>
                                        <CustomInput
                                            label="Expected Salary"
                                            require={true}
                                            placeholder='Expected Salary'
                                            value={expectedSalary}
                                            onChange={(e) => setExpectedSalary(e.target.value)}
                                        />
                                    </div>
                                    <div className='col-md-3'>
                                        <CustomInput
                                            label="Expected DOJ"
                                            require={true}
                                            type="date"
                                            placeholder='Expected DOJ'
                                            value={expectedDateOfJoining}
                                            onChange={(e) => setExpectedDateOfJoining(e.target.value)}
                                        />
                                    </div>
                                </div>
                            }
                        />
                    </div>

                    <div className='col-md-12 text-end'>
                        <CustomButton children={"Submit"} style={"btn--approved--solid"} onClick={() => handleSubmit(candidate?.candidate_id)} />
                    </div>
                </div>
            </div>

            <SubmitRequisitionModal
                show={submitModal}
                hide={() => {
                    setSubmitModal(false)
                    clearCacheData()
                }}
                title={"You have successfully submitted form"}
            />
        </div>
    )
}

export default CandidateApplicantLink
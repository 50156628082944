import React, { useState } from "react";
import Modal from "react-bootstrap/Modal";
import successImage from "../../../assets/images/checked@2x.png";
import crossImage from "../../../assets/images/close (4).png";
import CustomButton from "../../Button/Button";
import Customselect from "../../CustomSelect";

const SegmentModal = ({ hide, show ,renderSegmentModal}) => {


  return (
    <Modal show={show} centered className="sucess-content">
      <Modal.Body className="sucess-modal">
        <div className="modal-container">
          <img
            src={crossImage}
            alt=""
            className="cross-img"
            onClick={() => hide()}
          />
          {renderSegmentModal()}
        </div>
      </Modal.Body>
    </Modal>
  );
};

export default SegmentModal;

import React from "react";
import './customInput.scss';

const CustomInput = (props) => {
  const { label, type = "text", name, value, onChange, placeholder, style ,readOnly , min ,  onKeyDown} = props;;
  return (
    <div className="custominput-container">
      <div className="custom-input">
        <label>{label}{props.require && <span className="text-danger">*</span>}</label>
        <input
          className={style}
          readOnly={readOnly}
          type={type}
          placeholder={placeholder}
          name={name}
          value={value}
          min={min}
          onKeyDown={onKeyDown} 
          style={{ backgroundColor: readOnly ? '#e3e3e3' : 'white' }}
          onChange={onChange} />
          
      </div>
    </div>
  )
}

export default CustomInput; 
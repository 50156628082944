import React, { useState } from "react";
import Modal from "react-bootstrap/Modal";
import "./style.scss";
import cancellationImage from "../../assets/images/rejected@2x.png"
import crossImage from "../../assets/images/close (4).png"
import CustomButton from "../Button/Button";
import notify from "../../shared/notify";
import { useStores } from "../../stores";
import { useNavigate } from "react-router-dom";
import Loader from "../helper/Loader/loader";

const CancellationModal = (props) => {
  const { mainStore } = useStores()
  const navigate = useNavigate()
  const [approverComments, setApproverComments] = useState("")
  const [isLoading, setIsLoading] = useState(false)

  const rejectApproval = async () => {
    setIsLoading(true)
    let payload = {
      isApproved: false,
      comment:approverComments
    }
   
    await mainStore.approveBgInssuance(props?.requestNumber, payload, (response) => {
      if (response?.data?.meta?.status === "success") {
        notify(response?.data?.meta?.message)
        navigate("/approver-journey-dashboard")
        setIsLoading(false)
        props.hide()
      }
      else {
        notify(response?.data?.meta?.message, "danger")
        setIsLoading(false)
        props.hide()
      }
    }, (error) => {
      notify(error?.response?.data?.meta?.message, "danger")
      setIsLoading(false)
      props.hide()
    })

  }

  return (
    <Modal {...props} centered className="sucess-content">    
      <Modal.Body className="sucess-modal">
        <img src={crossImage} alt="" className="cross-img" onClick={() => props.hide()} />
        {isLoading && <Loader />}
        <div className="moodal" >
          <img src={cancellationImage} alt="" className="modal-img" />
          <h5>OOPS!</h5>
          <h5>You have reject the issue</h5>
          <div className="addName-model" mt-3>
            <textarea
              cols="10"
              className="form-control p-3"
              type="text"
              value={approverComments}
              onChange={(e) => setApproverComments(e.target.value)}
              placeholder="Please add the comment for the requestor*"
            />
          </div>
          <CustomButton
            children={"Submit"}
            style={"mt-3"}
            onClick={rejectApproval}
          />
        </div>
      </Modal.Body>
    </Modal>
  );
}
export default CancellationModal;

import React, { useState, useEffect } from "react";
import Header from "../Header";
import CustomCard from "../CustomeCard";
import CustomInput from "../CustomInput";
import CustomButton from "../Button/Button";
import notify from "../../shared/notify";
import crossImg from "../../assets/images/rejected@2x.png"
import { useStores } from "../../stores";
import { useLocation, useNavigate } from "react-router-dom"
import Loader from "../helper/Loader/loader";
import ReactExport from "react-export-excel";
import FormatDate from "../helper/formatDate/formateDate";



const ExcelFile = ReactExport.ExcelFile;
const ExcelSheet = ReactExport.ExcelFile.ExcelSheet;
const ExcelColumn = ReactExport.ExcelFile.ExcelColumn;

const EditLcRequest = () => {
    const { mainStore } = useStores()
    const navigate = useNavigate()
    const { state } = useLocation();
    const Viewer = sessionStorage.getItem("role")
    const [isReadOnly, setIsReadOnly] = useState(false)

    useEffect(() => {
        if (state?.item?.isSubmitted === true || Viewer === "Viewer") {
            setIsReadOnly(true)
        }
    }, [state?.item?.isSubmitted])
    const [isLoading, setIsLoading] = useState(false)
    const [requestNumber, setRequestNumber] = useState(state?.item?.requestNumber);
    const [requestName] = useState(state?.item?.requesterName);
    const [requesterMail] = useState(state?.item?.requesterEmail);
    const [entity, setEntity] = useState([]);
    const [requestEntity, setRequestEntity] = useState(state?.item?.entity);
    const [requestUnit, setRequestUnit] = useState(state?.item?.unit);
    const [approverInfo, setApproverInfo] = useState([
        { approverName: "", approverDepartment: "", approverEmail: "", approverLevel: "L1", approverId: "" },
        { approverName: "", approverDepartment: "", approverEmail: "", approverLevel: "L2", approverId: "" }
    ]);
    const [bankName, setBankName] = useState(state?.item?.bankName);
    const [bankAccountNumber, setBankAccountNumber] = useState(state?.item?.bankAccountNumber);
    const [bankGlCode, setBankGlCode] = useState(state?.item?.requestNumber);
    const [refrenceNumber, setRefrenceNumber] = useState(state?.item?.referenceNumber);
    const [typeOfLc, setTypeOfLc] = useState(state?.item?.lcType);
    const [beneficiaryName, setBeneficiaryName] = useState(state?.item?.beneficiaryName);
    const [beneficiaryLocation, setBeneficiaryLocation] = useState(state?.item?.beneficiaryAddress);
    const [inssuanceDate, setInssuanceDate] = useState(state?.item?.issuanceDate );
    const [expiryDate, setExpiryDate] = useState(state?.item?.expiryDate);
    const [placeOfExpiry, setPlaceOfExpiry] = useState(state?.item?.expiryPlace)
    const [currency, setCurrency] = useState(state?.item?.currency);
    const [ammount, setAmmount] = useState(state?.item?.amount);
    const [nameOfApplicant, setNameOfApplicant] = useState(state?.item?.applicantName);
    const [applicantAddress, setApplicantAddress] = useState(state?.item?.applicantAddress);
    const [bgCharges, setBgCharges] = useState();
    const [gst, setGst] = useState();
    const [bgChargesGlCode, setBgChargesGlCode] = useState();
    const [gstGlCode, setGstGlCode] = useState();
    const [purchaseOrderNumber, setPurchaseOrderNumber] = useState(state?.item?.purchaseOrderNumber)
    const [purchaseOrderDate, setPurchaseOrderDate] = useState(state?.item?.purchaseOrderDate )
    const [beneficiaryBankName, setBeneficiaryBankName] = useState(state?.item?.beneficiaryBankName)
    const [beneficiaryBankAddress, setBeneficiaryBankAddress] = useState(state?.item?.beneficiaryBankAddress)
    const [beneficiaryBankSwiftCode, setBeneficiaryBankSwiftCode] = useState(state?.item?.beneficiaryBankSwiftCode)
    const [modeOfShipment, setModeOfShipment] = useState(state?.item?.shipmentMode)
    const [quantityTolerance, setQuantityTolerance] = useState(state?.item?.toleranceQuantity)
    const [valueTolerance, setValueTolerance] = useState(state?.item?.toleranceValue)
    const [draftsAt, setDraftAt] = useState(state?.item?.draftsAt)
    const [dispatchFrom, setDispatchFrom] = useState(state?.item?.dispatchFrom)
    const [hsnCode, setHsnCode] = useState(state?.item?.hsnCode)
    const [dispatchDate, setDispatchDate] = useState(state?.item?.lastShipmentDate)
    const [descriptionOfGood, setDescriptionOfGood] = useState(state?.item?.goodsDescription)
    const [approverList, setApproverList] = useState([])

    const [requesterDetailComments, setRequesterDetailComments] = useState(state?.item?.requestorComment)
    const [approverDetailsComment, setApproverDetailsComment] = useState(state?.item?.approverComment)
    const [bankDetailComment, setBankDetailComment] = useState(state?.item?.bankComment)
    const [letterOfCreditDetailComment, setLetterOfCreditDetailComment] = useState(state?.item?.letterOfCreditComment)
    const [bankChargesComment, setBankChargesComment] = useState(state?.item?.bankChargesComments)
    const [attachmentComment, setAttachmentComment] = useState(state?.item?.attachmentsComment)
    const [getAuditDetail, setGetAuditDetail] = useState([])
    const userName = sessionStorage.getItem("name")
    const roleName = sessionStorage.getItem("roleName")
    const [chatComment, setChatComment] = useState("")
    const [chat, setChat] = useState([])


    const [selectedTypeOfLC, setSelectedTypeOfLC] = useState(false)


    const getFormattedDate = (dateString) => {
        const date = new Date(dateString);
        const year = date.getFullYear();
        const month = (date.getMonth() + 1).toString().padStart(2, "0");
        const day = date.getDate().toString().padStart(2, "0");
        return `${year}-${month}-${day}`;
      };

    const [LcDraft, setLcDraft] = useState(() => {
        const item = state?.item?.lcDraftAttachement;
        if (item) {
            try {
                return JSON.parse(item);
            } catch (error) {
                console.error("Error parsing bgText JSON:", error);
            }
        }
        return [];
    });
    const [purchaseOrder, setPurchaseOrder] = useState(() => {
        const item = state?.item?.purchaseOrderAttachment;
        if (item) {
            try {
                return JSON.parse(item);
            } catch (error) {
                console.error("Error parsing bgText JSON:", error);
            }
        }
        return [];
    });
    const [proformaInvoice, setProformaInvoice] = useState(() => {
        const item = state?.item?.performaInvoiceAttachment;
        if (item) {
            try {
                return JSON.parse(item);
            } catch (error) {
                console.error("Error parsing bgText JSON:", error);
            }
        }
        return [];
    });
    const [creditReport, setCreditReport] = useState(() => {
        const item = state?.item?.creditReportAttachment;
        if (item) {
            try {
                return JSON.parse(item);
            } catch (error) {
                console.error("Error parsing bgText JSON:", error);
            }
        }
        return [];
    });
    const [ibanCode, setIbanCode] = useState(state?.item?.IBANcode)


    const [lineOfService] = useState([
        { value: "Solar", label: "Solar" },
        { value: "Solar Module Line", label: "Solar Module Line" },
        { value: "Biomass (Punjab & Haryana)", label: "Biomass (Punjab & Haryana)" },
        { value: "Biomass- Rajasthan", label: "Biomass- Rajasthan" },
        { value: "HO", label: "HO" },
    ])
    const [division, setDivision] = useState(state?.item?.lineOfService);
    const [currencyList, setCurrencyList] = useState([])

    useEffect(() => {

        if (!typeOfLc) {
            return
        }

        if (typeOfLc === "ILC  ") {
            setSelectedTypeOfLC(true);
            setCurrencyList([{ name: 'INR' }]);
        } else if (typeOfLc === "FLC  ") {
            setSelectedTypeOfLC(false)
            setCurrencyList([
                { name: "USD" },
                { name: "EUR" },
                { name: "CHF" }
            ]);
        } else {
            setSelectedTypeOfLC(false)
            setCurrencyList([
                { name: "USD" },
                { name: "EUR" },
                { name: "INR" },
                { name: "CHF" }
            ]);
        }

    }, [typeOfLc]);

    const [typeOfLcList, setTypeOfLcList] = useState([
        { name: "ILC  " },
        { name: "FLC  " },
        { name: "Others " }
    ])

    const isValidApproverInfo = approverInfo.every(approver => (
        approver.approverName !== "" &&
        approver.approverDepartment !== "" &&
        approver.approverEmail !== "" &&
        approver.approverId !== ""
    ));

    useEffect(() => {
        fetchApproverDetail()
        fetchEntity()
    }, [])

    const fetchEntity = async () => {
        await mainStore.getEntity((status, response) => {
            if (response?.meta?.status === "success") {
                setEntity(response?.data?.entitites)
            } else {
                notify(response?.meta?.message, "danger");
            }
        },
            (error) => {
                notify(error?.response?.meta?.message, "danger");
            }
        );
    }


    useEffect(() => {
        fetchChatResponse()
        if (state?.item?.approvers?.length > 0) {
            let arr = [];
            state.item.approvers.map((item) => {
                let obj = {
                    approverId: item?.approver_id,
                    approverName: item?.user?.name,
                    approverEmail: item?.user?.email,
                    approverDepartment: item?.user?.user_department,
                    approverLevel: item?.approver_level
                }
                arr.push(obj)
            })
            setApproverInfo(arr);
        }
    }, [])

    const handleSendMassege = async () => {
        setIsLoading(true)
        let payload = {
            requestNumber: state?.item?.requestNumber,
            userName: userName,
            userRole: roleName,
            message: chatComment
        }

        await mainStore.sendMassege(payload, (response) => {
            if (response?.data?.meta?.status === "success") {
                notify(response?.data?.meta?.message)
                fetchChatResponse()
                setIsLoading(false)
                setChatComment("")
            } else {
                notify(response?.data?.meta?.message, "danger")
                setIsLoading(false)
                setChatComment("")
            }
        }, (error) => {
            notify(error?.response?.data?.meta?.message, "danger")
            setChatComment("")
        })
    }

    const fetchChatResponse = async () => {
        mainStore.getChatResponse(state?.item?.requestNumber, (status, response) => {

            if (response?.meta?.status === "success") {
                setChat(response?.data?.chats)
            } else {
                notify(response?.meta?.message, "danger")
            }
        }, (error) => {
            notify(error?.response?.meta?.message, "danger")
        })
    }

    const fetchApproverDetail = async (lineOfService) => {
        if (!lineOfService) {
            return;
        }

        let payload = {
            lineOfService
        };
        await mainStore.getApprovers(payload, (response) => {
            if (response?.data?.meta?.status === "success") {
                setApproverList(response?.data?.data?.users)
            } else {
                notify(response?.data?.meta?.message, "danger");
            }
        },
            (error) => {
                notify(error?.response?.data?.meta?.message, "danger");
            }
        );
    }
    const handleInputChange = (e) => {
        let value = e.target.value
        setDivision(value)
        fetchApproverDetail(value)
        setApproverInfo([
            { approverName: "", approverDepartment: "", approverEmail: "", approverLevel: "L1", approverId: "" },
            { approverName: "", approverDepartment: "", approverEmail: "", approverLevel: "L2", approverId: "" }
        ]);
    }


    useEffect(() => {
        getLcAuditDetail()
        // fetchApproverDetail()
        if (division) {
            fetchApproverDetail(division);
        }
      
    }, [division]);



    const handleApproverChange = (index, field, value) => {
        const updatedApproverInfo = [...approverInfo];
        updatedApproverInfo[index][field] = value;
        setApproverInfo(updatedApproverInfo);
    };


    const submitForApproval = async (type) => {


        if (typeOfLc === "FLC  " && creditReport.length === 0  && type ==="submit") {
            return notify("Please upload the credit report attachment", "danger")
        }

        if (proformaInvoice.length === 0  && type ==="submit") {
            return notify("Please upload the Proforma Invoice attachment", "danger")
        }
        if (purchaseOrder.length === 0  && type ==="submit") {
            return notify("Please upload the Purchase Order attachment", "danger")
        }
        if (LcDraft.length === 0  && type ==="submit") {
            return notify("Please upload the LcDraft attachment", "danger")
        }

        setIsLoading(true)

        let payload = {
            ...(requestName !== undefined && requestName !== null && requestName !== "" && { requesterName: requestName }),
            ...(requesterMail !== undefined && requesterMail !== null && requesterMail !== "" && { requesterEmail: requesterMail }),
            ...(requestEntity !== undefined && requestEntity !== null && requestEntity !== "" && { entity: requestEntity }),
            ...(requestUnit !== undefined && requestUnit !== null && requestUnit !== "" && { unit: requestUnit }),
            ...(typeOfLc !== undefined && typeOfLc !== null && typeOfLc !== "" && { lcType: typeOfLc }),
            ...(expiryDate !== undefined && expiryDate !== null && expiryDate !== "" && { expiryDate: expiryDate }),
            ...(placeOfExpiry !== undefined && placeOfExpiry !== null && placeOfExpiry !== "" && { expiryPlace: placeOfExpiry }),
            ...(currency !== undefined && currency !== null && currency !== "" && { currency: currency }),
            approver: isValidApproverInfo ? approverInfo : undefined,
            ...(ammount !== undefined && ammount !== null && ammount !== "" && { amount: ammount }),
            ...(nameOfApplicant !== undefined && nameOfApplicant !== null && nameOfApplicant !== "" && { applicantName: nameOfApplicant }),
            ...(applicantAddress !== undefined && applicantAddress !== null && applicantAddress !== "" && { applicantAddress: applicantAddress }),
            ...(beneficiaryName !== undefined && beneficiaryName !== null && beneficiaryName !== "" && { beneficiaryName: beneficiaryName }),
            ...(beneficiaryLocation !== undefined && beneficiaryLocation !== null && beneficiaryLocation !== "" && { beneficiaryAddress: beneficiaryLocation }),
            ...(purchaseOrderNumber !== undefined && purchaseOrderNumber !== null && purchaseOrderNumber !== "" && { purchaseOrderNumber: purchaseOrderNumber }),
            ...(purchaseOrderDate !== undefined && purchaseOrderDate !== null && purchaseOrderDate !== "" && { purchaseOrderDate: purchaseOrderDate }),
            ...(beneficiaryBankName !== undefined && beneficiaryBankName !== null && beneficiaryBankName !== "" && { beneficiaryBankName: beneficiaryBankName }),
            ...(beneficiaryBankAddress !== undefined && beneficiaryBankAddress !== null && beneficiaryBankAddress !== "" && { beneficiaryBankAddress: beneficiaryBankAddress }),
            ...(beneficiaryBankSwiftCode !== undefined && beneficiaryBankSwiftCode !== null && beneficiaryBankSwiftCode !== "" && { beneficiaryBankSwiftCode: beneficiaryBankSwiftCode }),
            ...(modeOfShipment !== undefined && modeOfShipment !== null && modeOfShipment !== "" && { shipmentMode: modeOfShipment }),
            ...(quantityTolerance !== undefined && quantityTolerance !== null && quantityTolerance !== "" && { toleranceQuantity: quantityTolerance }),
            ...(valueTolerance !== undefined && valueTolerance !== null && valueTolerance !== "" && { toleranceValue: valueTolerance }),
            ...(draftsAt !== undefined && draftsAt !== null && draftsAt !== "" && { draftsAt: draftsAt }),
            ...(dispatchFrom !== undefined && dispatchFrom !== null && dispatchFrom !== "" && { dispatchFrom: dispatchFrom }),
            ...(dispatchDate !== undefined && dispatchDate !== null && dispatchDate !== "" && { lastShipmentDate: dispatchDate }),
            ...(descriptionOfGood !== undefined && descriptionOfGood !== null && descriptionOfGood !== "" && { goodsDescription: descriptionOfGood }),
            ...(hsnCode !== undefined && hsnCode !== null && hsnCode !== "" && { hsnCode: hsnCode }),
            ...(ibanCode !== undefined && ibanCode !== null && ibanCode !== "" && { IBANcode: ibanCode }),
            lcDraftAttachement: JSON.stringify(LcDraft),
            purchaseOrderAttachment: JSON.stringify(purchaseOrder),
            performaInvoiceAttachment: JSON.stringify(proformaInvoice),
            creditReportAttachment: JSON.stringify(creditReport),
            ...(requesterDetailComments !== undefined && requesterDetailComments !== null && requesterDetailComments !== "" && { requestorComment: requesterDetailComments }),
            ...(approverDetailsComment !== undefined && approverDetailsComment !== null && approverDetailsComment !== "" && { approverComment: approverDetailsComment }),
            ...(letterOfCreditDetailComment !== undefined && letterOfCreditDetailComment !== null && letterOfCreditDetailComment !== "" && { letterOfCreditComment: letterOfCreditDetailComment }),
            // ...(bankChargesComment !== undefined && bankChargesComment !== null && bankChargesComment !== "" && { bankChargesComments: bankChargesComment }),
            ...(attachmentComment !== undefined && attachmentComment !== null && attachmentComment !== "" && { attachmentsComment: attachmentComment }),
            lineOfService: division,
            role: roleName,
            type: type
        };


        await mainStore.editLcInssuance(state?.item?.requestNumber, payload, (response) => {
            if (response?.data?.meta?.status === "success") {
                notify(response?.data?.meta?.message);
                navigate("/requestor-journey-dashboard")
                setIsLoading(false)


            } else {
                notify(response?.data?.meta?.message, "danger");
                setIsLoading(false)

            }
        },
            (error) => {
                notify(error?.response?.data?.meta?.message, "danger");
                setIsLoading(false)

            }
        );
    };


    const handleCreditReportdownload = async (item) => {
        let payload = {
            filename: item
        }
        callDownloadApi(payload)
    }
    const handleProformaDownload = async (item) => {
        let payload = {
            filename: item
        }
        callDownloadApi(payload)
    }
    const handlePurchaseOrderDowmload = async (file) => {
        let payload = {
            filename: file
        }
        callDownloadApi(payload)
    }
    const handleLcDraftDownload = async (item) => {
        let payload = {
            filename: item
        }
        callDownloadApi(payload)
    }


    const callDownloadApi = async (payload) => {
        setIsLoading(true);
        await mainStore.downloadImage(payload, (response) => {
            if (response?.data?.meta?.status === "success") {
                notify(response?.data?.meta?.message);
                setIsLoading(false);
                const base64File = response?.data?.data?.base64File;
                const mimeType = response?.data?.data?.mimeType;
                const fileNameParts = response?.data?.data?.filename?.split("-");
                const fileName = fileNameParts.slice(1).join("-");
                if (base64File && mimeType) {
                    const downloadLink = document.createElement('a');
                    downloadLink.href = `data:${mimeType};base64,${base64File}`;
                    downloadLink.download = fileName;
                    document.body.appendChild(downloadLink);
                    downloadLink.click();
                    document.body.removeChild(downloadLink);
                } else {
                    notify("Download link or File type not found", "danger");
                }
            } else {
                notify(response?.data?.meta?.message, "danger");
                setIsLoading(false);
            }
        },
            (error) => {
                notify(error?.response?.data?.meta?.message, "danger");
                setIsLoading(false);
            });
    };

    const addDaysToDate = (dateString, days) => {
        const date = new Date(dateString);
        date.setDate(date.getDate() + days);
        return date.toISOString()?.split('T')[0];
    };

    const handleDispatchDateChange = (e) => {
        const selectedDate = e.target.value;
        setDispatchDate(selectedDate);
        const presentationDate = addDaysToDate(selectedDate, 21);
        setExpiryDate(presentationDate);
    };

    const handleCreditReport = async (e) => {
        setIsLoading(true)
        const files = e.target.files;
        let formData = new FormData();

        for (let i = 0; i < files.length; i++) {
            formData.append('file', files[i]);
            let params = 'LcRequest'
            await mainStore.uploadfile(params, formData, (response) => {
                if (response?.data?.meta?.status === "success") {
                    notify(response?.data?.meta?.message);
                    setCreditReport(prevState => [...prevState, response.data.data]);
                    setIsLoading(false)
                } else {
                    notify(response?.data?.meta?.message, "danger");
                    setIsLoading(false)
                }
            },
                (error) => {
                    notify(error?.response?.data?.meta?.message, "danger");
                    setIsLoading(false)
                });
            formData.delete('file');
            formData.delete('filepath');
        }
    }

    const handleProforma = async (e) => {
        setIsLoading(true)
        const files = e.target.files;
        let formData = new FormData();

        for (let i = 0; i < files.length; i++) {
            formData.append('file', files[i]);
            let params = 'LcRequest'
            await mainStore.uploadfile(params, formData, (response) => {
                if (response?.data?.meta?.status === "success") {
                    notify(response?.data?.meta?.message);
                    setProformaInvoice(prevState => [...prevState, response.data.data]);
                    setIsLoading(false)
                } else {
                    notify(response?.data?.meta?.message, "danger");
                    setIsLoading(false)
                }
            },
                (error) => {
                    notify(error?.response?.data?.meta?.message, "danger");
                    setIsLoading(false)
                });
            formData.delete('file');
            formData.delete('filepath');
        }
    }

    const handlePurchaseOrder = async (e) => {
        setIsLoading(true)
        const files = e.target.files;
        let formData = new FormData();

        for (let i = 0; i < files.length; i++) {
            formData.append('file', files[i]);
            let params = 'LcRequest'
            await mainStore.uploadfile(params, formData, (response) => {
                if (response?.data?.meta?.status === "success") {
                    notify(response?.data?.meta?.message);
                    setPurchaseOrder(prevState => [...prevState, response.data.data]);
                    setIsLoading(false)
                } else {
                    notify(response?.data?.meta?.message, "danger");
                    setIsLoading(false)
                }
            },
                (error) => {
                    notify(error?.response?.data?.meta?.message, "danger");
                    setIsLoading(false)
                });
            formData.delete('file');
            formData.delete('filepath');
        }
    }

    const handleLcDraft = async (e) => {
        setIsLoading(true)
        const files = e.target.files;
        let formData = new FormData();

        for (let i = 0; i < files.length; i++) {
            formData.append('file', files[i]);
            let params = 'LcRequest'
            await mainStore.uploadfile(params, formData, (response) => {
                if (response?.data?.meta?.status === "success") {
                    notify(response?.data?.meta?.message);
                    setLcDraft(prevState => [...prevState, response.data.data]);
                    setIsLoading(false)
                } else {
                    notify(response?.data?.meta?.message, "danger");
                    setIsLoading(false)
                }
            },
                (error) => {
                    notify(error?.response?.data?.meta?.message, "danger");
                    setIsLoading(false)
                });
            formData.delete('file');
            formData.delete('filepath');
        }
    }


    const getLcAuditDetail = async () => {
        let payload = {}
        await mainStore.getAuditLcDetail(state?.item?.requestNumber, payload, (response) => {
            if (response?.data?.meta?.status === "success") {
                setGetAuditDetail(response?.data?.data?.audit)
            }
            else {
                notify(response?.data?.meta?.message, "danger")
            }
        }, (error) => {
            notify(error?.response?.data?.meta?.message, "danger")
        })
    }

    return (
        <div className="main-container">
            <div className="right-view">
                {isLoading && <Loader />}
                <Header title={"Edit LC > Issuance"} />
                <div className="row">
                    <div className="col-md-12">
                        <CustomCard
                            cardtitle={"Requester"}
                            cardboldtitle={"Details"}
                            cardbody={
                                <div className="row">
                                    <div className="col-md-3">
                                        <CustomInput
                                            label="Request Number"
                                            value={requestNumber}
                                            onChange={(e) => setRequestNumber(e.target.value)}
                                            placeholder="Request Number"
                                            readOnly={true}
                                        />
                                    </div>
                                    <div className="col-md-3">
                                        <CustomInput
                                            require={true}
                                            label="Requester Name"
                                            value={requestName}
                                            readOnly={true}
                                            placeholder="Requester Name"
                                        />
                                    </div>
                                    <div className="col-md-3">
                                        <CustomInput
                                            require={true}
                                            label="Requester Mail ID"
                                            value={requesterMail}
                                            readOnly={true}
                                            placeholder="Requester mail ID"
                                        />
                                    </div>
                                    <div className="col-md-3">
                                        <div className="custominput-container">
                                            <div className="custom-input">
                                                <label htmlFor="" >Entity </label>
                                                <div style={{ position: 'relative' }}>
                                                    <select
                                                        className="form-control"
                                                        value={requestEntity}
                                                        disabled={isReadOnly ? true : false}
                                                        onChange={(e) => { setRequestEntity(e.target.value) }}
                                                    >
                                                        <option value="">Select Entity</option>
                                                        {entity.map((entity, index) => (
                                                            <option key={index} value={entity.name}>
                                                                {entity.name}
                                                            </option>
                                                        ))}
                                                    </select>
                                                    <i className="fas fa-chevron-down approver-drop-down"></i>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col-md-3">
                                        <CustomInput
                                            require={true}
                                            label="Unit"
                                            value={requestUnit}
                                            readOnly={isReadOnly ? true : false}
                                            onChange={(e) => setRequestUnit(e.target.value)}
                                            placeholder="Unit"
                                        />
                                    </div>

                                    <div className="col-md-3">
                                        <div className="custominput-container">
                                            <div className="custom-input">
                                                <label htmlFor="" >Line Of Service  </label>
                                                <div style={{ position: 'relative' }}>
                                                    <select
                                                        className="form-control"
                                                        value={division}
                                                        disabled={isReadOnly ? true : false}
                                                        onChange={handleInputChange}
                                                    >
                                                        <option value="">Select Line Of Service </option>
                                                        {lineOfService.map((item, index) => (
                                                            <option key={index} value={item.value}>
                                                                {item.label}
                                                            </option>
                                                        ))}
                                                    </select>
                                                    <i className="fas fa-chevron-down approver-drop-down"></i>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col-md-3">
                                        <CustomInput
                                            label="Comment"
                                            readOnly={isReadOnly ? true : false}
                                            value={requesterDetailComments}
                                            onChange={(e) => setRequesterDetailComments(e.target.value)}
                                            placeholder="Comment"
                                        />
                                    </div>
                                </div>
                            }
                        />
                    </div>
                    <div className="col-md-12">
                        <CustomCard
                            cardtitle={"Approver"}
                            cardboldtitle={"Details"}
                            cardbody={
                                // <div>
                                //     {approverInfo.map((approver, index) => (
                                //         <div key={index} className="row">
                                //             <div className="col-md-4">
                                //                 <div className="custominput-container">
                                //                     <div className="custom-input">
                                //                         <label htmlFor={`approverName${index}`} >
                                //                             {/* Approver L{index + 1} Name <span style={{ color: 'red' }}>*</span> */}
                                //                             {index === 0 && <>Approver Purchase <span style={{ color: 'red' }}>*</span></>}
                                //                             {index === 1 && <>Approver Finance <span style={{ color: 'red' }}>*</span></>}
                                //                         </label>
                                //                         <div style={{ position: 'relative' }}>
                                //                             <select
                                //                                 id={`approverName${index}`}
                                //                                 className="form-control"
                                //                                 value={approver.approverName}
                                //                                 disabled={isReadOnly ? true : false}
                                //                                 onChange={(e) => {
                                //                                     const selectedApprover = approverList.find(approver => approver.name === e.target.value);
                                //                                     handleApproverChange(index, "approverName", e.target.value, selectedApprover.approverLevel);
                                //                                     handleApproverChange(index, "approverId", selectedApprover.id);
                                //                                     handleApproverChange(index, "approverEmail", selectedApprover.email, selectedApprover.approverLevel);
                                //                                 }}
                                //                             >
                                //                                 <option value="">Select Approver</option>
                                //                                 {index === 0 && approverList.filter((approver) => approver.user_department === "Purchase").map((filteredApprover, filteredIndex) => (
                                //                                     <option key={filteredIndex} value={filteredApprover.name}>{filteredApprover.name}</option>
                                //                                 ))}
                                //                                 {index === 1 && approverList.filter((approver) => approver.user_department === "Finance").map((filteredApprover, filteredIndex) => (
                                //                                     <option key={filteredIndex} value={filteredApprover.name}>{filteredApprover.name} </option>
                                //                                 ))}
                                //                             </select>
                                //                             <i className="fas fa-chevron-down approver-drop-down"></i>
                                //                         </div>
                                //                     </div>
                                //                 </div>
                                //             </div>
                                //             <div className="col-md-4">
                                //                 <CustomInput
                                //                     readOnly={true}
                                //                     label="Department"
                                //                     value={approver.approverDepartment}
                                //                     onChange={(e) => handleApproverChange(index, "approverDepartment", e.target.value)}
                                //                     placeholder="Department"
                                //                 />
                                //             </div>
                                //             <div className="col-md-4">
                                //                 <CustomInput
                                //                     require={true}
                                //                     readOnly={true}
                                //                     label="Mail ID"
                                //                     value={approver.approverEmail}
                                //                     onChange={(e) => handleApproverChange(index, "approverEmail", e.target.value)}
                                //                     placeholder="Mail ID"
                                //                 />
                                //             </div>
                                //         </div>))}
                                //     <div className="col-md-4">
                                //         <CustomInput
                                //             label="Comment"
                                //             readOnly={isReadOnly ? true : false} s
                                //             value={approverDetailsComment}
                                //             onChange={(e) => setApproverDetailsComment(e.target.value)}
                                //             placeholder="Comment"
                                //         />
                                //     </div>
                                // </div>

                                <div>
                                {approverInfo.map((approver, index) => (
                                  <div key={index} className="row">
                                    <div className="col-md-4">
                                      <div className="custominput-container">
                                        <div className="custom-input">
                                          <label htmlFor={`approverName${index}`}>
                                            Approver L{index + 1} Name{" "}
                                            <span style={{ color: "red" }}>*</span>
                                          </label>
                                          <div style={{ position: "relative" }}>
                                            <select
                                              id={`approverName${index}`}
                                              className="form-control"
                                              value={approver.approverName}
                                              disabled={isReadOnly ? true : false}
                                              onChange={(e) => {
                                                const selectedApprover = approverList.find((approver) => approver.name === e.target.value);
                                                handleApproverChange(index,"approverName",e.target.value);
                                                handleApproverChange(index,"approverId",selectedApprover.id );
                                                handleApproverChange( index,"approverEmail",selectedApprover.email );
                                                handleApproverChange(index,"approverDepartment",selectedApprover.user_department);
                                              }}
                                            >
                                              <option value="">Select Approver</option>
                                              {index === 0 &&approverList.filter((approver) =>approver.user_department === "Purchase").map((filteredApprover, filteredIndex) => (
                                                    <option key={filteredIndex}value={filteredApprover.name}>{filteredApprover.name}</option>
                                                  ))}
                                              {index === 1 &&approverList.filter((approver) =>approver.user_department === "Finance").map((filteredApprover, filteredIndex) => (
                                                    <option key={filteredIndex}value={filteredApprover.name}>{filteredApprover.name} </option>
                                                  ))}
                                            </select>
                                            <i className="fas fa-chevron-down approver-drop-down"></i>
                                          </div>
                                        </div>
                                      </div>
                                    </div>
                                    <div className="col-md-4">
                                      <CustomInput
                                        require={true}
                                        readOnly={true}
                                        label="Department"
                                        value={approver.approverDepartment}
                                        onChange={(e) =>
                                          handleApproverChange(
                                            index,
                                            "approverDepartment",
                                            e.target.value
                                          )
                                        }
                                        placeholder="Department"
                                      />
                                    </div>
                                    <div className="col-md-4">
                                      <CustomInput
                                        require={true}
                                        readOnly={true}
                                        label="Mail ID"
                                        value={approver.approverEmail}
                                        onChange={(e) =>
                                          handleApproverChange(
                                            index,
                                            "approverEmail",
                                            e.target.value
                                          )
                                        }
                                        placeholder="Mail ID"
                                      />
                                    </div>
                                  </div>
                                ))}
                                    <div className="col-md-4">
                                         <CustomInput
                                            label="Comment"
                                            readOnly={isReadOnly ? true : false} s
                                            value={approverDetailsComment}
                                            onChange={(e) => setApproverDetailsComment(e.target.value)}
                                            placeholder="Comment"
                                        />
                                    </div>
                              </div>
                            }
                        />
                    </div>

                    <div className="col-md-12">
                        <CustomCard
                            cardtitle={"Bank "}
                            cardboldtitle={"Details"}
                            cardbody={
                                <div className="row">
                                    <div className="col-md-3">
                                        <CustomInput
                                            readOnly={true}
                                            label="Bank Name"
                                            value={bankName}
                                            onChange={(e) => setBankName(e.target.value)}
                                            placeholder="Bank Name"
                                        />
                                    </div>
                                    <div className="col-md-3">
                                        <CustomInput
                                            readOnly={true}
                                            label="Bank Account Number"
                                            value={bankAccountNumber}
                                            onChange={(e) => setBankAccountNumber(e.target.value)}
                                            placeholder="Bank Account Number"
                                        />
                                    </div>
                                    <div className="col-md-3">
                                        <CustomInput
                                            readOnly={true}
                                            label="Bank GL Code"
                                            value={bankGlCode}
                                            onChange={(e) => setBankGlCode(e.target.value)}
                                            placeholder="Bank GL Code"
                                        />
                                    </div>
                                    <div className="col-md-3">
                                        <CustomInput
                                            label="Comment"
                                            readOnly={true}
                                            value={bankDetailComment}
                                            onChange={(e) => setBankDetailComment(e.target.value)}
                                            placeholder="Comment"
                                        />
                                    </div>
                                </div>
                            }
                        />
                    </div>

                    <div className="col-md-12">
                        <CustomCard
                            cardtitle={"Letter of Credit"}
                            cardboldtitle={"Details"}
                            cardbody={
                                <div className="row">
                                    <div className="col-md-3">
                                        <CustomInput
                                            label="Reference Number"
                                            readOnly={true}
                                            value={refrenceNumber}
                                            onChange={(e) => setRefrenceNumber(e.target.value)}
                                            placeholder="Reference Number"
                                        />
                                    </div>
                                    <div className="col-md-3">
                                        <div className="custominput-container">
                                            <div className="custom-input">
                                                <label htmlFor="" >Type of LC <span style={{ color: 'red' }}>*</span></label>
                                                <div style={{ position: 'relative' }}>
                                                    <select
                                                        className="form-control"
                                                        value={typeOfLc}
                                                        disabled={isReadOnly ? true : false}
                                                        onChange={(e) => setTypeOfLc(e.target.value)}
                                                    >
                                                        <option value="">Select Type of LC</option>
                                                        {typeOfLcList.map((item, index) => (
                                                            <option key={index} value={item.name}>
                                                                {item.name}
                                                            </option>
                                                        ))}
                                                    </select>
                                                    <i className="fas fa-chevron-down approver-drop-down"></i>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col-md-3">
                                        <CustomInput
                                            label="Issuance Date"
                                            value={inssuanceDate && getFormattedDate(inssuanceDate)}
                                            readOnly={true}
                                            onChange={(e) => setInssuanceDate(e.target.value)}
                                            placeholder="Issuance Date"
                                        />
                                    </div>
                                    <div className="col-md-3">
                                        <CustomInput
                                            require={true}
                                            readOnly={isReadOnly ? true : false}
                                            type="date"
                                            label="Date of Shipment/dispatch"
                                            value={dispatchDate && getFormattedDate(dispatchDate)}
                                            onChange={handleDispatchDateChange}
                                            placeholder="Date of Shipment/dispatch"
                                        />
                                    </div>
                                    <div className="col-md-3">
                                        <CustomInput
                                            require={true}
                                            readOnly={true}
                                            type="date"
                                            label="Expiry Date"
                                            value={ expiryDate && getFormattedDate(expiryDate)}
                                            onChange={(e) => setExpiryDate(e.target.value)}
                                            placeholder="Expiry Date"
                                        />
                                    </div>
                                    <div className="col-md-3">
                                        <CustomInput
                                            require={true}
                                            readOnly={isReadOnly ? true : false}
                                            label="Place of Expiry"
                                            value={placeOfExpiry}
                                            onChange={(e) => setPlaceOfExpiry(e.target.value)}
                                            placeholder="Place of Expiry"
                                        />
                                    </div>
                                    <div className="col-md-3">
                                        <div className="custominput-container">
                                            <div className="custom-input">
                                                <label htmlFor="" >Currency <span style={{ color: 'red' }}>*</span></label>
                                                <div style={{ position: 'relative' }}>
                                                    <select
                                                        className="form-control"
                                                        value={currency}
                                                        disabled={isReadOnly ? true : false}
                                                        onChange={(e) => setCurrency(e.target.value)}
                                                    >
                                                        <option value="">Select Currency</option>
                                                        {currencyList.map((item, index) => (
                                                            <option key={index} value={item.name}>
                                                                {item.name}
                                                            </option>
                                                        ))}
                                                    </select>
                                                    <i className="fas fa-chevron-down approver-drop-down"></i>
                                                </div>
                                            </div>
                                        </div>
                                    </div>

                                    <div className="col-md-3">
                                        <CustomInput
                                            require={true}
                                            label="Amount"
                                            readOnly={isReadOnly ? true : false}
                                            value={ammount}
                                            onChange={(e) => setAmmount(e.target.value)}
                                            placeholder="Amount"
                                        />
                                    </div>
                                    <div className="col-md-3">
                                        <div className="custominput-container">
                                            <div className="custom-input">
                                                <label htmlFor="" >Name of Applicant <span style={{ color: 'red' }}>*</span> </label>
                                                <div style={{ position: 'relative' }}>
                                                    <select
                                                        className="form-control"
                                                        value={nameOfApplicant}
                                                        onChange={(e) => { setNameOfApplicant(e.target.value) }}
                                                        disabled={isReadOnly ? true : false}
                                                    >
                                                        <option value="">Select Name of Applicant</option>
                                                        {entity.map((entity, index) => (
                                                            <option key={index} value={entity.name}>
                                                                {entity.name}
                                                            </option>
                                                        ))}
                                                    </select>
                                                    <i className="fas fa-chevron-down approver-drop-down"></i>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col-md-3">
                                        <CustomInput
                                            require={true}
                                            readOnly={isReadOnly ? true : false}
                                            label="Address of Applicant"
                                            value={applicantAddress}
                                            onChange={(e) => setApplicantAddress(e.target.value)}
                                            placeholder="Address of Applicant"
                                        />
                                    </div>
                                    <div className="col-md-3">
                                        <CustomInput
                                            require={true}
                                            label="Name of Beneficiary"
                                            readOnly={isReadOnly ? true : false}
                                            value={beneficiaryName}
                                            onChange={(e) => setBeneficiaryName(e.target.value)}
                                            placeholder="Name of Beneficiary"
                                        />
                                    </div>
                                    <div className="col-md-3">
                                        <CustomInput
                                            require={true}
                                            label="Address of Beneficiary"
                                            value={beneficiaryLocation}
                                            readOnly={isReadOnly ? true : false}
                                            onChange={(e) => setBeneficiaryLocation(e.target.value)}
                                            placeholder="Address of Beneficiary"
                                        />
                                    </div>
                                    <div className="col-md-3">
                                        <CustomInput
                                            require={true}
                                            label="Purchase Order No."
                                            value={purchaseOrderNumber}
                                            readOnly={isReadOnly ? true : false}
                                            onChange={(e) => setPurchaseOrderNumber(e.target.value)}
                                            placeholder="Purchase Order No."
                                        />
                                    </div>
                                    <div className="col-md-3">
                                        <CustomInput
                                            require={true}
                                            label="Purchase Order Date"
                                            type="date"
                                            readOnly={isReadOnly ? true : false}
                                            value={purchaseOrderDate && getFormattedDate(purchaseOrderDate)}
                                            onChange={(e) => setPurchaseOrderDate(e.target.value)}
                                            placeholder="Purchase Order Date"
                                        // min={new Date().toISOString()?.split("T")[0]}
                                        />
                                    </div>
                                    <div className="col-md-3">
                                        <CustomInput
                                            require={true}
                                            label="Beneficiary Bank Name"
                                            value={beneficiaryBankName}
                                            readOnly={isReadOnly ? true : false}
                                            onChange={(e) => setBeneficiaryBankName(e.target.value)}
                                            placeholder="Beneficiary Bank Name"
                                        />
                                    </div>
                                    <div className="col-md-3">
                                        <CustomInput
                                            require={true}
                                            label="Beneficiary Bank Address"
                                            value={beneficiaryBankAddress}
                                            readOnly={isReadOnly ? true : false}
                                            onChange={(e) => setBeneficiaryBankAddress(e.target.value)}
                                            placeholder="Beneficiary Bank Address"
                                        />
                                    </div>
                                    <div className="col-md-3">
                                        <CustomInput
                                            require={true}
                                            label="Beneficiary Bank Swift Code"
                                            readOnly={isReadOnly ? true : false}
                                            value={beneficiaryBankSwiftCode}
                                            onChange={(e) => setBeneficiaryBankSwiftCode(e.target.value)}
                                            placeholder="Beneficiary Bank Swift Code"
                                        />
                                    </div>
                                    <div className="col-md-3">
                                        <CustomInput
                                            require={true}
                                            label="Mode of Shipment"
                                            value={modeOfShipment}
                                            onChange={(e) => setModeOfShipment(e.target.value)}
                                            placeholder="Mode of Shipment"
                                            readOnly={isReadOnly ? true : false}
                                        />
                                    </div>
                                    <div className="col-md-3">
                                        <CustomInput
                                            require={true}
                                            label="Quantity Tolerance"
                                            value={quantityTolerance}
                                            onChange={(e) => setQuantityTolerance(e.target.value)}
                                            placeholder="Quantity Tolerance"
                                            readOnly={isReadOnly ? true : false}
                                        />
                                    </div>
                                    <div className="col-md-3">
                                        <CustomInput
                                            require={true}
                                            label="Value Tolerance"
                                            value={valueTolerance}
                                            onChange={(e) => setValueTolerance(e.target.value)}
                                            placeholder="Value Tolerance"
                                            readOnly={isReadOnly ? true : false}
                                        />
                                    </div>
                                    <div className="col-md-3">
                                        <CustomInput
                                            require={true}
                                            label="Drafts At"
                                            value={draftsAt}
                                            onChange={(e) => setDraftAt(e.target.value)}
                                            placeholder="Drafts At"
                                            readOnly={isReadOnly ? true : false}
                                        />
                                    </div>
                                    <div className="col-md-3">
                                        <CustomInput
                                            require={true}
                                            label="Dispatch From"
                                            value={dispatchFrom}
                                            onChange={(e) => setDispatchFrom(e.target.value)}
                                            placeholder="Dispatch From"
                                            readOnly={isReadOnly ? true : false}
                                        />
                                    </div>

                                    <div className="col-md-3">
                                        <CustomInput
                                            require={true}
                                            label="Description of Goods"
                                            value={descriptionOfGood}
                                            onChange={(e) => setDescriptionOfGood(e.target.value)}
                                            placeholder="Description of Goods"
                                            readOnly={isReadOnly ? true : false}
                                        />
                                    </div>
                                    <div className="col-md-3">
                                        <CustomInput
                                            require={true}
                                            label="HSN Code"
                                            value={hsnCode}
                                            onChange={(e) => setHsnCode(e.target.value)}
                                            placeholder="HSN Code"
                                            readOnly={isReadOnly ? true : false}
                                        />
                                    </div>
                                    <div className="col-md-3">
                                        <CustomInput
                                            label="IBAN Code"
                                            value={ibanCode}
                                            onChange={(e) => setIbanCode(e.target.value)}
                                            placeholder="IBAN Code"
                                            readOnly={isReadOnly ? true : false}
                                        />
                                    </div>
                                    <div className="col-md-3">
                                        <CustomInput
                                            label="Comment"
                                            value={letterOfCreditDetailComment}
                                            onChange={(e) => setLetterOfCreditDetailComment(e.target.value)}
                                            placeholder="Comment"
                                            readOnly={isReadOnly ? true : false}
                                        />
                                    </div>
                                </div>
                            }
                        />
                    </div>

                    <div className="col-md-12">
                        <CustomCard
                            cardtitle={"Bank Charges"}
                            cardboldtitle={"Details"}
                            cardbody={
                                <div className="row">
                                    <div className="col-md-3">
                                        <CustomInput
                                            label="LC Charges"
                                            readOnly={true}
                                            value={bgCharges}
                                            onChange={(e) => setBgCharges(e.target.value)}
                                            placeholder="BG Charges"
                                        />
                                    </div>
                                    <div className="col-md-3">
                                        <CustomInput
                                            label="GST"
                                            value={gst}
                                            readOnly={true}
                                            onChange={(e) => setGst(e.target.value)}
                                            placeholder="GST"
                                        />
                                    </div>
                                    <div className="col-md-3">
                                        <CustomInput
                                            readOnly={true}
                                            label="Bank Charges GL Code"
                                            value={bgChargesGlCode}
                                            onChange={(e) => setBgChargesGlCode(e.target.value)}
                                            placeholder="Bank Charges GL Code"
                                        />
                                    </div>
                                    <div className="col-md-3">
                                        <CustomInput
                                            readOnly={true}
                                            label="GST GL Code"
                                            value={gstGlCode}
                                            onChange={(e) => setGstGlCode(e.target.value)}
                                            placeholder="GST GL Code"
                                        />
                                    </div>
                                    <div className="col-md-3">
                                        <CustomInput
                                            readOnly={true}
                                            label="Comment"
                                            value={bankChargesComment}
                                            onChange={(e) => setBankChargesComment(e.target.value)}
                                            placeholder="Comment"
                                        />
                                    </div>
                                </div>
                            }
                        />
                    </div>

                    <div className="col-md-12">
                        <CustomCard
                            cardboldtitle={"Attachments"}
                            cardbody={
                                <div className="row">
                                    <div className="col-md-3">
                                        <CustomInput
                                            require={true}
                                            label="LC Draft"
                                            // onChange={handleLcDraft}
                                            // type="file"
                                            {...(isReadOnly ? { type: "text" }  : { type: "file", onChange: handleLcDraft })}
                                            placeholder="Browse File"
                                            readOnly={isReadOnly ? true : false}
                                        />
                                        <div className="uploaded-images">
                                            {LcDraft.map((item, index) => (
                                                <div key={index} style={{ cursor: "pointer", color: "#65a6fa", marginBottom: '8px' }} onClick={() => handleLcDraftDownload(item)} className="url-download">
                                                    {item}
                                                </div>
                                            ))}
                                        </div>
                                    </div>
                                    <div className="col-md-3">
                                        <CustomInput
                                            require={true}
                                            label="Purchase Order"
                                            // onChange={handlePurchaseOrder}
                                            {...(isReadOnly ? { type: "text" }  : { type: "file", onChange: handlePurchaseOrder })}
                                            placeholder="Browse File"
                                            readOnly={isReadOnly ? true : false}
                                        />
                                        <div className="uploaded-images">
                                            {purchaseOrder.map((item, index) => (
                                                <div key={index} style={{ cursor: "pointer", color: "#65a6fa", marginBottom: '8px' }} onClick={() => handlePurchaseOrderDowmload(item)} className="url-download">
                                                    {item}
                                                </div>
                                            ))}
                                        </div>
                                    </div>
                                    <div className="col-md-3">
                                        <CustomInput
                                            require={true}
                                            label="Proforma Invoice"
                                            placeholder="Browse File"
                                            // type="file"
                                            // onChange={handleProforma}
                                            {...(isReadOnly ? { type: "text" }  : { type: "file", onChange: handleProforma })}
                                            readOnly={isReadOnly ? true : false}
                                        />
                                        <div className="uploaded-images">
                                            {proformaInvoice.map((item, index) => (
                                                <div key={index} style={{ cursor: "pointer", color: "#65a6fa", marginBottom: '8px' }} onClick={() => handleProformaDownload(item)} className="url-download">
                                                    {item}
                                                </div>
                                            ))}
                                        </div>
                                    </div>
                                    <div className="col-md-3">
                                        <CustomInput
                                            require={selectedTypeOfLC ? false : true}
                                            label="Credit Report"
                                            // type="file"
                                            // onChange={handleCreditReport}
                                            {...(isReadOnly ? { type: "text" }  : { type: "file", onChange: handleCreditReport })}
                                            placeholder="Browse File"
                                            readOnly={isReadOnly ? true : false}
                                        />
                                        <div className="uploaded-images">
                                            {creditReport.map((item, index) => (
                                                <div key={index} style={{ cursor: "pointer", color: "#65a6fa", marginBottom: '8px' }} onClick={() => handleCreditReportdownload(item)} className="url-download">
                                                    {item}
                                                </div>
                                            ))}
                                        </div>
                                    </div>
                                    <div className="col-md-3">
                                        <CustomInput
                                            label="Comment"
                                            value={attachmentComment}
                                            readOnly={isReadOnly ? true : false}
                                            onChange={(e) => setAttachmentComment(e.target.value)}
                                            placeholder="Comment"
                                        />
                                    </div>
                                    <div className="col-md-12">
                                        <CustomCard
                                            cardtitle={"Audit"}
                                            cardboldtitle={" trail"}
                                            toprightview={
                                                <button className="export-btn">
                                                    <ExcelFile element={<div> <img width={20} className="me-2" src={require("../../assets/images/excel.png")} />Export</div>}>
                                                        <ExcelSheet data={getAuditDetail?.map((item, i) => ({
                                                            ...item, index: i + 1, role: item?.role,
                                                            created_at: item?.created_at && `${item.created_at?.split("T")[0]} ${item.created_at?.split("T")[1]?.split(".")[0].substring(0, 5)}`,
                                                        }))} name="Sheet">
                                                            <ExcelColumn label="Status Date & Time" value="created_at" />
                                                            <ExcelColumn label="Name" value="userName" />
                                                            <ExcelColumn label="Role" value="role" />
                                                            <ExcelColumn label="Action Required" value="status" />
                                                        </ExcelSheet>
                                                    </ExcelFile>
                                                </button>
                                            }
                                            cardbody={
                                                <React.Fragment>
                                                    <div class="table-responsive">
                                                        <table class="table">
                                                            <thead class="table-customlight">
                                                                <tr>
                                                                    <th className="auditTrail">Status Date & Time</th>
                                                                    <th className="auditTrail">Name</th>
                                                                    <th className="auditTrail">Role</th>
                                                                    <th className="auditTrail">Action Required</th>
                                                                </tr>
                                                            </thead>
                                                            {getAuditDetail?.length > 0 && (
                                                                <tbody>
                                                                    {getAuditDetail?.map((item, i) => {
                                                                        return (
                                                                            <tr key={item?.requestNumber}>
                                                                                <td scope="row"><FormatDate dateString={item?.created_at} /> </td>
                                                                                <td>{item?.userName}</td>
                                                                                <td>{item?.role}</td>
                                                                                <td className="status-box"><span className="status"></span>{item?.status}</td>

                                                                            </tr>)
                                                                    })}
                                                                </tbody>)}
                                                        </table>
                                                    </div>
                                                </React.Fragment>
                                            }
                                        />
                                    </div>
                                </div>
                            }
                        />
                    </div>

                    <div className="col-md-12">
                        <CustomCard
                            cardtitle={""}
                            cardboldtitle={"Chat"}
                            cardbody={
                                <div className="row">
                                    {chat?.length > 0 && (
                                        <>
                                            {chat?.map((item, i) => (
                                                <div key={i} className="col-md-12 chat-left">
                                                    <div className="chat-container">
                                                        <h4 className="chat-username">{item.userName}({item?.userRole})</h4>
                                                        <div className="chat-username-box">
                                                            <p className="chat-massege">{item?.message}</p>
                                                            <p className="chat-time"><FormatDate dateString={item?.created_at} /></p>
                                                        </div>
                                                    </div>
                                                </div>
                                            ))}
                                            <div className="col-md-6 " style={{ width: "44%" }}>
                                                <CustomInput
                                                    label="Comment Box"
                                                    value={chatComment}
                                                    onChange={(e) => setChatComment(e.target.value)}
                                                    placeholder="Comment Box"
                                                />
                                            </div>
                                            <div className="col-md-2 chat-button">
                                                <CustomButton
                                                    style={"btn--outline me-3"}
                                                    children={"Send"}
                                                    onClick={handleSendMassege}
                                                />
                                            </div>
                                        </>
                                    )}
                                </div>
                            }
                        />
                    </div>

                    {!isReadOnly  && <div className="col-md-12 text-end">
                        <CustomButton style={"btn--outline me-3"} children={"Save"} onClick={() => { submitForApproval("save") }} />
                        <CustomButton children={"Submit for approval"} onClick={() => { submitForApproval("submit") }} />
                    </div>}
                </div>
            </div>
        </div>
    );
};

export default EditLcRequest;

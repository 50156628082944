import React from "react";
import Modal from "react-bootstrap/Modal";
import "./style.scss";
import crossImage from "../../assets/images/close (4).png";
import CustomButton from "../Button/Button";
import CustomInput from "../CustomInput";
import Textarea from "../CustomeTextarea";

const AddTRNumberModal = (props) => {


    return (
        <Modal {...props} centered className="sucess-content sucess_content_modal">
            <Modal.Body className="sucess-modal py-5">
                <div className="modal-container">
                    <img
                        src={crossImage}
                        alt=""
                        className="cross-img"
                        onClick={() => props.hide()}
                    />
                    <div className="modal_submit ">
                        <h4 className="success_title1" style={{ textAlign: "center" }}>
                            Add TR Number
                        </h4>
                        <div className="addName-model">
                            <div className="container-fluid">
                                <div className="row">
                                    <div className="col-md-6 text-start">
                                        <CustomInput 
                                        label={'Number'}
                                         placeholder={'Enter Number'}
                                         value={props?.trNo}
                                         onChange={(e) =>props?.handleTR(e.target.value)}
                                          require
                                          />
                                    </div>
                                    
                                    <div className="col-md-12 text-start">
                                        <Textarea 
                                        label={'Description'} 
                                        require
                                         style={'textarea_bg'}
                                         value={props?.description} 
                                         onChange={(e) => props?.handleDesc(e.target.value)}
                                        placeholder={'Please write a Description....'}
                                         defaultValue/>
                                    </div>


                                </div>
                            </div>

                        </div>
                        <CustomButton
                            children={"Submit"}
                            style={'btn--approved--solid'}
                            onClick={props?.handleSendTrDetails}
                        />

                    </div>
                </div>

            </Modal.Body>
        </Modal>
    );
};

export default AddTRNumberModal;

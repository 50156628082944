import React from 'react'
import './btnStyle.scss'

const STYLES = [
    "btn--solid",
    "btn--outline",
    "btn--approved--solid",
    "btn--onHold--solid",
    "mr-10",
    "full-btn"
]

const SIZES=["btn--medium", "btn--large"]

const CustomButton = ({
    children,
    onClick,
    buttonStyle,
    buttonSize,
    style,
    styles
}) => {

    const checkButtonStyle = STYLES.includes(buttonStyle) ? buttonStyle : STYLES[0];

    const checkButtonSize = SIZES.includes(buttonSize) ? buttonSize : SIZES[1];
     

    return (
        <button type='button' onClick={onClick} style={styles} className={[`custom-btn ${checkButtonStyle} ${checkButtonSize} ${style}`]} >{children}</button>
    )
}

export default CustomButton;
import React, { useState, useEffect } from "react";
import Header from "../../../../component/Header";
import CustomCard from "../../../../component/CustomeCard";
import "./style.scss";
import CustomInput from "../../../../component/CustomInput";
import CustomButton from "../../../../component/Button/Button";
import TextEditor from "../../../../component/CustomeTextEditior";
import Textarea from "../../../../component/CustomeTextarea";
import Tab from 'react-bootstrap/Tab';
import Tabs from 'react-bootstrap/Tabs';
import commentImg from '../../../../assets/images/comment-img.png';
import commentImg1 from '../../../../assets/images/comment1-img.png';
import { useLocation, useNavigate } from "react-router-dom"
import ReactExport from "react-export-excel";
import FormatDate from "../../../../component/helper/formatDate/formateDate";
import notify from "../../../../shared/notify";
import { useStores } from "../../../../stores";
import TicketSubmitModal from "../../../../component/CRMModuleComponent/ticketSubmitModal";
import ApprovalModal from "../../../../component/CRMModuleComponent/approvalModal";
import ApprovalModal1 from "../../../../component/CRMModuleComponent/approvalModal";
import ConsultantModal from "../../../../component/CRMModuleComponent/consultantModal";
import Loader from "../../../../component/helper/Loader/loader";
import commentIcon from '../../../../assets/images/send-comment.png';
import AddTRNumberModal from "../../../../component/CRMModuleComponent/addTRNumberModal";
import SubmitTicketModal from "../../../../component/CRMModuleComponent/successModal";
import RejectedTicketModal from "../../../../component/CRMModuleComponent/rejectedModal";
import L3ApprovalModal from "../../../../component/CRMModuleComponent/L3ApprovalModal";



const ExcelFile = ReactExport.ExcelFile;
const ExcelSheet = ReactExport.ExcelFile.ExcelSheet;
const ExcelColumn = ReactExport.ExcelFile.ExcelColumn;

const ViewTickets = () => {
    const { state } = useLocation();
    const navigate = useNavigate()
    const [isLoading, setIsLoading] = useState(false)
    const [auditTrail, setAuditTrail] = useState([])
    const [showAll, setShowAll] = useState(false);
    const { crmStore } = useStores()
    let fetchRole = sessionStorage.getItem("role")
    const role = fetchRole?.split(",")
    let sessions = sessionStorage.getItem("candidate")
    let session = JSON.parse(sessions)
    let crmApproverL2 = ["L2"]
    let crmApproverL3 = ["L3"]
    let approver = ["L2", "L3"]
    const [submitModal, setSubmitModal] = useState(false)
    const [appeovalModal, setApprovalModal] = useState(false)
    const [consulantModal, setConsulantlModal] = useState(false)
    const [comment, setComment] = useState("")
    const [repComment, setRepComment] = useState("")
    const [newState, setNewState] = useState(state?.item)
    const [complaintStatusList, setComplaintStatusList] = useState([])
    const [selectedComplaintStatus, setSelectedComplaintStatus] = useState("")
    const [commentText, setCommentText] = useState("")
    const [approvalList, setApprovalList] = useState([])
    const [selectedApprover, setSelectedApprover] = useState("")
    const [supportList, setSupportList] = useState([])
    const [supportListL3, setSupportListL3] = useState([])
    let forwardList = [
        { value: "Yes", label: "Yes" },
        { value: "No", label: "No" },
    ]
    const [selectedSupportLevel, setSelectedSupportLevel] = useState("")
    const [outsourceConsultantList, setOutsourceConsultantList] = useState([])
    const [selectedOutsourceConsultant, setSelectedOutsourceConsultant] = useState("")
    const [addTRmodal, setAddTRModal] = useState(false)
    const [trNo, setTrNo] = useState("")
    const [description, setDescription] = useState(newState?.description)
    const [sendL1Modal, SetSendL1Modal] = useState(false)
    const [rejectModal, setRejectModal] = useState(false);
    const [approveL2Modal, setApproveL2Modal] = useState(false)
    const [approverL3Modal, setApprovarL3Modal] = useState(false)
    const [accRes, setAccRes] = useState(newState?.status_id);
    const [selectedForward, setSelectedForward] = useState("")

    const openTRmodal = () => {
        setAddTRModal(true)
    }
    const handleSendTrDetails = async () => {
        let payload = {
            "tr_numbers": {
                tr_number: trNo,
                description: description
            }
        }
        setIsLoading(true)
        await crmStore.addTdDetails(newState?.complaint_id, payload, (res) => {
            if (res?.data?.meta?.status == "success") {
                notify(res?.data?.meta?.message)
                setIsLoading(false)
                setAddTRModal(false)
                setTrNo("")
                fetchAuditTrail()
                setDescription("")
                CallFetchedComplain()
            }
        }, (err) => {
            notify(err?.response?.data?.meta?.message, "danger")
            setIsLoading(false)
        })

    }


    const toggleVisibility = () => {
        setShowAll(prevState => !prevState);
    };
    const dataToDisplay = showAll ? auditTrail : [auditTrail[0]]



    useEffect(() => {
        fetchAuditTrail()
        CallFetchedComplain()
        CallFetchedStatus()
        setSupportList([{ id: 2, name: 'L2' }])
        setSupportListL3([{ id: 3, name: 'L3' }])
        CallFetchedOutsource();
    }, [])

    const fetchAuditTrail = async () => {
        await crmStore.auditTrailCms(newState?.complaint_id, (status, res) => {
            if (res?.meta?.status == "success") {
                setAuditTrail(res?.data?.auditTrailRecords)
            } else {
                console.log(res?.meta?.message, "error");

            }
        }, (error) => {
            notify(error?.response?.data?.meta?.message, "danger")
        })
    }

    const getFormattedDate = (dateString) => {
        const date = new Date(dateString);
        const year = date.getFullYear();
        const monthNames = ["Jan", "Feb", "Mar", "Apr", "May", "Jun", "Jul", "Aug", "Sep", "Oct", "Nov", "Dec"];
        const month = monthNames[date.getMonth()];
        const day = date.getDate();

        return `${month} ${day}, ${year}`;
    };



    const AddComment = async (type) => {
        let payload = {
            comment: type == "public" ? repComment : comment,
            type: type
        }
        setIsLoading(true)
        await crmStore.addComment(newState?.complaint_id, payload, (res) => {
            if (res?.data?.meta?.status == "success") {
                notify(res?.data?.meta?.message)
                CallFetchedComplain()
                fetchAuditTrail()
                setIsLoading(false)
                setComment("")
                setRepComment("")
            }
        }, (error) => {
            notify(error?.response?.data?.meta?.message, "danger")
            setIsLoading(false)
        })
    }

    const CallFetchedComplain = async () => {
        setIsLoading(true)
        await crmStore.getCompById(newState?.complaint_id, (status, res) => {
            if (res?.meta?.status === "success") {
                setNewState(res?.data?.complaintDetail)
                setAccRes(res?.data?.complaintDetail?.status_id)
                setIsLoading(false)
            }

        }, (err) => {
            notify(err?.response?.data?.meta?.message, "danger")
            setIsLoading(false)
        })
    }

    const CallFetchedStatus = async () => {
        setIsLoading(true)
        await crmStore.getStatus(newState?.complaint_id, (status, res) => {
            if (res?.meta?.status === "success") {
                setComplaintStatusList(res.data.complaintStatuses);
                setIsLoading(false);
            } else {
                setIsLoading(false);
            }
        }, (error) => {
            notify(error?.response?.data?.meta?.message, "danger");
            setIsLoading(false);
        });
    }

    const CallFetchedApprovals = async (id) => {
        if (id == "" || id == undefined || id == null || id == "NA") {
            return notify("Please select support level", "danger")
        }
        setIsLoading(true)
        await crmStore.getApprovals(id, (status, res) => {
            if (res?.meta?.status === "success") {
                setApprovalList(res.data.approversList);
                setIsLoading(false);
            } else {
                setIsLoading(false);
            }
        }, (error) => {
            notify(error?.response?.data?.meta?.message, "danger");
            setIsLoading(false);
        });
    }
    const CallFetchedOutsource = async (id) => {
        setIsLoading(true)
        await crmStore.getOutsourceCons(newState?.complaint_id, (status, res) => {
            if (res?.meta?.status === "success") {
                setOutsourceConsultantList(res.data.outsourceConsultantList);
                setIsLoading(false);
            } else {
                setIsLoading(false);
            }
        }, (error) => {
            notify(error?.response?.data?.meta?.message, "danger");
            setIsLoading(false);
        });
    }

    const SubmitComplaint = async (flag) => {
        let payload = {

            comment: commentText,
        }
        if (flag == 'status') {
            if (selectedComplaintStatus.length == 0) {
                notify('Please select "Status"', "danger")
                return false;
            }
            payload.type = "public"
            payload.selectedStatusId = selectedComplaintStatus
        }


        if (flag == 'approver') {
            if (selectedSupportLevel.length == 0) {
                notify('Please select "Support Level"', "danger")
                return false;
            }
            if (selectedApprover.length == 0) {
                notify('Please select "Approver"', "danger")
                return false;
            }
            payload.type = "internal"
            payload.assignSupportLevelId = selectedSupportLevel;
            payload.assignApproverId = selectedApprover;
        }


        if (flag == 'outsource') {
            if (selectedOutsourceConsultant.length == 0) {
                notify('Please select "Outsource Consultant"', "danger")
                return false;
            }
            payload.type = "internal"
            payload.assignOutsourceConsultantId = selectedOutsourceConsultant
        }
        if (commentText.length == 0) {
            notify('"comment" is not allowed to be empty', "danger")
            return false;
        }
        setIsLoading(true)
        await crmStore.updateComplaintStatus(newState?.complaint_id, payload, (res) => {
            if (res?.data?.meta?.status == "success") {
                notify(res?.data?.meta?.message)
                CallFetchedComplain()
                fetchAuditTrail()
                setIsLoading(false)
                setCommentText("")
                setSelectedComplaintStatus("")
                setSelectedApprover("")
                setSelectedSupportLevel("")
                setSelectedOutsourceConsultant("")
                setSubmitModal(false)
                setConsulantlModal(false)
                setApprovalModal(false)
                navigate("/raise-ticket")
            }
        }, (error) => {
            notify(error?.response?.data?.meta?.message, "danger")
            setIsLoading(false)
            setSubmitModal(false)
        })
    }


    const handleL2Response = async (flag) => {
        let payload = {
            comment: commentText,
        }

        if (selectedForward == "Yes") {
            if (flag == 'approver') {
                if (selectedSupportLevel.length == 0) {
                    notify('Please select "Support Level"', "danger")
                    return false;
                }
                if (selectedApprover.length == 0) {
                    notify('Please select "Approver"', "danger")
                    return false;
                }
                payload.type = "internal"
                payload.assignSupportLevelId = selectedSupportLevel;
                payload.assignApproverId = selectedApprover;
                payload.isNextApprover = true;
                payload.isTicketRejected = false;
                payload.comment = commentText
            }
        }

        if (selectedForward == "Yes") {
            payload.type = "internal"
            payload.comment = commentText
            payload.isTicketRejected = false;
            payload.isNextApprover = false;
        }

        if (commentText.length == 0) {
            notify('"comment" is not allowed to be empty', "danger")
            return false;
        }
        setIsLoading(true)
        await crmStore.acceptRejectL2(newState?.complaint_id, payload, (res) => {
            if (res?.data?.meta?.status == "success") {
                notify(res?.data?.meta?.message)
                CallFetchedComplain()
                setIsLoading(false)
                setCommentText("")
                setSelectedForward("")
                setSelectedComplaintStatus("")
                setSelectedApprover("")
                setSelectedSupportLevel("")
                setSelectedOutsourceConsultant("")
                navigate("/raise-ticket")
            }
        }, (error) => {
            notify(error?.response?.data?.meta?.message, "danger")
            setIsLoading(false)
            setSubmitModal(false)
        })
    }

    const handleRejectionL2 = async () => {
        let payload = {
            isTicketRejected: true,
        }
        setIsLoading(true)
        await crmStore.acceptRejectL2(newState?.complaint_id, payload, (res) => {
            if (res?.data?.meta?.status == "success") {
                notify(res?.data?.meta?.message)
                setIsLoading(false)
                setRejectModal(true)
            }
        }, (error) => {
            notify(error?.response?.data?.meta?.message, "danger")
            setIsLoading(false)
            setSubmitModal(false)
        })
    }


    const handleRejectionL3 = async () => {
        let payload = {
            isTicketRejected: true,
        }
        setIsLoading(true)
        await crmStore.acceptRejectL3(newState?.complaint_id, payload, (res) => {
            if (res?.data?.meta?.status == "success") {
                notify(res?.data?.meta?.message)
                setIsLoading(false)
                setRejectModal(true)
            }
        }, (error) => {
            notify(error?.response?.data?.meta?.message, "danger")
            setIsLoading(false)
            setSubmitModal(false)
        })
    }
    const handleAcceptL3 = async () => {
        let payload = {
            isTicketRejected: false,
            type: "internal",
            comment: commentText
        }
        setIsLoading(true)
        await crmStore.acceptRejectL3(newState?.complaint_id, payload, (res) => {
            if (res?.data?.meta?.status == "success") {
                notify(res?.data?.meta?.message)
                setIsLoading(false)
                navigate("/raise-ticket")
            }
        }, (error) => {
            notify(error?.response?.data?.meta?.message, "danger")
            setIsLoading(false)
            setSubmitModal(false)
        })
    }


    const handleSendL1 = async () => {
        setIsLoading(true)
        let payload = {}
        await crmStore?.sendToL1(newState?.complaint_id, payload, (response) => {
            if (response?.data?.meta?.status === "success") {
                notify(response?.data?.meta?.message)
                SetSendL1Modal(true)
                setIsLoading(false)
            }
        }, (err) => {
            notify(err?.response?.data?.meta?.message, "danger")
            setIsLoading(false)
        })
    }

    const Acknowledge = async () => {
        let payload = {
            complaint_id: newState?.complaint_id
        }
        setIsLoading(true)
        await crmStore?.acknowledgeTicket(payload, (res) => {
            if (res?.data?.meta?.status == "success") {
                notify(res?.data?.meta?.message)
                setIsLoading(false)
                CallFetchedComplain()
            }
        }, (err) => {
            notify(err?.response?.data?.meta?.message, "danger")
            setIsLoading(false)
        })
    }

    const handleMarkUatCompleted = async () => {
        debugger
        let payload = {
            reporterId: session?.id,
            selectedStatusId: 4
        }
        setIsLoading(true)
        await crmStore?.markUatCompleted(newState?.complaint_id, payload, (res) => {
            if (res?.data?.meta?.status === "success") {
                notify(res?.data?.meta?.message)
                CallFetchedComplain()
                setIsLoading(false)
            }
        }, (err) => {
            notify(err?.response?.data?.meta?.message, "danger")
            setIsLoading(false)
        })
    }

    const getClassName = (status) => {
        switch (status) {
            case 'Open':
                return 'circle-orange';
            case 'Closed':
                return 'circle-red';
            case 'UAT Pending':
                return 'circle-less-green';
            case 'In-Progress':
                return 'circle-yellow';
            case 'Cancelled':
                return 'circle-red';
            case 'UAT Completed':
                return 'circle-green';
            default:
                return '';
        }
    };


    return (
        <div className="main-container raiseTicket_container_crm">
            {isLoading && <Loader />}
            <div className="right-view">
                <Header title={"View Ticket,"} />
                <div className="container-fluid px-0">
                    <div className="row">
                        <div className="col-md-12">
                            <CustomCard
                                cardtitle={"Ticket"}
                                cardboldtitle={"Details"}
                                toprightview={
                                    role.includes("Inhouse consultant") &&
                                    <CustomButton
                                        children={accRes == "1" ? 'Acknowledge Ticket' : 'In-Progress'}
                                        style={accRes == "1" ? 'btn_margin' : "btn--approved--solid"}
                                        onClick={accRes == "1" ? Acknowledge : null}
                                    />
                                }
                                cardbody={
                                    <>
                                        <img src={require("../../../../assets/images/left-arrow.png")} className="back-btn-dashboard" onClick={() => navigate(-1)} />
                                        <div className="ticket_detaile_box">
                                            <div className="row">
                                                <div className="col-md-12">
                                                    <Textarea label={'Summary'}
                                                        style={''}
                                                        readOnly
                                                        require
                                                        title={newState?.issue_summary} />

                                                </div>
                                                <div className="col-md-12">
                                                    <TextEditor
                                                        label={'Description'}
                                                        readOnly
                                                        require
                                                        value={newState?.description}
                                                        placeholder={'Please write a comment.....'} />

                                                </div>

                                            </div>
                                            <div className="row">
                                                <div className="col-md-3">
                                                    <CustomInput
                                                        label={'Reporter'}
                                                        require
                                                        placeholder={'Sushit M.'}
                                                        value={newState?.reporter?.name}
                                                        readOnly />
                                                </div>
                                                <div className="col-md-3">
                                                    <CustomInput
                                                        label={'Issue type'}
                                                        require
                                                        value={newState?.issue_type?.name}
                                                        readOnly />
                                                </div>

                                            </div>
                                            <div className="row">
                                                <div className="col-md-3">
                                                    <CustomInput
                                                        label={'Plant'}
                                                        require
                                                        value={newState?.company_plants?.plant_name}
                                                        readOnly />
                                                </div>

                                                <div className="col-md-3">
                                                    <CustomInput
                                                        label={'Entity'}
                                                        require
                                                        placeholder={'Sushit M.'}
                                                        value={newState?.entity?.name}
                                                        readOnly />
                                                </div>

                                                <div className="col-md-3">
                                                    <CustomInput
                                                        label={'Select Category'}
                                                        placeholder={'Select Category'}
                                                        value={newState?.category?.category_name}
                                                        require
                                                        readOnly />
                                                </div>
                                                <div className="col-md-3">
                                                    <CustomInput
                                                        label={'Select Sub-Category'}
                                                        placeholder={'Select Sub-Category'}
                                                        require
                                                        value={newState?.subcategory?.subcategory_name}
                                                        readOnly />
                                                </div>
                                                <div className="col-md-3">
                                                    <CustomInput
                                                        label={'Select Status'}
                                                        placeholder={'Select Status'}
                                                        value={newState?.status?.complaint_status_name}
                                                        require
                                                        readOnly />
                                                </div>
                                                <div className="col-md-3">
                                                    <CustomInput
                                                        label={'Select Priority'}
                                                        placeholder={'Select Priority'}
                                                        value={newState?.priority?.priority_name}
                                                        require
                                                        readOnly />
                                                </div>
                                            </div>
                                        </div>
                                    </>
                                }
                            />

                            <CustomCard
                                cardboldtitle={"TR Number"}
                                toprightview={
                                    role.includes("Inhouse consultant") && <CustomButton onClick={openTRmodal} children={'+ Add TR'} />
                                }
                                cardbody={
                                    <div className="ticket_detaile_box">
                                        <div className="row">
                                            <div className="col-md-12">
                                                <table className="tr_table_box">
                                                    <thead className="trNumber">
                                                        <tr>
                                                            <th>S.no.</th>
                                                            <th>Number</th>
                                                            <th>Description</th>
                                                        </tr>
                                                    </thead>
                                                    {newState?.tr_numbers?.length > 0 ?
                                                        (
                                                            newState?.tr_numbers?.map((item, i) => {
                                                                return (
                                                                    <tbody className="trNumber">
                                                                        <tr key={item?.tr_number}>
                                                                            <td>{i + 1}</td>
                                                                            <td>{item?.tr_number}</td>
                                                                            <td>{item?.description}</td>
                                                                        </tr>
                                                                    </tbody>
                                                                )
                                                            })
                                                        )
                                                        : (
                                                            <tbody>
                                                                <tr>
                                                                    <td className="text-center p-3" colSpan={3}>
                                                                        No Records found...
                                                                    </td>
                                                                </tr>
                                                            </tbody>

                                                        )}
                                                </table>
                                            </div>
                                        </div>
                                    </div>
                                }
                            />

                            <CustomCard
                                cardtitle={"Ticket"}
                                cardboldtitle={"Details"}

                                cardbody={
                                    <div className="ticket_detaile_box">
                                        <div className="row">
                                            <div className="col-md-12">
                                                <img className="commentImg1" src={commentImg1} alt="comment image" />
                                                {!role.includes("Employee") && !role.includes("Outsourced consultant/Vendor") && <img className="commentImg" src={commentImg} alt="comment image" />}
                                                <Tabs
                                                    defaultActiveKey={(role.includes("Employee") ) ? "home" : "profile"}
                                                    id="uncontrolled-tab-example"
                                                    className="mb-3"
                                                >

                                                    {!role.includes("Employee") && <Tab eventKey="profile" title="Internal Comments">
                                                        {(role.includes("Inhouse consultant") || role.includes("Outsourced consultant/Vendor") || session?.support_levels?.some(element => approver.includes(element)))
                                                            && <div className="comment_input_box">
                                                                <CustomInput
                                                                    placeholder={'Comment'}
                                                                    value={comment}
                                                                    onChange={(e) => setComment(e.target.value)}

                                                                />
                                                                <img className="comment_icon" src={commentIcon} alt="send comment icon" onClick={() => AddComment("internal")} />
                                                            </div>}
                                                        <h3 className="comment_title">Comment History</h3>
                                                        <div className="comment_main_box">
                                                            {newState?.comments?.filter((item) => item.is_internal).map((item, index) => (
                                                                <div className="comment_box" key={index}>
                                                                    <h3 className="comment_title">
                                                                        {item.senderName}
                                                                        <span className="comment_time">
                                                                            {getFormattedDate(item.created_at)}
                                                                        </span>
                                                                    </h3>
                                                                    <p className="comment_text">{item.content}</p>
                                                                </div>
                                                            ))}
                                                        </div>
                                                    </Tab>}

                                                    {!role.includes("Outsourced consultant/Vendor") &&
                                                        <Tab eventKey="home" title="Public Comments">
                                                            {(role.includes("Inhouse consultant") || role.includes("Outsourced consultant/Vendor") || session?.support_levels?.some(element => approver.includes(element)))
                                                                && <div className="comment_input_box">
                                                                    <CustomInput
                                                                        placeholder={'Comment'}
                                                                        value={repComment}
                                                                        onChange={(e) => setRepComment(e.target.value)}
                                                                    />
                                                                    <img className="comment_icon" src={commentIcon} alt="send comment icon" onClick={() => AddComment("public")} />
                                                                </div>
                                                            }
                                                            <h3 className="comment_title">Comment History</h3>
                                                            <div className="comment_main_box">
                                                                {newState?.comments?.filter((item) => !item.is_internal).map((item, index) => (
                                                                    <div className="comment_box" key={index}>
                                                                        <h3 className="comment_title">
                                                                            {item.senderName}
                                                                            <span className="comment_time">
                                                                                {getFormattedDate(item.created_at)}
                                                                            </span>
                                                                        </h3>
                                                                        <p className="comment_text">{item.content}</p>
                                                                    </div>
                                                                ))}
                                                            </div>
                                                        </Tab>
                                                    }

                                                </Tabs>
                                            </div>
                                        </div>
                                    </div>
                                }
                            />

                            <CustomCard
                                cardtitle={"Audit"}
                                cardboldtitle={" trail"}
                                toprightview={
                                    <>
                                        <CustomButton style={"me-3"} children={'Show All Pipeline'} onClick={toggleVisibility} />
                                        <button className="export-btn">
                                            <ExcelFile element={<div> <img width={20} className="me-2" src={require("../../../../assets/images/excel.png")} />Export</div>}>
                                                <ExcelSheet data={auditTrail?.map((item, i) => ({
                                                    ...item, index: i + 1, role: item?.role,
                                                    created_at: item?.created_at && `${item.created_at?.split("T")[0]} ${item.created_at?.split("T")[1]?.split(".")[0].substring(0, 5)}`,
                                                }))} name="Sheet">
                                                    <ExcelColumn label="Status Date & Time" value="created_at" />
                                                    <ExcelColumn label="Name" value="reporter_name" />
                                                    <ExcelColumn label="Support Level" value="support_level" />
                                                    <ExcelColumn label="Status" value="status" />
                                                    <ExcelColumn label="Comment" value="comments" />
                                                </ExcelSheet>
                                            </ExcelFile>
                                        </button>
                                    </>
                                }
                                cardbody={
                                    <React.Fragment>
                                        <div class="table-responsive">
                                            <table class="table">
                                                <thead class="table-customlight">
                                                    <tr>
                                                        <th className="auditTrailcms">Status Date & Time</th>
                                                        <th className="auditTrailcms">Name</th>
                                                        <th className="auditTrailcms">Support Level</th>
                                                        <th className="auditTrailcms">Status</th>
                                                        <th className="auditTrailcms">Comment</th>
                                                        <th className="auditTrailcms">Remarks</th>
                                                    </tr>
                                                </thead>
                                                {dataToDisplay?.length > 0 && (
                                                    <tbody>
                                                        {dataToDisplay?.map((item, i) => {
                                                            return (
                                                                <tr key={item?.requestNumber}>
                                                                    <td scope="row"><FormatDate dateString={item?.created_at} /> </td>
                                                                    <td>{item?.reporter_name}</td>
                                                                    <td>{item?.support_level}</td>
                                                                    <td>
                                                                        <div className="circle-box">
                                                                            <span className={getClassName(item?.status)}></span>{item?.status}
                                                                        </div>
                                                                    </td>
                                                                    <td className="status-box">{item?.comments}</td>
                                                                    <td>{item?.remarks}</td>
                                                                </tr>)
                                                        })}
                                                    </tbody>)}
                                            </table>
                                        </div>
                                    </React.Fragment>
                                }
                            />

                            {role.includes("Inhouse consultant") &&
                                <div className="col-md-12 text-end">
                                    <div>
                                        <CustomButton style={'btn--approved--solid'} children={'Submit'} onClick={() => { setSubmitModal(true) }} />
                                        <CustomButton style={'btn_margin'} children={'Send for approval'} onClick={() => setApprovalModal(true)} />
                                        <CustomButton style={'outsource_consultant_btn btn_margin'} children={'Send to outsource consultant'} onClick={() => setConsulantlModal(true)} />

                                    </div>
                                </div>
                            }

                            {role.includes("Outsourced consultant/Vendor") &&
                                <div className="col-md-12 text-end">
                                    <div>
                                        <CustomButton style={'btn_margin'} children={'Send for L1 approval'} onClick={handleSendL1} />
                                    </div>
                                </div>
                            }

                            {session?.support_levels?.some(element => crmApproverL2.includes(element)) &&
                                <div className="col-md-12 text-end">
                                    <div>
                                        <CustomButton style={'btn--outline '} children={'Rejected'} onClick={() => handleRejectionL2()} />
                                        <CustomButton style={'btn--approved--solid btn_margin'} children={'Approved'} onClick={() => setApproveL2Modal(true)} />
                                    </div>
                                </div>
                            }
                            {session?.support_levels?.some(element => crmApproverL3.includes(element)) &&
                                <div className="col-md-12 text-end">
                                    <div>
                                        <CustomButton style={'btn--outline '} children={'Rejected'} onClick={() => handleRejectionL3()} />
                                        <CustomButton style={'btn--approved--solid btn_margin'} children={'Approved'} onClick={() => setApprovarL3Modal(true)} />
                                    </div>
                                </div>
                            }

                            {role?.includes("Employee") &&
                                <div className="col-md-12 text-end">
                                    <div>
                                        <CustomButton style={'btn--approved--solid btn_margin'} children={'Mark UAT Completed'} onClick={newState?.status_id == "3" ? handleMarkUatCompleted : null} />
                                    </div>
                                </div>
                            }
                        </div>

                    </div>
                </div>
            </div>
            <TicketSubmitModal
                show={submitModal}
                hide={() => {
                    setSubmitModal(false)
                    setCommentText("")
                    setSelectedComplaintStatus("")
                }
                }
                value={selectedComplaintStatus}
                optionview={
                    complaintStatusList.length > 0 &&
                    complaintStatusList.map((i) => {
                        return (
                            <option value={i?.complaint_status_id}>{i?.complaint_status_name}</option>
                        );
                    })
                }
                textvalue={commentText}
                selectOnChange={(e) => {
                    setSelectedComplaintStatus(e.target.value);
                }}
                textOnChange={(e) => {
                    setCommentText(e.target.value);
                }}
                submit={() => {
                    SubmitComplaint('status');
                }}
            />
            <ApprovalModal
                show={appeovalModal}
                hide={() => {
                    setCommentText("")
                    setSelectedApprover("")
                    setSelectedSupportLevel("")
                    setSelectedForward("")
                    setApprovalModal(false)
                }}

                value={selectedComplaintStatus}
                optionview={
                    approvalList.length > 0 &&
                    approvalList.map((i) => {
                        return (
                            <option value={i?.id}>{i?.name}</option>
                        );
                    })
                }
                support_optionview={
                    supportList.length > 0 &&
                    supportList.map((i) => {
                        return (
                            <option value={i?.id}>{i?.name}</option>
                        );
                    })
                }
                support_selectOnChange={(e) => {
                    CallFetchedApprovals(e.target.value)
                    setSelectedSupportLevel(e.target.value);
                }}
                textvalue={commentText}
                selectOnChange={(e) => {
                    setSelectedApprover(e.target.value);
                }}
                forward_optionview={
                    forwardList?.length > 0 &&
                    forwardList?.map((i) => {
                        return (
                            <option value={i?.value}>{i?.label}</option>
                        );
                    })
                }
                forward_selectOnChange={(e) => {
                    setSelectedForward(e.target.value);
                }}

                selectedForward={selectedForward}
                textOnChange={(e) => {
                    setCommentText(e.target.value);
                }}
                submit={() => {
                    SubmitComplaint('approver');
                }}
            />
            <ConsultantModal
                show={consulantModal}
                hide={() => {
                    setSelectedOutsourceConsultant("");
                    setConsulantlModal(false)
                }
                }
                value={selectedComplaintStatus}
                optionview={
                    outsourceConsultantList.length > 0 &&
                    outsourceConsultantList.map((i) => {
                        return (
                            <option value={i?.id}>{i?.name}</option>
                        );
                    })
                }
                textvalue={commentText}
                selectOnChange={(e) => {
                    setSelectedOutsourceConsultant(e.target.value);
                }}
                textOnChange={(e) => {
                    setCommentText(e.target.value);
                }}
                submit={() => {
                    SubmitComplaint('outsource');
                }}
            />

            <AddTRNumberModal
                show={addTRmodal}
                hide={() => setAddTRModal(false)}
                description={description}
                trNo={trNo}
                handleTR={(e) => setTrNo(e)}
                handleDesc={(e) => setDescription(e)}
                handleSendTrDetails={handleSendTrDetails}
            />

            <SubmitTicketModal
                show={sendL1Modal}
                hide={() => {
                    SetSendL1Modal(false)
                    navigate("/raise-ticket")
                }}
                title={`Your ticket id: ${newState?.complaint_number} has been sent for approval`}
            />

            <RejectedTicketModal
                show={rejectModal}
                hide={() => {
                    setRejectModal(false)
                    navigate("/raise-ticket")
                }}
                title={`You have rejected case id  : `}
                title1={` #${newState?.complaint_id} `}
            />

            <ApprovalModal1
                show={approveL2Modal}
                hide={() => {
                    setCommentText("")
                    setSelectedApprover("")
                    setSelectedSupportLevel("")
                    setSelectedForward("")
                    setApproveL2Modal(false)

                }}

                value={selectedComplaintStatus}
                optionview={
                    approvalList.length > 0 &&
                    approvalList.map((i) => {
                        return (
                            <option value={i?.id}>{i?.name}</option>
                        );
                    })
                }
                support_optionview={
                    supportListL3?.length > 0 &&
                    supportListL3?.map((i) => {
                        return (
                            <option value={i?.id}>{i?.name}</option>
                        );
                    })
                }
                support_selectOnChange={(e) => {
                    CallFetchedApprovals(e.target.value)
                    setSelectedSupportLevel(e.target.value);
                }}
                textvalue={commentText}
                selectOnChange={(e) => {
                    setSelectedApprover(e.target.value);
                }}
                textOnChange={(e) => {
                    setCommentText(e.target.value);
                }}

                forward_optionview={
                    forwardList?.length > 0 &&
                    forwardList?.map((i) => {
                        return (
                            <option value={i?.value}>{i?.label}</option>
                        );
                    })
                }
                forward_selectOnChange={(e) => {
                    setSelectedForward(e.target.value);
                }}

                selectedForward={selectedForward}

                submit={() => {
                    handleL2Response('approver');
                }}
                forward="true"
            />

            <L3ApprovalModal
                show={approverL3Modal}
                hide={() => {
                    setApprovarL3Modal(false)
                }}
                title={`You have approved case id  : `}
                title1={` #${newState?.complaint_id} `}
                submit={handleAcceptL3}
                textOnChange={(e) => {
                    setCommentText(e.target.value);
                }}
                textvalue={commentText}
            />
        </div >
    );
};

export default ViewTickets;

import React from "react";
import './style.scss';
import Modal from "react-bootstrap/Modal";
import rejectedImage from "../../assets/images/rejected@2x.png";
import crossImage from "../../assets/images/close (4).png";

const RejectedTicketModal = ({ hide, show, title ,title1}) => {

    return (
        <Modal show={show} centered className="sucess-content sucess_content_modal">
            <Modal.Body className="sucess-modal">
                <div className="modal-container">
                    <img
                        src={crossImage}
                        alt=""
                        className="cross-img"
                        onClick={() => hide()}
                    />
                    <div className="row moodal">
                        <img src={rejectedImage} alt="" className="modal-img" />
                        <h4 className="success_title1" style={{ textAlign: "center" }}>
                        {title} <span className="span_color">{title1}</span>
                        </h4>
                        <p className="success_text" style={{ textAlign: "center" }}>
                            {/* {title} */}
                        </p>
                    </div>
                </div>
            </Modal.Body>
        </Modal>
    );
};

export default RejectedTicketModal;

import React, { useState } from "react";
import CustomCard from "../CustomeCard";
import ReactExport from "react-export-excel";
import ReactPaginate from "react-paginate";
import Loader from "../helper/Loader/loader";
import FormatDate from "../helper/formatDate/FormatFixDate";



const ExcelFile = ReactExport.ExcelFile;
const ExcelSheet = ReactExport.ExcelFile.ExcelSheet;
const ExcelColumn = ReactExport.ExcelFile.ExcelColumn;

const limit = [
  {
    option: 10,
    value: 10,
  },
  {
    option: 100,
    value: 100,
  },
  {
    option: 500,
    value: 500,
  },
  {
    option: 1000,
    value: 1000,
  },
];

const LcRequest = (props) => {
  const [isLoading, setIsLoading] = useState(false);
  const [page, setPage] = useState(1);
  const [pageLimit, setPageLimit] = useState(10);
  const [filterRefernceNumber, setFilterRefernceNumber] = useState();
  const [filterRequestNumber, setFilterRequestNumber] = useState();
  const [filterApplicant, setFilterApplicant] = useState();
  const [filterBeneficiary, setFilterBeneficiary] = useState();
  const [status, setStatus] = useState()
  const roleName = sessionStorage.getItem("roleName")

  const handlePageSubmit = async (index) => {
    setPage(index.selected + 1);
    let payload = {
      limit: pageLimit,
      filters: {
      },
      pageNumber: index.selected + 1,
    };
    props?.callLcApi(payload);
  };

  const limitChangeHandler = async (index) => {
    setPageLimit(index);
    let payload = {
      limit: index,
      filters: {
      },
      pageNumber: page,
      roleName: roleName,
    };
    props?.callLcApi(payload);
  };

  const handleFilter = async () => {
    let payload = {
      limit: pageLimit,
      filters: {
        referenceNumber: filterRefernceNumber,
        requestNumber: filterRequestNumber,
        applicantName: filterApplicant,
        beneficiaryName: filterBeneficiary,
      },
      pageNumber: page,
    };

    if (status === "Pending from L1") {
      payload.filters.isL1Approved = false
       payload.filters.isSubmitted = true
    }
    if (status === "Pending from L2") {
      payload.filters.isL1Approved = true
      payload.filters.isL2Approved = false;
    }
    if (status === "Closed") {
      payload.filters.isClosed = true;
    }
    if (status === "Pending from treasury") {
      payload.filters.isClosed = false;
      payload.filters.isL1Approved = true;
      payload.filters.isL2Approved = true
    }if(status === "Rejected"){
      payload.filters.isRejected = true
    }

    props?.callLcApi(payload);
  };

  const handleReset = async () => {
    setFilterRefernceNumber("");
    setFilterRequestNumber("");
    setFilterApplicant("");
    setFilterBeneficiary("");
    setPage(1);
    setPageLimit(10);
    setStatus("")
    await props.fetchLcData()
  };


  const getFormattedDate = (dateString) => {
    const date = new Date(dateString);
    const year = date.getFullYear();
    const month = (date.getMonth() + 1).toString().padStart(2, "0");
    const day = date.getDate().toString().padStart(2, "0");
    return `${year}-${month}-${day}`;
  };

  return (
    <CustomCard
      cardtitle={"LC"}
      cardboldtitle={"Request"}
      toprightview={
        <div>
          {props?.fetchedLcData?.length > 0 && (
            <button className="export-btn">
              <ExcelFile element={<div><img width={20} className="me-2" src={require("../../assets/images/excel.png")} />Export</div>}>
                <ExcelSheet data={props.fetchedLcData?.map((item, i) => ({
                  ...item, index: i + 1, issuanceDate: item?.issuanceDate &&  getFormattedDate(item?.issuanceDate),
                  expiryDate: item?.expiryDate &&  getFormattedDate(item?.expiryDate) 

                }))} name="Sheet">
                  <ExcelColumn label="Request Number" value="requestNumber" />
                  <ExcelColumn label="Reference Number" value="referenceNumber" />
                  <ExcelColumn label="Currency" value="currency" />
                  <ExcelColumn label="Amount" value="amount" />
                  <ExcelColumn label="Applicant Name" value="applicantName" />
                  <ExcelColumn label="Beneficiary Name" value="beneficiaryName" />
                  <ExcelColumn label="Issuance Date" value="issuanceDate" />
                  <ExcelColumn label="Expiry Date" value="expiryDate" />
                </ExcelSheet>
              </ExcelFile>
            </button>)}
        </div>
      }
      cardbody={
        <React.Fragment>
          {isLoading && <Loader />}

          <div className="col-md-12 filter-container">
            <div>
              <input
                className="form-control"
                type="text"
                placeholder="Reference Number"
                value={filterRefernceNumber}
                onChange={(e) => setFilterRefernceNumber(e.target.value)}
              />
            </div>
            <div>
              <input
                className="form-control"
                type="text"
                placeholder="Request Number"
                value={filterRequestNumber}
                onChange={(e) => setFilterRequestNumber(e.target.value)}
              />
            </div>

            <div>
              <input
                className="form-control"
                type="text"
                placeholder="Applicant Name"
                value={filterApplicant}
                onChange={(e) => setFilterApplicant(e.target.value)}
              />
            </div>
            <div>
              <input
                className="form-control"
                type="text"
                placeholder="Beneficiary Name"
                value={filterBeneficiary}
                onChange={(e) => setFilterBeneficiary(e.target.value)}
              />
            </div>
            <div>
              <select
                className="form-control"
                style={{ appearance: "auto" }}
                value={status}
                onChange={(e) => setStatus(e.target.value)}
              >
                <option value="">Select an option</option>
                <option value="Pending from L1">Pending from L1</option>
                <option value="Pending from L2">Pending from L2</option>
                <option value="Closed">Closed</option>
                <option value="Pending from treasury">Pending from treasury</option>
              </select>
            </div>
            <div className="btn-container">
              <button
                className="submit-button"
                type="submit"
                onClick={handleFilter}
              >
                Search
              </button>
              <button
                className="submit-button"
                type="submit"
                onClick={handleReset}
              >
                Reset
              </button>
            </div>
          </div>

          <div className="col-md-12 mb-4">
            <div>
              <label className="d-flex align-items-center p-0 col-md-2">
                {page === 1 ? (
                  <select
                    value={pageLimit}
                    className="form-control selectbox-container mr-2 drop-down-dashboard"
                    onChange={(e) => limitChangeHandler(e.target.value)}
                  >
                    {limit.length > 0
                      ? limit.map((item, index) => (
                        <option key={index} value={item.option}>
                          {item.value}
                        </option>
                      ))
                      : null}
                  </select>
                ) : (
                  <p className="form-control selectbox-container mr-2 drop-down-dashboard">
                    {pageLimit}
                  </p>
                )}
                <span> entries</span>
              </label>
            </div>
          </div>

          <div className="table-responsive">
            <table className="table">
              <thead className="table-customlight">
                <tr>
                  <th scope="col">Request Number</th>
                  <th scope="col">Reference Number</th>
                  <th scope="col">Currency</th>
                  <th scope="col">Amount</th>
                  <th scope="col">Applicant Name</th>
                  <th scope="col">Beneficiary Name</th>
                  <th scope="col">Issuance Date</th>
                  <th scope="col">Expiry Date</th>
                  {/* <th scope="col">Claim Expiry Date</th> */}
                  <th scope="col">Status</th>
                </tr>
              </thead>
              {props.fetchedLcData?.length > 0 && (
                <tbody>
                  {props.fetchedLcData.map((item, i) => {
                    const status =
                      item.isApproved === 2 && !item?.isSubmitted
                        ? "Rejected"
                        : !item.isSubmitted
                          ? "Not Submitted"
                          : !item.isL1Approved
                            ? "Pending from L1"
                            : !item.isL2Approved
                              ? "Pending from L2"
                              : item.isApproved && !item.isClosed
                                ? "Pending from treasury"
                                : item.isClosed
                                  ? "Closed"
                                  : "Unknown";
                    return (
                      <tr key={item.requestNumber}>
                        <td
                          onClick={(e) => props.handleFetchedLcIssue(e, item)}
                          scope="row"
                          className="text-link"
                        >
                          {item?.requestNumber}
                        </td>
                        <td>{item?.referenceNumber}</td>
                        <td>{item?.currency}</td>
                        <td>{item?.amount}</td>
                        <td>{item?.applicantName}</td>
                        <td>{item?.beneficiaryName}</td>
                        <td>{ item?.issuanceDate && <FormatDate dateString={item?.issuanceDate} />}</td>
                        <td>{  item?.expiryDate &&  <FormatDate dateString={item?.expiryDate} />}</td>
                        <td>{status}</td>
                      </tr>
                    );
                  })}
                </tbody>
              )}
            </table>
          </div>


          <div className="col-md-12">
            <div className="d-flex align-items-center justify-content-between my-4">
              <div>
                <p>
                  showing {props.fetchedLcData?.length} out of{" "}
                  {props.fetchedLcData?.length}
                </p>
              </div>
              <div>
                <ReactPaginate
                  previousLabel={"Previous"}
                  nextLabel={"Next"}
                  breakLabel={"..."}
                  pageCount={props?.fetchLcAllData?.totalPages}
                  marginPagesDisplayed={3}
                  pageRangeDisplayed={3}
                  onPageChange={handlePageSubmit}
                  containerClassName={"pagination justify-content-center"}
                  pageClassName={"page-item"}
                  pageLinkClassName={"page-link"}
                  previousClassName={"page-item"}
                  previousLinkClassName={"page-link"}
                  nextClassName={"page-item"}
                  nextLinkClassName={"page-link"}
                  breakClassName={"page-item"}
                  breakLinkClassName={"page-link"}
                  activeClassName={"active"}
                />
              </div>
            </div>
          </div>
        </React.Fragment>
      }
    />
  );
};


export default LcRequest;
import React from "react";
import './style.scss';
import Modal from "react-bootstrap/Modal";
import successImage from "../../assets/images/checked@2x.png";
import crossImage from "../../assets/images/close (4).png";
import Textarea from "../CustomeTextarea";
import CustomButton from "../Button/Button";

const L3ApprovalModal = ({ hide, show, title, title1, textOnChange, textvalue, submit }) => {

    return (
        <Modal show={show} centered className="sucess-content sucess_content_modal">
            <Modal.Body className="sucess-modal">
                <div className="modal-container">
                    <img
                        src={crossImage}
                        alt=""
                        className="cross-img"
                        onClick={() => hide()}
                    />
                    <div className="row moodal">
                        <img src={successImage} alt="" className="modal-img" />
                        <h4 className="success_title1" style={{ textAlign: "center" }}>
                            {title} <span className="span_color">{title1}</span>
                        </h4>
                        <div className="addName-model">
                            <div className="container-fluid">
                                <div className="row">
                                    <div className="col-md-12">
                                        <Textarea
                                            style={'textarea_bg'}
                                            placeholder={'Please write a comment....'}
                                            textvalue={textvalue}
                                            onChange={textOnChange}
                                        />
                                    </div>
                                    <div className="text-end">
                                    <CustomButton
                                        children={"Approve"}
                                        onClick={submit}
                                    />
                                    </div>
                                  
                                </div>
                            </div>

                        </div>



                    </div>
                </div>
            </Modal.Body>
        </Modal>
    );
};

export default L3ApprovalModal;

import React, { useState } from "react";
import Modal from "react-bootstrap/Modal";
import "./style.scss";
import crossImage from "../../assets/images/close (4).png";
import CustomButton from "../Button/Button";
import Customselect from "../CustomSelect";
import Textarea from "../CustomeTextarea";

const ApprovalModal = (props) => {


    return (
        <Modal {...props} centered className="sucess-content sucess_content_modal">
            <Modal.Body className="sucess-modal py-5">
                <div className="modal-container">
                    <img
                        src={crossImage}
                        alt=""
                        className="cross-img"
                        onClick={() => props.hide()}
                    />
                    <div className="modal_submit ">
                        <div className="addName-model">
                            <div className="container-fluid">
                                <div className="row">

                                    {props?.forward == "true" ?
                                        <>
                                            <div className="col-md-12 text-start">
                                                <Customselect label={'Forward'}
                                                    optionview={props.forward_optionview}
                                                    onChange={props.forward_selectOnChange}
                                                    value={props?.selectedForward}
                                                    require />
                                            </div>

                                            {props.selectedForward == "Yes" &&
                                                <>
                                                    <div className="col-md-6 text-start">
                                                        <Customselect label={'Select Support level'}
                                                            optionview={props.support_optionview}
                                                            onChange={props.support_selectOnChange}
                                                            require />
                                                    </div>
                                                    <div className="col-md-6 text-start">
                                                        <Customselect
                                                            label={'Select Approver'}
                                                            optionview={props.optionview}
                                                            onChange={props.selectOnChange}
                                                            require />
                                                    </div>
                                                </>
                                            }

                                        </>
                                        : <>
                                            <div className="col-md-6 text-start">
                                                <Customselect label={'Select Support level'}
                                                    optionview={props.support_optionview}
                                                    onChange={props.support_selectOnChange}
                                                    require />
                                            </div>
                                            <div className="col-md-6 text-start">
                                                <Customselect
                                                    label={'Select Approver'}
                                                    optionview={props.optionview}
                                                    onChange={props.selectOnChange}
                                                    require />
                                            </div>

                                        </>

                                    }

                                    <div className="col-md-12">
                                        <Textarea
                                            style={'textarea_bg'}
                                            placeholder={'Please write a comment....'}
                                            textvalue={props.textvalue}
                                            onChange={props.textOnChange}
                                        />
                                    </div>
                                </div>
                            </div>

                        </div>
                        <CustomButton
                            children={"Send for approval"}
                            onClick={props.submit}
                        />

                    </div>
                </div>

            </Modal.Body>
        </Modal>
    );
};

export default ApprovalModal;

import React, { useState } from "react";
import Modal from "react-bootstrap/Modal";
import successImage from "../../../assets/images/checked@2x.png";
import crossImage from "../../../assets/images/close (4).png";

const SubmitRequisitionModal = ({ hide, show, title }) => {

  return (
    <Modal show={show} centered className="sucess-content">
      <Modal.Body className="sucess-modal">
        <div className="modal-container">
          <img
            src={crossImage}
            alt=""
            className="cross-img"
            onClick={() => hide()}
          />
          <div className="row moodal">
            <img src={successImage} alt="" className="modal-img" />
            <h2 className=" text-center">Thank You</h2>
            <p className="p-2 mt-3" style={{ textAlign: "center" }}>
              {title ?  title : "Your requisition submit for HOD approval"}
            </p>
          </div>
        </div>
      </Modal.Body>
    </Modal>
  );
};

export default SubmitRequisitionModal;

import React, { useState } from "react";
import "./style.scss";
import Header from "../../../component/Header";
import CustomInput from "../../../component/CustomInput";
import CustomCard from "../../../component/CustomeCard";
import CustomButton from "../../../component/Button/Button";

const PettyCashRequisition =() => {
  const[entity , setEntity] = useState()
  const [requestingDepartment , setRequestingDepartment] = useState()
  const[pettyDate , setPettyDate] = useState()
  const[address , setAddress] = useState()
  const[pettyPhone , setPettyPhone] = useState()
  const[pettyCustodianSignature,setPettyCustodianSignature]= useState()
  const[receivedCash,setReceivedCash]= useState()
  const[treasuryApproval,setTreasuryApproval]=useState()
  const[authorizedBy , setAuthorizedBy] = useState()

  return (
    <div className="Imprest-container">
      <div className="right-view">
        <Header title={"Petty Cash Requisition"} />
        <div className="row">
          <div className="col-md-12">
            <CustomCard
              cardtitle={"Basic "}
              cardboldtitle={"Information"}
              cardbody={
                <div className="row">
                  <div className="col-md-3">
                    <CustomInput label="Entity" placeholder="Entity" 
                      value ={entity}
                      onchange ={(e) => setEntity(e.target.value)}
                    />
                  </div>
                  <div className="col-md-3">
                    <CustomInput
                      label="Requesting Department"
                      placeholder="Requesting Department"
                      value={requestingDepartment}
                      onchange={(e) => setRequestingDepartment(e.target.value)}
                    
                    />
                  </div>
                  <div className="col-md-3">
                    <CustomInput label="Date" placeholder="Date" 
                    value={pettyDate}
                    onchange={(e)=>setPettyDate(e.target.value)}
                    />
                  </div>

                  <div className="col-md-3">
                    <CustomInput label="Address" placeholder="Address" 
                    value={address}
                    onchange={(e) => setAddress(e.target.value)}
                    />
                  </div>
                  <div className="col-md-3">
                    <CustomInput label="Phone" placeholder="Phone" 
                    value={pettyPhone}
                    onchange={(e) => setPettyPhone(e.target.value)}
                    />
                  </div>
                </div>
              }
            />
          </div>
          <div className="col-md-12">
            <CustomCard
              cardtitle={"Fund "}
              cardboldtitle={"Information"}
              cardbody={
                <div className="row">
                  <div className="col-md-6">
                    <CustomInput
                      label="Petty Cash Custodian Signature"
                      placeholder="Fund Custodian"
                      value={pettyCustodianSignature}
                      onchange={(e) => setPettyCustodianSignature(e.target.value)}
                    />
                  </div>
                  <div className="col-md-6">
                    <CustomInput
                      label="Received in Cash By"
                      placeholder="Received in Cash By"
                      value={receivedCash}
                      onchange={(e) => setReceivedCash(e.target.value)}
                    />
                  </div>
                  <div className="col-md-6">
                    <CustomInput
                      label="Treasury Approval"
                      placeholder="Treasury Approval"
                      value={treasuryApproval}
                      onchange={(e) => setTreasuryApproval(e.target.value)}
                    />
                  </div>
                  <div className="col-md-6">
                    <CustomInput
                      label="Authorized By"
                      placeholder="Authorized By"
                      value={authorizedBy}
                      onchange={(e) => setAuthorizedBy(e.target.value)}
                    />
                  </div>
                </div>
              }
            />
          </div>
          <div className="col-md-12 text-end">
            <CustomButton style={"btn--outline me-3"} children={"Save"} />
            <CustomButton children={"Submit for approval"} />
          </div>
        </div>
      </div>
    </div>
  );
}  

export default PettyCashRequisition;

import React from 'react'
import CustomCard from '../../component/CustomeCard'
import "./style.scss"
import Header from '../../component/Header'
import { Link } from 'react-router-dom'



const Home = () => {
    const fetchrole = sessionStorage.getItem("role")
    const role = fetchrole?.split(",")
    let treasuryArr = ["Requestor", "Approver", "Treasury", "Viewer",]
    let hrArr = ["Manager", "HR", "CXO", "HOD", "CHRO", "CEO", "MD", "Director","Interviewer","Segment HR"]
    let crmArr = ["Inhouse consultant", "Outsourced consultant/Vendor","Admin",'Employee']
    let crmApprover =["L2" ,"L3"]
    let sessions = sessionStorage.getItem("candidate")
    let session = (JSON.parse(sessions))
    



    const handleTreasury = () => {
        sessionStorage.setItem("treasury", 1)
        sessionStorage.setItem("crmPortal", 0)
        sessionStorage.setItem("hrPortal", 0)
        let redirectTo = "";
        if (role.includes("Treasury")) {
            redirectTo = "/treasury-journey-dashboard";
        } else if (role.includes("Approver")) {
            redirectTo = "/approver-journey-dashboard";
        } else if (role.includes("Requestor")) {
            redirectTo = "/requestor-journey-dashboard";
        } else {
            redirectTo = "/requestor-journey-dashboard";
        }

        sessionStorage.setItem("selectedDashboard", redirectTo);
        window.location.href = redirectTo;
    }

    let ManpowerReqArray = ["Manager"]
    let ManPowerApprovalArray = ["HOD", "HR", "CHRO", "MD", "CEO", "Director", "CXO" ,"Segment HR" ]
    let InterviewAppArray = ["Interviewer"]


    const handleHrPortal = () => {
        let redirectTo = "";
        sessionStorage.setItem("hrPortal", 1)
        sessionStorage.setItem("crmPortal", 0)
        sessionStorage.setItem("treasury", 0)
        if (role.some(element => ManpowerReqArray.includes(element))) {
            redirectTo = "/manpower-requisition-dashboard"
            window.location.href = redirectTo;
        } else if (role.some(element => ManPowerApprovalArray.includes(element))) {
            redirectTo = "/approver-requisition-dashboard"
            window.location.href = redirectTo;
        }else if(role.some(element => InterviewAppArray.includes(element))){
             redirectTo = "/hr-portal-approver"
            window.location.href = redirectTo;
        } else {
            redirectTo = "/manpower-requisition-dashboard"
            window.location.href = redirectTo;
        }
    }
    const handleCrm = () => {
        sessionStorage.setItem("crmPortal", 1)
        sessionStorage.setItem("treasury", 0)
        sessionStorage.setItem("hrPortal", 0)
        window.location.href = '/Dashboard';
    }
    return (
        <>
            <Header />
            <div className="login-mainContainer1">
                <div className='home-container'>
                    <div className=''>
                        <div className='col-md-12 '>
                            <CustomCard
                                cardboldtitle={"Home"}
                                cardbody={
                                    <React.Fragment>
                                        <div className='row mb-4 mt-4'>
                                            {role.some(element => treasuryArr.includes(element)) &&
                                                <div className='col-md-2'>
                                                    <div className='home-box' onClick={handleTreasury}>
                                                        <img src={require("../../assets/images/home1.png")} className='home-img' />
                                                        <h3 className='home-text'>Treasury</h3>
                                                    </div>
                                                </div>}

                                            {role.some(element => hrArr.includes(element)) &&
                                                <div className='col-md-2'>
                                                    <div className='home-box' onClick={handleHrPortal}>
                                                        <img src={require("../../assets/images/home2.png")} className='home-img' />
                                                        <h3 className='home-text'>HR Portal</h3>
                                                    </div>
                                                </div>
                                            }


                                            {(role.some(element => crmArr.includes(element)) || session?.support_levels?.some(element => crmApprover.includes(element))) &&
                                                <div className='col-md-2'>
                                                    <div className='home-box' onClick={handleCrm}>
                                                            <img src={require("../../assets/images/home3.png")} className='home-img' />
                                                            <h3 className='home-text'>CRM</h3>
                                                    </div>
                                                </div>
                                            }
                                        </div>
                                    </React.Fragment>
                                }
                            />
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}

export default Home
import React, { useState } from "react";
import "./style.scss";
import Header from "../../../component/Header";
import CustomButton from "../../../component/Button/Button";
import CustomInput from "../../../component/CustomInput";
import CustomCard from "../../../component/CustomeCard";

const MissingRecieptForm =() =>{
  const[name , setName] = useState("")
  const[department , setDepartment] = useState("")
  const[entity , setEntity] = useState("")
  const[employeeID , setEmployeeID] = useState("")
  const[expenseAmount , setExpenseAmount] = useState()
  const[dateOfExpense , setDateOfExpense] = useState()
  const[purposeOfExpense , setPurposeOfExpense] = useState()
  const [explaination , setExplaination] = useState("")
  const[supplierName , setSupplierName]= useState("")
  const[supplierPhoneNo , setSupplierPhoneNo]= useState("")
  const[supplierAddress , setSupplierAddress]= useState("")
  const[supplierWebsite , setSupplierWebsite]= useState("")
  const[descriptionExpenditure, setDescriptionExpenditure] = useState("")
  const[purchaser , setPurchaser] = useState("")
  const[fundCustodian , setFundCustodian] = useState()
  const[departmentHead , setDepartmentHead] = useState("")
  const[approvedBy , setApprovedBy] = useState()
  const[approvedDate , setApprovedDate] = useState()
  const[lostReceipt , setLostReceipt] = useState()
  const[providedByMerchant , setProvidedByMerchant] = useState()
  const[other , setOther] = useState()



  return (
    <div className="Imprest-container">
      <div className="right-view">
        <Header title={"Missing Receipt Form"} />
        <div className="row">
          <div className="col-md-12">
            <CustomCard
              cardtitle={"Basic "}
              cardboldtitle={"Information"}
              cardbody={
                <div className="row">
                  <div className="col-md-3">
                    <CustomInput label="Name" placeholder="Name" 
                    value={name}
                    onChange={(e) => setName(e.target.value)}
                    />
                  </div>
                  <div className="col-md-3">
                    <CustomInput label="Department" placeholder="Department"
                    value={department}
                    onChange={setDepartment}
                    />
                  </div>
                  <div className="col-md-3">
                    <CustomInput label="Entity" placeholder="Entity" 
                    value={entity}
                    onChange={(e) => setEntity(e.target.value)}
                    />
                  </div>

                  <div className="col-md-3">
                    <CustomInput
                      label="Employee ID"
                      placeholder="Employee ID"
                      value={employeeID}
                      onChange={(e) => setEmployeeID(e.target.value)}
                    />
                  </div>
                </div>
              }
            />
          </div>
          <div className="col-md-12">
            <CustomCard
              cardtitle={"Fund "}
              cardboldtitle={"Information"}
              cardbody={
                <div className="row">
                  <div className="col-md-3">
                    <CustomInput
                      label="Expense Amount"
                      placeholder="Expense Amount"
                      value={expenseAmount}
                      onChange={(e) => setExpenseAmount(e.target.value)}
                    />
                  </div>
                  <div className="col-md-3">
                    <CustomInput
                      label="Date of Expense"
                      placeholder="Date of Expense"
                      value={dateOfExpense}
                      onChange={(e) => setDateOfExpense(e.target.value)}
                    />
                  </div>
                  <div className="col-md-3">
                    <CustomInput
                      label="Purpose of Expense"
                      placeholder="Purpose of Expense"
                      value={purposeOfExpense}
                      onChange={(e) => setPurposeOfExpense(e.target.value)}
                    />
                  </div>
                  <div className="col-md-12">
                    <div className="redio-btn">
                      <input onChange={()=>setLostReceipt("Lost Receipt")} type="radio" id="html" name="fav_language" value="HTML" />
                      <label for="html">Lost Receipt</label>
                    </div>
                    <div className="redio-btn">
                      <input  onChange={()=>setProvidedByMerchant("Receipt not Provided by Merchant")} type="radio" id="html2" name="fav_language" value="HTML2" />
                      <label for="html2">Receipt not Provided by Merchant</label>
                    </div>
                    <div className="redio-btn">
                      <input  onChange={()=>setOther("Other (Explain)")} type="radio" id="html3" name="fav_language" value="HTML3" />
                      <label for="html3">Other (Explain)</label>
                    </div>
                  </div>
                  <div className="col-md-9">
                    <CustomInput label="Explain" placeholder="Explain" 
                    value={explaination}
                    onChange={(e) => setExplaination(e.target.value)}
                    />
                  </div>
                </div>
              }
            />
          </div>
          <div className="col-md-12">
            <CustomCard
              cardtitle={"Merchant/Supplier"}
              cardboldtitle={"Information"}
              cardbody={
                <div className="row">
                  <div className="col-md-3">
                    <CustomInput
                      label="Supplier Name"
                      placeholder="Supplier Name"
                      value={supplierName}
                      onChange={(e) => setSupplierName(e.target.value)}
                    />
                  </div>
                  <div className="col-md-3">
                    <CustomInput
                      label="Supplier Phone Number"
                      placeholder="Supplier Phone Number"
                      value={supplierPhoneNo}
                      onChange={(e) => setSupplierPhoneNo(e.target.value)}
                    />
                  </div>
                  <div className="col-md-3">
                    <CustomInput
                      label="Supplier Address"
                      placeholder="Supplier Address"
                      value={supplierAddress}
                      onChange={(e)=> setSupplierAddress(e.target.value)}
                    />
                  </div>
                  <div className="col-md-3">
                    <CustomInput
                      label="Supplier Website"
                      placeholder="Supplier Website"
                      value={supplierWebsite}
                      onChange={(e) => setSupplierWebsite(e.target.value)}
                    />
                  </div>
                  <div className="col-md-12 text-area-view">
                    <label>Description of Expenditure / Business Purpose</label>
                    <textarea rows="4" value={descriptionExpenditure} onChange={(e) => setDescriptionExpenditure(e.target.value)}/>
                  </div>
                </div>
              }
            />
          </div>
          <div className="col-md-12">
            <CustomCard
              cardboldtitle={"Authorization"}
              cardbody={
                <div className="row">
                  <div className="col-md-3">
                    <CustomInput
                      label="Purchaser"
                      placeholder="Purchaser"
                      value={purchaser}
                      onChange={(e) => setPurchaser(e.target.value)}
                    />
                  </div>
                  <div className="col-md-3">
                    <CustomInput
                      label="Fund Custodian"
                      placeholder="Fund Custodian"
                      value={fundCustodian}
                      onChange={(e) => setFundCustodian(e.target.value)}
                    />
                  </div>
                  <div className="col-md-3">
                    <CustomInput
                      label="Department Head"
                      placeholder="Department Head"
                      value={departmentHead}
                      onChange={(e) => setDepartmentHead(e.target.value)}
                    />
                  </div>
                  <div className="col-md-3">
                    <CustomInput
                      label="Approved by"
                      placeholder="Approved by"
                      value={approvedBy}
                      onChange={(e) => setApprovedBy(e.target.value)}
                    />
                  </div>
                  <div className="col-md-3">
                    <CustomInput
                      label="Date"
                      placeholder="Date"
                      value={approvedDate}
                      onChange={(e) => setApprovedDate(e.target.value)}
                    />
                  </div>
                </div>
              }
            />
          </div>
          <div className="col-md-12 text-end">
            <CustomButton style={"btn--outline me-3"} children={"Save"} />
            <CustomButton children={"Submit for approval"} />
          </div>
        </div>
      </div>
    </div>
  );
}

export default MissingRecieptForm;

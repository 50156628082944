import React, { useEffect, useState } from "react";
import Header from "../../../component/Header";
import CustomCard from "../../../component/CustomeCard";
import "./style.scss";
import GraphChart from "../../../component/GraphChart";
import { Link, useNavigate } from "react-router-dom";
import Customselect from "../../../component/CustomSelect";
import ApproverRoleCard from "../../../component/ApproverRoleCard";
import RequestTab from "../../../component/Tabs/requestTab";
import notify from "../../../shared/notify";
import { useStores } from "../../../stores";
import BGRequest from "../../../component/AjBgRequestor/BgRequest";
import LCRequest from "../../../component/AjBgRequestor/LcRequest";
import Loader from "../../../component/helper/Loader/loader";

const AJDashboard = () => {
  const navigate = useNavigate();
  const { mainStore } = useStores()
  const [showLcRequest, setShowLcRequest] = useState(false);
  const [showBgRequest, setShowBgRequest] = useState(true);
  const [showImprestRequest, setShowImprestRequest] = useState(false);
  const [fetchedBgData, setFetchedBgData] = useState([])
  const [lcChartShowType, setLCChartShowType] = useState("");
  const [bgChartShowType, setBGChartShowType] = useState("");
  const [imprestChartShowType, setImprestChartShowType] = useState("");
  const [dashboardData, setDashboardData] = useState({});
  const [fetchedLcData, setFetchedLcData] = useState([]);
  const [fetchedbBgAllData, setFetchedBgAllData] = useState([]);
  const [fetchedbLcAllData, setFetchedLcAllData] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const roleName = sessionStorage.getItem("roleName")


  const options = [
    { label: "Percentage Data", value: "percentage" },
    { label: "Number Data", value: "value" },
  ];

  const bggraphdata = [
    ["Task", "each Instrument"],
    ["Pending with Approver", dashboardData?.BG?.totalApproverPending],
    ["Pending with Treasury", dashboardData?.BG?.totalTreasuryPending],
    ["Closed", dashboardData?.BG?.totalClosed],
    // ["Rejected", dashboardData?.BG?.totalRejected],
  ];
  const lcgraphdata = [
    ["Task", "each Instrument"],
    ["Pending with Approver", dashboardData?.LC?.totalApproverPending],
    ["Pending with Treasury", dashboardData?.LC?.totalTreausuryPending],
    ["Closed", dashboardData?.LC?.totalClosed],
    // ["Rejected", dashboardData?.LC?.totalRejected],
  ];
  // const imprestgraphdata = [
  //   ["Task", "each Instrument"],
  //   ["L1 Approved", dashboardData?.Imprest?.totalL1Approved],
  //   ["L2 Approved", dashboardData?.Imprest?.totalL2Approved],
  //   ["Closed", dashboardData?.Imprest?.totalClosed],
  //   ["Rejected", dashboardData?.Imprest?.totalRejected],
  // ];

  const lcgraphoptions = {
    slices: {
      0: { color: "#a3b18a" },
      1: { color: "#3a5a40" },
      2: { color: "#e76f51" },
      // 3: { color: "#83c5be" },
    },
    chartArea: { width: 400, height: 400 },
    pieSliceText: lcChartShowType,
    sliceVisibilityThreshold: 0,
  };

  const bggraphoptions = {
    slices: {
      0: { color: "#a3b18a" },
      1: { color: "#3a5a40" },
      2: { color: "#e76f51" },
      // 3: { color: "#83c5be" },
    },
    chartArea: { width: 400, height: 400 },
    pieSliceText: bgChartShowType,
    sliceVisibilityThreshold: 0,
  };
  const imprestgraphoptions = {
    slices: {
      0: { color: "#a3b18a" },
      1: { color: "#3a5a40" },
      2: { color: "#e76f51" },
      // 3: { color: "#83c5be" },
    },
    chartArea: { width: 400, height: 400 },
    pieSliceText: imprestChartShowType,
    sliceVisibilityThreshold: 0,
  };

  useEffect(() => {
    fetchBgData()
    fetchDashboardData()
    fetchLcData()
  }, [])

  const fetchLcData = async () => {
    let payload = {
      limit: 10,
      filters: {},
      pageNumber: 1,
      roleName: roleName,
    };
    callLcApi(payload)
  };

  const callLcApi = async(payload) =>{
    setIsLoading(true)
    await mainStore.getLcInssuance(
      payload,
      (response) => {
        if (response?.data?.meta?.status === "success") {
          const filteredData = response?.data?.data?.filteredData?.results.filter(item => item.isCompleted === true);
          setFetchedLcData(filteredData);
          setFetchedLcAllData(response?.data?.data)
          setIsLoading(false)
        } else {
          notify(response?.data?.meta?.message, "danger");
          setIsLoading(false)
        }
      },
      (error) => {
        notify(error?.response?.data?.meta?.message, "danger");
        setIsLoading(false)
      }
    );

  }

  const fetchDashboardData = async () => {
    setIsLoading(true)
    let payload ={
      currentRole:roleName
    }
    await mainStore.getDashboardData(payload ,(response) => {
        if (response?.data?.meta?.status === "success") {
          setDashboardData(response?.data?.data?.data);
          setIsLoading(false)
        } else {
          notify(response?.data?.meta?.message, "danger");
          setIsLoading(false)
        }
      },
      (error) => {
        notify(error?.response?.data?.meta?.message, "danger");
        setIsLoading(false)
      }
    );
  };

  const fetchBgData = async () => {
    setIsLoading(true)
    let payload = {
      limit: 10,
      filters: {},
      pageNumber: 1,
      roleName: roleName,
    };
    callApi(payload)
  };


const callApi =async(payload) =>{
  setIsLoading(true)
  await mainStore.getRequestorBgInssuance(
    payload,
    (response) => {
      if (response?.data?.meta?.status === "success") {
        const filteredData = response?.data?.data?.filteredData?.results.filter(item => item.isCompleted === true);
        setFetchedBgData(filteredData);
        setFetchedBgAllData(response?.data?.data)
        setIsLoading(false)
      } else {
        notify(response?.data?.meta?.message, "danger");
        setIsLoading(false)
      }
    },
    (error) => {
      notify(error?.response?.data?.meta?.message, "danger");
      setIsLoading(false)
    }
  );
}


  const handleFetchedIssue = (e, item) => {
    e.preventDefault();
    navigate("/approver-bg-issue", { state: { item } });
  };
  const handleFetchedLcIssue = (e, item) => {
    e.preventDefault();
    navigate("/approver-lc-issue", { state: { item } });
  };

  const bgRequest = () => {
    fetchBgData()
    // setShowImprestRequest(false);
    setShowBgRequest(true);
    setShowLcRequest(false);
  };
  const lcRequest = () => {
    fetchLcData()
    // setShowImprestRequest(false);
    setShowBgRequest(false);
    setShowLcRequest(true);
  };
  // const imprestRequest = () => {
  //   setShowImprestRequest(true);
  //   setShowBgRequest(false);
  //   setShowLcRequest(false);
  // };
  const totalClosed = Number(dashboardData?.BG?.totalClosed) || 0;
  const totalApproverPending1 = Number(dashboardData?.BG?.totalApproverPending) || 0;
  const totalTreasuryPending = Number(dashboardData?.BG?.totalTreasuryPending) || 0;

  const totalBg = totalClosed + totalTreasuryPending +totalApproverPending1


  const totalClosedLC = Number(dashboardData?.LC?.totalClosed) || 0;
  const totalApproverPending = Number(dashboardData?.LC?.totalApproverPending) || 0;
  const totalTreasuryPendingLC = Number(dashboardData?.LC?.totalTreausuryPending) || 0;
  const totalLC = totalClosedLC + totalTreasuryPendingLC + totalApproverPending;


  return (
    <div className="main-container dashboard-container">
        {isLoading && <Loader />}
      <div className="right-view">
        <Header title={"Approver Journey Dashboard"} />
        <div className="container-fluid px-0">
          <div className="row">
            <div className="col-md-6">
              <ApproverRoleCard
                src={require("../../../assets/images/list.png")}
                role={"LC"}
                requests={totalLC}
              />
            </div>
            <div className="col-md-6">
              <ApproverRoleCard
                src={require("../../../assets/images/pinklist.png")}
                role={"BG"}
                requests={totalBg}
              />
            </div>
            {/* <div className="col-md-4">
              <ApproverRoleCard
                src={require("../../../assets/images/greenlist.png")}
                role={"Imprest"}
                requests={dashboardData?.Imprest?.totalRecords}
              />
            </div> */}
            <div className="col-md-6">
              <CustomCard
                cardtitle={"LC "}
                cardboldtitle={"Requests"}
                toprightview={<Customselect
                  onChange={(e) => {
                    setLCChartShowType(e.target.value);
                  }}
                  blankLabel={true}
                  optionview={options.map((option) => {
                    return (
                      <option value={option.value}>{option.label}</option>
                    );
                  })} />}
                cardbody={
                  <GraphChart data={lcgraphdata} options={lcgraphoptions} />
                }
              />
            </div>


            <div className="col-md-6">
              <CustomCard
                cardtitle={"BG "}
                cardboldtitle={"Requests"}
                toprightview={<Customselect
                  onChange={(e) => {
                    setBGChartShowType(e.target.value);
                  }}
                  blankLabel={true}
                  optionview={options.map((option) => {
                    return (
                      <option value={option.value}>{option.label}</option>
                    );
                  })}
                />}
                cardbody={
                  <GraphChart data={bggraphdata} options={bggraphoptions} />
                }
              />
            </div>


            {/* <div className="col-md-4">
              <CustomCard
                cardtitle={"Imprest "}
                cardboldtitle={"Requests"}
                toprightview={<Customselect
                  onChange={(e) => {
                    setImprestChartShowType(e.target.value);
                  }}
                  blankLabel={true}
                  optionview={options.map((option) => {
                    return (
                      <option value={option.value}>{option.label}</option>
                    );
                  })}
                />}
                cardbody={
                  <GraphChart data={imprestgraphdata} options={imprestgraphoptions} />
                }
              />
            </div> */}

            <div className="col-md-12">
              <RequestTab
                showLcRequest={showLcRequest}
                showBgRequest={showBgRequest}
                // showImprestRequest={showImprestRequest}
                lcRequest={lcRequest}
                bgRequest={bgRequest}
                // imprestRequest={imprestRequest}
              />
            </div>
            <div className="col-md-12 listing-table">
              {showBgRequest && (
                <BGRequest
                  fetchedBgData={fetchedBgData}
                  handleFetchedIssue={handleFetchedIssue}
                  fetchBgAllData={fetchedbBgAllData}
                  fetchBgData={fetchBgData}
                  callApi ={callApi}
                />
              )}
              {showLcRequest && (
                <LCRequest
                  fetchedLcData={fetchedLcData}
                  handleFetchedLcIssue={handleFetchedLcIssue}
                  fetchLcAllData={fetchedbLcAllData}
                  fetchLcData={fetchLcData}
                  callLcApi ={callLcApi}
                />
              )}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default AJDashboard;

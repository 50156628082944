import React, { useState, useEffect } from 'react'
import Header from '../../../component/Header'
import CustomCard from '../../../component/CustomeCard'
import CustomButton from '../../../component/Button/Button'
import CustomInput from '../../../component/CustomInput'
import Customselect from '../../../component/CustomSelect'
import './style.scss'
import { useLocation, useNavigate } from "react-router-dom"
import Loader from '../../../component/helper/Loader/loader'
import { useStores } from '../../../stores'
import notify from '../../../shared/notify'
import RequisitionApprover from '../../../component/HrNewModuleComponent/Modal/RequisitionApprover'
import RequisitionRejection from '../../../component/HrNewModuleComponent/Modal/RequisitionRejected'
import ForwardRequisitionForm from '../../../component/HrNewModuleComponent/Modal/ForwardRequisitionForm'
import successImage from "../../../assets/images/checked@2x.png"
import FormatDate from '../../../component/helper/formatDate/formateDate'
import ReactExport from "react-export-excel";
import SegmentModal from '../../../component/HrNewModuleComponent/Modal/SegmentModal'


const ExcelFile = ReactExport.ExcelFile;
const ExcelSheet = ReactExport.ExcelFile.ExcelSheet;
const ExcelColumn = ReactExport.ExcelFile.ExcelColumn;


const ApproverRequisitionForm = () => {
    const { crmStore } = useStores()
    const { mainStore } = useStores()
    const { state } = useLocation();
    const navigate = useNavigate()
    const [isLoading, setIsLoading] = useState(false)
    const [businessList, setBusinessList] = useState([])
    const [selectedBusinessList, setSelectedBusinessList] = useState(state?.item?.business?.business_id)
    const [companyNameList, setCompanyNameList] = useState([])
    const [selectedCompanyName, setSelectedCompanyName] = useState(state?.item?.company?.entity_code)
    const [plantList, setPlantList] = useState([])
    const [selectedPlant, setSelectedPlant] = useState(state?.item?.company_plants?.plant_id)
    const [locationList, setLocationList] = useState([])
    const [locationName, setLocationName] = useState(state?.item?.plant_location)
    const [dateOfRequisition, setDateOfRequisition] = useState(state?.item?.date_of_requisition?.split("T")[0])
    const [departmentList, setDeparmentList] = useState([])
    const [selectedDepartment, setSelectedDepartment] = useState(state?.item?.departments?.department_id)
    const [designationList, setDesignationList] = useState([])
    const [designation, setDesignation] = useState(state?.item?.designation?.designation_id)
    const [age, setAge] = useState(state?.item?.person_age)
    const [qualification, setQualification] = useState(state?.item?.person_qualification)
    const [experience, setExperience] = useState(state?.item?.person_experience)
    const [numOfPosition, setNumOfPosition] = useState(state?.item?.number_of_new_position)
    const [positionName, setPositionName] = useState(state?.item?.employee_name)
    const [empId, setEmpId] = useState(state?.item?.employee_id)
    const [currentCtc, setCurrentCtc] = useState(state?.item?.current_ctc)
    const [existingManPower, setExistingManPower] = useState(state?.item?.existing_manpower)
    const [selectedCtcRange, setSelectedCtcRange] = useState(state?.item?.budgeted_manpower)
    const [justification, setJustification] = useState(state?.item?.justification)
    const [jobTitle, setJobTitle] = useState(state?.item?.job_title)
    const [aboutRole, setAboutRole] = useState(state?.item?.about_role)
    const [keyResponsibilities, setKeyResponsibility] = useState(state?.item?.key_responsibilites)
    const [yearOfExperience, setYearOfExperience] = useState(state?.item?.years_of_experience)
    const [educationQualification, setEducationQualification] = useState(state?.item?.education_qualification)
    const [showSubmitModal, setShowSubmitNodal] = useState(false)
    const [selectedTypeOfPosition, setSelectedTypeOfPosition] = useState(state?.item?.position_type)
    const [isSelectedTwo, setIsSelectedTwo] = useState(false)
    const [isSelectedReplacement, setIsSelectedReplacement] = useState(false)
    const [showApproverModal, setShowApproveModal] = useState(false)
    const [showRejectionModal, setShowRejectionModal] = useState(false)
    const [showForwardRequisitionModal, setShowForwardRequisitionModal] = useState(false)
    const [showOther, setShowOther] = useState(false)
    const [other, setOther] = useState(state?.item?.budgeted_manpower_others)
    let fetchRole = sessionStorage.getItem("role")
    const role = fetchRole?.split(",")
    const [isSelected, setIsSelected] = useState("")
    const [isSelectedDeparment, setIsSelectedDepartment] = useState("")
    const [rejectComment, setRejectComment] = useState("")
    const [auditTrail, setAuditTrail] = useState([])
    const [showAll, setShowAll] = useState(false);
    const [subQualification, setSubQualification] = useState(state?.item?.qualification_subcategory)
    const [segmentModal, setSegmentModal] = useState(false)
    const [segmentList , setSegmentList] = useState([])
    const [selectedSegment , setSelectedSegment] = useState("")

    const toggleVisibility = () => {
        setShowAll(prevState => !prevState);
    };

    const dataToDisplay = showAll ? auditTrail : [auditTrail[auditTrail.length - 1]]

    useEffect(() => {
        fetchAuditTrail()
    }, [])

    const fetchAuditTrail = async () => {
        await crmStore.auditTailManpower(state?.item?.requisition_id, (status, res) => {
            if (res?.meta?.status == "success") {
                setAuditTrail(res?.data)
            } else {
                console.log(res?.meta?.message, "error");

            }
        }, (error) => {
            notify(error?.response?.meta?.message, "danger")
        })
    }

    const [ctcRangeList, setCtcRangeList] = useState([
        { value: "1-2 LPA", label: "1-2 LPA" },
        { value: "2-3 LPA", label: "2-3 LPA" },
        { value: "3-4 LPA", label: "3-4 LPA" },
        { value: "5-6 LPA", label: "5-6 LPA" },
        { value: "Others", label: "Others" },
    ])

    useEffect(() => {
        if (selectedCtcRange === "Others") {
            setShowOther(true)
        } else {
            setShowOther(false)
        }
    }, [selectedCtcRange])


    let options = [
        { value: "Yes", label: "Yes" },
        { value: "No", label: "No" },
    ];

    let businessDepartment = [
        { value: "CEO", label: "CEO" },
        { value: "MD", label: "MD" },
        { value: "Director", label: "Director" }
    ]

    let management = ["CEO", "Director", "MD"]

    useEffect(() =>{
        if(role.includes("HR")){
            fetchSegment()
        }
       
    },[])

    const fetchSegment = async() =>{
       await crmStore?.getSegment((status , res) =>{
        if(res?.meta?.status === "success") {
           setSegmentList(res?.data?.users)
        }
       },(error) =>{
        notify(error?.response?.data?.meta?.message , "danger")
       })
    }


    const reUsableApprovalApi = async (payload) => {
        setIsLoading(true)
        await crmStore.approveManPowerForm(state?.item?.requisition_id, payload, (res) => {
            if (res?.data?.meta?.status === "success") {
                notify(res?.data?.meta?.message)
                setIsLoading(false)
                setShowApproveModal(false)
                navigate("/approver-requisition-dashboard")
            } else {
                notify(res?.data?.meta?.message, "danger")
                setIsLoading(false)
            }
        },
            (error) => {
                notify(error?.response?.data?.meta?.message, "danger")
                setIsLoading(false)
            })
    }

    const HOD = () => {
        if (role.includes("HOD")) {
            return (
                <div className="row moodal">
                    <img src={successImage} alt="" className="modal-img" />
                    <h2 className=" text-center">Thank You</h2>
                    <h6 className="mt-3" style={{ textAlign: "center", padding: "0px 40px" }}>
                        You have approved the request and the request is
                        forwarded to Business CXO.
                    </h6>
                </div>
            )
        }
    }

    const businessCXO = () => {
        if (role.includes("CXO")) {
            return (
                <div className="row moodal">
                    <img src={successImage} alt="" className="modal-img" />
                    <h2 className=" text-center">Thank You</h2>
                    <h6 className="mt-3" style={{ textAlign: "center", padding: "0px 40px" }}>
                        You have approved the request and the request is
                        forwarded to Business HR.
                    </h6>
                </div>
            )
        }
    }

    const Management = () => {
        if (role.some(element => management.includes(element))) {
            return (
                <div className="row moodal">
                    <img src={successImage} alt="" className="modal-img" />
                    <h2 className=" text-center">Thank You</h2>
                    <h6 className="mt-3" style={{ textAlign: "center", padding: "0px 40px" }}>
                        Request is successfully approved and forwarded to Business HR for further proccessing.
                    </h6>
                </div>
            )
        }
    }

    const BusinessHR = () => {
        return (
            <div className="row moodal">
                <img src={successImage} alt="" className="modal-img" />
                <h2 className=" text-center">Thank You</h2>
                <h6 className="mt-3" style={{ textAlign: "center", padding: "0px 40px" }}>
                    You have approved the request and the request is
                    forwarded to CHRO.
                </h6>
            </div>
        )
    }

    const CHRO = () => {
        let payload = {
            forwarded_to: isSelectedDeparment,
            is_approved: 1,
        }
        if (role.includes("CHRO")) {
            return (
                <div className="row moodal">
                    <img src={successImage} alt="" className="modal-img" />
                    <h5 className="mt-3" style={{ textAlign: "center", padding: "0px 40px" }}>
                        You have approved the request, do you want to forward it to management
                    </h5>
                    <div className="row">
                        <div className="col-md-12 mt-4">
                            <Customselect
                                onChange={(e) => setIsSelectedDepartment(e.target.value)}
                                optionview={businessDepartment?.map((option) => {
                                    return (
                                        <option value={option?.value}>{option?.label}</option>
                                    );
                                })}
                            />
                        </div>
                    </div>
                    <div className="col-md-12 text-center mt-3">
                        <CustomButton children={"Submit"} style={" btn--approved--solid me-3"} onClick={() => {
                            if (isSelectedDeparment == "" || isSelectedDeparment == null || isSelectedDeparment == undefined) {
                                return notify("please select one", "danger")
                            }
                            reUsableApprovalApi(payload)
                        }} />
                    </div>
                </div>
            )
        }
    }

    const renderModal = () => {
        switch (true) {
            case role.includes("HOD"):
                return HOD();
            case role.includes("CXO"):
                return businessCXO();
            case role.some(element => management.includes(element)):
                return Management();
            case role.includes("HR"):
                return BusinessHR();
            case role.includes("CHRO"):
                return CHRO();
            default:
                return null;
        }
    };



    const getFormattedDate = (dateString) => {
        const date = new Date(dateString);
        const year = date.getFullYear();
        const month = (date.getMonth() + 1).toString().padStart(2, "0");
        const day = date.getDate().toString().padStart(2, "0");
        return `${year}-${month}-${day}`;
    };

    const [typeOfPosition] = useState([
        { value: 'New pos within org chart', label: 'New pos within org chart' },
        { value: 'New pos outside org chart', label: 'New pos outside org chart' },
        { value: 'Replacement', label: 'Replacement' },
    ])


    useEffect(() => {
        if (selectedTypeOfPosition === "New pos within org chart"
            || selectedTypeOfPosition === "New pos outside org chart") {
            setIsSelectedTwo(true)
            setIsSelectedReplacement(false)
        }
        if (selectedTypeOfPosition === "Replacement") {
            setIsSelectedReplacement(true)
            setIsSelectedTwo(false)
        }

    }, [selectedTypeOfPosition])




    useEffect(() => {
        handleBusinessDropDown()
        handleDepartment()
        handleDesignation()
        handleCompanyName()
    }, [])



    const handleBusinessDropDown = async () => {
        await crmStore.getAllBusinessList((status, res) => {
            if (res?.meta?.status === "success") {
                setBusinessList(res?.data)
            } else {
                console.log(res?.data?.meta?.message, "businessList")
            }
        })
    }

    const handleCompanyName = async () => {
        await mainStore.getEntity((status, res) => {
            if (res?.meta?.status === "success") {
                setCompanyNameList(res?.data?.entitites)
            } else {
                console.log(res?.data?.meta?.message, "businessList")
            }
        })
    }

    const handlePlantName = async (id) => {
        let payload = {
            company_id: id
        }
        await crmStore.getAllCompanyPlant(payload, (res) => {
            if (res?.data?.meta?.status === "success") {
                setPlantList(res?.data?.data)
            } else {
                console.log(res?.data?.meta?.message, "businessList")
            }
        })
    }

    const handlePlantLocation = async (id) => {
        let payload = {
            company_id: selectedCompanyName,
            plant_id: id,
        }
        await crmStore.getAllCompanyPlantLocation(payload, (res) => {
            if (res?.data?.meta?.status === "success") {
                let location = res?.data?.data.map((item) => {
                    return item?.location
                })
                setLocationName(location)
            } else {
                console.log(res?.data?.meta?.message, "businessList")
            }
        })
    }
    const handleCompanyNameChange = (e) => {
        setSelectedCompanyName(e.target.value)
        handlePlantName(e.target.value)
        setLocationName("")
    }

    const handlePlantNameChange = (e) => {
        setSelectedPlant(e.target.value)
        handlePlantLocation(e.target.value)
    }




    const handleDepartment = async () => {
        await crmStore.getDeparments((status, res) => {
            if (res?.meta?.status === "success") {
                setDeparmentList(res?.data)
            } else {
                console.log(res?.data?.meta?.message, "setDeparmentList")
            }
        })
    }
    const handleDesignation = async () => {
        await crmStore.getDesignations((status, res) => {
            if (res?.meta?.status === "success") {
                setDesignationList(res?.data)
            } else {
                console.log(res?.data?.meta?.message, "setDesignationList")
            }
        })
    }

    useEffect(() => {
        handlePlantName(selectedCompanyName)
        handlePlantLocation(selectedPlant)
    }, [])


    const handleRejectRequest = () => {
        setShowRejectionModal(true)
    }

    const handleForwordRequisitionModal = () => {
        setShowForwardRequisitionModal(true)
    }


    const handleAprroverOrRejection = async (id) => {
        let payload = {
            is_approved: id
        }
        setIsLoading(true)
        await crmStore.approveManPowerForm(state?.item?.requisition_id, payload, (res) => {
            if (res?.data?.meta?.status === "success") {
                setShowApproveModal(true)
                setIsLoading(false)
            } else {
                notify(res?.data?.meta?.message, "danger")
                setShowApproveModal(false)
                setIsLoading(false)
            }
        },
            (error) => {
                notify(error?.response?.data?.meta?.message, "danger")
                setIsLoading(false)
            })
    }



    const rejectForm = async () => {
        let payload = {
            is_approved: 2,
            comments: rejectComment,
        }
        setIsLoading(true)
        await crmStore.approveManPowerForm(state?.item?.requisition_id, payload, (res) => {
            if (res?.data?.meta?.status === "success") {
                notify(res?.data?.meta?.message)
                setShowRejectionModal(false)
                setIsLoading(false)
                navigate("/approver-requisition-dashboard")
                setRejectComment("")
            } else {
                notify(res?.data?.meta?.message, "danger")
                setIsLoading(false)
            }
        },
            (error) => {
                notify(error?.response?.data?.meta?.message, "danger")
                setIsLoading(false)
            })
    }

    const approverBtn = (id) => {
        if (role.includes("HOD")) {
            handleAprroverOrRejection(id)
        } else if (role.includes("CXO")) {
            handleAprroverOrRejection(id)
        } else if (role.includes("HR")) {
            handleAprroverOrRejection(id)
        } else if (role.includes("CHRO")) {
            setShowApproveModal(true)
        }
        else if (role.some(element => management.includes(element))) {
            handleAprroverOrRejection(id)
        } else {
            notify("Roles not found", "danger")
        }
    }

    const postSegment  = async(payload) =>{
        setIsLoading(true)
      await crmStore?.sendToSegment(state?.item?.requisition_id,payload ,(res) =>{
        if(res?.data?.meta?.status ==="success"){
            setIsLoading(false)
            notify(res?.data?.meta?.message)
            navigate("/approver-requisition-dashboard")
        }
      },(error) =>{
        notify(error?.response?.data?.meta?.message , "danger")
        setIsLoading(false)
      })
    }

    const renderSegmentModal = () => {
        let payload = {
            segmentHR: selectedSegment,
        }
        if (role.includes("HR")) {
            return (
                <div className="row moodal">
                    <img src={successImage} alt="" className="modal-img" />
                    <h5 className="mt-3" style={{ textAlign: "center", padding: "0px 40px" }}>
                        Choose the Segment HR to forward the requisition.
                    </h5>
                    <div className="row">
                        <div className="col-md-12 mt-4">
                            <Customselect
                                onChange={(e) => setSelectedSegment(e.target.value)}
                                optionview={segmentList?.map((option) => {
                                    return (
                                        <option value={option?.id}>{option?.name}</option>
                                    );
                                })}
                            />
                        </div>
                    </div>
                    <div className="col-md-12 text-center mt-3">
                        <CustomButton children={"Submit"} style={" btn--approved--solid me-3"} onClick={() => {
                            if (selectedSegment == "" || selectedSegment == null || selectedSegment == undefined) {
                                return notify("please select one", "danger")
                            }
                            postSegment(payload)
                        }} />
                    </div>
                </div>
            )
        }
    }

        const handleSegment = () => {
            setSegmentModal(true)
        }


        return (
            <div className="main-container">
                {isLoading && <Loader />}
                <div className="right-view">
                    <Header title={" ManPower Requisition Form,"} />
                    <div className='row'>
                        <div className='col-md-12'>
                            <CustomCard
                                cardtitle={"Business"}
                                cardboldtitle={"Details"}
                                cardbody={
                                    <>
                                        <img src={require("../../../assets/images/left-arrow.png")} className="back-btn-dashboard" onClick={() => navigate(-1)} />
                                        <div className='row'>
                                            <div className='col-md-3'>
                                                <Customselect
                                                    label="Select Business Type"
                                                    require={true}
                                                    readOnly={true}
                                                    value={selectedBusinessList}
                                                    onChange={(e) => {
                                                        setSelectedBusinessList(e.target.value);
                                                    }}
                                                    optionview={businessList?.map((item) => {
                                                        return (
                                                            <option value={item?.business_id}>{item?.business_name}</option>
                                                        );
                                                    })}
                                                />
                                            </div>
                                            <div className='col-md-3'>
                                                <Customselect
                                                    label="Select Company Name"
                                                    require={true}
                                                    readOnly={true}
                                                    value={selectedCompanyName}
                                                    onChange={handleCompanyNameChange}
                                                    optionview={companyNameList?.map((item) => {
                                                        return (
                                                            <option value={item?.entity_code}>{item?.name}</option>
                                                        );
                                                    })}
                                                />
                                            </div>
                                            <div className='col-md-3'>
                                                <Customselect
                                                    label="Select Plant"
                                                    readOnly={true}
                                                    value={selectedPlant}
                                                    onChange={handlePlantNameChange}
                                                    optionview={plantList?.map((item) => {
                                                        return (
                                                            <option value={item?.plant_id}>{item?.plant_name}</option>
                                                        );
                                                    })}

                                                />
                                            </div>
                                            <div className='col-md-3'>
                                                <CustomInput
                                                    label="Select Location"
                                                    readOnly={true}
                                                    value={locationName[0]}
                                                    placeholder="Select Location"
                                                />
                                            </div>
                                            <div className='col-md-3'>
                                                <CustomInput
                                                    label="Date of Requisition"
                                                    require={true}
                                                    type="date"
                                                    placeholder='Date of Requisition'
                                                    value={dateOfRequisition && getFormattedDate(dateOfRequisition)}
                                                    readOnly={true}
                                                />
                                            </div>
                                            <div className='col-md-3'>
                                                <Customselect
                                                    label="Department"
                                                    require={true}
                                                    readOnly={true}
                                                    value={selectedDepartment}
                                                    onChange={(e) => {
                                                        setSelectedDepartment(e.target.value);
                                                    }}
                                                    optionview={departmentList?.map((option) => {
                                                        return (
                                                            <option value={option?.department_id}>{option?.department_name}</option>
                                                        );
                                                    })}
                                                />
                                            </div>
                                            <div className='col-md-3'>
                                                <Customselect
                                                    label="Designation"
                                                    require={true}
                                                    readOnly={true}
                                                    value={designation}
                                                    onChange={(e) => {
                                                        setDesignation(e.target.value);
                                                    }}
                                                    optionview={designationList?.map((option) => {
                                                        return (
                                                            <option value={option?.designation_id}>{option?.designation_name}</option>
                                                        );
                                                    })}
                                                />
                                            </div>
                                        </div>
                                    </>}
                            />
                        </div>

                        {/* <div className='col-md-12'>
                        <CustomCard
                            cardtitle={'Person'}
                            cardboldtitle={'Specification'}
                            cardbody={
                                <div className='row'>
                                    <div className='col-md-3'>
                                        <CustomInput
                                            label="Age"
                                            placeholder="Age"
                                            require={true}
                                            readOnly={true}
                                            value={age}
                                            onChange={(e) => setAge(e.target.value)}
                                        />
                                    </div>
                                    <div className='col-md-3'>
                                        <CustomInput
                                            label="Qualification"
                                            placeholder="Qualification"
                                            require={true}
                                            readOnly={true}
                                            value={qualification}
                                            onChange={(e) => setQualification(e.target.value)}
                                        />
                                    </div>
                                    <div className='col-md-3'>
                                        <CustomInput
                                            label="Sub Qualification"
                                            placeholder="Sub Qualification"
                                            require={true}
                                            readOnly={true}
                                            value={subQualification}
                                            onChange={(e) => setSubQualification(e.target.value)}
                                        />
                                    </div>
                                    <div className='col-md-3'>
                                        <CustomInput
                                            label="Experience"
                                            placeholder="Experience"
                                            require={true}
                                            readOnly={true}
                                            value={experience}
                                            onChange={(e) => setExperience(e.target.value)}
                                        />
                                    </div>
                                </div>
                            }
                        />
                    </div> */}


                        <div className='col-md-12'>
                            <CustomCard
                                cardtitle={'New'}
                                cardboldtitle={'Position Details'}
                                cardbody={
                                    <div className='row'>
                                        <div className='col-md-3'>
                                            <Customselect
                                                label="Type of Position"
                                                require={true}
                                                readOnly={true}
                                                value={selectedTypeOfPosition}
                                                onChange={(e) => {
                                                    setSelectedTypeOfPosition(e.target.value);
                                                }}
                                                optionview={typeOfPosition?.map((option) => {
                                                    return (
                                                        <option value={option.value}>{option.label}</option>
                                                    );
                                                })}
                                            />
                                        </div>
                                        {isSelectedTwo && <div className='col-md-3'>
                                            <CustomInput
                                                label="Number of Positions"
                                                placeholder="Number of Positions"
                                                require={true}
                                                readOnly={true}
                                                value={numOfPosition}
                                                onChange={(e) => setNumOfPosition(e.target.value)}
                                            />
                                        </div>}
                                        {
                                            isSelectedReplacement &&
                                            <>
                                                <div className='col-md-3'>
                                                    <CustomInput
                                                        label="Name"
                                                        placeholder="Name"
                                                        require={true}
                                                        readOnly={true}
                                                        value={positionName}
                                                        onChange={(e) => setPositionName(e.target.value)}
                                                    />
                                                </div>
                                                <div className='col-md-3'>
                                                    <CustomInput
                                                        label="Employee ID"
                                                        placeholder="Employee ID"
                                                        require={true}
                                                        value={empId}
                                                        readOnly={true}
                                                        onChange={(e) => setEmpId(e.target.value)}
                                                    />
                                                </div>
                                                <div className='col-md-3'>
                                                    <CustomInput
                                                        label="Current CTC"
                                                        placeholder="Current CTC"
                                                        // require={true}
                                                        readOnly={true}
                                                        value={currentCtc}
                                                        onChange={(e) => setCurrentCtc(e.target.value)}
                                                    />
                                                </div>
                                            </>
                                        }
                                    </div>
                                }
                            />
                        </div>

                        <div className='col-md-12'>
                            <CustomCard
                                cardtitle={'Manpower'}
                                cardboldtitle={'Details'}
                                cardbody={
                                    <div className='row'>
                                        <div className='col-md-3'>
                                            <CustomInput
                                                label="Existing Manpower"
                                                placeholder="Existing Manpower"
                                                require={true}
                                                readOnly={true}
                                                value={existingManPower}
                                                onChange={(e) => setExistingManPower(e.target.value)}
                                            />
                                        </div>
                                        <div className='col-md-3'>
                                            <Customselect
                                                label="CTC Range"
                                                require={true}
                                                readOnly={true}
                                                value={selectedCtcRange}
                                                onChange={(e) => {
                                                    setSelectedCtcRange(e.target.value);
                                                }}
                                                optionview={ctcRangeList?.map((option) => {
                                                    return (
                                                        <option value={option?.value}>{option?.label}</option>
                                                    );
                                                })}
                                            />
                                        </div>

                                        {showOther && <div className='col-md-3'>
                                            <CustomInput
                                                label="Others"
                                                placeholder="Others"
                                                readOnly={true}
                                                require={true}
                                                value={other}
                                                onChange={(e) => setOther(e.target.value)}
                                            />
                                        </div>
                                        }

                                        <div className='col-md-11'>
                                            <p>Justification For New Position  <span style={{ color: "red" }}>*</span></p>
                                            <textarea
                                                cols="10"
                                                className="form-control p-3"
                                                type="text"
                                                placeholder="Justification For New Position"
                                                value={justification}
                                                style={{ background: "#e3e3e3" }}
                                                readOnly={true}
                                                onChange={(e) => setJustification(e.target.value)}
                                            />
                                        </div>
                                    </div>
                                }
                            />
                        </div>

                        <div className='col-md-12'>
                            <CustomCard
                                cardtitle={'Job '}
                                cardboldtitle={'Description'}
                                cardbody={
                                    <div className='row'>
                                        <div className='col-md-11'>
                                            <CustomInput
                                                label='Job Title'
                                                placeholder='Job Title'
                                                value={jobTitle}
                                                readOnly={true}
                                                onChange={(e) => setJobTitle(e.target.value)}
                                            />
                                        </div>
                                        <div className='col-md-11'>
                                            <p>About the Role  <span style={{ color: "red" }}>*</span></p>
                                            <textarea
                                                cols="10"
                                                className="form-control p-3"
                                                type="text"
                                                placeholder="About the Role"
                                                value={aboutRole}
                                                style={{ background: "#e3e3e3" }}
                                                readOnly={true}
                                                onChange={(e) => setAboutRole(e.target.value)}
                                            />
                                        </div>
                                        <div className='col-md-11 mt-3'>
                                            <p>Key Responsibilities  <span style={{ color: "red" }}>*</span></p>
                                            <textarea
                                                cols="10"
                                                className="form-control p-3"
                                                type="text"
                                                placeholder="Key Responsibilities"
                                                value={keyResponsibilities}
                                                readOnly={true}
                                                style={{ background: "#e3e3e3" }}
                                                onChange={(e) => setKeyResponsibility(e.target.value)}
                                            />
                                        </div>
                                        <div className='col-md-11 mt-3'>
                                            <CustomInput
                                                label='Years of experience'
                                                placeholder="Years of experience"
                                                require={true}
                                                value={yearOfExperience}
                                                readOnly={true}
                                                onChange={(e) => setYearOfExperience(e.target.value)}
                                            />
                                        </div>
                                        <div className='col-md-11'>
                                            <CustomInput
                                                label='Education Qualification'
                                                placeholder="Education Qualification"
                                                require={true}
                                                readOnly={true}
                                                value={educationQualification}
                                                onChange={(e) => setEducationQualification(e.target.value)}
                                            />
                                        </div>
                                    </div>
                                }
                            />
                        </div>
                        <div className="col-md-12">
                            <CustomCard
                                cardtitle={"Audit"}
                                cardboldtitle={" trail"}
                                toprightview={
                                    <>
                                        <CustomButton style={"me-3"} children={'Show All Pipeline'} onClick={toggleVisibility} />
                                        <button className="export-btn">
                                            <ExcelFile element={<div> <img width={20} className="me-2" src={require("../../../assets/images/excel.png")} />Export</div>}>
                                                <ExcelSheet data={auditTrail?.map((item, i) => ({
                                                    ...item, index: i + 1, role: item?.role,
                                                    created_at: item?.created_at && `${item.created_at?.split("T")[0]} ${item.created_at?.split("T")[1]?.split(".")[0].substring(0, 5)}`,
                                                }))} name="Sheet">
                                                    <ExcelColumn label="Status Date & Time" value="created_at" />
                                                    <ExcelColumn label="Name" value="userName" />
                                                    <ExcelColumn label="Role" value="role" />
                                                    <ExcelColumn label="Action Required" value="status" />
                                                </ExcelSheet>
                                            </ExcelFile>
                                        </button>
                                    </>
                                }
                                cardbody={
                                    <React.Fragment>
                                        <div class="table-responsive">
                                            <table class="table">
                                                <thead class="table-customlight">
                                                    <tr>
                                                        <th className="auditTrail">Status Date & Time</th>
                                                        <th className="auditTrail">Name</th>
                                                        <th className="auditTrail">Role</th>
                                                        <th className="auditTrail">Status</th>
                                                        <th className="auditTrail">Comment</th>
                                                    </tr>
                                                </thead>
                                                {dataToDisplay?.length > 0 && (
                                                    <tbody>
                                                        {dataToDisplay?.map((item, i) => {
                                                            return (
                                                                <tr key={item?.requestNumber}>
                                                                    <td scope="row"><FormatDate dateString={item?.created_at} /> </td>
                                                                    <td>{item?.userName}</td>
                                                                    <td>{item?.role}</td>
                                                                    <td className="status-box"><span className="status"></span>{item?.status}</td>
                                                                    <td>{item?.comment}</td>

                                                                </tr>)
                                                        })}
                                                    </tbody>)}
                                            </table>
                                        </div>
                                    </React.Fragment>
                                }
                            />
                        </div>


                        {role.includes("HOD") && state?.item?.is_hod_approved == "0" && <div className='col-md-12 text-end'>
                            <CustomButton children={"Rejected"} style={"btn--outline me-3"} onClick={handleRejectRequest} />
                            <CustomButton children={"Approved"} style={"btn--approved--solid"} onClick={() => approverBtn(1)} />
                        </div>}
                        {role.includes("CXO") && state?.item?.is_hod_approved == "1" && state?.item?.is_cxo_approved == "0" && <div className='col-md-12 text-end'>
                            <CustomButton children={"Rejected"} style={"btn--outline me-3"} onClick={handleRejectRequest} />
                            <CustomButton children={"Approved"} style={"btn--approved--solid"} onClick={() => approverBtn(1)} />
                        </div>}

                        {role.includes("HR") && state?.item?.is_cxo_approved == "1" && state?.item?.is_hr_approved == "0" && <div className='col-md-12 text-end'>
                            <CustomButton children={"Rejected"} style={"btn--outline me-3"} onClick={handleRejectRequest} />
                            <CustomButton children={"Approved"} style={"btn--approved--solid"} onClick={() => approverBtn(1)} />
                        </div>}

                        {role.includes("CHRO") && state?.item?.is_hr_approved == "1" && state?.item?.is_chro_approved == "0" && <div className='col-md-12 text-end'>
                            <CustomButton children={"Rejected"} style={"btn--outline me-3"} onClick={handleRejectRequest} />
                            <CustomButton children={"Approved"} style={"btn--approved--solid"} onClick={() => approverBtn(1)} />
                        </div>}

                        {role.includes("CEO") && state?.item?.is_chro_approved == "1" && state?.item?.is_ceo_approved == "0" && <div className='col-md-12 text-end'>
                            <CustomButton children={"Rejected"} style={"btn--outline me-3"} onClick={handleRejectRequest} />
                            <CustomButton children={"Approved"} style={"btn--approved--solid"} onClick={() => approverBtn(1)} />
                        </div>}
                        {role.includes("MD") && state?.item?.is_chro_approved == "1" && state?.item?.is_md_approved == "0" && <div className='col-md-12 text-end'>
                            <CustomButton children={"Rejected"} style={"btn--outline me-3"} onClick={handleRejectRequest} />
                            <CustomButton children={"Approved"} style={"btn--approved--solid"} onClick={() => approverBtn(1)} />
                        </div>}
                        {role.includes("Director") && state?.item?.is_chro_approved == "1" && state?.item?.is_director_approved == "0" && <div className='col-md-12 text-end'>
                            <CustomButton children={"Rejected"} style={"btn--outline me-3"} onClick={handleRejectRequest} />
                            <CustomButton children={"Approved"} style={"btn--approved--solid"} onClick={() => approverBtn(1)} />
                        </div>}


                        {role.includes("HR") && state?.item?.forwarded_to == "HR" && <div className='col-md-12 text-end'>
                            {/* <CustomButton children={"Rejected"} style={"btn--outline me-3"} onClick={handleRejectRequest} /> */}
                            <CustomButton children={"Forward To"} style={"btn--approved--solid"} onClick={() => handleSegment()} />
                        </div>}





                    </div>

                    <RequisitionRejection
                        hide={() => {
                            setShowRejectionModal(false)
                        }}
                        show={showRejectionModal}
                        rejectComment={rejectComment}
                        handleRejectComment={(e) => setRejectComment(e)}
                        rejectForm={rejectForm}
                    />
                    <RequisitionApprover
                        hide={() => {
                            setShowApproveModal(false)
                            navigate("/approver-requisition-dashboard")
                        }}
                        show={showApproverModal}
                        handleForwordApproval={handleForwordRequisitionModal}
                        renderModal={renderModal}
                    />

                    <ForwardRequisitionForm
                        hide={() => {
                            setShowForwardRequisitionModal(false)
                        }}
                        show={showForwardRequisitionModal}
                    />
                    <SegmentModal
                        renderSegmentModal={renderSegmentModal}
                        hide={() => {
                            setSegmentModal(false)
                           
                        }}
                        show={segmentModal}
                    />
                </div>
            </div>
        )
    }

    export default ApproverRequisitionForm
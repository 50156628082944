import { makeAutoObservable } from "mobx";
import Service from "../shared/service/service";




class authStore {
    constructor(rootStore) {
        this.rootStore = rootStore;
        makeAutoObservable(this);
    }

    async login(formData, callback, callbackerror) {
        await Service.postAdmin("login", formData, async (response) => {
            return callback(response)
        }).catch((error) => {
            return callbackerror(error);
        })
    }
    async sendPasswordUrl(formData , callback , callbackerror){
        await Service.post("sendPasswordLink" , formData , async(response) =>{
            return callback(response)
        }).catch((error) =>{
            return callbackerror(error)
        })
    }
    async resetPassword(userId, token,formData , callback , callbackerror){
        await Service.post(`resetPassword/${userId}/${token}` , formData , async(response) =>{
            return callback(response)
        }).catch((error) =>{
            return callbackerror(error)
        })
    }

}


export default authStore;

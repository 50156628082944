import React, { useState } from "react";
import Modal from "react-bootstrap/Modal";
import successImage from "../../assets/images/checked@2x.png";
import crossImage from "../../assets/images/close (4).png";
import CustomButton from "../Button/Button";

const ShowError = ({ hide, show, title1,handleView , item}) => {



    return (
        <Modal show={show} centered className="sucess-content">
            <Modal.Body className="sucess-modal">
                <div className="modal-container">
                    <img
                        src={crossImage}
                        alt=""
                        className="cross-img"
                        onClick={() => hide()}
                    />
                    <div className="row moodal">
                        <img src={successImage} alt="" className="modal-img" />
                        {/* <h5 className="text-center modal-text">"Hi"</h5> */}
                        <span className="mt-1 text-center ">{title1}</span>


                        <div className="text-center">
                            <CustomButton
                            onClick={()=>handleView(item)}
                                children={"View Details"}
                                style={" mt-4"}
                            />
                        </div>
                    </div>

                </div>
            </Modal.Body>
        </Modal>
    );
};

export default ShowError;

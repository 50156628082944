import React, { useState, useEffect } from "react";
import { useLocation } from "react-router-dom";
import Header from "../Header";
import CustomCard from "../CustomeCard";
import CustomInput from "../CustomInput";
import CustomButton from "../Button/Button";
import { useStores } from "../../stores";
import notify from "../../shared/notify";
import ApprovedModal from "../Modal/SubmittedModal";
import CancellationModal from "../Modal/CancellationModal";
import Loader from "../helper/Loader/loader";
import ReactExport from "react-export-excel";
import FormatDate from "../helper/formatDate/formateDate";


const ExcelFile = ReactExport.ExcelFile;
const ExcelSheet = ReactExport.ExcelFile.ExcelSheet;
const ExcelColumn = ReactExport.ExcelFile.ExcelColumn;

const AprrovalBgIssuance = () => {
    const { state } = useLocation();
    const [isL1Approved, setIsL1Approved] = useState(false)
    const [isL2Approved, setIsL2Approved] = useState(false)
    const [isApproverL1, setIsApproverL1] = useState(false)
    const [isApproverL2, setIsApproverL2] = useState(false)
    const [showButton, setShowButton] = useState(false)
    const ApproverRole = sessionStorage.getItem("Approver_role")

    useEffect(() => {
        if (ApproverRole === "Purchase") {
            setIsApproverL1(true)
        }

        if (ApproverRole === "Finance") {
            setIsApproverL2(true)
        }
    }, [ApproverRole])

    useEffect(() => {

        if (state?.item?.isL1Approved === true) {
            setIsL1Approved(true)
        }

        if (state?.item?.isL2Approved === true) {
            setIsL2Approved(true)
        }

    }, [isL2Approved])

    useEffect(() => {
        if (isApproverL1 && !isL1Approved) {
            setShowButton(true)
        } else if (isApproverL2 && isL1Approved && !isL2Approved) {
            setShowButton(true)
        } else {
            setShowButton(false)
        }
    }, [isApproverL1, isApproverL2, isL1Approved, isL2Approved])

    const { mainStore } = useStores()
    const [isLoading, setIsLoading] = useState(false)
    const [requestNumber] = useState(state?.item?.requestNumber);
    const [requestName] = useState(state?.item?.requesterName);
    const [requesterMail] = useState(state?.item?.requesterEmail);
    const [requestEntity] = useState(state?.item?.entity);
    const [requestUnit] = useState(state?.item?.unit);
    const userName = sessionStorage.getItem("name")
    const roleName = sessionStorage.getItem("roleName")
    const [chatComment, setChatComment] = useState("")
    const [chat, setChat] = useState([])
    const [approverInfo, setApproverInfo] = useState([
        { approverId: "", approverName: "", approverDepartment: "", approverEmail: "", approverLevel: "L1" },
        { approverId: "", approverName: "", approverDepartment: "", approverEmail: "", approverLevel: "L2" }
    ]);
    const [bankName] = useState(state?.item?.bankName);
    const [bankAccountNumber] = useState(state?.item?.bankAccountNumber);
    const [bankGlCode] = useState(state?.item?.bankGlCode);
    const [refrenceNumber] = useState(state?.item?.referenceNumber);
    const [applicantName] = useState(state?.item?.applicantName);
    const [applicantLocation] = useState(state?.item?.applicantLocation);
    const [beneficiaryName] = useState(state?.item?.beneficiaryName);
    const [beneficiaryLocation] = useState(state?.item?.beneficiaryLocation);
    const [inssuanceDate] = useState(state?.item?.issuanceDate);
    const [expiryDate] = useState(state?.item?.expiryDate);
    const [currency] = useState(state?.item?.currency);
    const [claimExpiryDate] = useState(state?.item?.claimExpiryDate);
    const [ammount] = useState(state?.item?.amount);
    const [printLocation] = useState(state?.item?.printLocation);
    const [typeOfBg] = useState(state?.item?.bgType);
    const [paybleLocation] = useState(state?.item?.payableLocation);
    const [bgCharges] = useState(state?.item?.bgCharge);
    const [gst] = useState(state?.item?.gst);
    const [stampcharges] = useState(state?.item?.stampCharges);
    const [bgChargesGlCode] = useState(state?.item?.bgChargesGlCode);
    const [gstGlCode] = useState(state?.item?.gstGlCode);
    const [bgText] = useState(() => {
        const bgTextString = state?.item?.bgText;
        if (bgTextString) {
            try {
                return JSON.parse(bgTextString);
            } catch (error) {
                console.error("Error parsing bgText JSON:", error);
            }
        }
        return [];
    });
    const [purchaseOrder] = useState(() => {
        const purchaseOrderString = state?.item?.purchaseOrder;
        if (purchaseOrderString) {
            try {
                return JSON.parse(purchaseOrderString);
            } catch (error) {
                console.error("Error parsing purchaseOrder JSON:", error);
            }
        }
        return [];
    });
    const [showApproveModal, setShowApproveModal] = useState(false)
    const [showCancellationModal, setShowCancellationModal] = useState(false)


    const bgrequestAmendment = state?.item?.bgrequestAmendment;
    const bgrequestCancellation = state?.item?.bgrequestCancellation
    const [division] = useState(state?.item?.lineOfService);

    const [requesterDetailComments, setRequesterDetailComments] = useState(state?.item?.requestorComment)
    const [approverDetailsComment, setApproverDetailsComment] = useState(state?.item?.approverComment)
    const [bankDetailComment, setBankDetailComment] = useState(state?.item?.bankComment)
    const [bankGuaranteeComment, setBankGuaranteeComment] = useState(state?.item?.bankGuaranteeComment)
    const [bankChargesComment, setBankChargesComment] = useState(state?.item?.bankChargesComments)
    const [attachmentComment, setAttachmentComment] = useState(state?.item?.attachmentsComment)
    const [getAuditDetail, setGetAuditDetail] = useState([])


    const getFormattedDate = (dateString) => {
        const date = new Date(dateString);
        const year = date.getFullYear();
        const month = (date.getMonth() + 1).toString().padStart(2, "0");
        const day = date.getDate().toString().padStart(2, "0");
        return `${year}-${month}-${day}`;
      };

    useEffect(() => {
        getBgAuditDetail()
        fetchChatResponse()
        if (state?.item?.approvers.length > 0) {
            let arr = [];
            state.item.approvers.map((item) => {
                let obj = {
                    approverId: item?.approver_id,
                    approverName: item?.user?.name,
                    approverEmail: item?.user?.email,
                    approverDepartment: item?.user?.user_department,
                    approverLevel: item?.approver_level
                }
                arr.push(obj)
            })
            setApproverInfo(arr);
        }
    }, [])

    const handleSendMassege = async () => {

        setIsLoading(true)
        let payload = {
            requestNumber: state?.item?.requestNumber,
            userName: userName,
            userRole: roleName,
            message: chatComment
        }

        await mainStore.sendMassege(payload, (response) => {
            if (response?.data?.meta?.status === "success") {
                notify(response?.data?.meta?.message)
                fetchChatResponse()
                setIsLoading(false)
                setChatComment("")
            } else {
                notify(response?.data?.meta?.message, "danger")
                setChatComment("")
                setIsLoading(false)
            }
        }, (error) => {
            notify(error?.response?.data?.meta?.message, "danger")
            setChatComment("")
        })
    }

    const fetchChatResponse = async () => {
        mainStore.getChatResponse(state?.item?.requestNumber, (status, response) => {
            if (response?.meta?.status === "success") {
                setChat(response?.data?.chats)
            } else {
                notify(response?.meta?.message, "danger")
            }
        }, (error) => {
            notify(error?.response?.meta?.message, "danger")
        })
    }

    const getBgAuditDetail = async () => {
        setIsLoading(true)
        let payload = {}
        await mainStore.getBgAudittrail(state?.item?.requestNumber, payload, (response) => {
            if (response?.data?.meta?.status === "success") {
                setGetAuditDetail(response?.data?.data?.audit)
                setIsLoading(false)
            }
            else {
                notify(response?.data?.meta?.message, "danger")
                setIsLoading(false)
            }
        }, (error) => {
            notify(error?.response?.data?.meta?.message, "danger")
            setIsLoading(false)
        })
    }


    const submitApproval = async () => {
        setShowApproveModal(true)
    }


    const rejectApproval = async () => {
        setShowCancellationModal(true)

    }

    const handleBgTextdownload = async (file) => {
        let payload = {
            filename: file
        }
        callDownloadApi(payload)
    }


    const handlePurchasedownload = async (file) => {
        let payload = {
            filename: file
        }
        callDownloadApi(payload)
    }


    const handleAttachmentDownload = async (attachments) => {
        let payload = {
            filename: attachments
        }
        callDownloadApi(payload)
    }


    const handleCancellationDownload = async (attachments) => {
        let payload = {
            filename: attachments
        }
        callDownloadApi(payload)

    }

    const callDownloadApi = async (payload) => {
        setIsLoading(true);
        await mainStore.downloadImage(payload, (response) => {
            if (response?.data?.meta?.status === "success") {
                notify(response?.data?.meta?.message);
                setIsLoading(false);
                const base64File = response?.data?.data?.base64File;
                const mimeType = response?.data?.data?.mimeType;
                const fileNameParts = response?.data?.data?.filename?.split("-");
                const fileName = fileNameParts.slice(1).join("-");
                if (base64File && mimeType) {
                    const downloadLink = document.createElement('a');
                    downloadLink.href = `data:${mimeType};base64,${base64File}`;
                    downloadLink.download = fileName;
                    document.body.appendChild(downloadLink);
                    downloadLink.click();
                    document.body.removeChild(downloadLink);
                } else {
                    notify("Download link or File type not found", "danger");
                }
            } else {
                notify(response?.data?.meta?.message, "danger");
                setIsLoading(false);
            }
        },
            (error) => {
                notify(error?.response?.data?.meta?.message, "danger");
                setIsLoading(false);
            });
    };


    return (
        <div className="main-container">
            <div className="right-view">
                {isLoading && <Loader />}
                <Header title={"BG > Issue"} />
                <div className="row">
                    <div className="col-md-12">
                        <CustomCard
                            cardtitle={"Requester"}
                            cardboldtitle={"Details"}
                            cardbody={
                                <div className="row">
                                    <div className="col-md-3">
                                        <CustomInput
                                            readOnly={true}
                                            label="Request Number"
                                            placeholder="Request Number"
                                            value={requestNumber}
                                        />
                                    </div>
                                    <div className="col-md-3">
                                        <CustomInput
                                            label="Requester Name"
                                            readOnly={true}
                                            placeholder="Requester Name"
                                            value={requestName}
                                        />
                                    </div>
                                    <div className="col-md-3">
                                        <CustomInput
                                            label="Requester mail ID"
                                            readOnly={true}
                                            placeholder="Requester mail ID"
                                            value={requesterMail}
                                        />
                                    </div>
                                    <div className="col-md-3">
                                        <CustomInput label="Entity" placeholder="Entity"
                                            value={requestEntity}
                                            readOnly={true}
                                        />
                                    </div>
                                    <div className="col-md-3">
                                        <CustomInput label="Unit" placeholder="Unit"
                                            value={requestUnit}
                                            readOnly={true}
                                        />
                                    </div>

                                    <div className="col-md-3 ">
                                        <CustomInput
                                            label="Line Of Service"
                                            placeholder="Line Of Service"
                                            value={division}
                                            readOnly={true}
                                        />
                                    </div>
                                    <div className="col-md-3">
                                        <CustomInput
                                            readOnly={true}
                                            label="Comment"
                                            placeholder="Comment"
                                            value={requesterDetailComments}
                                        />
                                    </div>
                                </div>
                            }
                        />
                    </div>

                    <div className="col-md-12">
                        <CustomCard
                            cardtitle={"Approver"}
                            cardboldtitle={"Details"}
                            cardbody={
                                <div>
                                    {approverInfo.map((approver, index) => (
                                        <div key={index} className="row">
                                            <div className="col-md-4">
                                                <CustomInput

                                                    label={index === 0 ? "Approver Purchase" : "Approver Finance"}
                                                    value={approver.approverName}
                                                    readOnly={true}
                                                    placeholder="Approver Name"
                                                />
                                            </div>
                                            <div className="col-md-4">
                                                <CustomInput
                                                    label="Department"
                                                    value={approver.approverDepartment}
                                                    readOnly={true}
                                                    placeholder="Department"
                                                />
                                            </div>
                                            <div className="col-md-4">
                                                <CustomInput

                                                    label="Mail ID"
                                                    value={approver.approverEmail}
                                                    placeholder="Mail ID"
                                                    readOnly={true}
                                                />
                                            </div>
                                        </div>
                                    ))}
                                    <div className="col-md-4">
                                        <CustomInput
                                            readOnly={true}
                                            label="Comment"
                                            placeholder="Comment"
                                            value={approverDetailsComment}
                                        />
                                    </div>
                                </div>
                            }
                        />
                    </div>

                    <div className="col-md-12">
                        <CustomCard
                            cardtitle={"Bank"}
                            cardboldtitle={"Details"}
                            cardbody={
                                <div className="row">
                                    <div className="col-md-3">
                                        <CustomInput
                                            label="Bank Name"
                                            placeholder="Bank Name"
                                            value={bankName}
                                            readOnly={true}
                                        />
                                    </div>
                                    <div className="col-md-3">
                                        <CustomInput
                                            label="Bank Account Number"
                                            placeholder="Bank Account Number"
                                            value={bankAccountNumber}
                                            readOnly={true}
                                        />
                                    </div>
                                    <div className="col-md-3">
                                        <CustomInput
                                            label="Bank GL Code"
                                            placeholder="Bank GL Code"
                                            value={bankGlCode}
                                            readOnly={true}
                                        />
                                    </div>
                                    <div className="col-md-3">
                                        <CustomInput
                                            readOnly={true}
                                            label="Comment"
                                            placeholder="Comment"
                                            value={bankDetailComment}
                                        />
                                    </div>
                                </div>
                            }
                        />
                    </div>

                    <div className="col-md-12">
                        <CustomCard
                            cardtitle={"Bank Guarantee"}
                            cardboldtitle={"Details"}
                            cardbody={
                                <div className="row">
                                    <div className="col-md-3">
                                        <CustomInput
                                            label="Reference Number"
                                            placeholder="Reference Number"
                                            value={refrenceNumber}
                                            readOnly={true}
                                        />
                                    </div>
                                    <div className="col-md-3">
                                        <CustomInput
                                            label="Applicant Name"
                                            placeholder="Applicant Name"
                                            value={applicantName}
                                            readOnly={true}
                                        />
                                    </div>
                                    <div className="col-md-3">
                                        <CustomInput
                                            label="Applicant Location"
                                            placeholder="Applicant Location"
                                            value={applicantLocation}
                                            readOnly={true}
                                        />
                                    </div>
                                    <div className="col-md-3">
                                        <CustomInput
                                            label="Beneficiary Name"
                                            placeholder="Beneficiary Name"
                                            value={beneficiaryName}
                                            readOnly={true}
                                        />
                                    </div>
                                    <div className="col-md-3">
                                        <CustomInput
                                            label="Beneficiary Location"
                                            placeholder="Beneficiary Location"
                                            value={beneficiaryLocation}
                                            readOnly={true}
                                        />
                                    </div>
                                    <div className="col-md-3">
                                        <CustomInput
                                            label="Issuance Date"
                                            placeholder="Issuance Date"
                                            value={ inssuanceDate && getFormattedDate(inssuanceDate)}
                                            readOnly={true}
                                        />
                                    </div>
                                    <div className="col-md-3">
                                        <CustomInput
                                            label="Expiry Date"
                                            value={ expiryDate && getFormattedDate(expiryDate)}
                                            readOnly={true}
                                            placeholder="Expiry Date"
                                        />
                                    </div>
                                    <div className="col-md-3">
                                        <CustomInput
                                            label="Currency"
                                            value={currency}
                                            readOnly={true}
                                            placeholder="Currency"
                                        />
                                    </div>
                                    <div className="col-md-3">
                                        <CustomInput
                                            label="Claim Expiry Date"
                                            value={ claimExpiryDate && getFormattedDate(claimExpiryDate)}
                                            readOnly={true}
                                            placeholder="Claim Expiry Date"
                                        />
                                    </div>
                                    <div className="col-md-3">
                                        <CustomInput
                                            label="Amount"
                                            value={ammount}
                                            readOnly={true}
                                            placeholder="Amount"
                                        />
                                    </div>
                                    <div className="col-md-3">
                                        <CustomInput
                                            label="Print Location"
                                            value={printLocation}
                                            readOnly={true}
                                            placeholder="Print Location"
                                        />
                                    </div>
                                    <div className="col-md-3">
                                        <CustomInput
                                            label="Type of BG"
                                            readOnly={true}
                                            value={typeOfBg}
                                            placeholder="Type of BG"
                                        />
                                    </div>
                                    <div className="col-md-3">
                                        <CustomInput
                                            label="Payable Location"
                                            value={paybleLocation}
                                            readOnly={true}
                                            placeholder="Payable Location"
                                        />
                                    </div>
                                    <div className="col-md-3">
                                        <CustomInput
                                            readOnly={true}
                                            label="Comment"
                                            placeholder="Comment"
                                            value={bankGuaranteeComment}
                                        />
                                    </div>
                                </div>
                            }
                        />
                    </div>

                    <div className="col-md-12">
                        <CustomCard
                            cardtitle={"Bank Charges"}
                            cardboldtitle={"Details"}
                            cardbody={
                                <div className="row">
                                    <div className="col-md-3">
                                        <CustomInput
                                            label="BG Charges"
                                            value={bgCharges}
                                            readOnly={true}
                                            placeholder="BG Charges"
                                        />
                                    </div>
                                    <div className="col-md-3">
                                        <CustomInput label="GST" placeholder="GST"
                                            value={gst}
                                            readOnly={true}
                                        />
                                    </div>
                                    <div className="col-md-3">
                                        <CustomInput
                                            label="Stamp Charges"
                                            value={stampcharges}
                                            readOnly={true}
                                            placeholder="Stamp Charges"
                                        />
                                    </div>
                                    <div className="col-md-3">
                                        <CustomInput
                                            label="BG Charges GL Code"
                                            value={bgChargesGlCode}
                                            readOnly={true}
                                            placeholder="BG Charges GL Code"
                                        />
                                    </div>
                                    <div className="col-md-3">
                                        <CustomInput
                                            label="GST GL Code"
                                            value={gstGlCode}
                                            readOnly={true}
                                            placeholder="GST GL Code"
                                        />
                                    </div>
                                    <div className="col-md-3">
                                        <CustomInput
                                            readOnly={true}
                                            label="Comment"
                                            placeholder="Comment"
                                            value={bankChargesComment}
                                        />
                                    </div>
                                </div>
                            }
                        />
                    </div>

                    <div className="col-md-12">
                        <CustomCard
                            cardboldtitle={"Attachments"}
                            cardbody={
                                <div className="row">
                                    <div className="col-md-3">
                                        <CustomInput
                                            label="BG Text"
                                            readOnly={true}
                                            placeholder="Browse File"
                                        />
                                        <div className="uploaded-images">
                                            {bgText.map((bgText, index) => (
                                                <div key={index} style={{ cursor: "pointer", color: "#65a6fa", marginBottom: '8px' }} onClick={() => handleBgTextdownload(bgText)} className="url-download">
                                                    {bgText}
                                                </div>
                                            ))}
                                        </div>
                                    </div>
                                    <div className="col-md-3">
                                        <CustomInput
                                            label="Purchase Order/ RFP"
                                            readOnly={true}
                                            placeholder="Browse File"
                                        />
                                        <div className="uploaded-images">
                                            {purchaseOrder.map((order, index) => (
                                                <div key={index} style={{ cursor: "pointer", color: "#65a6fa", marginBottom: '8px' }} onClick={() => handlePurchasedownload(order)} className="url-download">
                                                    {order}
                                                </div>
                                            ))}
                                        </div>
                                    </div>
                                    <div className="col-md-3">
                                        <CustomInput
                                            readOnly={true}
                                            label="Comment"
                                            placeholder="Comment"
                                            value={attachmentComment}
                                        />
                                    </div>
                                </div>
                            }
                        />
                    </div>

                    {bgrequestAmendment?.length > 0 &&
                        <div className="col-md-12">
                            {bgrequestAmendment.map((amendment, index) => {
                                let ammendmentData;
                                if (amendment?.attachments) {
                                    ammendmentData = JSON.parse(amendment?.attachments)
                                }

                                return <>
                                    <CustomCard
                                        cardboldtitle={`Amendment ${index + 1}`}
                                        cardbody={
                                            <div className="row">
                                                <div className="col-md-3">
                                                    <CustomInput
                                                        readOnly={true}
                                                        label="Expiry Date"
                                                        placeholder="Expiry Date"
                                                        type="date"
                                                        value={amendment?.expiryDate?.split?.("T")[0]}
                                                    />
                                                </div>
                                                <div className="col-md-3">
                                                    <CustomInput
                                                        readOnly={true}
                                                        label="Claim Expiry Date"
                                                        placeholder="Claim Expiry Date"
                                                        type="date"
                                                        value={amendment?.claimExpiryDate?.split?.("T")[0]}
                                                    />
                                                </div>
                                                <div className="col-md-3">
                                                    <CustomInput
                                                        readOnly={true}
                                                        label="Clause"
                                                        placeholder="Clause"
                                                        value={amendment?.clause}
                                                    />
                                                </div>
                                                <div className="col-md-3">
                                                    <CustomInput
                                                        readOnly={true}
                                                        label="Others"
                                                        placeholder="Others"
                                                        value={amendment?.others}
                                                    />
                                                </div>
                                                <div className="col-md-3">
                                                    <CustomInput
                                                        label="Attachment"
                                                        placeholder="Browse file"
                                                        readOnly={true}
                                                    />
                                                    {ammendmentData && ammendmentData.map((item) => {
                                                        return <div style={{ cursor: "pointer", color: "#65a6fa" }} onClick={() => handleAttachmentDownload(item)} className="url-download">
                                                            {item}
                                                        </div>
                                                    })}
                                                </div>
                                                <div className="col-md-3">
                                                    <CustomInput
                                                        readOnly={true}
                                                        label="BG Charges"
                                                        value={(amendment?.bgCharge)}
                                                        placeholder="BG Charges"
                                                    />
                                                </div>
                                                <div className="col-md-3">
                                                    <CustomInput label="GST" placeholder="GST"
                                                        readOnly={true}
                                                        value={(amendment?.gst)}

                                                    />
                                                </div>
                                                <div className="col-md-3">
                                                    <CustomInput
                                                        readOnly={true}
                                                        label="Stamp Charges"
                                                        value={(amendment?.stampCharges)}
                                                        placeholder="Stamp Charges"

                                                    />
                                                </div>
                                                <div className="col-md-3">
                                                    <CustomInput
                                                        readOnly={true}
                                                        label="BG Charges GL Code"
                                                        value={(amendment?.bgChargesGlCode)}
                                                        placeholder="BG Charges GL Code"

                                                    />
                                                </div>
                                                <div className="col-md-3">
                                                    <CustomInput
                                                        readOnly={true}
                                                        label="GST GL Code"
                                                        value={(amendment?.gstGlCode)}
                                                        placeholder="GST GL Code"

                                                    />
                                                </div>
                                                <div className="col-md-3">
                                                    <CustomInput
                                                        readOnly={true}
                                                        label="Comment"
                                                        placeholder="Comment"
                                                        value={amendment?.comment}
                                                    />
                                                </div>
                                            </div>
                                        }

                                    />
                                </>
                            })}

                        </div>}

                    {bgrequestCancellation?.length > 0 &&
                        <div className="col-md-12">
                            {bgrequestCancellation.map((cancellation, index) => {
                                let cancellationData;
                                if (cancellation?.attachments) {
                                    cancellationData = JSON.parse(cancellation?.attachments)
                                }
                                return <CustomCard

                                    cardboldtitle={`Cancellation ${index + 1}`}
                                    cardbody={
                                        <div className="row">
                                            <div className="col-md-3">
                                                <CustomInput
                                                    readOnly={true}
                                                    label="Cancellation Date"
                                                    placeholder="Cancellation Date"
                                                    type="date"
                                                    value={cancellation?.cancellationDate?.split?.("T")[0]}
                                                />
                                            </div>
                                            <div className="col-md-3">
                                                <CustomInput
                                                    label="Attachment"
                                                    placeholder="Browse file"
                                                    readOnly={true}

                                                />
                                                {cancellationData && cancellationData.map((item) => {
                                                    return <div style={{ cursor: "pointer", color: "#65a6fa" }} onClick={() => handleCancellationDownload(item)} className="url-download">
                                                        {item}
                                                    </div>
                                                })}
                                            </div>
                                            <div className="col-md-3">
                                                <CustomInput
                                                    readOnly={true}
                                                    label="BG Charges"
                                                    value={cancellation?.bgCharge}
                                                    placeholder="BG Charges"
                                                />
                                            </div>
                                            <div className="col-md-3">
                                                <CustomInput label="GST" placeholder="GST"
                                                    readOnly={true}
                                                    value={cancellation?.gst}

                                                />
                                            </div>
                                            <div className="col-md-3">
                                                <CustomInput
                                                    readOnly={true}
                                                    label="Stamp Charges"
                                                    value={cancellation?.stampCharges}
                                                    placeholder="Stamp Charges"

                                                />
                                            </div>
                                            <div className="col-md-3">
                                                <CustomInput
                                                    readOnly={true}
                                                    label="BG Charges GL Code"
                                                    value={cancellation?.bgChargesGlCode}
                                                    placeholder="BG Charges GL Code"

                                                />
                                            </div>
                                            <div className="col-md-3">
                                                <CustomInput
                                                    readOnly={true}
                                                    label="GST GL Code"
                                                    value={cancellation?.gstGlCode}
                                                    placeholder="GST GL Code"

                                                />
                                            </div>
                                            <div className="col-md-3">
                                                <CustomInput
                                                    readOnly={true}
                                                    label="Comment"
                                                    placeholder="Comment"
                                                    value={cancellation?.comment}
                                                />
                                            </div>
                                        </div>
                                    }
                                />
                            })}
                        </div>}

                    <div className="col-md-12">
                        <CustomCard
                            cardtitle={"Audit"}
                            cardboldtitle={" trail"}
                            toprightview={
                                <button className="export-btn">
                                    <ExcelFile element={<div> <img width={20} className="me-2" src={require("../../assets/images/excel.png")} />Export</div>}>
                                        <ExcelSheet data={getAuditDetail?.map((item, i) => ({
                                            ...item, index: i + 1, role: item?.role,
                                            created_at: item?.created_at && `${item.created_at?.split("T")[0]} ${item.created_at?.split("T")[1]?.split(".")[0].substring(0, 5)}`,
                                        }))} name="Sheet">
                                            <ExcelColumn label="Status Date & Time" value="created_at" />
                                            <ExcelColumn label="Name" value="userName" />
                                            <ExcelColumn label="Role" value="role" />
                                            <ExcelColumn label="Action Required" value="status" />
                                        </ExcelSheet>
                                    </ExcelFile>
                                </button>
                            }
                            cardbody={
                                <React.Fragment>
                                    <div className="table-responsive">
                                        <table className="table">
                                            <thead className="table-customlight">
                                                <tr>
                                                    <th className="auditTrail">Status Date & Time</th>
                                                    <th className="auditTrail">Name</th>
                                                    <th className="auditTrail">Role</th>
                                                    <th className="auditTrail">Action Required</th>
                                                </tr>
                                            </thead>
                                            {getAuditDetail?.length > 0 && (
                                                <tbody>
                                                    {getAuditDetail?.map((item, i) => {
                                                        return (
                                                            <tr key={item?.requestNumber}>
                                                                <td scope="row"><FormatDate dateString={item?.created_at} /> </td>
                                                                <td>{item?.userName}</td>
                                                                <td>{item?.role}</td>
                                                                <td className="status-box"><span className="status"></span>{item?.status}</td>

                                                            </tr>)
                                                    })}
                                                </tbody>)}
                                        </table>
                                    </div>
                                </React.Fragment>
                            }
                        />
                    </div>

                    <div className="col-md-12">
                        <CustomCard
                            cardtitle={""}
                            cardboldtitle={"Chat"}
                            cardbody={
                                <div className="row">
                                    {chat?.length > 0 && (
                                        <>
                                            {chat?.map((item, i) => (
                                                <div key={i} className="col-md-12 chat-left">
                                                    <div className="chat-container">
                                                        <h4 className="chat-username">{item?.userName}({item?.userRole})</h4>
                                                        <div className="chat-username-box">
                                                            <p className="chat-massege">{item?.message}</p>
                                                            <p className="chat-time">
                                                                <FormatDate dateString={item?.created_at} />
                                                            </p>
                                                        </div>
                                                    </div>
                                                </div>
                                            ))}
                                            <div className="col-md-6 " style={{ width: "44%" }}>
                                                <CustomInput
                                                    label="Comment Box"
                                                    value={chatComment}
                                                    onChange={(e) => setChatComment(e.target.value)}
                                                    placeholder="Comment Box"
                                                />
                                            </div>
                                            <div className="col-md-2 chat-button">
                                                <CustomButton
                                                    style={"btn--outline me-3"}
                                                    children={"Send"}
                                                    onClick={handleSendMassege}
                                                />
                                            </div>
                                        </>
                                    )}
                                </div>
                            }
                        />
                    </div>

                    {showButton &&
                        <div className="col-md-12 text-end">
                            <CustomButton style={"btn--outline me-3"} children={"Rejected"} onClick={rejectApproval} />
                            <CustomButton children={"Approved"} style={"btn--approved--solid"} onClick={submitApproval} />
                        </div>}
                </div>
            </div>
            <ApprovedModal
                show={showApproveModal}
                hide={() => {
                    setShowApproveModal(false)
                }}
                requestNumber={state?.item?.requestNumber}
            />
            <CancellationModal
                show={showCancellationModal}
                hide={() => {
                    setShowCancellationModal(false)
                }}
                requestNumber={state?.item?.requestNumber}
            />

        </div>
    );
};

export default AprrovalBgIssuance;

import React, { useEffect, useState ,useRef } from 'react'
import ManPowerRequistionTable from '../../../component/HrNewModuleComponent/ManPowerRequistionTable'
import Header from '../../../component/Header'
import './style.scss'
import { useLocation, useNavigate } from "react-router-dom"
import { useStores } from '../../../stores';
import notify from '../../../shared/notify';
import Loader from '../../../component/helper/Loader/loader';



const limit = [
  {
    option: 10,
    value: 10,
  },
  {
    option: 100,
    value: 100,
  },
  {
    option: 500,
    value: 500,
  },
  {
    option: 1000,
    value: 1000,
  },
];

const ManPowerRequisition = () => {
  const [page, setPage] = useState(parseInt(sessionStorage.getItem('currentManpowerPage')) || 1);
  const [pageLimit, setPageLimit] = useState(parseInt(sessionStorage.getItem('pageManpowerLimit')) || 10);
  const navigate = useNavigate()
  const location = useLocation();
  const url = location?.pathname?.split("/")[1];
  const { crmStore } = useStores()
  const [isLoading, setIsLoading] = useState(false)
  const [fetchedAllRequisition, setFetchedAllRequisition] = useState([])
  const [requistionData, setRequistionData] = useState([])
  const [designationName, setDesignationName] = useState("")
  const [deparmentName, setDeparmentName] = useState("")
  const [dateOfRequisition, setDateOfRequisition] = useState("")
  const [selectedTypeOfPosition, setSelectedTypeOfPosition] = useState("")
  const[selectedStatusList , setSelectedStatusList] = useState("")
  const [typeOfPosition, setTypeOfPosition] = useState([
    { value: 'New pos within org chart', label: 'New pos within org chart' },
    { value: 'New pos outside org chart', label: 'New pos outside org chart' },
    { value: 'Replacement', label: 'Replacement' },
  ])
  const candidateString = sessionStorage.getItem("candidate");
  const candidate = candidateString ? JSON.parse(candidateString) : null;

  const [departmentList, setDeparmentList] = useState([])
  const [designationList, setDesignationList] = useState([])

  useEffect(() =>{
    fetchDepartment()
    fetchDesignation()
  },[])

  const targetRowRef = useRef(null);

  const fetchDepartment = async () => {
    await crmStore.getDeparments((status, res) => {
        if (res?.meta?.status === "success") {
            setDeparmentList(res?.data)
        } else {
            console.log(res?.data?.meta?.message, "setDeparmentList")
        }
    })
}
const fetchDesignation = async () => {
    await crmStore.getDesignations((status, res) => {
        if (res?.meta?.status === "success") {
            setDesignationList(res?.data)
        } else {
            console.log(res?.data?.meta?.message, "setDesignationList")
        }
    })
}

  const handleSelectPostion = (e) => {
    setSelectedTypeOfPosition(e)
  }



  const handleDateOfRequistionn = (e) => {
    setDateOfRequisition(e)
  }

  const handleDepartment = (e) => {
    setDeparmentName(e)
  }

  const handleDesignation = (e) => {
    setDesignationName(e)
  }


  const handleTypeOfPositionn = (e) => {
    setTypeOfPosition(e)
  }


  const handleEditForm = (item) => {
    navigate('/edit-requisition-form', { state: { item } })
  }

  const handleView = (item) => {
    navigate('/view-requisition-form', { state: { item } })
  }

  const handleAddNewRequisition = () => {
    navigate('/add-new-requisition')
  }

 
  // useEffect(() => {
  //   // fetchAllManRequisition();
  //   const interval = setInterval(() => {
  //     fetchAllManRequisition();
  //   }, 90000);
  //   return () => clearInterval(interval);
  // }, []);

  const fetchAllManRequisition = async () => {
    let payload = {
      pageNumber: page,
      filters: {
        business_id : candidate?.business_id
      },
      limit: pageLimit
    }
    // if(candidate){
    //   payload.filters.business_id = candidate?.business_id
    // }
    callRequisitionApi(payload)
  }

  useEffect(() => {
    const storedDesignationName = sessionStorage.getItem('designationName');
    const storedTypeOfPosition = sessionStorage.getItem('selectedTypeOfPosition');
    const storedDateOfRequisition = sessionStorage.getItem('dateOfRequisition');
    const storedDepartmentName = sessionStorage.getItem('deparmentName');
    const storedStatusList = sessionStorage.getItem('selectedStatusList');
    if (storedDesignationName) setDesignationName(storedDesignationName);
    if (storedTypeOfPosition) setSelectedTypeOfPosition(storedTypeOfPosition);
    if (storedDateOfRequisition) setDateOfRequisition(storedDateOfRequisition);
    if (storedDepartmentName) setDeparmentName(storedDepartmentName);
    if (storedStatusList) setSelectedStatusList(storedStatusList);
    let payload = {
      limit: pageLimit,
      filters: {
        designation_name: storedDesignationName || "",
        position_type: storedTypeOfPosition || "",
        date_of_requisition: storedDateOfRequisition || "",
        department_name: storedDepartmentName || "",
      },
      pageNumber: page,
    };
  
    if (candidate) {
      payload.filters.business_id = candidate?.business_id;
    }
  
    if (storedStatusList && storedStatusList === "Final Approved") {
      payload.filters.is_final_approved = 1;
    }
    if (storedStatusList && storedStatusList === "Approved") {
      payload.filters.is_current_approved = 1;
    }
    if (storedStatusList && storedStatusList === "Rejected") {
      payload.filters.is_final_approved = 2;
    }
  
    callRequisitionApi(payload);
  }, [pageLimit, page]);

  const callRequisitionApi = async (payload) => {
    setIsLoading(true)
    await crmStore.getAllManRequsition(payload, (res) => {
      if (res?.data?.meta?.status === "success") {
        console.log(res?.data?.data?.filteredData?.results,"res?.data?.data?.filteredData?.results");

        setFetchedAllRequisition(res?.data?.data?.filteredData?.results)
        setRequistionData(res?.data?.data)
        setIsLoading(false)
        if (targetRowRef.current) {
          setTimeout(() => {
              targetRowRef?.current?.scrollIntoView({ behavior: 'smooth', block: 'center' });
          }, 1000); // Adjust the timeout as necessary
      }
      }
      else {
        setIsLoading(false)
        notify(res?.data?.meta?.message, "danger")
      }
    }, (error) => {
      notify(error?.response?.data?.meta?.message ,"danger")
      setIsLoading(false)
    })
  }

  const handlePageSubmitt = async (index) => {
    const newPage = index.selected + 1;
    setPage(newPage);
    sessionStorage.setItem('currentManpowerPage', newPage);
    let payload = {
      limit: pageLimit,
      filters: {
      },
      pageNumber: index.selected + 1,
    };
    if(candidate){
      payload.filters.business_id = candidate?.business_id
    }
    callRequisitionApi(payload);
  };

  const limitChangeHandlerr = async (index) => {
    setPageLimit(index);
    sessionStorage.setItem('pageManpowerLimit', index);
    let payload = {
      limit: index,
      filters: {
      },
      pageNumber: page,
    };
    if(candidate){
      payload.filters.business_id = candidate?.business_id
    }
    callRequisitionApi(payload);
  };

  const handleFilterr = async () => {
    sessionStorage.setItem('designationName', designationName);
    sessionStorage.setItem('selectedTypeOfPosition', selectedTypeOfPosition);
    sessionStorage.setItem('dateOfRequisition', dateOfRequisition);
    sessionStorage.setItem('deparmentName', deparmentName);
    sessionStorage.setItem('selectedStatusList', selectedStatusList);
    let payload = {
      limit: pageLimit,
      filters: {
        designation_name: designationName,
        position_type: selectedTypeOfPosition,
      },
      pageNumber: page,
    };
    if(candidate){
      payload.filters.business_id = candidate?.business_id
    }
    if (dateOfRequisition) {
      payload.filters.date_of_requisition = dateOfRequisition
    }
    if (deparmentName) {
      payload.filters.department_name = deparmentName
    }
    if(selectedStatusList && (selectedStatusList == "Final Approved")){
      payload.filters.is_final_approved =  1 
    }
    if(selectedStatusList && (selectedStatusList == "Approved")){
      payload.filters.is_current_approved =  1 
    }
    if(selectedStatusList && (selectedStatusList == "Rejected")){
      payload.filters.is_final_approved =  2
    }
    callRequisitionApi(payload);
  };

  const handleResett = async () => {
    setDeparmentName("")
    setDesignationName("")
    setSelectedTypeOfPosition("")
    setDateOfRequisition("")
    setSelectedStatusList("")
    setPage(1);
    setPageLimit(10);
    sessionStorage.removeItem("currentManpowerPage")
    sessionStorage.removeItem("pageManpowerLimit")
    sessionStorage.removeItem("designationName");
    sessionStorage.removeItem("selectedTypeOfPosition");
    sessionStorage.removeItem("dateOfRequisition");
    sessionStorage.removeItem("deparmentName");
    sessionStorage.removeItem("selectedStatusList");
    let payload = {
      limit: 10,
      filters: {
      },
      pageNumber: 1
    }
    callRequisitionApi(payload);

    const interval = setInterval(() => {
      callRequisitionApi(payload)();
    }, 500000);
    return () => clearInterval(interval);
  };



  return (
    <div className="main-container">
      {isLoading && <Loader />}
      <div className="right-view">
        <Header title={" ManPower Requisition"} />
        <div className='row'>
          <div className='col-md-12'>
            <ManPowerRequistionTable
              handleEdit={handleEditForm}
              handleView={handleView}
              handleAddNewRequisition={handleAddNewRequisition}
              getRequisitionData={fetchedAllRequisition}
              requistionAllData={requistionData}
              handlePageSubmit={handlePageSubmitt}
              page={page}
              pageLimit={pageLimit}
              limit={limit}
              limitChangeHandler={limitChangeHandlerr}
              handleFilter={handleFilterr}
              handleReset={handleResett}
              handleDateOfRequistion={handleDateOfRequistionn}
              handleDepartmentName={handleDepartment}
              handleDesignationName={handleDesignation}
              handleTypeOfPosition={handleTypeOfPositionn}
              dateOfRequisition={dateOfRequisition}
              designationName={designationName}
              deparmentName={deparmentName}
              handleSelectPostion={handleSelectPostion}
              handleStatus = {(e) =>setSelectedStatusList(e)}
              typeOfPosition={typeOfPosition}
              selectedTypeOfPosition={selectedTypeOfPosition}
              selectedStatusList={selectedStatusList}
              designationList={designationList}
              departmentList={departmentList}
              targetRowRef={targetRowRef}
            />
          </div>
        </div>
      </div>
    </div>
  )
}

export default ManPowerRequisition
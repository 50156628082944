import React, { useState } from "react";
// import "./authStyle.scss";
import logo from "../assets/images/logo.png"
import CustomButton from "../component/Button/Button";
import CustomInput from "../component/CustomInput";
import { useStores } from "../stores";
import Loader from "../component/helper/Loader/loader";
import notify from "../shared/notify";
import { useLocation } from "react-router-dom";

const ForgetPassword = () => {
  const { authStore } = useStores()
  const [password, setPassword] = useState("")
  const [showpassword, setShowpassword] = useState("password")
  const [isLoading, setIsLoading] = useState(false)
  const location = useLocation();
  const userId = location.pathname?.split("/")[2];
  const token = location.pathname?.split("/")[3];

  const handleResetPassword = async () => {
    setIsLoading(true)

    let payload = {
      password: password
    }
    await authStore.resetPassword(userId, token,payload,(response) => {
      if (response?.data?.meta?.status === "success") {
        setIsLoading(false)
        notify(response?.data?.meta?.message)


      } else {
        notify(response?.data?.meta?.message, "danger")
        setIsLoading(false)
        setPassword("")
      }

    }, (error) => {

      notify(error?.response?.data?.meta?.message, "danger")
      setIsLoading(false)
      setPassword("")
    })

  }

  const togglePassword = () => {
    if (showpassword === "password") {
      setShowpassword("text")
      return;
    }
    setShowpassword("password")
  }

  return (
    <div className="login-mainContainer">
      {isLoading && <Loader />}
      <div className="login-container">
        <div className="login-box">
          <img src={logo} className="logo-style" alt="" />
          <div>
            <h2 className="login-heading">Forget to your SAEL email account</h2>
            <div className="heading-underline"></div>
          </div>
          <div className="row login-dashboard">
            <div className="col-md-12" style={{ position: "relative" }}>
              <CustomInput
                label="Password"
                type={showpassword}
                value={password}
                onChange={(e) => setPassword(e.target.value)}
                placeholder="Password"
              />
              <div className="password-hideshow">
                <div type="button" onClick={togglePassword}>
                  {showpassword === "password" ?
                    <img src={require('../assets/images/eye-slash-solid.png')} alt="" />
                    :
                    <img src={require('../assets/images/eye-solid.png')} alt="" />
                  }
                </div>
              </div>
            </div>
          </div>
          <CustomButton children={"Reset"} onClick={handleResetPassword} />
        </div>
      </div>
    </div>
  );
};

export default ForgetPassword;

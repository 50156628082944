import React, { useState, useEffect } from "react";
import Header from "../../../../component/Header";
import "./style.scss";
import Loader from "../../../../component/helper/Loader/loader";
import { useNavigate } from "react-router-dom";
import { useStores } from "../../../../stores";
import notify from "../../../../shared/notify";
import ComplaintDashboard from "../../../../component/CRMModuleComponent/complaintDashboard";

const limit = [
  {option: 10, value: 10,},
  { option: 100, value: 100,},
  {option: 500, value: 500,},
  {option: 1000, value: 1000,},
];

const MyTicket = () => {
  const [isLoading, setIsLoading] = useState(false);
  const [complaintData, setComplaintData] = useState([]);
  const [allComplainData, setAllComplainData] = useState([]);
  const navigate = useNavigate();
  const { crmStore } = useStores();
  const [page, setPage] = useState(parseInt(sessionStorage.getItem('currentPagee')) || 1);
  const [pageLimit, setPageLimit] = useState(parseInt(sessionStorage.getItem('pageLimitt')) || 10);
  const [categoryList, setCategoryList] = useState([]);
  const [selectCategory, setSelectCategory] = useState(sessionStorage.getItem('selectedCategoryy') || "");
  const [priorityList, setPriorityList] = useState([]);
  const [selectedPriority, setSelectedPriority] = useState(sessionStorage.getItem('selectedPriorityy') || "");

  let fetchRole = sessionStorage.getItem("role");
  const role = fetchRole?.split(",");
  let sessions = sessionStorage.getItem("candidate");
  let session = JSON.parse(sessions);

  useEffect(() => {
    fetchAllCategories();
    getPriorities();

    // Restore filters and pagination from sessionStorage
    const storedCategory = sessionStorage.getItem('selectedCategoryy');
    const storedPriority = sessionStorage.getItem('selectedPriorityy');

    if (storedCategory) setSelectCategory(storedCategory);
    if (storedPriority) setSelectedPriority(storedPriority);

    // Fetch complaints with restored filters
    FetchedComplain();
  }, [pageLimit, page]);

  const FetchedComplain = async () => {
    let payload = {
      limit: pageLimit,
      filters: {},
      pageNumber: page,
    };

    if (role?.includes("Inhouse consultant")) {
      payload.filters.reporter_id = session?.id;
    }
    if (selectCategory) {
      payload.filters.category_id = selectCategory;
    }
    if (selectedPriority) {
      payload.filters.priority_id = selectedPriority;
    }

    CallFetchedComplain(payload);
  };

  const CallFetchedComplain = async (payload) => {
    setIsLoading(true);
    await crmStore.getAllCmsComplaints(
      payload,
      (res) => {
        if (res?.data?.meta?.status === "success") {
          setComplaintData(res?.data?.data?.filteredData?.results);
          setAllComplainData(res?.data?.data);
          setIsLoading(false);
        }
      },
      (err) => {
        notify(err?.response?.data?.meta?.message, "danger");
        setIsLoading(false);
      }
    );
  };

  const handlePageSubmitt = async (index) => {
    const newPage = index.selected + 1;
    setPage(newPage);
    sessionStorage.setItem('currentPagee', newPage);
    FetchedComplain();
  };

  const limitChangeHandlerr = async (index) => {
    setPageLimit(index);
    sessionStorage.setItem('pageLimitt', index);
    FetchedComplain();
  };

  const handleFilterr = async () => {
    sessionStorage.setItem('selectedCategoryy', selectCategory);
    sessionStorage.setItem('selectedPriorityy', selectedPriority);

    FetchedComplain();
  };

  const handleResett = async () => {
    setSelectCategory("");
    setSelectedPriority("");
    setPage(1);
    setPageLimit(10);

    sessionStorage.removeItem('selectedCategoryy');
    sessionStorage.removeItem('selectedPriorityy');
    sessionStorage.removeItem('currentPagee');
    sessionStorage.removeItem('pageLimitt');

    let payload = {
      limit: 10,
      filters: {
        reporter_id: session?.id,
      },
      pageNumber: 1,
    };

    CallFetchedComplain(payload);
  };

  const fetchAllCategories = () => {
    setIsLoading(true);
    crmStore.getAllCategories(
      (status, res) => {
        if (res?.meta?.status === "success") {
          setCategoryList(res.data.categories);
          setIsLoading(false);
        } else {
          setIsLoading(false);
        }
      },
      (error) => {
        notify(error?.response?.data?.meta?.message, "danger");
        setIsLoading(false);
      }
    );
  };

  const getPriorities = () => {
    crmStore.getPriorityNoFilters((status, res) => {
      if (res?.meta?.status === "success") {
        setPriorityList(res?.data?.getAllComplaintPriorityLevels);
      } else {
        console.log(res?.data?.meta?.message, "setDeparmentList");
      }
    });
  };

  return (
    <div className="main-container raiseTicket_container_crm">
      {isLoading && <Loader />}
      <div className="right-view">
        <Header title={"Tickets,"} />
        <div className="container-fluid px-0">
          <ComplaintDashboard
            allComplainData={allComplainData}
            complaintData={complaintData}
            handlePageSubmit={handlePageSubmitt}
            page={page}
            pageLimit={pageLimit}
            limit={limit}
            limitChangeHandler={limitChangeHandlerr}
            handleFilter={handleFilterr}
            handleReset={handleResett}
            handleView={(item) => navigate('/view-my-ticket', { state: { item } })}
            handleEdit={(item) => navigate('/edit-ticket-end-user', { state: { item } })}
            categoryList={categoryList}
            selectCategory={selectCategory}
            handleSelectCategory={(e) => setSelectCategory(e)}
            handlePriority={(e) => setSelectedPriority(e)}
            selectedPriority={selectedPriority}
            priorityList={priorityList}
            FetchedComplain={FetchedComplain}
          />
        </div>
      </div>
    </div>
  );
};

export default MyTicket;

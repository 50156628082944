import React, { useState, useEffect } from "react";
import CustomCard from "../../CustomeCard";
import CustomButton from "../../Button/Button";
import "./style.scss";
import FormatFixDate from "../../helper/formatDate/FormatFixDate";
import ReactPaginate from "react-paginate";
import Customselect from "../../CustomSelect";
import ReactExport from "react-export-excel";
import { useNavigate, useLocation } from "react-router-dom";



const ExcelFile = ReactExport.ExcelFile;
const ExcelSheet = ReactExport.ExcelFile.ExcelSheet;
const ExcelColumn = ReactExport.ExcelFile.ExcelColumn;

const ManPowerRequistionTable = ({ handleEdit, handleView, handleAddNewRequisition, pageLimit, page, limit, limitChangeHandler, handleReset, handleFilter,
    getRequisitionData, requistionAllData, handlePageSubmit, dateOfRequisition, handleDateOfRequistion, handleDepartmentName, handleDesignationName,
    typeOfPosition, designationName, deparmentName, handleSelectPostion, handleStatus, selectedTypeOfPosition, selectedStatusList, designationList, departmentList,targetRowRef }) => {

    const [selectedCandidateId, setSelectedCandidateId] = useState(sessionStorage.getItem('manPowerId') || null);

    useEffect(() => {
        if (selectedCandidateId) {
            sessionStorage.setItem('manPowerId', selectedCandidateId);
        }
    }, [selectedCandidateId]);

    const handleRowClick = (candidateId) => {
        setSelectedCandidateId(candidateId);
        sessionStorage.setItem('manPowerId', candidateId);
    };


    const location = useLocation();
    const url = location?.pathname?.split("/")[1];



    const handleViewForm = (item) => {
        setSelectedCandidateId(item?.requisition_id)
        handleView(item)
        sessionStorage.setItem('manPowerId', item?.requisition_id);
    }

    const handleEditForm = (item) => {
        setSelectedCandidateId(item?.requisition_id)
        handleEdit(item)
        sessionStorage.setItem('manPowerId', item?.requisition_id);
    }

    const role = sessionStorage.getItem("role")
    const EXtractRole = role.split(",")
    let management = ["CEO", "Director", "MD"]
    const getFormattedDate = (dateString) => {
        const date = new Date(dateString);
        const year = date.getFullYear();
        const month = (date.getMonth() + 1).toString().padStart(2, "0");
        const day = date.getDate().toString().padStart(2, "0");
        return `${year}-${month}-${day}`;
    };


    let statusList;

    if (EXtractRole.some(element => management.includes(element))) {
        statusList = [
            { value: "Final Approved", label: "Final Approved" },
            { value: "Rejected", label: "Rejected" },
        ];
    } else {
        statusList = [
            { value: "Final Approved", label: "Final Approved" },
            { value: "Rejected", label: "Rejected" },
            { value: "Approved", label: "Approved" },
        ];
    }

    // useEffect(() => {
    //     const storedPage = sessionStorage.getItem('currentManpowerPage');
    //     const storedPageLimit = sessionStorage.getItem('pageManpowerLimit');

    //     if (storedPage) {
    //         handlePageSubmit({ selected: parseInt(storedPage) - 1 });
    //     }

    //     if (storedPageLimit) {
    //         limitChangeHandler(storedPageLimit);
    //     }
    // }, []);

    const scrollToRow = () => {
        const element = document.getElementById(selectedCandidateId);
        if (element) {
            element.scrollIntoView({ behavior: 'smooth', block: 'center' });
            element.focus();
        }
    };

    useEffect(() => {
        setTimeout(() => {
            scrollToRow();
        }, 1000);

    }, [selectedCandidateId , getRequisitionData?.length]);

    if (getRequisitionData?.length > 0) {
        scrollToRow()
    }

    const [currentPage, setCurrentPage] = useState(() => {
        return parseInt(sessionStorage.getItem('currentManpowerPage'), 10) - 1 || 0;
    });

    useEffect(() => {
        sessionStorage.setItem('currentManpowerPage', currentPage + 1);
    }, [currentPage]);

    const handlePageChange = (selectedItem) => {
        const selectedPage = selectedItem.selected;
        setCurrentPage(selectedPage);
        handlePageSubmit({ selected: selectedPage });
    };

    const handleResett = () => {
        setCurrentPage(0)
        handleReset()
    }


    return (
        <div className="manpower-requisition-container">
            <CustomCard
                cardtitle={"All"}
                cardboldtitle={"Requisition"}
                toprightview={
                    <div>
                        {url !== "approver-requisition-dashboard" && <CustomButton style={"me-3"} children={"Add New Requisition"} onClick={handleAddNewRequisition} />}
                        <button className="export-btn">
                            <ExcelFile
                                element={
                                    <div>
                                        <img
                                            width={20}
                                            className="me-2"
                                            src={require("../../../assets/images/excel.png")}
                                        />
                                        Export
                                    </div>
                                }
                            >
                                <ExcelSheet
                                    data={getRequisitionData?.map((item, i) => ({
                                        ...item, index: i + 1,
                                        dateOfRequisitions: item?.date_of_requisition && getFormattedDate(item?.date_of_requisition),
                                        business: item?.business?.business_name,
                                        company: item?.company?.name,
                                        plant: item?.company_plants?.plant_name,
                                        location: item?.plant_location,
                                        dep: item?.departments?.department_name,
                                        des: item?.designation?.designation_name

                                    }))}
                                    name="Sheet"
                                >
                                    <ExcelColumn label="Business Type" value="business" />
                                    <ExcelColumn label="Company Name" value="company" />
                                    <ExcelColumn label="Plant" value="plant" />
                                    <ExcelColumn label="Location" value="location" />
                                    <ExcelColumn label="Date of Requisition" value="dateOfRequisitions" />
                                    <ExcelColumn label="Department" value="dep" />
                                    <ExcelColumn label="Designation" value="des" />
                                </ExcelSheet>
                            </ExcelFile>
                        </button>
                    </div>
                }
                cardbody={
                    <React.Fragment>
                        <div className="row">
                            <div className="col-md-3">
                                <label className="filter-label">Date of Requisition</label>
                                <input
                                    className="form-control"
                                    type="date"
                                    placeholder="Date of Requisition"
                                    value={dateOfRequisition}
                                    onChange={(e) => handleDateOfRequistion(e.target.value)}
                                />
                            </div>
                            <div className="col-md-3">
                                <Customselect
                                    label="Department Name"
                                    value={deparmentName}
                                    onChange={(e) => {
                                        handleDepartmentName(e.target.value);
                                    }}
                                    optionview={departmentList?.map((option) => {
                                        return (
                                            <option value={option?.department_name}>{option?.department_name}</option>
                                        );
                                    })}
                                />
                            </div>
                            <div className="col-md-3">
                                <Customselect
                                    label="Designation Name"
                                    value={designationName}
                                    onChange={(e) => {
                                        handleDesignationName(e.target.value);
                                    }}
                                    optionview={designationList?.map((option) => {
                                        return (
                                            <option value={option?.designation_name}>{option?.designation_name}</option>
                                        );
                                    })}
                                />
                            </div>
                            <div className="col-md-3">
                                <Customselect
                                    label="Type of Position"
                                    value={selectedTypeOfPosition}
                                    onChange={(e) => {
                                        handleSelectPostion(e.target.value);
                                    }}
                                    optionview={typeOfPosition?.map((option) => {
                                        return (
                                            <option value={option?.value}>{option?.label}</option>
                                        );
                                    })}
                                />
                            </div>

                            {!role.includes("Segment HR") && <div className="col-md-3">
                                <Customselect
                                    label="Status"
                                    value={selectedStatusList}
                                    onChange={(e) => {
                                        handleStatus(e.target.value);
                                    }}
                                    optionview={statusList?.map((option) => {
                                        return (
                                            <option value={option?.value}>{option?.label}</option>
                                        );
                                    })}
                                />
                            </div>}
                            <div className="col-md-3 search_btn">
                                <div className="btn-container">
                                    <CustomButton style={"me-3"} children={'Search'} onClick={handleFilter} />
                                    <CustomButton style={"me-3"} children={'Reset'} onClick={handleResett} />
                                </div>
                            </div>
                        </div>

                        <div className="col-md-12 mb-4">
                            <div>
                                <label className="d-flex align-items-center p-0 col-md-2">
                                    {page === 1 ? (
                                        <select
                                            value={pageLimit}
                                            className="form-control selectbox-container mr-2 drop-down-dashboard"
                                            onChange={(e) => limitChangeHandler(e.target.value)}
                                        >
                                            {limit.length > 0
                                                ? limit?.map((item, index) => (
                                                    <option key={index} value={item.option}>
                                                        {item.value}
                                                    </option>
                                                ))
                                                : null}
                                        </select>
                                    ) : (
                                        <p className="form-control selectbox-container mr-2 drop-down-dashboard">
                                            {pageLimit}
                                        </p>
                                    )}
                                    <span> entries</span>
                                </label>
                            </div>
                        </div>

                        <div class="table-responsive">
                            <table class="table">
                                <thead class="table-customlight">
                                    <tr>
                                        <th className="manpower-table1">Sr no.</th>
                                        <th scope="col" className="manpower-table">Business Type</th>
                                        <th scope="col" className="manpower-table">Company Name</th>
                                        <th scope="col" className="manpower-table">Plant</th>
                                        <th scope="col" className="manpower-table">Location</th>
                                        <th scope="col" className="manpower-table">Date of Requisition</th>
                                        <th scope="col" className="manpower-table">Department</th>
                                        <th scope="col" className="manpower-table">Designation</th>
                                        <th scope="col" className="manpower-table2">Status</th>
                                        <th scope="col" className="manpower-table1">Action</th>
                                    </tr>
                                </thead>
                                {getRequisitionData?.length > 0 ? (
                                    <tbody>
                                        {getRequisitionData?.map((item, i) => {
                                            const isSelected = item.requisition_id == selectedCandidateId;
                                            return (
                                                <tr
                                                    ref={targetRowRef}
                                                    key={item?.requisition_id}
                                                    className={isSelected ? 'highlighted-row' : ''}
                                                    id={isSelected ? item?.requisition_id :""}
                                                // onClick={() => handleRowClick(item.requisition_id)}
                                                >
                                                    <td>{i + 1}</td>
                                                    <td scope="row">{item?.business?.business_name} </td>
                                                    <td>{item?.company?.name}</td>
                                                    <td>{item?.company_plants?.plant_name}</td>
                                                    <td>{item?.plant_location}</td>
                                                    <td>{<FormatFixDate dateString={item?.date_of_requisition} />}</td>
                                                    <td>{item?.departments?.department_name}</td>
                                                    <td>{item?.designation?.designation_name}</td>
                                                    <td>{item?.current_status}</td>
                                                    {url === "approver-requisition-dashboard" ?
                                                        <td>
                                                            <img src={require("../../../assets/images/visible.png")} className="requisition-edit" onClick={(e) => handleViewForm(item)} />
                                                        </td>
                                                        :
                                                        <td className="requisition-edit-box">
                                                            {item?.is_hod_approved == "0" && <img src={require("../../../assets/images/editCrm.png")} className="requisition-edit me-1" onClick={(e) => handleEditForm(item)} />}
                                                            <img src={require("../../../assets/images/visible.png")} className="requisition-edit" onClick={(e) => handleViewForm(item)} />
                                                        </td>
                                                    }
                                                </tr>
                                            )
                                        })}

                                    </tbody>
                                ) : (
                                    <tbody>
                                        <tr>
                                            <td className="text-center p-3" colSpan={10}>
                                                No Records found...
                                            </td>
                                        </tr>
                                    </tbody>

                                )}
                            </table>
                        </div>

                        <div className="col-md-12">
                            <div className="d-flex align-items-center justify-content-between my-4">
                                <div>
                                    <p>
                                        showing {requistionAllData?.filteredCount} out of{" "}
                                        {requistionAllData?.totalRecords}
                                    </p>
                                </div>
                                <div>
                                    <ReactPaginate
                                        previousLabel={"Previous"}
                                        nextLabel={"Next"}
                                        breakLabel={"..."}
                                        pageCount={requistionAllData?.totalPages}
                                        marginPagesDisplayed={3}
                                        pageRangeDisplayed={3}
                                        onPageChange={handlePageChange}
                                        containerClassName={"pagination paginationBox justify-content-end"}
                                        pageClassName={"page-item"}
                                        pageLinkClassName={"page-link pageNumber"}
                                        previousClassName={"page-item previosBtn"}
                                        previousLinkClassName={"page-link"}
                                        nextClassName={"page-item"}
                                        nextLinkClassName={"page-link previosBtn"}
                                        breakClassName={"page-item"}
                                        breakLinkClassName={"page-link pageNumber"}
                                        activeClassName={"active"}
                                        forcePage={currentPage}
                                    />
                                </div>
                            </div>
                        </div>

                    </React.Fragment>
                }
            />
        </div>
    );
};

export default ManPowerRequistionTable;

import React, { useEffect, useState } from "react";
import Header from "../../../component/Header";
import CustomCard from "../../../component/CustomeCard";
import "./style.scss";
import RoleCard from "../../../component/RoleCard";
import GraphChart, { data } from "../../../component/GraphChart";
import { useNavigate} from "react-router-dom";
import Customselect from "../../../component/CustomSelect";
import CustomButton from "../../../component/Button/Button";
import { useStores } from "../../../stores";
import notify from "../../../shared/notify";
import BGRequest from "../../../component/RJMyRequest/bgRequest";
import ImprestRequest from "../../../component/RJMyRequest/imprestRequest";
import LCRequest from "../../../component/RJMyRequest/lcRequest";
import LCList from "../../../assets/images/list.png";
import ImprestList from "../../../assets/images/greenlist.png";
import BGList from "../../../assets/images/pinklist.png";
import RequestTab from "../../../component/Tabs/requestTab";
import Loader from "../../../component/helper/Loader/loader";

const Dashboard = () => {
  const navigate = useNavigate();
  const { mainStore } = useStores();
  const [fetchedBgData, setFetchedBgData] = useState([]);
  const [fetchedLcData, setFetchedLcData] = useState([]);
  const [fetchedbBgAllData, setFetchedBgAllData] = useState([]);
  const [fetchedbLcAllData, setFetchedLcAllData] = useState([]);
  const [showLcRequest, setShowLcRequest] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [showBgRequest, setShowBgRequest] = useState(true);
  const [showImprestRequest, setShowImprestRequest] = useState(false);
  const [requestType, setRequestType] = useState(false);
  const [showRequestType, setShowRequestType] = useState(false);
  const [instrumentType, setInstrumentType] = useState("");
  const [selectedRequestType, setSelectedRequestType] = useState("");
  const [intrument, setInstrument] = useState([]);
  const [showBgAmendment, setShowBgAmendment] = useState(false)
  const [showBgCancellation, setShowBgCancellation] = useState(false)
  const [dashboardData, setDashboardData] = useState({});
  const [chartShowType, setChartShowType] = useState("");
  const roleName = sessionStorage.getItem("roleName")
  const Viewer = sessionStorage.getItem("role")


  const options = [
    { label: "Percentage Data", value: "percentage" },
    { label: "Number Data", value: "value" },
  ];

  useEffect(() => {
    fetchDashboardData();
    fetchBgData();
    fetchInstrument();
    fetchLcData()
  }, []);

  const fetchDashboardData = async () => {
    setIsLoading(true)
    let payload ={
      currentRole :Viewer.includes("Viewer")? "Viewer" :roleName
    }
    await mainStore.getDashboardData(payload,(response) => {
        if (response?.data?.meta?.status === "success") {
          setDashboardData(response?.data?.data?.data);
          setIsLoading(false)
        } else {
          notify(response?.data?.meta?.message, "danger");
          setIsLoading(false)
        }
      },
      (error) => {
        notify(error?.response?.data?.meta?.message, "danger");
        setIsLoading(false)
      }
    );
  };

  const fetchInstrument = async () => {
    setIsLoading(true)
    await mainStore.getInstruments((data, response) => {
        if (response?.status === "success") {
          setInstrument(response?.data?.instruments);
          setIsLoading(false)
        } else {
          notify(response?.data?.meta?.message, "danger");
          setIsLoading(false)
        }
      },
      (error) => {
        notify(error?.response?.data?.meta?.message, "danger");
        setIsLoading(false)
      }
    );
  };

  const fetchInstrumentType = async (id) => {
    setIsLoading(true)
    await mainStore.getInstrumentstype(id,(data, response) => {
        if (response?.status === "success") {
          setRequestType(response?.data?.requestType);
          setIsLoading(false)
        } else {
          notify(response?.data?.meta?.message, "danger");
          setIsLoading(false)
          setRequestType([]);
        }
      },
      (error) => {
        notify(error?.response?.data?.meta?.message, "danger");
        setIsLoading(false)
      }
    );
  };

  const fetchBgData = async () => {
    let payload = {
      limit: 10,
      filters: {},
      pageNumber: 1,
      roleName: roleName,
    };
    callApi(payload)
  };

  const callApi = async (payload) => {
    setIsLoading(true)
    await mainStore.getRequestorBgInssuance(payload,(response) => {
        if (response?.data?.meta?.status === "success") {
          setFetchedBgData(response?.data?.data?.filteredData?.results);
          setFetchedBgAllData(response?.data?.data)
          setIsLoading(false)
        } else {
          notify(response?.data?.meta?.message, "danger");
          setIsLoading(false)
        }
      },
      (error) => {
        notify(error?.response?.data?.meta?.message, "danger");
        setIsLoading(false)
      }
    );
  };


  const fetchLcData = async () => {
    let payload = {
      limit: 10,
      filters: {},
      pageNumber: 1,
      roleName: roleName,
    };
    callLcApi(payload)
  };

  const callLcApi = async (payload) => {
    setIsLoading(true)
    await mainStore.getLcInssuance(payload,(response) => {
        if (response?.data?.meta?.status === "success") {
          setFetchedLcData(response?.data?.data?.filteredData?.results);
          setFetchedLcAllData(response?.data?.data)
          setIsLoading(false)
        } else {
          notify(response?.data?.meta?.message, "danger");
          setIsLoading(false)
        }
      },
      (error) => {
        notify(error?.response?.data?.meta?.message, "danger");
        setIsLoading(false)
      }
    );
  }

  const bgRequest = () => {
    fetchBgData()
    setShowImprestRequest(false);
    setShowBgRequest(true);
    setShowLcRequest(false);
  };
  const lcRequest = () => {
    fetchLcData()
    setShowImprestRequest(false);
    setShowBgRequest(false);
    setShowLcRequest(true);
  };
  const imprestRequest = () => {
    setShowImprestRequest(true);
    setShowBgRequest(false);
    setShowLcRequest(false);
  };

  const graphdata = [
    ["Task", "each Instrument"],
    ["BG", dashboardData?.total?.BgCount],
    // ["Imprest", dashboardData?.total?.ImCount],
    ["LC", dashboardData?.total?.LcCount],
  ];

  const graphoptions = {
    slices: {
      0: { color: "#00cadc" },
      // 1: { color: "#49c3fb" },
      1: { color: "#65a6fa" },
    },
    chartArea: { width: 400, height: 350 },
    pieSliceText: chartShowType,
    sliceVisibilityThreshold: 0,
  };

  const handlebgModal = (e, item) => {
    e.preventDefault();
    navigate("/edit-bg-issuance", { state: { item } });
  };
  const handlelcModal = (e, item) => {
    e.preventDefault();
    navigate("/edit-lc-issuance", { state: { item } });
  };

  const requestFun = (name) => {
    if (name == "LC") {
      return dashboardData?.total?.LcCount;
    } else if (name == "BG") {
      return dashboardData?.total?.BgCount;
    } else if (name == "Imprest") {
      return dashboardData?.total?.ImCount;
    }
  };

  return (
    <div className="main-container dashboard-container">
      {isLoading && <Loader />}
      <div className="right-view">
        <Header title={"Requestor Journey Dashboard"} />
        <div className="container-fluid px-0">
          <div className="row">
            {showBgAmendment ? (
              <div className="col-md-12">
              </div>)
              : (showBgCancellation ? (
                <div className="col-md-12">
                </div>
              ) : (
                <React.Fragment>
                  <div className="col-md-6">
                    {!showRequestType ? (
                      <CustomCard
                        underline={true}
                        cardtitle={"Select"}
                        cardboldtitle={"Type of Instrument"}
                        cardbody={
                          <div>
                            {intrument.length > 0 &&
                              intrument.map((item) => {
                                return (
                                  <RoleCard
                                    role={item.name}
                                    requests={requestFun(item.name)}
                                    img={
                                      (item.name == "LC" && LCList) ||
                                      (item.name == "BG" && BGList) ||
                                      (item?.name == "Imprest" && ImprestList)
                                    }
                                    onClick={() => {
                                      setShowRequestType(true);
                                      fetchInstrumentType(item.id);
                                      setInstrumentType(item.name);
                                    }}
                                  />
                                );
                              })}
                          </div>
                        }
                      />
                    ) : (
                      <CustomCard
                        underline={true}
                        cardtitle={"Select"}
                        cardboldtitle={`${instrumentType.toUpperCase()} Request Type`}
                        cardbody={
                          <div>
                            <Customselect
                              optionview={
                                requestType.length > 0 &&
                                requestType.map((option) => {
                                  return (
                                    <option value={option.name}>{option.name}</option>
                                  );
                                })
                              }
                              onChange={(e) => {
                                setSelectedRequestType(e.target.value);
                              }}
                            />
                            <CustomButton
                              onClick={() => {
                                if (selectedRequestType === "Missing Receipt Form") {
                                  navigate(
                                    `/requestor-imprest-missing-reciept`
                                  );
                                } else if (selectedRequestType === "Petty Cash Requisition") {
                                  navigate(
                                    `/requestor-imprest-petty-cash-requistion`
                                  );
                                } else {
                                  if (instrumentType === "BG" && selectedRequestType === "Amendment") {
                                    setShowBgAmendment(true)
                                  } else if (instrumentType === "BG" && selectedRequestType === "Cancellation") {
                                    setShowBgCancellation(true)
                                  }else if(instrumentType === "LC" && selectedRequestType === "Amendment") {
                                      navigate("/closed-lc-amendment")
                                  }else if(instrumentType === "LC" && selectedRequestType === "Cancellation"){
                                    navigate("/closed-lc-cancellation")
                                  } else {
                                    navigate(
                                      `/requestor-${instrumentType}-${selectedRequestType}`
                                    );
                                  }
                                }
                              }}
                              children={"Submit"}
                            />
                          </div>
                        }
                      />
                    )}
                  </div>
                  <div className="col-md-6">
                    <CustomCard
                      cardtitle={"Requests in each Instrument"}
                      cardboldtitle={"Instrument"}
                      toprightview={
                        <Customselect
                          onChange={(e) => {
                            setChartShowType(e.target.value);
                          }}
                          blankLabel={true}
                          optionview={options.map((option) => {
                            return (
                              <option value={option.value}>{option.label}</option>
                            );
                          })}
                        />
                      }
                      cardbody={
                        <GraphChart data={graphdata} options={graphoptions} />
                      }
                    />
                  </div>
                </React.Fragment>)
              )}
            {(showBgAmendment || showBgCancellation) ? "" : (
              <div className="col-md-12">
                <RequestTab
                  showLcRequest={showLcRequest}
                  showBgRequest={showBgRequest}
                  // showImprestRequest={showImprestRequest}
                  lcRequest={lcRequest}
                  bgRequest={bgRequest}
                  // imprestRequest={imprestRequest}
                />
              </div>)}


            {showBgAmendment ? (
              navigate("/closed-bg-amendment")
            ) : (
              <div className="col-md-12 listing-table">
                {showBgCancellation
                  ?
                  navigate("/closed-bg-cancellation")
                  : (
                    <>
                      {showBgRequest && (
                        <BGRequest
                          fetchedBgData={fetchedBgData}
                          fetchBgAllData={fetchedbBgAllData}
                          handlebgModal={handlebgModal}
                          fetchBgData={fetchBgData}
                          callApi={callApi}
                        />
                      )}
                      {showLcRequest && (
                        <LCRequest
                          fetchedLcData={fetchedLcData}
                          fetchLcAllData={fetchedbLcAllData}
                          handleLcModal={handlelcModal}
                          fetchLcData={fetchLcData}
                          callLcApi={callLcApi}
                        />
                      )}
                      {showImprestRequest && (
                        <ImprestRequest
                          // fetchedBgData={fetchedBgData}
                          // handlebgModal={handlebgModal}
                        />
                      )}
                    </>
                  )}
              </div>
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

export default Dashboard;

import React, { useEffect, useState } from "react";
import "./style.scss";
import Header from "../Header";
import CustomCard from "../CustomeCard";
import CustomInput from "../CustomInput";
import CustomButton from "../Button/Button";
import { useLocation, useNavigate } from "react-router-dom";
import { useStores } from "../../stores";
import notify from "../../shared/notify";
import isEmail from "validator/lib/isEmail";
import Loader from "../helper/Loader/loader";
import ReactExport from "react-export-excel";
import FormatDate from "../helper/formatDate/formateDate";

const ExcelFile = ReactExport.ExcelFile;
const ExcelSheet = ReactExport.ExcelFile.ExcelSheet;
const ExcelColumn = ReactExport.ExcelFile.ExcelColumn;

const BgAmendment = (props) => {
  const { state } = useLocation();
  const Viewer = sessionStorage.getItem("role")

  const [isReadOnly, setIsReadOnly] = useState(false)

  useEffect(() => {
    if (state?.item?.isSubmitted === true || Viewer ==="Viewer") {
      setIsReadOnly(true)
    }
  }, [state?.item?.isSubmitted])


  const { mainStore } = useStores();
  const navigate = useNavigate();
  const userName = sessionStorage.getItem("name")
  const [chatComment, setChatComment] = useState("")
  const [chat, setChat] = useState([])
  const [isLoading, setIsLoading] = useState(false);
  const [requestNumber, setRequestNumber] = useState(
    state?.item?.requestNumber
  );
  const [requestName, setRequestName] = useState(state?.item?.requesterName);
  const [requesterMail, setRequesterMail] = useState(
    state?.item?.requesterEmail
  );
  const [entity, setEntity] = useState([]);
  const [requestEntity, setRequestEntity] = useState(state?.item?.entity);
  const [requestUnit, setRequestUnit] = useState(state?.item?.unit);
  const roleName = sessionStorage.getItem("roleName")
  const [approverInfo, setApproverInfo] = useState([
    {
      approverId: "",
      approverName: "",
      approverDepartment: "",
      approverEmail: "",
      approverLevel: "L1",
    },
    {
      approverId: "",
      approverName: "",
      approverDepartment: "",
      approverEmail: "",
      approverLevel: "L2",
    },
  ]);
  const [bankName] = useState(state?.item?.bankName);
  const [bankAccountNumber] = useState(state?.item?.bankAccountNumber);
  const [bankGlCode] = useState(state?.item?.bankGlCode);
  const [refrenceNumber, setRefrenceNumber] = useState(state?.item?.referenceNumber);
  const [applicantName, setApplicantName] = useState(state?.item?.applicantName);
  const [applicantLocation, setApplicantLocation] = useState(state?.item?.applicantLocation);
  const [beneficiaryName, setBeneficiaryName] = useState(state?.item?.beneficiaryName);
  const [beneficiaryLocation, setBeneficiaryLocation] = useState(state?.item?.beneficiaryLocation);
  const [inssuanceDate, setInssuanceDate] = useState(state?.item?.issuanceDate);
  const [expiryDate, setExpiryDate] = useState(state?.item?.expiryDate);
  const [currency, setCurrency] = useState(state?.item?.currency);
  const [claimExpiryDate, setClaimExpiryDate] = useState(state?.item?.claimExpiryDate );
  const [ammount, setAmmount] = useState(state?.item?.amount);
  const [printLocation, setPrintLoation] = useState(state?.item?.printLocation);
  const [typeOfBg, setTypeOfBg] = useState(state?.item?.bgType);
  const [paybleLocation, setPaybleLocation] = useState(state?.item?.payableLocation);
  const [bgCharges, setBgCharges] = useState(state?.item?.bgCharge);
  const [gst, setGst] = useState(state?.item?.gst);
  const [stampcharges, setStampCharges] = useState(state?.item?.stampCharges);
  const [bgChargesGlCode, setBgChargesGlCode] = useState(state?.item?.bgChargesGlCode);
  const [gstGlCode, setGstGlCode] = useState(state?.item?.gstGlCode);
  const [bgText, setBgText] = useState(() => {
    const bgTextString = state?.item?.bgText;
    if (bgTextString) {
      try {
        return JSON.parse(bgTextString);
      } catch (error) {
        console.error("Error parsing bgText JSON:", error);
      }
    }
    return [];
  });

  const [purchaseOrder, setPurchaseOrder] = useState(() => {
    const purchaseOrderString = state?.item?.purchaseOrder;
    if (purchaseOrderString) {
      try {
        return JSON.parse(purchaseOrderString);
      } catch (error) {
        console.error("Error parsing purchaseOrder JSON:", error);
      }
    }
    return [];
  });

  const [approverList, setApproverList] = useState([]);
  const [requesterDetailComments, setRequesterDetailComments] = useState(
    state?.item?.requestorComment
  );
  const [approverDetailsComment, setApproverDetailsComment] = useState(
    state?.item?.approverComment
  );
  const [bankDetailComment] = useState(state?.item?.bankComment);
  const [bankGuaranteeComment, setBankGuaranteeComment] = useState(
    state?.item?.bankGuaranteeComment
  );
  const [bankChargesComment, setBankChargesComment] = useState(
    state?.item?.bankChargesComments
  );
  const [attachmentComment, setAttachmentComment] = useState(
    state?.item?.attachmentsComment
  );
  const [getAuditDetail, setGetAuditDetail] = useState([]);

  const [lineOfService] = useState([
    { value: "Solar", label: "Solar" },
    { value: "Solar Module Line", label: "Solar Module Line" },
    {
      value: "Biomass (Punjab & Haryana)",
      label: "Biomass (Punjab & Haryana)",
    },
    { value: "Biomass- Rajasthan", label: "Biomass- Rajasthan" },
    { value: "HO", label: "HO" },
  ]);
  const [division, setDivision] = useState(state?.item?.lineOfService);

  const [typeOfBgList] = useState([
    { name: "PBG " },
    { name: "FBG " },
    { name: "EMD " },
    { name: "SBLC" },
  ]);
  const [currencyList] = useState([
    { name: "USD" },
    { name: "EUR" },
    { name: "INR" },
    { name: "CHF" },
  ]);

  const isValidApproverInfo = approverInfo.every(approver => (
    approver.approverName !== "" &&
    approver.approverDepartment !== "" &&
    approver.approverEmail !== "" &&
    approver.approverId !== ""
  ));

  const fetchEntity = async () => {
    await mainStore.getEntity(
      (status, response) => {
        if (response?.meta?.status === "success") {
          setEntity(response?.data?.entitites);
        } else {
          notify(response?.meta?.message, "danger");
        }
      },
      (error) => {
        notify(error?.response?.meta?.message, "danger");
      }
    );
  };

  const getBgAuditDetail = async () => {
    setIsLoading(true);
    let payload = {};
    await mainStore.getBgAudittrail(
      state?.item?.requestNumber,
      payload,
      (response) => {
        if (response?.data?.meta?.status === "success") {
          setGetAuditDetail(response?.data?.data?.audit);
          setIsLoading(false);
        } else {
          notify(response?.data?.meta?.message, "danger");
          setIsLoading(false);
        }
      },
      (error) => {
        notify(error?.response?.data?.meta?.message, "danger");
        setIsLoading(false);
      }
    );
  };

  useEffect(() => {
    fetchApproverDetail();
    fetchEntity();
    getBgAuditDetail();
  }, []);

  useEffect(() => {
    fetchChatResponse()
    if (state?.item?.approvers?.length > 0) {
      let arr = [];
      state.item.approvers.map((item) => {
        let obj = {
          approverId: item?.approver_id,
          approverName: item?.user?.name,
          approverEmail: item?.user?.email,
          approverDepartment: item?.user?.user_department,
          approverLevel: item?.approver_level,
        };
        arr.push(obj);
      });
      setApproverInfo(arr);
    }
  }, []);

  const fetchApproverDetail = async (lineOfService) => {
    if (!lineOfService) {
      return;
    }

    let payload = {
      lineOfService,
    };
    await mainStore.getApprovers(payload, (response) => {
      if (response?.data?.meta?.status === "success") {
        setApproverList(response?.data?.data?.users);
      } else {
        notify(response?.data?.meta?.message, "danger");
      }
    },
      (error) => {
        notify(error?.response?.data?.meta?.message, "danger");
      }
    );
  };
  const handleInputChange = (e) => {
    let value = e.target.value;
    setDivision(value);
    fetchApproverDetail(value);
    setApproverInfo([
      { approverName: "", approverDepartment: "", approverEmail: "", approverLevel: "L1", approverId: "" },
      { approverName: "", approverDepartment: "", approverEmail: "", approverLevel: "L2", approverId: "" }
    ]);
  };
  useEffect(() => {
    if (division) {
      fetchApproverDetail(division);
    }
  }, []);

  const handlePurchaseOrder = async (e) => {
    setIsLoading(true);
    const files = e.target.files;
    let formData = new FormData();

    for (let i = 0; i < files.length; i++) {
      formData.append("file", files[i]);
      let params = "BgRequest";
      await mainStore.uploadfile(params, formData, (response) => {
        if (response?.data?.meta?.status === "success") {
          notify(response?.data?.meta?.message);
          setPurchaseOrder((prevState) => [...prevState, response.data.data]);
          setIsLoading(false);
        } else {
          notify(response?.data?.meta?.message, "danger");
          setIsLoading(false);
        }
      },
        (error) => {
          notify(error?.response?.data?.meta?.message, "danger");
          setIsLoading(false);
        }
      );
      formData.delete("file");
      formData.delete("filepath");
    }
  };

  const handleBgText = async (e) => {
    setIsLoading(true);
    const files = e.target.files;
    let formData = new FormData();

    for (let i = 0; i < files.length; i++) {
      formData.append("file", files[i]);
      // formData.append('filepath', 'BgRequest');
      let params = "BgRequest";
      await mainStore.uploadfile(
        params,
        formData,
        (response) => {
          if (response?.data?.meta?.status === "success") {
            notify(response?.data?.meta?.message);
            setBgText((prevState) => [...prevState, response.data.data]);
            setIsLoading(false);
          } else {
            notify(response?.data?.meta?.message, "danger");
            setIsLoading(false);
          }
        },
        (error) => {
          notify(error?.response?.data?.meta?.message, "danger");
          setIsLoading(false);
        }
      );
      formData.delete("file");
      formData.delete("filepath");
    }
  };

  const handleBgTextdownload = async (filename) => {
    let payload = {
      filename: filename,
    };
    callDownloadApi(payload);
  };

  const handlePurchasedownload = async (filename) => {
    let payload = {
      filename: filename,
    };

    callDownloadApi(payload);
  };
  const callDownloadApi = async (payload) => {
    setIsLoading(true);
    await mainStore.downloadImage(
      payload,
      (response) => {
        if (response?.data?.meta?.status === "success") {
          notify(response?.data?.meta?.message);
          setIsLoading(false);
          const base64File = response?.data?.data?.base64File;
          const mimeType = response?.data?.data?.mimeType;
          const fileNameParts = response?.data?.data?.filename?.split("-");
          const fileName = fileNameParts.slice(1).join("-");
          if (base64File && mimeType) {
            const downloadLink = document.createElement("a");
            downloadLink.href = `data:${mimeType};base64,${base64File}`;
            downloadLink.download = fileName;
            document.body.appendChild(downloadLink);
            downloadLink.click();
            document.body.removeChild(downloadLink);
          } else {
            notify("Download link or File type not found", "danger");
          }
        } else {
          notify(response?.data?.meta?.message, "danger");
          setIsLoading(false);
        }
      },
      (error) => {
        notify(error?.response?.data?.meta?.message, "danger");
        setIsLoading(false);
      }
    );
  };

  const submitForApproval = async (type) => {

    // if ( type ==="submit" && requesterMail === "undefined" || requesterMail === "" ||requesterMail === "null" ||!(isEmail(requesterMail) &&requesterMail.toLowerCase().endsWith("@sael.co"))
    // ) {
    //   return notify("Requester Mail should end with '@sael.co'", "danger");
    // }

    if (type ==="submit" && bgText.length === 0) {
      return notify("Please upload the bgText attachment", "danger")
    }
    if (type ==="submit" && purchaseOrder.length === 0) {
      return notify("Please upload the purchaseOrder attachment", "danger")
    }

    setIsLoading(true);
    let payload = {
      ...(requestName !== undefined && requestName !== null && requestName !== "" && { requesterName: requestName }),
      ...(requesterMail !== undefined && requesterMail !== null && requesterMail !== "" && { requesterEmail: requesterMail }),
      ...(requestEntity !== undefined && requestEntity !== null && requestEntity !== "" && { entity: requestEntity }),
      ...(requestUnit !== undefined && requestUnit !== null && requestUnit !== "" && { unit: requestUnit }),
      approver: isValidApproverInfo ? approverInfo : undefined,
      ...(applicantName !== undefined && applicantName !== null && applicantName !== "" && { applicantName: applicantName }),
      ...(applicantLocation !== undefined && applicantLocation !== null && applicantLocation !== "" && { applicantLocation: applicantLocation }),
      ...(beneficiaryName !== undefined && beneficiaryName !== null && beneficiaryName !== "" && { beneficiaryName: beneficiaryName }),
      ...(beneficiaryLocation !== undefined && beneficiaryLocation !== null && beneficiaryLocation !== "" && { beneficiaryLocation: beneficiaryLocation }),
      ...(expiryDate !== undefined && expiryDate !== null && expiryDate !== "" && { expiryDate: expiryDate }),
      ...(currency !== undefined && currency !== null && currency !== "" && { currency: currency }),
      ...(claimExpiryDate !== undefined && claimExpiryDate !== null && claimExpiryDate !== "" && { claimExpiryDate: claimExpiryDate }),
      ...(ammount !== undefined && ammount !== null && ammount !== "" && { amount: ammount }),
      ...(printLocation !== undefined && printLocation !== null && printLocation !== "" && { printLocation: printLocation }),
      ...(typeOfBg !== undefined && typeOfBg !== null && typeOfBg !== "" && { bgType: typeOfBg }),
      ...(paybleLocation !== undefined && paybleLocation !== null && paybleLocation !== "" && { payableLocation: paybleLocation }),
      bgText: JSON.stringify(bgText),
      purchaseOrder: JSON.stringify(purchaseOrder),
      ...(requesterDetailComments !== undefined && requesterDetailComments !== null && requesterDetailComments !== "" && { requestorComment: requesterDetailComments }),
      ...(approverDetailsComment !== undefined && approverDetailsComment !== null && approverDetailsComment !== "" && { approverComment: approverDetailsComment }),
      ...(bankGuaranteeComment !== undefined && bankGuaranteeComment !== null && bankGuaranteeComment !== "" && { bankGuaranteeComment: bankGuaranteeComment }),
      ...(bankChargesComment !== undefined && bankChargesComment !== null && bankChargesComment !== "" && { bankChargesComments: bankChargesComment }),
      ...(attachmentComment !== undefined && attachmentComment !== null && attachmentComment !== "" && { attachmentsComment: attachmentComment }),
      lineOfService: division,
      role: roleName,
      type: type,
    };

    if (approverInfo !== undefined || approverInfo !== "" || approverInfo !== null) {
      payload.approver = approverInfo
    }


    await mainStore.editRequestorBgInssuance(
      state?.item?.requestNumber,
      payload,
      (response) => {
        if (response?.data?.meta?.status === "success") {
          notify(response?.data?.meta?.message, "success");
          navigate("/requestor-journey-dashboard");
          setIsLoading(false);
        } else {
          notify(response?.data?.meta?.message, "danger");
          setIsLoading(false);
        }
      },
      (error) => {
        notify(error?.response?.data?.meta?.message, "danger");
        setIsLoading(false);
      }
    );
  };

  const handleApproverChange = (index, field, value) => {
    const updatedApproverInfo = [...approverInfo];
    updatedApproverInfo[index][field] = value;
    setApproverInfo(updatedApproverInfo);
  };

  const handleSendMassege = async () => {
    setIsLoading(true)
    let payload = {
      requestNumber: state?.item?.requestNumber,
      userName: userName,
      userRole: roleName,
      message: chatComment
    }

    await mainStore.sendMassege(payload, (response) => {
      if (response?.data?.meta?.status === "success") {
        notify(response?.data?.meta?.message)
        fetchChatResponse()
        setIsLoading(false)
        setChatComment("")
      } else {
        notify(response?.data?.meta?.message, "danger")
        setChatComment("")
        setIsLoading(false)
      }
    }, (error) => {
      notify(error?.response?.data?.meta?.message, "danger")
      setChatComment("")
    })
  }

  const fetchChatResponse = async () => {
    mainStore.getChatResponse(state?.item?.requestNumber, (status, response) => {

      if (response?.meta?.status === "success") {
        setChat(response?.data?.chats)
      } else {
        notify(response?.meta?.message, "danger")
      }
    }, (error) => {
      notify(error?.response?.meta?.message, "danger")
    })
  }

  const getFormattedDate = (dateString) => {
    const date = new Date(dateString);
    const year = date.getFullYear();
    const month = (date.getMonth() + 1).toString().padStart(2, "0");
    const day = date.getDate().toString().padStart(2, "0");
    return `${year}-${month}-${day}`;
  };


  return (
    <div className="main-container">
      <div className="right-view">
        {isLoading && <Loader />}
        <Header title={"Edit BG > Issuance"} />
        <div className="row">
          <div className="col-md-12">
            <CustomCard
              cardtitle={"Requester"}
              cardboldtitle={"Details"}
              cardbody={
                <div className="row">
                  <div className="col-md-3">
                    <CustomInput
                      readOnly={true}
                      label="Request Number"
                      placeholder="Request Number"
                      value={requestNumber}
                      onChange={(e) => setRequestNumber(e.target.value)}
                    />
                  </div>
                  <div className="col-md-3">
                    <CustomInput
                      label="Requester Name"
                      placeholder="Requester Name"
                      readOnly={true}
                      value={requestName}
                      onChange={(e) => setRequestName(e.target.value)}
                    />
                  </div>
                  <div className="col-md-3">
                    <CustomInput
                      label="Requester mail ID"
                      placeholder="Requester mail ID"
                      value={requesterMail}
                      readOnly={true}
                      onChange={(e) => setRequesterMail(e.target.value)}
                    />
                  </div>
                  <div className="col-md-3">
                    <div className="custominput-container">
                      <div className="custom-input">
                        <label htmlFor="">Entity </label>
                        <div style={{ position: "relative" }}>
                          <select
                            className="form-control"
                            value={requestEntity}
                            disabled={isReadOnly ? true : false}
                            onChange={(e) => {
                              setRequestEntity(e.target.value);
                            }}
                          >
                            <option value="">Select Entity</option>
                            {entity.map((entity, index) => (
                              <option key={index} value={entity.name}>
                                {entity.name}
                              </option>
                            ))}
                          </select>
                          <i className="fas fa-chevron-down approver-drop-down"></i>
                        </div>
                      </div>
                    </div>
                  </div>

                  <div className="col-md-3">
                    <CustomInput
                      label="Unit"
                      placeholder="Unit"
                      value={requestUnit}
                      readOnly={isReadOnly ? true : false}
                      onChange={(e) => setRequestUnit(e.target.value)}
                    />
                  </div>

                  <div className="col-md-3">
                    <div className="custominput-container">
                      <div className="custom-input">
                        <label htmlFor="">Line Of Service </label>
                        <div style={{ position: "relative" }}>
                          <select
                            className="form-control"
                            value={division}
                            disabled={isReadOnly ? true : false}
                            onChange={handleInputChange}
                          >
                            <option value="">Select Line Of Service </option>
                            {lineOfService.map((item, index) => (
                              <option key={index} value={item.value}>
                                {item.label}
                              </option>
                            ))}
                          </select>
                          <i className="fas fa-chevron-down approver-drop-down"></i>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="col-md-3">
                    <CustomInput
                      label="Comment"
                      value={requesterDetailComments}
                      readOnly={isReadOnly ? true : false}
                      onChange={(e) =>
                        setRequesterDetailComments(e.target.value)
                      }
                      placeholder="Comment"
                    />
                  </div>
                </div>
              }
            />
          </div>
          <div className="col-md-12">
            <CustomCard
              cardtitle={"Approver"}
              cardboldtitle={"Details"}
              cardbody={
                <div>
                  {approverInfo.map((approver, index) => (
                    <div key={index} className="row">
                      <div className="col-md-4">
                        <div className="custominput-container">
                          <div className="custom-input">
                            <label htmlFor={`approverName${index}`}>
                              Approver L{index + 1} Name{" "}
                              <span style={{ color: "red" }}>*</span>
                            </label>
                            <div style={{ position: "relative" }}>
                              <select
                                id={`approverName${index}`}
                                className="form-control"
                                value={approver.approverName}
                                disabled={isReadOnly ? true : false}
                                onChange={(e) => {
                                  const selectedApprover = approverList.find((approver) => approver.name === e.target.value);
                                  handleApproverChange(index,"approverName",e.target.value);
                                  handleApproverChange(index,"approverId",selectedApprover.id );
                                  handleApproverChange( index,"approverEmail",selectedApprover.email );
                                  handleApproverChange(index,"approverDepartment",selectedApprover.user_department);
                                }}
                              >
                                <option value="">Select Approver</option>
                                {index === 0 &&approverList.filter((approver) =>approver.user_department === "Purchase").map((filteredApprover, filteredIndex) => (
                                      <option key={filteredIndex}value={filteredApprover.name}>{filteredApprover.name}</option>
                                    ))}
                                {index === 1 &&approverList.filter((approver) =>approver.user_department === "Finance").map((filteredApprover, filteredIndex) => (
                                      <option key={filteredIndex}value={filteredApprover.name}>{filteredApprover.name} </option>
                                    ))}
                              </select>
                              <i className="fas fa-chevron-down approver-drop-down"></i>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div className="col-md-4">
                        <CustomInput
                          require={true}
                          readOnly={true}
                          label="Department"
                          value={approver.approverDepartment}
                          onChange={(e) =>
                            handleApproverChange(
                              index,
                              "approverDepartment",
                              e.target.value
                            )
                          }
                          placeholder="Department"
                        />
                      </div>
                      <div className="col-md-4">
                        <CustomInput
                          require={true}
                          readOnly={true}
                          label="Mail ID"
                          value={approver.approverEmail}
                          onChange={(e) =>
                            handleApproverChange(
                              index,
                              "approverEmail",
                              e.target.value
                            )
                          }
                          placeholder="Mail ID"
                        />
                      </div>
                    </div>
                  ))}
                  <div className="col-md-4">
                    <CustomInput
                      label="Comment"
                      value={approverDetailsComment}
                      readOnly={isReadOnly ? true : false}
                      onChange={(e) =>
                        setApproverDetailsComment(e.target.value)
                      }
                      placeholder="Comment"
                    />
                  </div>
                </div>
              }
            />
          </div>

          <div className="col-md-12">
            <CustomCard
              cardtitle={"Bank"}
              cardboldtitle={"Details"}
              cardbody={
                <div className="row">
                  <div className="col-md-3">
                    <CustomInput
                      label="Bank Name"
                      placeholder="Bank Name"
                      value={bankName}
                      readOnly={true}
                    />
                  </div>
                  <div className="col-md-3">
                    <CustomInput
                      label="Bank Account Number"
                      placeholder="Bank Account Number"
                      value={bankAccountNumber}
                      readOnly={true}
                    />
                  </div>
                  <div className="col-md-3">
                    <CustomInput
                      label="Bank GL Code"
                      placeholder="Bank GL Code"
                      value={bankGlCode}
                      readOnly={true}
                    />
                  </div>

                  <div className="col-md-3">
                    <CustomInput
                      label="Comment"
                      value={bankDetailComment}
                      readOnly={true}
                      placeholder="Comment"
                    />
                  </div>
                </div>
              }
            />
          </div>

          <div className="col-md-12">
            <CustomCard
              cardtitle={"Bank Guarantee"}
              cardboldtitle={"Details"}
              cardbody={
                <div className="row">
                  <div className="col-md-3">
                    <CustomInput
                      readOnly={true}
                      label="Reference Number"
                      placeholder="Reference Number"
                      value={refrenceNumber}
                      onChange={(e) => setRefrenceNumber(e.target.value)}
                    />
                  </div>
                  <div className="col-md-3">
                    <div className="custominput-container">
                      <div className="custom-input">
                        <label htmlFor="">
                          Applicant Name <span style={{ color: "red" }}>*</span>{" "}
                        </label>
                        <div style={{ position: "relative" }}>
                          <select
                            className="form-control"
                            value={applicantName}
                            disabled={isReadOnly ? true : false}
                            onChange={(e) => {
                              setApplicantName(e.target.value);
                            }}
                          >
                            <option value="">Select Applicant Name</option>
                            {entity.map((entity, index) => (
                              <option key={index} value={entity.name}>
                                {entity.name}
                              </option>
                            ))}
                          </select>
                          <i className="fas fa-chevron-down approver-drop-down"></i>
                        </div>
                      </div>
                    </div>
                  </div>

                  <div className="col-md-3">
                    <CustomInput
                      label="Applicant Location"
                      placeholder="Applicant Location"
                      value={applicantLocation}
                      readOnly={isReadOnly ? true : false}
                      onChange={(e) => setApplicantLocation(e.target.value)}
                    />
                  </div>
                  <div className="col-md-3">
                    <CustomInput
                      label="Beneficiary Name"
                      placeholder="Beneficiary Name"
                      value={beneficiaryName}
                      readOnly={isReadOnly ? true : false}
                      onChange={(e) => setBeneficiaryName(e.target.value)}
                    />
                  </div>
                  <div className="col-md-3">
                    <CustomInput
                      label="Beneficiary Location"
                      placeholder="Beneficiary Location"
                      value={beneficiaryLocation}
                      readOnly={isReadOnly ? true : false}
                      onChange={(e) => setBeneficiaryLocation(e.target.value)}
                    />
                  </div>
                  <div className="col-md-3">
                    <CustomInput
                      readOnly={true}
                      label="Issuance Date"
                      type="date"
                      placeholder="Issuance Date"
                      value={inssuanceDate && getFormattedDate(inssuanceDate)}
                      onChange={(e) => setInssuanceDate(e.target.value)}
                    />
                  </div>
                  <div className="col-md-3">
                    <CustomInput
                      label="Expiry Date"
                      value={ expiryDate && getFormattedDate(expiryDate)}
                      type="date"
                      onChange={(e) => setExpiryDate(e.target.value)}
                      placeholder="Expiry Date"
                      min={new Date().toISOString()?.split("T")[0]}
                      readOnly={isReadOnly ? true : false}
                    />
                  </div>
                  <div className="col-md-3">
                    <div className="custominput-container">
                      <div className="custom-input">
                        <label htmlFor="">Currency </label>
                        <div style={{ position: "relative" }}>
                          <select
                            className="form-control"
                            value={currency}
                            disabled={isReadOnly ? true : false}
                            onChange={(e) => setCurrency(e.target.value)}
                          >
                            <option value="">Select Currency</option>
                            {currencyList.map((item, index) => (
                              <option key={index} value={item.name}>
                                {item.name}
                              </option>
                            ))}
                          </select>
                          <i className="fas fa-chevron-down approver-drop-down"></i>
                        </div>
                      </div>
                    </div>
                  </div>

                  <div className="col-md-3">
                    <CustomInput
                      label="Claim Expiry Date"
                      value={ claimExpiryDate && getFormattedDate(claimExpiryDate)}
                      type="date"
                      readOnly={isReadOnly ? true : false}
                      min={expiryDate}
                      onChange={(e) => setClaimExpiryDate(e.target.value)}
                      placeholder="Claim Expiry Date"
                    />
                  </div>
                  <div className="col-md-3">
                    <CustomInput
                      label="Amount"
                      value={ammount}
                      readOnly={isReadOnly ? true : false}
                      onChange={(e) => setAmmount(e.target.value)}
                      placeholder="Amount"
                    />
                  </div>
                  <div className="col-md-3">
                    <CustomInput
                      label="Print Location"
                      value={printLocation}
                      readOnly={isReadOnly ? true : false}
                      onChange={(e) => setPrintLoation(e.target.value)}
                      placeholder="Print Location"
                    />
                  </div>
                  <div className="col-md-3">
                    <div className="custominput-container">
                      <div className="custom-input">
                        <label htmlFor="">Type of BG </label>
                        <div style={{ position: "relative" }}>
                          <select
                            className="form-control"
                            value={typeOfBg}
                            disabled={isReadOnly ? true : false}
                            onChange={(e) => setTypeOfBg(e.target.value)}
                          >
                            <option value="">Select Type of BG</option>
                            {typeOfBgList.map((item, index) => (
                              <option key={index} value={item.name}>
                                {item.name}
                              </option>
                            ))}
                          </select>
                          <i className="fas fa-chevron-down approver-drop-down"></i>
                        </div>
                      </div>
                    </div>
                  </div>

                  <div className="col-md-3">
                    <CustomInput
                      label="Payable Location"
                      value={paybleLocation}
                      readOnly={isReadOnly ? true : false}
                      onChange={(e) => setPaybleLocation(e.target.value)}
                      placeholder="Payable Location"
                    />
                  </div>
                  <div className="col-md-3">
                    <CustomInput
                      label="Comment"
                      readOnly={isReadOnly ? true : false}
                      value={bankGuaranteeComment}
                      onChange={(e) => setBankGuaranteeComment(e.target.value)}
                      placeholder="Comment"
                    />
                  </div>
                </div>
              }
            />
          </div>

          <div className="col-md-12">
            <CustomCard
              cardtitle={"Bank Charges"}
              cardboldtitle={"Details"}
              cardbody={
                <div className="row">
                  <div className="col-md-3">
                    <CustomInput
                      readOnly={true}
                      label="BG Charges"
                      value={bgCharges}
                      onChange={(e) => setBgCharges(e.target.value)}
                      placeholder="BG Charges"
                    />
                  </div>
                  <div className="col-md-3">
                    <CustomInput
                      label="GST"
                      readOnly={true}
                      placeholder="GST"
                      value={gst}
                      onChange={(e) => setGst(e.target.value)}
                    />
                  </div>
                  <div className="col-md-3">
                    <CustomInput
                      label="Stamp Charges"
                      value={stampcharges}
                      readOnly={true}
                      onChange={(e) => setStampCharges(e.target.value)}
                      placeholder="Stamp Charges"
                    />
                  </div>
                  <div className="col-md-3">
                    <CustomInput
                      label="BG Charges GL Code"
                      value={bgChargesGlCode}
                      readOnly={true}
                      onChange={(e) => setBgChargesGlCode(e.target.value)}
                      placeholder="BG Charges GL Code"
                    />
                  </div>
                  <div className="col-md-3">
                    <CustomInput
                      label="GST GL Code"
                      value={gstGlCode}
                      readOnly={true}
                      onChange={(e) => setGstGlCode(e.target.value)}
                      placeholder="GST GL Code"
                    />
                  </div>

                  <div className="col-md-3">
                    <CustomInput
                      label="Comment"
                      readOnly={true}
                      value={bankChargesComment}
                      onChange={(e) => setBankChargesComment(e.target.value)}
                      placeholder="Comment"
                    />
                  </div>
                </div>
              }
            />
          </div>

          <div className="col-md-12">
            <CustomCard
              cardboldtitle={"Attachments"}
              cardbody={
                <div className="row">
                  <div className="col-md-3">
                    <CustomInput
                      label="BG Text"
                      require={true}
                      placeholder="BG Text"
                      // type="file"
                      // onChange={handleBgText}
                      {...(isReadOnly ? { type: "text" }  : { type: "file", onChange: handleBgText })}
                      readOnly={isReadOnly ? true : false}
                    // multiple
                    />
                    <div className="uploaded-images">
                      {bgText.map((bgText, index) => (
                        <div
                          key={index}
                          style={{
                            cursor: "pointer",
                            color: "#65a6fa",
                            marginBottom: "8px",
                          }}
                          onClick={() => handleBgTextdownload(bgText)}
                          className="url-download"
                        >
                          {bgText}
                        </div>
                      ))}
                    </div>
                  </div>
                  <div className="col-md-3">
                    <CustomInput
                      label="Purchase Order/ RFP"
                      // type="file"
                      placeholder="Purchase Order/ RFP"
                      require={true}
                      // onChange={handlePurchaseOrder}
                      {...(isReadOnly ? { type: "text" }  : { type: "file", onChange: handlePurchaseOrder })}
                      readOnly={isReadOnly ? true : false}
                    />
                    <div className="uploaded-images">
                      {purchaseOrder.map((order, index) => (
                        <div
                          key={index}
                          style={{
                            cursor: "pointer",
                            color: "#65a6fa",
                            marginBottom: "8px",
                          }}
                          onClick={() => handlePurchasedownload(order)}
                          className="url-download"
                        >
                          {order}
                        </div>
                      ))}
                    </div>
                  </div>

                  <div className="col-md-3">
                    <CustomInput
                      label="Comment"
                      value={attachmentComment}
                      onChange={(e) => setAttachmentComment(e.target.value)}
                      placeholder="Comment"
                      readOnly={isReadOnly ? true : false}
                    />
                  </div>
                </div>
              }
            />
          </div>

          <div className="col-md-12">
            <CustomCard
              cardtitle={"Audit"}
              cardboldtitle={" trail"}
              toprightview={
                <button className="export-btn">
                  <ExcelFile
                    element={
                      <div>
                        {" "}
                        <img
                          width={20}
                          className="me-2"
                          alt=""
                          src={require("../../assets/images/excel.png")}
                        />
                        Export
                      </div>
                    }
                  >
                    <ExcelSheet
                      data={getAuditDetail?.map((item, i) => ({
                        ...item,
                        index: i + 1,
                        created_at:
                          item?.created_at &&
                          `${item.created_at?.split("T")[0]} ${item.created_at
                            ?.split("T")[1]
                            ?.split(".")[0]
                            .substring(0, 5)}`,
                        role: item?.role,
                      }))}
                      name="Sheet"
                    >
                      <ExcelColumn
                        label="Status Date & Time"
                        value="created_at"
                      />
                      <ExcelColumn label="Name" value="userName" />
                      <ExcelColumn label="Role" value="role" />
                      <ExcelColumn label="Action Required" value="status" />
                    </ExcelSheet>
                  </ExcelFile>
                </button>
              }
              cardbody={
                <React.Fragment>
                  <div class="table-responsive">
                    <table class="table">
                      <thead class="table-customlight">
                        <tr>
                          <th className="auditTrail">Status Date & Time</th>
                          <th className="auditTrail">Name</th>
                          <th className="auditTrail">Role</th>
                          <th className="auditTrail">Action Required</th>
                        </tr>
                      </thead>
                      {getAuditDetail?.length > 0 && (
                        <tbody>
                          {getAuditDetail?.map((item, i) => {
                            return (
                              <tr key={item?.requestNumber}>
                                <td scope="row"><FormatDate dateString={item?.created_at} /></td>
                                <td>{item?.userName}</td>
                                <td>{item?.role}</td>
                                <td className="status-box">
                                  <span className="status"></span>
                                  {item?.status}
                                </td>
                              </tr>
                            );
                          })}
                        </tbody>
                      )}
                    </table>
                  </div>
                </React.Fragment>
              }
            />
          </div>

          <div className="col-md-12">
            <CustomCard
              cardtitle={""}
              cardboldtitle={"Chat"}
              cardbody={
                <div className="row">
                  {chat?.length > 0 && (
                    <>
                      {chat?.map((item, i) => (
                        <div key={i} className="col-md-12 chat-left">
                          <div className="chat-container">
                            <h4 className="chat-username">{item?.userName.toUpperCase()}({item?.userRole})</h4>
                            <div className="chat-username-box">
                              <p className="chat-massege">{item?.message}</p>
                              <p className="chat-time">
                                <FormatDate dateString={item?.created_at} />
                              </p>
                            </div>
                          </div>
                        </div>
                      ))}
                      <div className="col-md-6 " style={{ width: "44%" }}>
                        <CustomInput
                          label="Comment Box"
                          value={chatComment}
                          onChange={(e) => setChatComment(e.target.value)}
                          placeholder="Comment Box"
                        />
                      </div>

                      <div className="col-md-2 chat-button">
                        <CustomButton
                          style={"btn--outline me-3"}
                          children={"Send"}
                          onClick={handleSendMassege}
                        />
                      </div>
                    </>
                  )}
                </div>
              }
            />
          </div>

          {!isReadOnly && <div className="col-md-12 text-end">
            <CustomButton
              style={"btn--outline me-3"}
              children={"Save"}
              onClick={() => {
                submitForApproval("save");
              }}
            />
            <CustomButton
              children={"Submit for approval"}
              onClick={() => {
                submitForApproval("submit");
              }}
            />
          </div>}
        </div>
      </div>
    </div>
  );
};

export default BgAmendment;

import React from "react";
import './style.scss';
import Modal from "react-bootstrap/Modal";
import successImage from "../../assets/images/checked@2x.png";
import crossImage from "../../assets/images/close (4).png";

const SubmitTicketModal = ({ hide, show, title }) => {
  debugger

  return (
    <Modal show={show} centered className="sucess-content sucess_content_modal">
      <Modal.Body className="sucess-modal">
        <div className="modal-container">
          <img
            src={crossImage}
            alt=""
            className="cross-img"
            onClick={() => hide()}
          />
          <div className="row moodal">
            <img src={successImage} alt="" className="modal-img" />
            <h2 className="text-center success_title">Thank You</h2>
            <p className="success_text" style={{ textAlign: "center" }}>
              {title}
            </p>
          </div>
        </div>
      </Modal.Body>
    </Modal>
  );
};

export default SubmitTicketModal;

import React, { useEffect, useState } from "react";
import "./headerStyle.scss";
import { useNavigate, useLocation, Link } from "react-router-dom";
import notify from "../../shared/notify";
import ChangePassword from "../Modal/changePassword";
import { useStores } from "../../stores";
import { error } from "jquery";

const Header = (props) => {
  const navigate = useNavigate();
  const location = useLocation();
  const { crmStore } = useStores()
  const url = location.pathname?.split("/")[1];
  const [selectedDashboard, setSelectedDashboard] = useState("");
  const [role, setRole] = useState(sessionStorage.getItem("role"));
  const [showDropdown, setShowDropdown] = useState(false);
  const [passwordModal, setPasswordModal] = useState("");
  const treasury = sessionStorage.getItem("treasury")
  const Username = sessionStorage.getItem("name")
  const [count, setCount] = useState("")

  const crmPortal = sessionStorage.getItem("crmPortal")


  const handleDropdownToggle = () => {
    setShowDropdown(!showDropdown);
  };

  const handleChangePassword = async () => {
    setPasswordModal(true)
  };

  const clearCacheData = () => {
    if ("caches" in window) {
      caches.keys().then((names) => {
        names.forEach((name) => {
          caches.delete(name);
        });
      });
    }
    // navigate(`/`);
    window.location.reload();
  };

  const handleLogOut = async () => {
    sessionStorage.clear()
    clearCacheData();
  };



  const handleDashboardSelection = (event) => {
    const selectedDashboard = event.target.value;
    setSelectedDashboard(selectedDashboard);
    sessionStorage.setItem("selectedDashboard", selectedDashboard);
    navigate(selectedDashboard);
    window.location.reload();
  };

  const name = sessionStorage.getItem("selectedDashboard");
  switch (name) {
    case "/treasury-journey-dashboard":
      sessionStorage.setItem("roleName", "Treasury");
      break;
    case "/approver-journey-dashboard":
      sessionStorage.setItem("roleName", "Approver");
      break;
    case "/requestor-journey-dashboard":
      sessionStorage.setItem("roleName", "Requestor");
      break;
    default:
      console.log("Unknown dashboard URL:", name);
      break;
  }

  const handleHome = () => {
    navigate("/home")
  }


  const roleNmaw = () => {
    if (url === "home" || treasury === "0") {
      return (
        <div className="flex-box">
          <div className="username-box">
            <h3 className="userid">{Username}</h3>
            <span>({role})</span>
          </div>
        </div>

      )
    }
  }

  useEffect(() => {
    handleCount();
    // const interval = setInterval(() => {
    //   handleCount(); 
    // }, 5000);

    // return () => clearInterval(interval); // Cleanup the interval on component unmount
  }, []);


  const handleCount = async () => {
    await crmStore?.getCount((staus, res) => {
      if (res?.meta?.status === "success") {
        setCount(res?.data[0]?.count)
      }
    }, (error) => {
      console.log(error?.response?.meta?.message);

    })
  }

  const update_Notification = async () => {
    await crmStore?.updateCount((staus, res) => {
      if (res?.meta?.status === "success") {
        console.log(res?.data);

      }
    }, (error) => {
      notify(error?.response?.meta?.message, "danger")
    })
  }

  return (
    <>
      <header className="HeaderSection">
        <div className="header-view">
          <div>
            <h2 className="page-title">{props.title}</h2>
            <div className={(url === "home" || url == "notification") ? "" : "heading-underline"}></div>
          </div>

          <div className={url === "home" ? "drop_main_box home-box-cont" : "drop_main_box"}>


            <div className="d-flex align-items-center dropDown-menu">
              {/* {!["home", "listing-dashboard", "requestor-journey-dashboard", "closed-bg-amendment", "closed-bg-cancellation","closed-lc-amendment", "approver-journey-dashboard", "treasury-journey-dashboard",
                "closed-lc-cancellation","create-lc-amendment","create-lc-cancellation","create-bg-amendment","create-bg-cancellation","edit-bg-issuance"].includes(url) && (
                  <Link to="/notification" className='notification_right'>
                    <img onClick={() => { update_Notification() }} className='notification_icon' src={require("../../assets/images/active.png")} alt="notification icon" />
                    {count > 0 && <div className="notification_nu">{count}</div>}
                  </Link>
                )} */}
              {
                treasury !== "1"  && url !== "home" && <Link to="/notification" className='notification_right'>
                  <img onClick={() => { update_Notification() }} className='notification_icon' src={require("../../assets/images/active.png")} alt="notification icon" />
                  {count > 0 && <div className="notification_nu">{count}</div>}
                </Link>
              }


              {url !== "home" && <div className="header-home" onClick={handleHome} style={{ cursor: "pointer" }}>
                <img src={require("../../assets/images/home.png")} />
                <h6 style={{ padding: "5px" }} >Home</h6>
              </div>
              }

              {treasury === "1" && url !== "home" && <div className="me-2 dropDown-menu-item">
                <select
                  className="select"
                  value={name}
                  onChange={handleDashboardSelection}
                >
                  <option value={""}>Select</option>
                  {role.includes("Treasury") && (
                    <option value="/treasury-journey-dashboard">
                      Treasury Dashboard
                    </option>
                  )}
                  {role.includes("Approver") && (
                    <option value="/approver-journey-dashboard">
                      Approver Dashboard
                    </option>
                  )}
                  {role.includes("Requestor") && (
                    <option value="/requestor-journey-dashboard">
                      Requestor Dashboard
                    </option>
                  )}

                </select>
              </div>}


              {roleNmaw()}


              <img
                className="user-profile-icon"
                src={require("../../assets/images/user.png")}
                alt="User Profile Icon"
                onClick={handleDropdownToggle}
              />


            </div>
            {showDropdown && (
              <div className="form-control dropDown-menu-item" style={{ display: "flex", flexDirection: "column", width: "89%" }}>
                {url !== "home" && <div onClick={handleChangePassword} className="change_passwoard"><img
                  className="change_icon"
                  src={require("../../assets/images/password.png")}
                  alt="User Profile Icon"
                /> Change Password
                </div>}
                <div onClick={handleLogOut} className={url === "home" && "home-txt"}>
                  <div className="change_passwoard">
                    <img
                      className="logout_icon"
                      src={require("../../assets/images/logout.png")}
                      alt="User Profile Icon"
                    /> Logout
                  </div>
                </div>
              </div>
            )}
          </div>
        </div>
        <ChangePassword
          show={passwordModal}
          hide={() => {
            setPasswordModal(false)
          }}


        />
      </header>
    </>
  );
};

export default Header;

import React, { useEffect, useRef, useState } from "react";
import CustomCard from "../CustomeCard";
import ReactExport from "react-export-excel";
import notify from "../../shared/notify";
import { useStores } from "../../stores";
import { useNavigate } from "react-router-dom";
import Header from "../Header";
import FormatFixDate from "../helper/formatDate/FormatFixDate";
import ReactPaginate from "react-paginate";
import Loader  from "../helper/Loader/loader";


const ExcelFile = ReactExport.ExcelFile;
const ExcelSheet = ReactExport.ExcelFile.ExcelSheet;
const ExcelColumn = ReactExport.ExcelFile.ExcelColumn;

const limit = [
  {
    option: 2,
    value: 2,
  },
  {
    option: 10,
    value: 10,
  },
  {
    option: 100,
    value: 100,
  },
  {
    option: 500,
    value: 500,
  },
  {
    option: 1000,
    value: 1000,
  },
];

const AmendmentLcClosed = (props) => {
  const { mainStore } = useStores()
  const [fetchedLcData, setFetchedLcData] = useState([]);
  const [fetchedLcDataAll, setFetchedLcDataAll] = useState([]);
  const navigate = useNavigate();
  const [page, setPage] = useState(1);
  const [pageLimit, setPageLimit] = useState(10);
  const [isLoading , setIsLoading] = useState(false)

  useEffect(() => {
    fetchLcData()
  }, [])

  const handlePageSubmit = async (index) => {
    setPage(index.selected + 1);
    let payload = {
      limit: pageLimit,
      filters: {
        isClosed: true
      },
      pageNumber: index.selected + 1,
    };
    callLcApi(payload);
  };

  const limitChangeHandler = async (index) => {
    setPageLimit(index);
    let payload = {
      limit: index,
      filters: {
        isClosed: true
      },
      pageNumber: page,
    };
    callLcApi(payload);
  };





  const fetchLcData = async () => {
    let payload = {
      limit: pageLimit,
      filters: {
        isClosed: true
      },
      pageNumber: 1
    };
    callLcApi(payload)
  };

  const callLcApi = async (payload) => {
    setIsLoading(true)
    await mainStore.getLcInssuance(
      payload,
      (response) => {
        if (response?.data?.meta?.status === "success") {
          // const filteredData = response?.data?.data?.filteredData?.results?.filter(item => item.isClosed === true);
          const filteredData = response?.data?.data?.filteredData?.results;
          setFetchedLcData(filteredData);
          setFetchedLcDataAll(response?.data?.data)
          setIsLoading(false)
        } else {
          notify(response?.data?.meta?.message, "danger");
          setIsLoading(false)
        }
      },
      (error) => {
        notify(error?.response?.data?.meta?.message, "danger");
        setIsLoading(false)
      }
    );
  }

  const handleNavigation = (e, item) => {
    e.preventDefault();
    navigate("/create-lc-amendment", { state: { item } });
  };

  const getFormattedDate = (dateString) => {
    const date = new Date(dateString);
    const year = date.getFullYear();
    const month = (date.getMonth() + 1).toString().padStart(2, "0");
    const day = date.getDate().toString().padStart(2, "0");
    return `${year}-${month}-${day}`;
  };

  return (
    <div className="main-container">
      {isLoading && <Loader />}
      <div className="right-view">
        <Header title={"LC > Amendment"} />
        <div className="row">
          <div className="col-md-12">
            <CustomCard
              cardtitle={"Treasury"}
              cardboldtitle={" closed LC Requests"}
              toprightview={
                <div>
                  {fetchedLcData?.length > 0 && (
                    <button className="export-btn">
                      <ExcelFile element={<div><img width={20} className="me-2" src={require("../../assets/images/excel.png")} />Export</div>}>
                        <ExcelSheet data={fetchedLcData?.map((item, i) => ({
                          ...item, index: i + 1, issuanceDate: item?.issuanceDate && getFormattedDate(item?.issuanceDate),
                          expiryDate: item?.expiryDate && getFormattedDate(item?.expiryDate)

                        }))} name="Sheet">
                          <ExcelColumn label="Request Number" value="requestNumber" />
                          <ExcelColumn label="Reference Number" value="referenceNumber" />
                          <ExcelColumn label="Currency" value="currency" />
                          <ExcelColumn label="Amount" value="amount" />
                          <ExcelColumn label="Applicant Name" value="applicantName" />
                          <ExcelColumn label="Beneficiary Name" value="beneficiaryName" />
                          <ExcelColumn label="Issuance Date" value="issuanceDate" />
                          <ExcelColumn label="Expiry Date" value="expiryDate" />
                        </ExcelSheet>
                      </ExcelFile>
                    </button>)}
                </div>
              }
              cardbody={
                <React.Fragment>
                  <div className="col-md-12 mb-4">
                    <div>
                      <label className="d-flex align-items-center p-0 col-md-2">
                        {page === 1 ? (
                          <select
                            value={pageLimit}
                            className="form-control selectbox-container mr-2 drop-down-dashboard"
                            onChange={(e) => limitChangeHandler(e.target.value)}
                          >
                            {limit.length > 0
                              ? limit.map((item, index) => (
                                <option key={index} value={item.option}>
                                  {item.value}
                                </option>
                              ))
                              : null}
                          </select>
                        ) : (
                          <p className="form-control selectbox-container mr-2 drop-down-dashboard">
                            {pageLimit}
                          </p>
                        )}
                        <span> entries</span>
                      </label>
                    </div>
                  </div>

                  <div className="col-md-12 listing-table">
                    <div class="table-responsive">
                      <table class="table">
                        <thead class="table-customlight">
                          <tr>
                            <th scope="col">Request Number</th>
                            <th scope="col">Reference Number</th>
                            <th scope="col">Currency</th>
                            <th scope="col">Amount</th>
                            <th scope="col">Applicant Name</th>
                            <th scope="col">Beneficiary Name</th>
                            <th scope="col">Issuance Date</th>
                            <th scope="col">Expiry Date</th>
                            {/* <th scope="col">Claim Expiry Date</th> */}
                            <th scope="col">Amendment Count</th>
                            <th scope="col">Status</th>
                          </tr>
                        </thead>
                        {fetchedLcData?.length > 0 && (
                          <tbody>
                            {fetchedLcData.map((item, i) => {
                              const status =
                                item.isApproved
                                && "Closed"
                              return (
                                <tr key={item.requestNumber}>
                                  <td
                                    onClick={(e) => handleNavigation(e, item)}
                                    scope="row"
                                    className="text-link"
                                  >
                                    {item?.requestNumber}
                                  </td>
                                  <td>{item?.referenceNumber}</td>
                                  <td>{item?.currency}</td>
                                  <td>{item?.amount}</td>
                                  <td>{item?.applicantName}</td>
                                  <td>{item?.beneficiaryName}</td>
                                  <td>{item?.issuanceDate && <FormatFixDate dateString={item?.issuanceDate} />}</td>
                                  <td>{item?.expiryDate && <FormatFixDate dateString={item?.expiryDate} />}</td>
                                  <td>{item?.lcrequestAmendment?.length}</td>
                                  <td>{status}</td>
                                </tr>
                              );
                            })}
                          </tbody>
                        )}
                      </table>
                    </div>
                  </div>

                  <div className="col-md-12">
                    <div className="d-flex align-items-center justify-content-between my-4">
                      <div>
                        <p>
                          showing {fetchedLcDataAll?.filteredCount} out of{" "}
                          {fetchedLcDataAll?.totalRecords}
                        </p>
                      </div>
                      <div>
                        <ReactPaginate
                          previousLabel={"Previous"}
                          nextLabel={"Next"}
                          breakLabel={"..."}
                          pageCount={fetchedLcDataAll?.totalPages}
                          marginPagesDisplayed={3}
                          pageRangeDisplayed={3}
                          onPageChange={handlePageSubmit}
                          containerClassName={"pagination justify-content-center"}
                          pageClassName={"page-item"}
                          pageLinkClassName={"page-link"}
                          previousClassName={"page-item"}
                          previousLinkClassName={"page-link"}
                          nextClassName={"page-item"}
                          nextLinkClassName={"page-link"}
                          breakClassName={"page-item"}
                          breakLinkClassName={"page-link"}
                          activeClassName={"active"}
                        />
                      </div>
                    </div>
                  </div>
                </React.Fragment>
              }

            />
          </div>
        </div>
      </div>
    </div>
  );
};

export default AmendmentLcClosed;

import React from "react";
import authStore from "./authStore";
import mainStore from "./mainStore";
import crmStore from "./crmStore"

class RootStore {
  constructor() {
    this.authStore = new authStore(this);
    this.mainStore = new mainStore(this);
    this.crmStore = new crmStore(this);
  }}


  const storesContext = React.createContext(new RootStore())
  export const useStores = () => React.useContext(storesContext)


import React, { useEffect, useState } from "react";
import "./style.scss";
import Header from "../Header";
import CustomCard from "../CustomeCard";
import CustomInput from "../CustomInput";
import CustomButton from "../Button/Button";
import { useLocation, useNavigate } from "react-router-dom"
import { useStores } from "../../stores";
import notify from "../../shared/notify";
import Loader from "../helper/Loader/loader";
import ReactExport from "react-export-excel";
import FormatDate from "../helper/formatDate/formateDate";


const ExcelFile = ReactExport.ExcelFile;
const ExcelSheet = ReactExport.ExcelFile.ExcelSheet;
const ExcelColumn = ReactExport.ExcelFile.ExcelColumn;

const BgAmendment = (props) => {
  const { state } = useLocation();
  const { mainStore } = useStores()
  const navigate = useNavigate()
  const userName = sessionStorage.getItem("name")
  const roleName = sessionStorage.getItem("roleName")
  const [chatComment, setChatComment] = useState("")
  const [chat, setChat] = useState([])
  const [ammendmentComment, setAmmendmentComment] = useState("")
  const [requesterDetailComments, setRequesterDetailComments] = useState(state?.item?.requestorComment)
  const [approverDetailsComment, setApproverDetailsComment] = useState(state?.item?.approverComment)
  const [bankDetailComment, setBankDetailComment] = useState(state?.item?.bankComment)
  const [bankGuaranteeComment, setBankGuaranteeComment] = useState(state?.item?.bankGuaranteeComment)
  const [bankChargesComment, setBankChargesComment] = useState(state?.item?.bankChargesComments)
  const [attachmentComment, setAttachmentComment] = useState(state?.item?.attachmentsComment)
  const [requestNumber, setRequestNumber] = useState(state?.item?.requestNumber);
  const [requestName, setRequestName] = useState(state?.item?.requesterName);
  const [requesterMail, setRequesterMail] = useState(state?.item?.requesterEmail);
  const [requestEntity, setRequestEntity] = useState(state?.item?.entity);
  const [requestUnit, setRequestUnit] = useState(state?.item?.unit);
  const [approverCount, setApproverCount] = useState(1);
  const [approverInfo, setApproverInfo] = useState([
    { approverId: "", approverName: "", approverDepartment: "", approverEmail: "", approverLevel: "L1" },
    { approverId: "", approverName: "", approverDepartment: "", approverEmail: "", approverLevel: "L2" }
  ]);
  const [bankName, setBankName] = useState(state?.item?.bankName);
  const [bankAccountNumber, setBankAccountNumber] = useState(state?.item?.bankAccountNumber);
  const [bankGlCode, setBankGlCode] = useState(state?.item?.bankGlCode);
  const [refrenceNumber, setRefrenceNumber] = useState(state?.item?.referenceNumber);
  const [applicantName, setApplicantName] = useState(state?.item?.applicantName);
  const [applicantLocation, setApplicantLocation] = useState(state?.item?.applicantLocation);
  const [beneficiaryName, setBeneficiaryName] = useState(state?.item?.beneficiaryName);
  const [beneficiaryLocation, setBeneficiaryLocation] = useState(state?.item?.beneficiaryLocation);
  const [inssuanceDate, setInssuanceDate] = useState(state?.item?.issuanceDate);
  const [expiryDate, setExpiryDate] = useState(state?.item?.expiryDate );
  const [currency, setCurrency] = useState(state?.item?.currency);
  const [claimExpiryDate, setClaimExpiryDate] = useState(state?.item?.claimExpiryDate);
  const [ammount, setAmmount] = useState(state?.item?.amount);
  const [printLocation, setPrintLoation] = useState(state?.item?.printLocation);
  const [typeOfBg, setTypeOfBg] = useState(state?.item?.bgType);
  const [paybleLocation, setPaybleLocation] = useState(state?.item?.payableLocation);
  const [bgCharges, setBgCharges] = useState(state?.item?.bgCharge);
  const [gst, setGst] = useState(state?.item?.gst);
  const [stampcharges, setStampCharges] = useState(state?.item?.stampCharges);
  const [bgChargesGlCode, setBgChargesGlCode] = useState(state?.item?.bgChargesGlCode);
  const [gstGlCode, setGstGlCode] = useState(state?.item?.gstGlCode);
  const [bgText, setBgText] = useState(() => {
    const bgTextString = state?.item?.bgText;
    if (bgTextString) {
      try {
        return JSON.parse(bgTextString);
      } catch (error) {
        console.error("Error parsing bgText JSON:", error);
      }
    }
    return [];
  });
  const [purchaseOrder, setPurchaseOrder] = useState(() => {
    const purchaseOrderString = state?.item?.purchaseOrder;
    if (purchaseOrderString) {
      try {
        return JSON.parse(purchaseOrderString);
      } catch (error) {
        console.error("Error parsing purchaseOrder JSON:", error);
      }
    }
    return [];
  });
  const [amendmentclaimExpiryDate, setAmendmentClaimExpiryDate] = useState()
  const [amendmentexpiryDate, setAmendmentExpiryDate] = useState()
  const [clause, setClause] = useState()
  const [other, setOther] = useState()
  const [attachment, setAttachment] = useState([])
  const [amendmentbgCharges, setAmendmentBgCharges] = useState();
  const [amendmentgst, setAmendmentGst] = useState();
  const [amendmentstampcharges, setAmendmentStampCharges] = useState();
  const [amendmentbgChargesGlCode, setAmendmentBgChargesGlCode] = useState();
  const [amendmentgstGlCode, setAmendmentGstGlCode] = useState();

  const bgrequestAmendment = state?.item?.bgrequestAmendment;
  const bgrequestCancellation = state?.item?.bgrequestCancellation

  const [isLoading, setIsLoading] = useState(false)
  const [division] = useState(state?.item?.lineOfService)
  const [getAuditDetail, setGetAuditDetail] = useState([])

  const Viewer = sessionStorage.getItem("role")


  const getFormattedDate = (dateString) => {
    const date = new Date(dateString);
    const year = date.getFullYear();
    const month = (date.getMonth() + 1).toString().padStart(2, "0");
    const day = date.getDate().toString().padStart(2, "0");
    return `${year}-${month}-${day}`;
  };


  const handleFileChange = async (e) => {
    setIsLoading(true)
    const files = e.target.files;
    let formData = new FormData();

    for (let i = 0; i < files.length; i++) {
      formData.append('file', files[i]);
      // formData.append('filepath', 'BgRequest');
      let params = 'BgRequest'
      await mainStore.uploadfile(params, formData, (response) => {
        if (response?.data?.meta?.status === "success") {
          notify(response?.data?.meta?.message);
          setAttachment(prevState => [...prevState, response.data.data]);
          setIsLoading(false)
        } else {
          notify(response?.data?.meta?.message, "danger");
          setIsLoading(false)
        }
      },
        (error) => {
          notify(error?.response?.data?.meta?.message, "danger");
          setIsLoading(false)
        });
      formData.delete('file');
      formData.delete('filepath');
    }
  }


  useEffect(() => {
    if (state?.item?.approvers.length > 0) {
      let arr = [];
      state.item.approvers.map((item) => {
        let obj = {
          approverId: item?.approver_id,
          approverName: item?.user?.name,
          approverEmail: item?.user?.email,
          approverDepartment: item?.user?.user_department,
          approverLevel: item?.approver_level
        }
        arr.push(obj)
      })
      setApproverInfo(arr);
    }
  }, [])




  const createAmendment = async () => {
    setIsLoading(true)
    let payload = {
      claimExpiryDate: amendmentclaimExpiryDate,
      expiryDate: amendmentexpiryDate,
      clause: clause,
      others: other,
      attachments: JSON.stringify(attachment),
      comment: ammendmentComment
    }


    await mainStore.createRequestorBgAmendmet(state?.item?.requestNumber, payload, (response) => {
      if (response?.data?.meta?.status === "success") {
        notify(response?.data?.meta?.message, "success");
        navigate("/requestor-journey-dashboard")
        setIsLoading(false)
      } else {
        notify(response?.data?.meta?.message, "danger");
        navigate("/requestor-journey-dashboard")
        setIsLoading(false)
      }
    },
      (error) => {
        notify(error?.response?.data?.meta?.message, "danger");
        navigate("/requestor-journey-dashboard")
        setIsLoading(false)
      }
    );
  }


  const handleApproverChange = (index, field, value) => {
    const newApproverInfo = [...approverInfo];
    newApproverInfo[index][field] = value;
    setApproverInfo(newApproverInfo);
  };



  const handleBgTextdownload = async (filename) => {
    let payload = {
      filename: filename
    }
    callDownloadApi(payload)
  }


  const handlePurchasedownload = async (filename) => {
    let payload = {
      filename: filename
    }
    callDownloadApi(payload)
  }


  const handleAttachmentDownload = async (filepath) => {
    let payload = {
      filename: filepath
    }
    callDownloadApi(payload)
  }

  const handlePreviousDownload = async (attachments) => {
    let payload = {
      filename: attachments
    }
    callDownloadApi(payload)
  }


  const handleCancellationDownload = async (attachments) => {
    let payload = {
      filename: attachments
    }

    callDownloadApi(payload)
  }

  const callDownloadApi = async (payload) => {
    setIsLoading(true);
    await mainStore.downloadImage(payload, (response) => {
      if (response?.data?.meta?.status === "success") {
        notify(response?.data?.meta?.message);
        setIsLoading(false);
        const base64File = response?.data?.data?.base64File;
        const mimeType = response?.data?.data?.mimeType;
        const fileNameParts = response?.data?.data?.filename?.split("-");
        const fileName = fileNameParts.slice(1).join("-");
        if (base64File && mimeType) {
          const downloadLink = document.createElement('a');
          downloadLink.href = `data:${mimeType};base64,${base64File}`;
          downloadLink.download = fileName;
          document.body.appendChild(downloadLink);
          downloadLink.click();
          document.body.removeChild(downloadLink);
        } else {
          notify("Download link or File type not found", "danger");
        }
      } else {
        notify(response?.data?.meta?.message, "danger");
        setIsLoading(false);
      }
    },
      (error) => {
        notify(error?.response?.data?.meta?.message, "danger");
        setIsLoading(false);
      });
  };


  const getBgAuditDetail = async () => {
    setIsLoading(true)
    let payload = {}
    await mainStore.getBgAudittrail(state?.item?.requestNumber, payload, (response) => {
      if (response?.data?.meta?.status === "success") {
        setGetAuditDetail(response?.data?.data?.audit)
        setIsLoading(false)
      }
      else {
        notify(response?.data?.meta?.message, "danger")
        setIsLoading(false)
      }
    }, (error) => {
      notify(error?.response?.data?.meta?.message, "danger")
      setIsLoading(false)
    })
  }

  useEffect(() => {
    getBgAuditDetail()
    fetchChatResponse()
  }, [])

  const handleSendMassege = async () => {
    setIsLoading(true)
    let payload = {
      requestNumber: state?.item?.requestNumber,
      userName: userName,
      userRole: roleName,
      message: chatComment
    }

    await mainStore.sendMassege(payload, (response) => {
      if (response?.data?.meta?.status === "success") {
        notify(response?.data?.meta?.message)
        fetchChatResponse()
        setIsLoading(false)
      } else {
        notify(response?.data?.meta?.message, "danger")
        setIsLoading(false)
      }
    }, (error) => {
      notify(error?.response?.data?.meta?.message, "danger")
    })
  }

  const fetchChatResponse = async () => {
    mainStore.getChatResponse(state?.item?.requestNumber, (status, response) => {
   
      if (response?.meta?.status === "success") {
        setChat(response?.data?.chats)
      } else {
        notify(response?.meta?.message, "danger")
      }
    }, (error) => {
      notify(error?.response?.meta?.message, "danger")
    })
  }

  return (
    <div className="main-container">
      {isLoading && <Loader />}
      <div className="right-view">
        <Header title={"BG > Amendment"} />
        <div className="row">
          <div className="col-md-12">
            <CustomCard
              cardtitle={"Requester"}
              cardboldtitle={"Details"}
              cardbody={
                <div className="row">
                  <div className="col-md-3">
                    <CustomInput
                      readOnly={true}
                      label="Request Number"
                      placeholder="Request Number"
                      value={requestNumber}
                    />
                  </div>
                  <div className="col-md-3">
                    <CustomInput
                      readOnly={true}
                      label="Requester Name"
                      placeholder="Requester Name"
                      value={requestName}
                    />
                  </div>
                  <div className="col-md-3">
                    <CustomInput
                      readOnly={true}
                      label="Requester mail ID"
                      placeholder="Requester mail ID"
                      value={requesterMail}
                    />
                  </div>
                  <div className="col-md-3">
                    <CustomInput label="Entity" placeholder="Entity"
                      readOnly={true}
                      value={requestEntity}
                    />
                  </div>
                  <div className="col-md-3">
                    <CustomInput label="Unit" placeholder="Unit"
                      readOnly={true}
                      value={requestUnit}
                    />
                  </div>
                  <div className="col-md-3">
                    <CustomInput label="Line Of Service" placeholder="Line Of Service"
                      readOnly={true}
                      value={division}
                    />
                  </div>
                  <div className="col-md-3">
                    <CustomInput
                      readOnly={true}
                      label="Comment"
                      placeholder="Comment"
                      value={requesterDetailComments}
                    />
                  </div>
                </div>
              }
            />
          </div>

          <div className="col-md-12">
            <CustomCard
              cardtitle={"Approver"}
              cardboldtitle={"Details"}
              cardbody={
                <div>
                  {approverInfo.map((approver, index) => (
                    <div key={index} className="row">
                      <div className="col-md-4">
                        <CustomInput
                          label={index === 0 ? "Approver Purchase" : "Approver Finance"}
                          readOnly={true}
                          value={approver.approverName}
                          onChange={(e) =>
                            handleApproverChange(index, "approverName", e.target.value)
                          }
                          placeholder="Approver Name"
                        />
                      </div>
                      <div className="col-md-4">
                        <CustomInput
                          label="Department"
                          value={approver.approverDepartment}
                          readOnly={true}
                          onChange={(e) =>
                            handleApproverChange(
                              index,
                              "approverDepartment",
                              e.target.value
                            )
                          }
                          placeholder="Department"
                        />
                      </div>
                      <div className="col-md-4">
                        <CustomInput
                          label="Mail ID"
                          readOnly={true}
                          value={approver.approverEmail}
                          onChange={(e) =>
                            handleApproverChange(index, "approverEmail", e.target.value)
                          }
                          placeholder="Mail ID"
                        />
                      </div>

                    </div>
                  ))}
                  <div className="col-md-4">
                    <CustomInput
                      readOnly={true}
                      label="Comment"
                      placeholder="Comment"
                      value={approverDetailsComment}
                    />
                  </div>
                </div>
              }
            />
          </div>

          <div className="col-md-12">
            <CustomCard
              cardtitle={"Bank"}
              cardboldtitle={"Details"}
              cardbody={
                <div className="row">
                  <div className="col-md-3">
                    <CustomInput label="Bank Name" placeholder="Bank Name"
                      value={bankName}
                      readOnly={true}
                      onChange={(e) => setBankName(e.target.value)}
                    />
                  </div>
                  <div className="col-md-3">
                    <CustomInput
                      label="Bank Account Number"
                      placeholder="Bank Account Number"
                      value={bankAccountNumber}
                      readOnly={true}
                      onChange={(e) => setBankAccountNumber(e.target.value)}
                    />
                  </div>
                  <div className="col-md-3">
                    <CustomInput
                      label="Bank GL Code"
                      placeholder="Bank GL Code"
                      value={bankGlCode}
                      readOnly={true}
                      onChange={(e) => setBankGlCode(e.target.value)}
                    />
                  </div>
                  <div className="col-md-3">
                    <CustomInput
                      readOnly={true}
                      label="Comment"
                      placeholder="Comment"
                      value={bankDetailComment}
                    />
                  </div>
                </div>
              }
            />
          </div>

          <div className="col-md-12">
            <CustomCard
              cardtitle={"Bank Guarantee"}
              cardboldtitle={"Details"}
              cardbody={
                <div className="row">
                  <div className="col-md-3">
                    <CustomInput
                      readOnly={true}
                      label="Reference Number"
                      placeholder="Reference Number"
                      value={refrenceNumber}
                      onChange={(e) => setRefrenceNumber(e.target.value)}
                    />
                  </div>
                  <div className="col-md-3">
                    <CustomInput
                      label="Applicant Name"
                      placeholder="Applicant Name"
                      value={applicantName}
                      readOnly={true}
                      onChange={(e) => setApplicantName(e.target.value)}
                    />
                  </div>
                  <div className="col-md-3">
                    <CustomInput
                      label="Applicant Location"
                      placeholder="Applicant Location"
                      value={applicantLocation}
                      readOnly={true}
                      onChange={(e) => setApplicantLocation(e.target.value)}
                    />
                  </div>
                  <div className="col-md-3">
                    <CustomInput
                      label="Beneficiary Name"
                      placeholder="Beneficiary Name"
                      value={beneficiaryName}
                      readOnly={true}
                      onChange={(e) => setBeneficiaryName(e.target.value)}
                    />
                  </div>
                  <div className="col-md-3">
                    <CustomInput
                      label="Beneficiary Location"
                      placeholder="Beneficiary Location"
                      value={beneficiaryLocation}
                      readOnly={true}
                      onChange={(e) => setBeneficiaryLocation(e.target.value)}
                    />
                  </div>
                  <div className="col-md-3">
                    <CustomInput
                      readOnly={true}
                      label="Issuance Date"
                      type="date"
                      placeholder="Issuance Date"
                      value={inssuanceDate && getFormattedDate(inssuanceDate)}
                      onChange={(e) => setInssuanceDate(e.target.value)}
                    />
                  </div>
                  <div className="col-md-3">
                    <CustomInput
                      label="Expiry Date"
                      value={expiryDate && getFormattedDate(expiryDate)}
                      type="date"
                      readOnly={true}
                      onChange={(e) => setExpiryDate(e.target.value)}
                      placeholder="Expiry Date"
                    />
                  </div>
                  <div className="col-md-3">
                    <CustomInput
                      label="Currency"
                      value={currency}
                      readOnly={true}
                      onChange={(e) => setCurrency(e.target.value)}
                      placeholder="Currency"
                    />
                  </div>
                  <div className="col-md-3">
                    <CustomInput
                      label="Claim Expiry Date"
                      value={claimExpiryDate && getFormattedDate(claimExpiryDate)}
                      type="date"
                      readOnly={true}
                      onChange={(e) => setClaimExpiryDate(e.target.value)}
                      placeholder="Claim Expiry Date"
                    />
                  </div>
                  <div className="col-md-3">
                    <CustomInput
                      label="Amount"
                      value={ammount}
                      readOnly={true}
                      onChange={(e) => setAmmount(e.target.value)}
                      placeholder="Amount"
                    />
                  </div>
                  <div className="col-md-3">
                    <CustomInput
                      label="Print Location"
                      value={printLocation}
                      readOnly={true}
                      onChange={(e) => setPrintLoation(e.target.value)}
                      placeholder="Print Location"
                    />
                  </div>
                  <div className="col-md-3">
                    <CustomInput
                      label="Type of BG"
                      readOnly={true}
                      value={typeOfBg}
                      onChange={(e) => setTypeOfBg(e.target.value)}
                      placeholder="Type of BG"
                    />

                  </div>
                  <div className="col-md-3">
                    <CustomInput
                      label="Payable Location"
                      value={paybleLocation}
                      readOnly={true}
                      onChange={(e) => setPaybleLocation(e.target.value)}
                      placeholder="Payable Location"
                    />

                  </div>
                  <div className="col-md-3">
                    <CustomInput
                      readOnly={true}
                      label="Comment"
                      placeholder="Comment"
                      value={bankGuaranteeComment}
                    />
                  </div>
                </div>
              }
            />
          </div>

          <div className="col-md-12">
            <CustomCard
              cardtitle={"Bank Charges"}
              cardboldtitle={"Details"}
              cardbody={
                <div className="row">
                  <div className="col-md-3">
                    <CustomInput
                      readOnly={true}
                      label="BG Charges"
                      value={bgCharges}
                      onChange={(e) => setBgCharges(e.target.value)}
                      placeholder="BG Charges"
                    />
                  </div>
                  <div className="col-md-3">
                    <CustomInput label="GST" readOnly={true} placeholder="GST"
                      value={gst}
                      onChange={(e) => setGst(e.target.value)}
                    />
                  </div>
                  <div className="col-md-3">
                    <CustomInput
                      label="Stamp Charges"
                      value={stampcharges}
                      readOnly={true}
                      onChange={(e) => setStampCharges(e.target.value)}
                      placeholder="Stamp Charges"
                    />
                  </div>
                  <div className="col-md-3">
                    <CustomInput
                      label="BG Charges GL Code"
                      value={bgChargesGlCode}
                      readOnly={true}
                      onChange={(e) => setBgChargesGlCode(e.target.value)}
                      placeholder="BG Charges GL Code"
                    />
                  </div>
                  <div className="col-md-3">
                    <CustomInput
                      label="GST GL Code"
                      value={gstGlCode}
                      readOnly={true}
                      onChange={(e) => setGstGlCode(e.target.value)}
                      placeholder="GST GL Code"
                    />
                  </div>
                  <div className="col-md-3">
                    <CustomInput
                      readOnly={true}
                      label="Comment"
                      placeholder="Comment"
                      value={bankChargesComment}
                    />
                  </div>
                </div>
              }
            />
          </div>

          <div className="col-md-12">
            <CustomCard
              cardboldtitle={"Attachments"}
              cardbody={
                <div className="row">
                  <div className="col-md-3">
                    <CustomInput
                      label="BG Text"
                      readOnly={true}
                      placeholder="Bg Text"
                    />
                    <div className="uploaded-images">
                      {bgText.map((bgText, index) => (
                        <div key={index} style={{ cursor: "pointer", color: "#65a6fa", marginBottom: '8px' }} onClick={() => handleBgTextdownload(bgText)} className="url-download">
                          {bgText}
                        </div>
                      ))}
                    </div>
                  </div>
                  <div className="col-md-3">
                    <CustomInput
                      label="Purchase Order/ RFP"
                      placeholder="Purchase Order/ RFP"
                      readOnly={true}
                    />
                    <div className="uploaded-images">
                      {purchaseOrder.map((order, index) => (
                        <div key={index} style={{ cursor: "pointer", color: "#65a6fa", marginBottom: '8px' }} onClick={() => handlePurchasedownload(order)} className="url-download">
                          {order}
                        </div>
                      ))}
                    </div>
                  </div>
                  <div className="col-md-3">
                    <CustomInput
                      readOnly={true}
                      label="Comment"
                      placeholder="Comment"
                      value={attachmentComment}
                    />
                  </div>
                </div>
              }
            />
          </div>

          {bgrequestCancellation?.length > 0 &&
            <div className="col-md-12">
              {bgrequestCancellation.map((cancellation, index) => {
                let cancellationData;
                if (cancellation?.attachments) {
                  cancellationData = JSON.parse(cancellation?.attachments)
                }

                return <CustomCard
                  cardboldtitle={`Cancellation ${index + 1}`}
                  cardbody={
                    <div className="row">
                      <div className="col-md-3">
                        <CustomInput
                          readOnly={true}
                          label="Claim Expiry Date"
                          placeholder="Claim Expiry Date"
                          type="date"
                          value={cancellation?.cancellationDate?.split?.("T")[0]}
                        />
                      </div>
                      <div className="col-md-3">
                        <CustomInput
                          label="Attachment"
                          placeholder="Browse file"
                          readOnly={true}
                          value={cancellation?.attachments}
                        />
                        {cancellationData && cancellationData.map((item) => {
                          return <div style={{ cursor: "pointer", color: "#65a6fa" }} onClick={() => handleCancellationDownload(item)} className="url-download">
                            {item}
                          </div>
                        })}
                      </div>
                      <div className="col-md-3">
                        <CustomInput
                          readOnly={true}
                          label="BG Charges"
                          value={cancellation?.bgCharge}
                          placeholder="BG Charges"
                        />
                      </div>
                      <div className="col-md-3">
                        <CustomInput label="GST" placeholder="GST"
                          readOnly={true}
                          value={cancellation?.gst}
                        />
                      </div>
                      <div className="col-md-3">
                        <CustomInput
                          readOnly={true}
                          label="Stamp Charges"
                          value={cancellation?.stampCharges}
                          placeholder="Stamp Charges"

                        />
                      </div>
                      <div className="col-md-3">
                        <CustomInput
                          readOnly={true}
                          label="BG Charges GL Code"
                          value={cancellation?.bgChargesGlCode}
                          placeholder="BG Charges GL Code"

                        />
                      </div>
                      <div className="col-md-3">
                        <CustomInput
                          readOnly={true}
                          label="GST GL Code"
                          value={cancellation?.gstGlCode}
                          placeholder="GST GL Code"

                        />
                      </div>

                      <div className="col-md-3">
                        <CustomInput
                          readOnly={true}
                          label="Comment"
                          placeholder="Comment"
                          value={cancellation?.comment}
                        />
                      </div>
                    </div>
                  }
                />
              })}
            </div>}

          {bgrequestAmendment?.length > 0 &&
            <div className="col-md-12">
              {bgrequestAmendment.map((amendment, index) => {
                let ammendmentData;
                if (amendment?.attachments) {
                  ammendmentData = JSON.parse(amendment?.attachments)
                }

                return <CustomCard

                  cardboldtitle={`Amendment ${index + 1}`}
                  cardbody={
                    <div className="row">
                      <div className="col-md-3">
                        <CustomInput
                          readOnly={true}
                          label="Claim Expiry Date"
                          placeholder="Claim Expiry Date"
                          type="date"
                          value={amendment?.claimExpiryDate?.split?.("T")[0]}
                        />
                      </div>
                      <div className="col-md-3">
                        <CustomInput
                          readOnly={true}
                          label="Expiry Date"
                          placeholder="Expiry Date"
                          type="date"
                          min={new Date().toISOString()?.split('T')[0]}
                          value={amendment?.expiryDate?.split?.("T")[0]}
                        />
                      </div>
                      <div className="col-md-3">
                        <CustomInput
                          readOnly={true}
                          label="Clause"
                          placeholder="Clause"
                          value={amendment?.clause}
                        />
                      </div>
                      <div className="col-md-3">
                        <CustomInput
                          readOnly={true}
                          label="Others"
                          placeholder="Others"
                          value={amendment?.others}
                          onChange={(e) => setOther(e.target.value)}
                        />
                      </div>
                      <div className="col-md-3">
                        <CustomInput
                          label="Attachment"
                          placeholder="Browse file"
                          readOnly={true}
                        />
                        {ammendmentData && ammendmentData.map((item) => {
                          return <div style={{ cursor: "pointer", color: "#65a6fa" }} onClick={() => handlePreviousDownload(item)} className="url-download">
                            {item}
                          </div>
                        })}

                      </div>
                      <div className="col-md-3">
                        <CustomInput
                          readOnly={true}
                          label="BG Charges"
                          value={amendment?.bgCharge}
                          placeholder="BG Charges"
                        />
                      </div>
                      <div className="col-md-3">
                        <CustomInput label="GST" placeholder="GST"
                          readOnly={true}
                          value={amendment?.gst}

                        />
                      </div>
                      <div className="col-md-3">
                        <CustomInput
                          readOnly={true}
                          label="Stamp Charges"
                          value={amendment?.stampCharges}
                          placeholder="Stamp Charges"

                        />
                      </div>
                      <div className="col-md-3">
                        <CustomInput
                          readOnly={true}
                          label="BG Charges GL Code"
                          value={amendment?.bgChargesGlCode}
                          placeholder="BG Charges GL Code"

                        />
                      </div>
                      <div className="col-md-3">
                        <CustomInput
                          readOnly={true}
                          label="GST GL Code"
                          value={amendment?.gstGlCode}
                          placeholder="GST GL Code"

                        />
                      </div>
                      <div className="col-md-3">
                        <CustomInput
                          readOnly={true}
                          label="Comment"
                          placeholder="Comment"
                          value={amendment?.comment}
                        />
                      </div>
                    </div>
                  }

                />
              })}
            </div>}

          <div className="col-md-12">
            <CustomCard
              cardboldtitle={"Amendment"}
              cardbody={
                <div className="row">

                  <div className="col-md-3">
                    <CustomInput
                      label="Expiry Date"
                      placeholder="Expiry Date"
                      type="date"
                      min={new Date().toISOString()?.split('T')[0]}
                      value={amendmentexpiryDate}
                      onChange={(e) => setAmendmentExpiryDate(e.target.value)}
                    />
                  </div>
                  <div className="col-md-3">
                    <CustomInput
                      label="Claim Expiry Date"
                      placeholder="Claim Expiry Date"
                      type="date"
                      min={amendmentexpiryDate}
                      value={amendmentclaimExpiryDate}
                      onChange={(e) => setAmendmentClaimExpiryDate(e.target.value)}
                    />
                  </div>
                  <div className="col-md-3">
                    <CustomInput
                      label="Clause"
                      placeholder="Clause"
                      value={clause}
                      onChange={(e) => setClause(e.target.value)}
                    />
                  </div>
                  <div className="col-md-3">
                    <CustomInput
                      label="Others"
                      placeholder="Others"
                      value={other}
                      onChange={(e) => setOther(e.target.value)}
                    />
                  </div>
                  <div className="col-md-3">
                    <CustomInput
                      label="Attachment"
                      placeholder="Browse file"
                      type="file"
                      onChange={handleFileChange}
                    />
                    <div className="uploaded-images">
                      {attachment.map((order, index) => (
                        <div key={index} style={{ cursor: "pointer", color: "#65a6fa", marginBottom: '8px' }} onClick={() => handleAttachmentDownload(order)} className="url-download">
                          {order}
                        </div>
                      ))}
                    </div>

                  </div>
                  <div className="col-md-3">
                    <CustomInput
                      readOnly={true}
                      label="BG Charges"
                      value={amendmentbgCharges}
                      onChange={(e) => setAmendmentBgCharges(e.target.value)}
                      placeholder="BG Charges"
                    />
                  </div>
                  <div className="col-md-3">
                    <CustomInput label="GST" placeholder="GST"
                      value={amendmentgst}
                      onChange={(e) => setAmendmentGst(e.target.value)}
                      readOnly={true}
                    />
                  </div>
                  <div className="col-md-3">
                    <CustomInput
                      label="Stamp Charges"
                      value={amendmentstampcharges}
                      onChange={(e) => setAmendmentStampCharges(e.target.value)}
                      placeholder="Stamp Charges"
                      readOnly={true}
                    />
                  </div>
                  <div className="col-md-3">
                    <CustomInput
                      label="BG Charges GL Code"
                      value={amendmentbgChargesGlCode}
                      onChange={(e) => setAmendmentBgChargesGlCode(e.target.value)}
                      placeholder="BG Charges GL Code"
                      readOnly={true}
                    />
                  </div>
                  <div className="col-md-3">
                    <CustomInput
                      label="GST GL Code"
                      value={amendmentgstGlCode}
                      onChange={(e) => setAmendmentGstGlCode(e.target.value)}
                      placeholder="GST GL Code"
                      readOnly={true}
                    />
                  </div>
                  <div className="col-md-3">
                    <CustomInput
                      label="Comment"
                      placeholder="Comment"
                      value={ammendmentComment}
                      onChange={(e) => setAmmendmentComment(e.target.value)}

                    />
                  </div>
                </div>
              }
            />
          </div>

          <div className="col-md-12">
            <CustomCard
              cardtitle={"Audit"}
              cardboldtitle={" trail"}
              toprightview={
                <button className="export-btn">
                  <ExcelFile element={<div> <img width={20} className="me-2" src={require("../../assets/images/excel.png")} />Export</div>}>
                    <ExcelSheet data={getAuditDetail?.map((item, i) => ({
                      ...item, index: i + 1, role: item?.role,
                      created_at: item?.created_at && `${item.created_at?.split("T")[0]} ${item.created_at?.split("T")[1]?.split(".")[0].substring(0, 5)}`,
                    }))} name="Sheet">
                      <ExcelColumn label="Status Date & Time" value="created_at" />
                      <ExcelColumn label="Name" value="userName" />
                      <ExcelColumn label="Role" value="role" />
                      <ExcelColumn label="Action Required" value="status" />
                    </ExcelSheet>
                  </ExcelFile>
                </button>
              }
              cardbody={
                <React.Fragment>
                  <div className="table-responsive">
                    <table className="table">
                      <thead className="table-customlight">
                        <tr>
                          <th className="auditTrail">Status Date & Time</th>
                          <th className="auditTrail">Name</th>
                          <th className="auditTrail">Role</th>
                          <th className="auditTrail">Action Required</th>
                        </tr>
                      </thead>
                      {getAuditDetail?.length > 0 && (
                        <tbody>
                          {getAuditDetail?.map((item, i) => {
                            return (
                              <tr key={item?.requestNumber}>
                                <td scope="row"><FormatDate dateString={item?.created_at} /> </td>
                                <td>{item?.userName}</td>
                                <td>{item?.role}</td>
                                <td className="status-box"><span className="status"></span>{item?.status}</td>

                              </tr>)
                          })}
                        </tbody>)}
                    </table>
                  </div>
                </React.Fragment>
              }
            />
          </div>

          <div className="col-md-12">
            <CustomCard
              cardtitle={""}
              cardboldtitle={"Chat"}
              cardbody={
                <div className="row">
                  {chat?.length > 0 && (
                    <>
                      {chat?.map((item, i) => (
                        <div key={i} className="col-md-12 chat-left">
                          <div className="chat-container">
                            <h4 className="chat-username">{item?.userName}({item?.userRole})</h4>
                            <div className="chat-username-box">
                              <p className="chat-massege">{item?.message}</p>
                              <p className="chat-time">
                                <FormatDate dateString={item?.created_at} />
                              </p>
                            </div>
                          </div>
                        </div>
                      ))}
                      <div className="col-md-6 " style={{ width: "44%" }}>
                        <CustomInput
                          label="Comment Box"
                          value={chatComment}
                          onChange={(e) => setChatComment(e.target.value)}
                          placeholder="Comment Box"
                        />
                      </div>
                      <div className="col-md-2 chat-button">
                        <CustomButton
                          style={"btn--outline me-3"}
                          children={"Send"}
                          onClick={handleSendMassege}
                        />
                      </div>
                    </>
                  )}
                </div>
              }
            />
          </div>


         {Viewer !=="Viewer" && <div className="col-md-12 text-end">
            <CustomButton style={"btn--outline me-3"} children={"Submit for Approval"} onClick={createAmendment} />
          </div>}

        </div>
      </div>
    </div>
  );
};

export default BgAmendment;

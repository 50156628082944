import React, { useState } from "react";
import "./authStyle.scss";
import logo from "../../assets/images/logo.png";
import CustomButton from "../../component/Button/Button";
import CustomInput from "../../component/CustomInput";
import { useStores } from "../../stores";
import notify from "../../shared/notify";
import Loader from "../../component/helper/Loader/loader";

const Login = () => {
  const { authStore } = useStores()
  const [email, setEmail] = useState("")
  const [emailReset, setEmailReset] = useState("")
  const [password, setPassword] = useState("")
  const [showpassword, setShowpassword] = useState("password")
  const [isLoading, setIsLoading] = useState(false)
  const [sendUrl, setSendUrl] = useState(false)
  
  const handleKeyPress = (e) => {
    if (e.key === "Enter") {
      handleLogin();
    }
  };

  const handleLogin = async () => {
    
    if (email === "") {
      notify("Email cannot be empty", "danger");
      return;
    }
    if (password === "") {
      notify("Password cannot be empty", "danger");
      return;
    }

    let payload = {
      email: email,
      password: password
    }
    setIsLoading(true)
    sessionStorage.removeItem("candidate")
    await authStore.login(payload, (response) => {
      if (response?.data?.meta?.status === "success") {
        setIsLoading(false)
        notify(response?.data?.meta?.message)
        sessionStorage.setItem('isAuth', true)
        sessionStorage.setItem("token", response?.data?.data?.token);
        sessionStorage.setItem("role", response?.data?.data?.user?.roles);
        sessionStorage.setItem("roleId", response?.data?.data?.user?.id);
        sessionStorage.setItem("name", response?.data?.data?.user?.name)
        sessionStorage.setItem("email", response?.data?.data?.user?.email)
        sessionStorage.setItem("line_of_service",response?.data?.data?.user?.line_of_service)
        sessionStorage.setItem("Approver_role" , response?.data?.data?.user?.user_department)
        sessionStorage.setItem("candidate" , JSON.stringify(response?.data?.data?.user))
        setEmail("")
        setPassword("")
        if(response?.data?.data?.user?.is_candidate_application_link_send === true){
          let redirectTo = "";
          redirectTo = "/candidate-applicant-form";
          window.location.href = redirectTo;
        }else{
          let redirectTo = "";
          redirectTo = "/home";
          window.location.href = redirectTo;
        }
       

      } else {
        notify(response?.data?.meta?.message, "danger")
        setIsLoading(false)
        setEmail("")
        setPassword("")
      }

    }, (error) => {

      notify(error?.response?.data?.meta?.message, "danger")
      setIsLoading(false)
      setEmail("")
      setPassword("")
    })

  }

  const togglePassword = () => {
    if (showpassword === "password") {
      setShowpassword("text")
      return;
    }
    setShowpassword("password")
  }

  const handleSendUrl = async () => {
    setSendUrl(true)
  }

  const handleResetPassword = async () => {
    if(emailReset === "" || emailReset === undefined || emailReset === null){
     return notify("Email is not allowed to be empty" , "danger")
    }
    let payload = {
      email: emailReset
    }

    setIsLoading(true)
    await authStore.sendPasswordUrl(payload, (response) => {
      if (response?.data?.meta?.status === "success") {
        notify(response?.data?.meta?.message)
        setIsLoading(false)
        setEmailReset("")

      } else {
        notify(response?.data?.meta?.message, "danger")
        setIsLoading(false)
        setEmailReset("")
      }
    }, (error) => {

      notify(error?.response?.data?.meta?.message, "danger")
      setIsLoading(false)
      setEmailReset("")
    })
  }

  return (
    <div className="login-mainContainer">
      {isLoading && <Loader />}
      <div className="login-container">
        <div className="login-box">
          <img src={logo} className="logo-style" alt="" />
          {sendUrl ? <>
            <div>
              <h2 className="login-heading">Send Reset Password Link</h2>
              <div className="heading-underline"></div>
            </div>
            <div className="row login-dashboard" style={{marginTop:"20px"}}>
              <div className="col-md-12 text-left login-text">
                <CustomInput
                  label="Email"
                  value={emailReset}
                  onChange={(e) => setEmailReset(e.target.value)}
                  placeholder="Email"
                />
              </div>
            </div>
            <CustomButton children={"Reset Password"} onClick={handleResetPassword} />
          </>
            : <>
              <div>
                <h2 className="login-heading">Log in to your SAEL email account</h2>
                <div className="heading-underline"></div>
              </div>
              <div className="row login-dashboard">
                <div className="col-md-12 login-text">
                  <CustomInput
                    label="Email"
                    value={email}
                    onChange={(e) => setEmail(e.target.value)}
                    placeholder="Email"
                  />
                </div>
                <div className="col-md-12 login-text" style={{ position: "relative" }}>
                  <CustomInput
                    label="Password"
                    type={showpassword}
                    value={password}
                    onChange={(e) => setPassword(e.target.value)}
                    placeholder="Password"
                    onKeyDown={handleKeyPress} 
                  />
                  <div className="password-hideshow">
                    <div type="button" onClick={togglePassword}>
                      {showpassword === "password" ?
                        <img src={require('../../assets/images/eye-slash-solid.png')} alt="" />
                        :
                        <img src={require('../../assets/images/eye-solid.png')} alt="" />
                      }
                    </div>
                  </div>
                </div>
              </div>
              <div className="col-md-12 text-center">
                <CustomButton children={"Login"} onClick={handleLogin} />
                <div className="col-md-12 text-center mt-4" onClick={handleSendUrl} style={{ cursor: "pointer", color: "#65a6fa" }} >  Forget Password ? </div>
              </div>
            </>
          }



        </div>
      </div>
    </div>
  );
};

export default Login;

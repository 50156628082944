import React, { useState } from "react";
import Modal from "react-bootstrap/Modal";
import "./style.scss";
import crossImage from "../../assets/images/close (4).png";
import CustomButton from "../Button/Button";
import Customselect from "../CustomSelect";
import Textarea from "../CustomeTextarea";

const ConsultantModal = (props) => {

    return (
        <Modal {...props} centered className="sucess-content sucess_content_modal">
            <Modal.Body className="sucess-modal pb-4">
                <div className="modal-container">
                    <img
                        src={crossImage}
                        alt=""
                        className="cross-img"
                        onClick={() => props.hide()}
                    />
                    <div className="modal_submit ">
                        <h4 className="success_title1" style={{ textAlign: "center" }}>
                            Send to outsource consultant
                        </h4>
                        <div className="addName-model">
                            <div className="container-fluid">
                                <div className="row">
                                    <div className="col-md-12 text-start">
                                        <Customselect 
                                        label={'Select Outsource Consultant'} 
                                        optionview={props.optionview}
                                        onChange={props.selectOnChange}
                                        require  
                                        />
                                    </div>
                                    <div className="col-md-12">
                                        <Textarea 
                                            style={'textarea_bg'} 
                                            placeholder={'Please write a comment....'} 
                                            textvalue={props.textvalue}
                                            onChange={props.textOnChange}
                                        />
                                    </div>
                                </div>
                            </div>

                        </div>
                        <CustomButton
                            children={"Submit"}
                            style={'btn--approved--solid'}
                            onClick={props.submit}
                        // onClick={Approval}
                        />
                    </div>
                </div>

            </Modal.Body>
        </Modal>
    );
};

export default ConsultantModal;

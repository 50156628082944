import React from "react";
import "./tabStyle.scss";
import CustomCard from "../CustomeCard";

const RequestTab = (props) => {
  const {
    showLcRequest,
    showBgRequest,
    showImprestRequest,
    lcRequest,
    bgRequest,
    imprestRequest,
  } = props;
  return (
    <div className="tab-container">
      <CustomCard
        cardtitle={"Select "}
        cardboldtitle={"Request"}
        cardbody={
          <div>
            <div className="tab-container">
              <button className={showLcRequest && "active"} onClick={lcRequest}>
                LC Requests
              </button>
              <button className={showBgRequest && "active"} onClick={bgRequest}>
                BG Requests
              </button>
              {/* <button
                className={showImprestRequest && "active"}
                onClick={imprestRequest}
              >
                Imprest Requests
              </button> */}
            </div>
          </div>
        }
      />
    </div>
  );
};

export default RequestTab;

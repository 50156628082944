import React, { useState } from "react";
import Modal from "react-bootstrap/Modal";
import "./style.scss";
import successImage from "../../assets/images/checked@2x.png";
import crossImage from "../../assets/images/close (4).png";
import notify from "../../shared/notify";
import { useStores } from "../../stores";
import CustomButton from "../Button/Button";
import {useNavigate } from "react-router-dom";
import Loader from "../helper/Loader/loader";

const SubmitModal = (props) => {
  const [approverComments, setApproverComments] = useState("")
  const { mainStore } = useStores()
  const navigate = useNavigate()
  const [isLoading, setIsLoading] = useState(false)

  const Approval = async () => {
    setIsLoading(true)
    let payload = {
      isApproved: true,
      comment:approverComments
    }
    await mainStore.approveBgInssuance(props?.requestNumber, payload, (response) => {
      if (response?.data?.meta?.status === "success") {
        notify(response?.data?.meta?.message)
        navigate("/approver-journey-dashboard")
        setIsLoading(false)
        props.hide()
      }
      else {
        notify(response?.data?.meta?.message, "danger")
        setIsLoading(false)
        props.hide()
      }
    }, (error) => {
      notify(error?.response?.data?.meta?.message, "danger")
      setIsLoading(false)
      props.hide()
    })
  }
  return (
    <Modal {...props} centered className="sucess-content">
      <Modal.Body className="sucess-modal">
      {isLoading && <Loader />}
        <div className="modal-container">
          <img
            src={crossImage}
            alt=""
            className="cross-img"
            onClick={() => props.hide()}
          />
          <div className="moodal">
            <img src={successImage} alt="" className="modal-img" />
            <h4 className="modal-heading p-2" style={{ textAlign: "center" }}>
              You have approved the request
            </h4>
            <div className="addName-model" mt-3>
              <textarea
                cols="10"
                className="form-control p-3"
                type="text"
                value={approverComments}
                onChange={(e) => setApproverComments(e.target.value)}
                placeholder="Please add the comment for the requestor*"
              />
            </div>
            <CustomButton
            children={"Submit"}
            style={"mt-3"}
            onClick={Approval}
          />
          </div>
        </div>

      </Modal.Body>
    </Modal>
  );
};

export default SubmitModal;

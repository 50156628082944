
import React, { useState, useEffect } from "react";
import Header from "../../../../component/Header";
import "./style.scss";
import Loader from "../../../../component/helper/Loader/loader";
import { useNavigate } from "react-router-dom";
import { useStores } from "../../../../stores";
import notify from "../../../../shared/notify";
import ComplaintDashboard from "../../../../component/CRMModuleComponent/complaintDashboard";

const limit = [
  { option: 10, value: 10 },
  { option: 100, value: 100 },
  { option: 500, value: 500 },
  { option: 1000, value: 1000 },
];

const RaiseATicket = () => {
  const [isLoading, setIsLoading] = useState(false);
  const [complaintData, setComplaintData] = useState([]);
  const [allComplainData, setAllComplainData] = useState([]);
  const navigate = useNavigate();
  const { mainStore, crmStore } = useStores();
  const [page, setPage] = useState(parseInt(sessionStorage.getItem('currentPage')) || 1);
  const [pageLimit, setPageLimit] = useState(parseInt(sessionStorage.getItem('pageLimit')) || 10);
  const [categoryList, setCategoryList] = useState([]);
  const [selectCategory, setSelectCategory] = useState("");
  const [priorityList, setPriorityList] = useState([]);
  const [selectedPriority, setSelectedPriority] = useState("");
  let fetchRole = sessionStorage.getItem("role");
  const role = fetchRole?.split(",");
  let sessions = sessionStorage.getItem("candidate");
  let session = JSON.parse(sessions);
  let crmApprover = ["L2", "L3"]


  useEffect(() => {
    FetchedComplain();
    fetchAllCategories();
    getPriorities();
  }, [page, pageLimit]);

  const FetchedComplain = async () => {
    let payload = {
      limit: pageLimit,
      filters: {
        // agent_id:session?.id,
      },
      pageNumber: page,
    };
    if ((role?.includes("Inhouse consultant")) || (role?.includes("Outsourced consultant/Vendor"))
      || (session?.support_levels?.some(element => crmApprover.includes(element)))) {
      payload.filters.agent_id = session?.id
    }
    if ((role?.includes("Employee")) ){
      payload.filters.reporter_id = session.id
  }

    CallFetchedComplain(payload);
  };

  const CallFetchedComplain = async (payload) => {
    setIsLoading(true);
    await crmStore.getAllCmsComplaints(payload, (res) => {
      if (res?.data?.meta?.status === "success") {
        setComplaintData(res?.data?.data?.filteredData?.results);
        setAllComplainData(res?.data?.data);
        setIsLoading(false);
      }
    }, (err) => {
      notify(err?.response?.data?.meta?.message, "danger");
      setIsLoading(false);
    });
  };

  const handlePageSubmitt = async (index) => {
    const newPage = index.selected + 1;
    setPage(newPage);
    sessionStorage.setItem('currentPage', newPage);
    let payload = {
      limit: pageLimit,
      filters: {
        // agent_id:session?.id,
      },
      pageNumber: newPage,
    };
    if ((role?.includes("Inhouse consultant")) || (role?.includes("Outsourced consultant/Vendor"))
      || (session?.support_levels?.some(element => crmApprover.includes(element)))) {
      payload.filters.agent_id = session?.id
    }
    if ((role?.includes("Employee")) ){
      payload.filters.reporter_id = session.id
  }
    CallFetchedComplain(payload);
  };

  const limitChangeHandlerr = async (index) => {
    setPageLimit(index);
    sessionStorage.setItem('pageLimit', index);
    let payload = {
      limit: index,
      filters: {
        // agent_id:session?.id,

      },
      pageNumber: page,
    };
    if ((role?.includes("Inhouse consultant")) || (role?.includes("Outsourced consultant/Vendor"))
      || (session?.support_levels?.some(element => crmApprover.includes(element)))) {
      payload.filters.agent_id = session?.id
    }
    if ((role?.includes("Employee")) ){
      payload.filters.reporter_id = session.id
  }

    CallFetchedComplain(payload);
  };

  useEffect(() => {
    // Load filters from sessionStorage
    const savedCategory = sessionStorage.getItem('selectedCategory');
    const savedPriority = sessionStorage.getItem('selectedPriority');

    if (savedCategory) {
      setSelectCategory(savedCategory);
    }
    if (savedPriority) {
      setSelectedPriority(savedPriority);
    }

    let payload = {
      limit: pageLimit,
      filters: {

        category_id: savedCategory || "",
        priority_id: savedPriority || "",
      },
      pageNumber: page,
    };
    if ((role?.includes("Inhouse consultant")) || (role?.includes("Outsourced consultant/Vendor"))
      || (session?.support_levels?.some(element => crmApprover.includes(element)))) {
      payload.filters.agent_id = session?.id
    }
    if ((role?.includes("Employee")) ){
      payload.filters.reporter_id = session.id
  }
    // FetchedComplain();
    CallFetchedComplain(payload);
    fetchAllCategories();
    getPriorities();
  }, [page, pageLimit]);



  const handleFilterr = async () => {
    sessionStorage.setItem('selectedCategory', selectCategory);
    sessionStorage.setItem('selectedPriority', selectedPriority);
    let payload = {
      limit: pageLimit,
      filters: {
        // agent_id:session?.id,
      },
      pageNumber: page,
    };

    if (selectedPriority) {
      payload.filters.priority_id = selectedPriority;
    }
    if (selectCategory) {
      payload.filters.category_id = selectCategory;
    }
    if ((role?.includes("Inhouse consultant")) || (role?.includes("Outsourced consultant/Vendor"))
      || (session?.support_levels?.some(element => crmApprover.includes(element)))) {
      payload.filters.agent_id = session?.id
    }
    if ((role?.includes("Employee")) ){
      payload.filters.reporter_id = session.id
  }

    CallFetchedComplain(payload);
  };

  const handleResett = async () => {
    setSelectCategory("");
    setSelectedPriority("");
    setPage(1);
    setPageLimit(10);
    sessionStorage.removeItem('selectedCategory');
    sessionStorage.removeItem('selectedPriority');
    sessionStorage.removeItem('currentPage');
    sessionStorage.removeItem('pageLimit');

    let payload = {
      limit: 10,
      filters: {
        // agent_id: session?.id,
      },
      pageNumber: 1,
    };
    if ((role?.includes("Inhouse consultant")) || (role?.includes("Outsourced consultant/Vendor"))
      || (session?.support_levels?.some(element => crmApprover.includes(element)))) {
      payload.filters.agent_id = session?.id
    }
    if ((role?.includes("Employee")) ){
      payload.filters.reporter_id = session.id
  }
    CallFetchedComplain(payload);
  };


  const fetchAllCategories = () => {
    setIsLoading(true);
    crmStore.getAllCategories((status, res) => {
      if (res?.meta?.status === "success") {
        setCategoryList(res.data.categories);
        setIsLoading(false);
      } else {
        setIsLoading(false);
      }
    }, (error) => {
      notify(error?.response?.data?.meta?.message, "danger");
      setIsLoading(false);
    });
  };

  const getPriorities = () => {
    crmStore.getPriorityNoFilters((status, res) => {
      if (res?.meta?.status === "success") {
        setPriorityList(res?.data?.getAllComplaintPriorityLevels);
      } else {
        console.log(res?.data?.meta?.message, "setDeparmentList");
      }
    });
  };

  return (
    <div className="main-container raiseTicket_container_crm">
      {isLoading && <Loader />}
      <div className="right-view">
        <Header title={"Tickets,"} />
        <div className="container-fluid px-0">
          <ComplaintDashboard
            allComplainData={allComplainData}
            complaintData={complaintData}
            handlePageSubmit={handlePageSubmitt}
            page={page}
            pageLimit={pageLimit}
            limit={limit}
            limitChangeHandler={limitChangeHandlerr}
            handleFilter={handleFilterr}
            handleReset={handleResett}
            handleView={(item) => navigate('/view-ticket', { state: { item } })}
            handleEdit={(item) => navigate('/edit-ticket-end-user', { state: { item } })}
            categoryList={categoryList}
            selectCategory={selectCategory}
            handleSelectCategory={(e) => setSelectCategory(e)}
            handlePriority={(e) => setSelectedPriority(e)}
            selectedPriority={selectedPriority}
            priorityList={priorityList}
            FetchedComplain={FetchedComplain}
          />
        </div>
      </div>
    </div>
  );
};

export default RaiseATicket;

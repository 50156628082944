import React from "react";
import "./roleStyle.scss";

const RoleCard = (props) => {
  return (
    <div className="role-card-container">
      <input type="checkbox" onChange={props.onClick} className="role-checkbox" id="lc" name="lc" />
      <label onClick={props.onClick} className="role-card" for="lc">
        <div className="rolecard-leftside">
          <div className="label-view">
            <h3>{props.role}</h3>
            <p>{props.requests} Requests</p>
          </div>
        </div>
        <div className="rolecard-rightside">
          <img src={props.img} />
        </div>
      </label>
    </div>
  );
};

export default RoleCard;

import React, { useRef } from "react";
import { useDownloadExcel } from "react-export-table-to-excel";
import CustomCard from "../CustomeCard";
import ReactExport from "react-export-excel";

const ExcelFile = ReactExport.ExcelFile;
const ExcelSheet = ReactExport.ExcelFile.ExcelSheet;
const ExcelColumn = ReactExport.ExcelFile.ExcelColumn;

const Imprest = (props) => {

  return (
    <CustomCard
      cardtitle={"BG"}
      cardboldtitle={"Request"}
      toprightview={
        <div>
           {props?.fetchedBgData?.length > 0 && (
          <button  className="export-btn">
            <ExcelFile element={<div><img width={20} className="me-2" src={require("../../assets/images/excel.png")} />Export</div>}>
              <ExcelSheet data={props.fetchedBgData?.map((item, i) => ({ ...item, index: i + 1, issuanceDate: item.issuanceDate?.split("T")[0],
              expiryDate:item?.expiryDate?.split("T")[0] , claimExpiryDate:item?.claimExpiryDate?.split("T")[0]
            
            }))} name="Sheet">
                <ExcelColumn label="Request Number" value="requestNumber" />
                <ExcelColumn label="Reference Number" value="referenceNumber" />
                <ExcelColumn label="Currency" value="currency" />
                <ExcelColumn label="Amount" value="amount" />
                <ExcelColumn label="Applicant Name" value="applicantName" />
                <ExcelColumn label="Beneficiary Name" value="beneficiaryName" />
                <ExcelColumn label="Issuance Date" value="issuanceDate" />
                <ExcelColumn label="Expiry Date" value="expiryDate" />
                <ExcelColumn label="Claim Expiry Date" value="claimExpiryDate" />
              </ExcelSheet>
            </ExcelFile>
          </button>)}
        </div>
      }
      cardbody={
        <React.Fragment>
          <div class="table-responsive">
            <table class="table">
              <thead class="table-customlight">
                <tr>
                  <th scope="col">Request Number</th>
                  <th scope="col">Reference Number</th>
                  <th scope="col">Currency</th>
                  <th scope="col">Amount</th>
                  <th scope="col">Applicant Name</th>
                  <th scope="col">Beneficiary Name</th>
                  <th scope="col">Issuance Date</th>
                  <th scope="col">Expiry Date</th>
                  <th scope="col">Claim Expiry Date</th>
                </tr>
              </thead>
              {props.fetchedBgData?.length > 0 && (
                <tbody>
                  {props.fetchedBgData.map((item, i) => {
                    return (
                      <tr key={item.requestNumber}>
                        <td
                          onClick={(e) => props.handlebgModal(e, item)}
                          scope="row"
                          className="text-link"
                        >
                          {item?.requestNumber}
                        </td>
                        <td>{item?.referenceNumber}</td>
                        <td>{item?.currency}</td>
                        <td>{item?.amount}</td>
                        <td>{item?.applicantName}</td>
                        <td>{item?.beneficiaryName}</td>
                        <td>
                          {item?.issuanceDate &&
                            new Date(item?.issuanceDate).toLocaleDateString(
                              "en-GB"
                            )}
                        </td>
                        <td>
                          {item?.expiryDate &&
                            new Date(item?.expiryDate).toLocaleDateString(
                              "en-GB"
                            )}
                        </td>
                        <td>
                          {item?.claimExpiryDate &&
                            new Date(item?.claimExpiryDate).toLocaleDateString(
                              "en-GB"
                            )}
                        </td>
                      </tr>
                    );
                  })}
                </tbody>
              )}
            </table>
          </div>
        </React.Fragment>
      }
    />
  );
};

export default Imprest;

import React, { useState } from "react";
import Modal from "react-bootstrap/Modal";
import "./style.scss";
import successImage from "../../assets/images/checked@2x.png";
import crossImage from "../../assets/images/close (4).png";
import CustomButton from "../Button/Button";
import Customselect from "../CustomSelect";
import Textarea from "../CustomeTextarea";

const TicketSubmitModal = (props) => {
        
    return (
        <Modal {...props} centered className="sucess-content sucess_content_modal">
            <Modal.Body className="sucess-modal py-5">
                <div className="modal-container">
                    <img
                        src={crossImage}
                        alt=""
                        className="cross-img"
                        onClick={() => props.hide()}
                    />
                    <div className="modal_submit ">
                        <img src={successImage} alt="" className="modal-img" />
                        <h4 className="success_title" style={{ textAlign: "center" }}>
                            Thank You For Submitting Solution
                        </h4>
                        <div className="addName-model" mt-3>
                            <div className="container-fluid">
                                <div className="row">
                                    <div className="col-md-4 text-start">
                                        <Customselect 
                                            label={'Select Status'} 
                                            optionview={props.optionview}
                                            onChange={props.selectOnChange}
                                            require 
                                        />
                                    </div>
                                    <div className="col-md-12">
                                        <Textarea 
                                            style={'textarea_bg'} 
                                            placeholder={'Please write a comment....'}
                                            textvalue={props.textvalue}
                                            onChange={props.textOnChange}
                                        />
                                    </div>
                                </div>
                            </div>

                        </div>
                        <CustomButton
                            children={"Submit"}
                            style={'btn--approved--solid'}
                            onClick={props.submit}
                        />
                    </div>
                </div>

            </Modal.Body>
        </Modal>
    );
};

export default TicketSubmitModal;

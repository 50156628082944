import React, { useState } from "react";
import Modal from "react-bootstrap/Modal";
import successImage from "../../../assets/images/checked@2x.png";
import crossImage from "../../../assets/images/close (4).png";
import CustomButton from "../../Button/Button";
import Customselect from "../../CustomSelect";

const ApproveCandidateModal = ({ handleSelectedInterviewer,selectedInterviewer, hide, show, title, title1, options, handleSelect, isSelected, interviewList ,handleApprovedModal}) => {



    return (
        <Modal show={show} centered className="sucess-content">
            <Modal.Body className="sucess-modal">
                <div className="modal-container">
                    <img
                        src={crossImage}
                        alt=""
                        className="cross-img"
                        onClick={() => hide()}
                    />
                    <div className="row moodal">
                        <img src={successImage} alt="" className="modal-img" />
                        <h5 className="text-center modal-text">{title}</h5>
                        <span className="mt-1 text-center ">{title1}</span>

                        <div className='mt-4'>
                            <Customselect
                                label="Select"
                                value={isSelected}
                                onChange={(e) => handleSelect(e.target.value)}
                                optionview={options?.map((option) => {
                                    return (
                                        <option value={option?.value}>{option?.label}</option>
                                    );
                                })}
                            />
                        </div>

                        {isSelected !== "No" && <div className='mt-2'>
                            <Customselect
                                label="Interviewer"
                                value={selectedInterviewer}
                                onChange={(e) => handleSelectedInterviewer(e.target.value)}
                                optionview={interviewList?.map((option) => {
                                    return (
                                        <option value={option?.id}>{option?.name}</option>
                                    );
                                })}
                            />
                        </div>}


                        <div className="text-center">
                            <CustomButton
                            onClick={handleApprovedModal}
                                children={"Submit"}
                                style={"btn--approved--solid  mt-3"}
                            />
                        </div>
                    </div>

                </div>
            </Modal.Body>
        </Modal>
    );
};

export default ApproveCandidateModal;

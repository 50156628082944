import React, { useState } from "react";
import Modal from "react-bootstrap/Modal";
// import "./style.scss";
import cancellationImage from "../../../assets/images/rejected@2x.png"
import crossImage from "../../../assets/images/close (4).png"
import CustomButton from "../../Button/Button";


const RejectCandidateModal = (props) => {

  

    return (
        <Modal show={props?.show} centered className="sucess-content">
            <Modal.Body className="sucess-modal">
                <img src={crossImage} alt="" className="cross-img" onClick={() => props.hide()} />
                <div className="moodal" >
                    <img src={cancellationImage} alt="" className="modal-img" />
                    <h5 className="modal-texts">OOPS!</h5>
                    <h5 className="mt-1 modal-text">{props?.title}</h5>
                </div>
            </Modal.Body>
        </Modal>
    );
}
export default RejectCandidateModal;

import React from "react";
import "./customselect.scss";

const Customselect = (props) => {
  const { label, blankLabel, value, onChange, style, optionview, readOnly } = props;
  return (
    <div className="customselect-container">
      <div className="custom-select">
        <label>
          {label}
          {props.require && <span className="text-danger">*</span>}
        </label>
        <select
          className={style}
          onChange={onChange}
          style={{ backgroundColor: readOnly ? '#e3e3e3' : 'white' }}
          value={value}
          disabled={readOnly}
        >
          {!blankLabel && <option value="">Please Select</option>}
          {optionview}
        </select>
      </div>
    </div>
  );
};

export default Customselect;

import React, { useEffect, useState } from 'react'
import Header from '../../../component/Header'
import ReactPaginate from "react-paginate";
import './style.scss'
import { useLocation, useNavigate } from "react-router-dom"
import CustomCard from '../../../component/CustomeCard';
import CustomInput from '../../../component/CustomInput';
import CustomButton from '../../../component/Button/Button';
import Customselect from '../../../component/CustomSelect';
import { useStores } from '../../../stores';
import notify from '../../../shared/notify';
import Loader from '../../../component/helper/Loader/loader';
import ShowError from '../../../component/CRMModuleComponent/ShowErrModal';

const limit = [
    {
        option: 10,
        value: 10,
    },
    {
        option: 100,
        value: 100,
    },
    {
        option: 500,
        value: 500,
    },
    {
        option: 1000,
        value: 1000,
    },
];

const GenerateApplicantLink = () => {
    const { crmStore } = useStores()
    const [page, setPage] = useState(1)
    const [pageLimit, setPageLimit] = useState(10)
    const navigate = useNavigate()
    const[isLoading , setIsLoading] = useState(false)
    const [fullName, setFullName] = useState("")
    const [emailId, setEmailId] = useState("")
    const [mobNumber, setMobNumber] = useState("")
    const [dateOfJoining, setDateOfJoining] = useState("")
    const [timeOfMeeting, setTimeOfMeeting] = useState("")
    const [expiryDateLink, setExpiryDateLink] = useState("")
    const [departmentList, setDeparmentList] = useState([])
    const [selectedDepartment, setSelectedDepartment] = useState("")
    const [interviewList, setInterviewList] = useState([])
    const [selectedInterview, setSelectedInterview] = useState("")
    const [showModal , setShowModal] = useState(false)
    const[title1,setTitle1] = useState("")
  const [item , setItem ] = useState()

    useEffect(() => {
        handleDepartment()
        fetchInterviewer()
    }, [])

    const fetchInterviewer = async () => {
        await crmStore.interviewer((status, res) => {
            if (res?.status === "success") {
                setInterviewList(res?.data)
            } else {
                console.log(res?.data?.meta?.message, "setDeparmentList")
            }
        })
    }

    const handleDepartment = async () => {
        await crmStore.getDeparments((status, res) => {
            if (res?.meta?.status === "success") {
                setDeparmentList(res?.data)
            } else {
                console.log(res?.data?.meta?.message, "setDeparmentList")
            }
        })
    }

  
    

    const validateEmail = (email) => {
        const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
        return emailRegex.test(email);
      };
    
      const validateContact = (contact) => {
        const contactRegex = /^[0-9]{10}$/; 
        return contactRegex.test(contact);
      };

    const handleSumbit = async () => {
        if (!validateEmail(emailId)) {
            notify('Please enter a valid email address', 'danger');
            return;
        }
    
        if (!validateContact(mobNumber)) {
            notify('Please enter a valid 10-digit contact number', 'danger');
            return;
        }
      

        let payload = {
            full_name: fullName,
            email_id: emailId,
            contact: mobNumber,
            department: selectedDepartment,
            interviewer_id: selectedInterview,
            date_of_the_meeting: dateOfJoining,
            time_of_the_meeting: timeOfMeeting,
            expiry_of_the_link: expiryDateLink
        }

        console.log(payload);
        
   setIsLoading(true)
        await crmStore.sendCandateUrl(payload, (res) => {
            if (res?.data?.meta?.status === "success") {
                notify(res?.data?.meta?.message)
                navigate("/hr-portal-dashboard")
                setFullName("")
                setDateOfJoining("")
                setEmailId("")
                setMobNumber("")
                setSelectedDepartment("")
                setExpiryDateLink("")
                setIsLoading(false)
                sessionStorage.removeItem("currentHrPage")
                sessionStorage.removeItem("pageHrLimit")
            }else if (res?.data?.meta?.status === "existing candidate details"){
                setShowModal(true)
                setTitle1(res?.data?.meta?.message)
                setItem(res?.data?.data?.existingCandidate)
                setIsLoading(false)
            }
            else {
                notify(res?.data?.meta?.message, "danger")
                setIsLoading(false)

            }
        }, (error) => {
            notify(error?.response?.data?.message, "danger")
            setIsLoading(false)

        })
    }

   
    const handleTimeChange = (event) => {
        const timeValue = event.target.value;
        // const [hours, minutes, seconds] = timeValue?.split(':');

        // let hoursFormatted = hours % 12 || 12; 
        // let period = hours >= 12 ? 'PM' : 'AM'; 

        // let formattedTime = `${hoursFormatted}:${minutes}:${seconds} ${period}`;
        
        setTimeOfMeeting(timeValue);
    };


    const handleView = () =>{
        navigate('/schedule-applicant-interview', { state: { item } })
    }

    return (
        <div className="main-container">
            {isLoading && <Loader />}
            <div className="right-view">
                <Header title={"Interview Application ,"} />
                <div className='row'>
                    <div className='col-md-12'>
                        <CustomCard
                            cardtitle={"Applicant"}
                            cardboldtitle={"Details"}
                            cardbody={
                                <div className='row'>
                                    <div className='col-md-3'>
                                        <CustomInput
                                            label="Full Name"
                                            require={true}
                                            placeholder='Full Name'
                                            value={fullName}
                                            onChange={(e) => setFullName(e.target.value)}
                                        />
                                    </div>
                                    <div className='col-md-3'>
                                        <CustomInput
                                            label="Email ID"
                                            require={true}
                                            placeholder='Email ID'
                                            value={emailId}
                                            onChange={(e) => setEmailId(e.target.value)}
                                        />
                                    </div>
                                    <div className='col-md-3'>
                                        <CustomInput
                                            label="Mobile Number"
                                            require={true}
                                            placeholder='Mobile Number'
                                            value={mobNumber}
                                            onChange={(e) => setMobNumber(e.target.value)}
                                        />
                                    </div>
                                    <div className='col-md-3'>
                                        <Customselect
                                            label="Department"
                                            require={true}
                                            value={selectedDepartment}
                                            onChange={(e) => {
                                                setSelectedDepartment(e.target.value);
                                            }}
                                            optionview={departmentList?.map((option) => {
                                                return (
                                                    <option value={option?.department_name}>{option?.department_name}</option>
                                                );
                                            })}

                                        />
                                    </div>
                                    <div className='col-md-3'>
                                        <Customselect
                                            label="Interviewer"
                                            require={true}
                                            value={selectedInterview}
                                            onChange={(e) => {
                                                setSelectedInterview(e.target.value);
                                            }}
                                            optionview={interviewList?.map((option) => {
                                                return (
                                                    <option value={option?.id}>{option?.name}</option>
                                                );
                                            })}

                                        />
                                    </div>
                                    <div className='col-md-3'>
                                        <CustomInput
                                            label="Date of the meeting"
                                            require={true}
                                            type="date"
                                            placeholder='Date of the meeting'
                                            value={dateOfJoining}
                                            onChange={(e) => setDateOfJoining(e.target.value)}
                                        />
                                    </div>
                                    <div className='col-md-3'>
                                    <label for="appt-time" className='time-label'>Time of the meeting</label><br/>
                                    <input id="appt-time" type="time" name="appt-time" step="2" className='time-input'  onChange={handleTimeChange}/>
                                    </div>
                                    <div className='col-md-3'>
                                        <CustomInput
                                            label="Expiry of the link"
                                            require={true}
                                            type="date"
                                            placeholder='Expiry of the link'
                                            value={expiryDateLink}
                                            onChange={(e) => setExpiryDateLink(e.target.value)}
                                        />
                                    </div>
                                </div>
                            }
                        />
                    </div>

                    <div className='col-md-12 text-end'>
                        <CustomButton children={"Generate Links"} style={"btn--approved--solid"} onClick={handleSumbit} />
                    </div>
                </div>
            </div>

            <ShowError
            show={showModal}
            hide={()=>{
                setShowModal(false)
            }}
            title1={title1}
            handleView={handleView}
            item={item}
            />
        </div>
    )
}

export default GenerateApplicantLink
import React, { useState, useEffect } from "react";
import Header from "../Header";
import CustomCard from "../CustomeCard";
import CustomInput from "../CustomInput";
import CustomButton from "../Button/Button";
import notify from "../../shared/notify";
import { useStores } from "../../stores";
import { useLocation, useNavigate } from "react-router-dom"
import ApproveLcRequest from "../Modal/ApproveLcRequest"
import RejectLcRequest from "../Modal/RejectLcRequest"
import Loader from "../helper/Loader/loader";
import ReactExport from "react-export-excel";
import FormatDate from "../helper/formatDate/formateDate";



const ExcelFile = ReactExport.ExcelFile;
const ExcelSheet = ReactExport.ExcelFile.ExcelSheet;
const ExcelColumn = ReactExport.ExcelFile.ExcelColumn;

const ApproverLcIssuance = () => {
    const { mainStore } = useStores()
    const navigate = useNavigate()
    const { state } = useLocation();
    const [isL1Approved, setIsL1Approved] = useState(false)
    const [isL2Approved, setIsL2Approved] = useState(false)
    const [isApproverL1, setIsApproverL1] = useState(false)
    const [isApproverL2, setIsApproverL2] = useState(false)
    const [showButton, setShowButton] = useState(false)
    const ApproverRole = sessionStorage.getItem("Approver_role")

    useEffect(() => {
        if (ApproverRole === "Purchase") {
            setIsApproverL1(true)
        }

        if (ApproverRole === "Finance") {
            setIsApproverL2(true)
        }
    }, [ApproverRole])

    useEffect(() => {

        if (state?.item?.isL1Approved === true) {
            setIsL1Approved(true)
        }

        if (state?.item?.isL2Approved === true) {
            setIsL2Approved(true)
        }

    }, [isL2Approved])

    useEffect(()=>{
        if(isApproverL1 && !isL1Approved){
            setShowButton(true)
        }else if(isApproverL2 && isL1Approved && !isL2Approved){
            setShowButton(true)
        }else{
            setShowButton(false)
        }
    },[isApproverL1,isApproverL2, isL1Approved, isL2Approved])
    const [isLoading, setIsLoading] = useState(false)
    const userName = sessionStorage.getItem("name")
    const roleName = sessionStorage.getItem("roleName")
    const [chatComment, setChatComment] = useState("")
    const [chat, setChat] = useState([])
    const [requestNumber] = useState(state?.item?.requestNumber);
    const [requestName] = useState(state?.item?.requesterName);
    const [requesterMail] = useState(state?.item?.requesterEmail);
    const [requestEntity] = useState(state?.item?.entity);
    const [requestUnit] = useState(state?.item?.unit);
    const [approverInfo, setApproverInfo] = useState([
        { approverName: "", approverDepartment: "", approverEmail: "", approverLevel: "L1", approverId: "" },
        { approverName: "", approverDepartment: "", approverEmail: "", approverLevel: "L2", approverId: "" }
    ]);
    const [bankName] = useState(state?.item?.bankName);
    const [bankAccountNumber] = useState(state?.item?.bankAccountNumber);
    const [bankGlCode] = useState(state?.item?.bankGlCode);
    const [refrenceNumber] = useState(state?.item?.referenceNumber);
    const [typeOfLc] = useState(state?.item?.lcType);
    const [beneficiaryName] = useState(state?.item?.beneficiaryName);
    const [beneficiaryLocation] = useState(state?.item?.beneficiaryAddress);
    const [inssuanceDate] = useState(state?.item?.issuanceDate);
    const [expiryDate] = useState(state?.item?.expiryDate);
    const [placeOfExpiry] = useState(state?.item?.expiryPlace)
    const [currency,] = useState(state?.item?.currency);
    const [ammount] = useState(state?.item?.amount);
    const [nameOfApplicant] = useState(state?.item?.applicantName);
    const [applicantAddress] = useState(state?.item?.applicantAddress);
    const [bgCharges] = useState(state?.item?.lcCharge);
    const [gst] = useState(state?.item?.gst);
    const [bgChargesGlCode] = useState(state?.item?.bankChargesGlCode);
    const [gstGlCode] = useState(state?.item?.gstGlCode);
    const [purchaseOrderNumber] = useState(state?.item?.purchaseOrderNumber)
    const [purchaseOrderDate] = useState(state?.item?.purchaseOrderDate)
    const [beneficiaryBankName] = useState(state?.item?.beneficiaryBankName)
    const [beneficiaryBankAddress] = useState(state?.item?.beneficiaryBankAddress)
    const [beneficiaryBankSwiftCode] = useState(state?.item?.beneficiaryBankSwiftCode)
    const [modeOfShipment] = useState(state?.item?.shipmentMode)
    const [quantityTolerance] = useState(state?.item?.toleranceQuantity)
    const [valueTolerance] = useState(state?.item?.toleranceValue)
    const [draftsAt] = useState(state?.item?.draftsAt)
    const [dispatchFrom] = useState(state?.item?.dispatchFrom)
    const [hsnCode] = useState(state?.item?.hsnCode)
    const [dispatchDate, setDispatchDate] = useState(state?.item?.lastShipmentDate)
    const [descriptionOfGood] = useState(state?.item?.goodsDescription)
    const [ibanCode, setIbanCode] = useState(state?.item?.IBANcode)
    const [requesterDetailComments, setRequesterDetailComments] = useState(state?.item?.requestorComment)
    const [approverDetailsComment, setApproverDetailsComment] = useState(state?.item?.approverComment)
    const [bankDetailComment, setBankDetailComment] = useState(state?.item?.bankComment)
    const [letterOfCreditDetailComment, setLetterOfCreditDetailComment] = useState(state?.item?.letterOfCreditComment)
    const [bankChargesComment, setBankChargesComment] = useState(state?.item?.bankChargesComments)
    const [attachmentComment, setAttachmentComment] = useState(state?.item?.attachmentsComment)
    const [getAuditDetail, setGetAuditDetail] = useState([])

    const [LcDraft] = useState(() => {
        const item = state?.item?.lcDraftAttachement;
        if (item) {
            try {
                return JSON.parse(item);
            } catch (error) {
                console.error("Error parsing bgText JSON:", error);
            }
        }
        return [];
    });
    const [purchaseOrder] = useState(() => {
        const item = state?.item?.purchaseOrderAttachment;
        if (item) {
            try {
                return JSON.parse(item);
            } catch (error) {
                console.error("Error parsing bgText JSON:", error);
            }
        }
        return [];
    });
    const [proformaInvoice] = useState(() => {
        const item = state?.item?.performaInvoiceAttachment;
        if (item) {
            try {
                return JSON.parse(item);
            } catch (error) {
                console.error("Error parsing bgText JSON:", error);
            }
        }
        return [];
    });
    const [creditReport] = useState(() => {
        const item = state?.item?.creditReportAttachment;
        if (item) {
            try {
                return JSON.parse(item);
            } catch (error) {
                console.error("Error parsing bgText JSON:", error);
            }
        }
        return [];
    });


    const [showApproveModal, setShowApproveModal] = useState(false)
    const [showCancellationModal, setShowCancellationModal] = useState(false)


    const lcrequestAmendment = state?.item?.lcrequestAmendment;
    const lcrequestCancellation = state?.item?.lcrequestCancellation

    const [division] = useState(state?.item?.lineOfService);

    useEffect(() => {
        getLcAuditDetail()
    }, [])

    const getLcAuditDetail = async () => {
        let payload = {}
        await mainStore.getAuditLcDetail(state?.item?.requestNumber, payload, (response) => {
            if (response?.data?.meta?.status === "success") {
                setGetAuditDetail(response?.data?.data?.audit)
            }
            else {
                notify(response?.data?.meta?.message, "danger")
            }
        }, (error) => {
            notify(error?.response?.data?.meta?.message, "danger")
        })
    }

    useEffect(() => {
        fetchChatResponse()
        if (state?.item?.approvers?.length > 0) {
            let arr = [];
            state.item.approvers.map((item) => {
                let obj = {
                    approverId: item?.approver_id,
                    approverName: item?.user?.name,
                    approverEmail: item?.user?.email,
                    approverDepartment: item?.department,
                    approverLevel: item?.approver_level
                }
                arr.push(obj)
            })
            setApproverInfo(arr);
        }
    }, [])

    const handleSendMassege = async () => {
        setIsLoading(true)
        let payload = {
            requestNumber: state?.item?.requestNumber,
            userName: userName,
            userRole: roleName,
            message: chatComment
        }

        await mainStore.sendMassege(payload, (response) => {
            if (response?.data?.meta?.status === "success") {
                notify(response?.data?.meta?.message)
                fetchChatResponse()
                setIsLoading(false)
                setChatComment("")
            } else {
                notify(response?.data?.meta?.message, "danger")
                setIsLoading(false)
                setChatComment("")
            }
        }, (error) => {
            notify(error?.response?.data?.meta?.message, "danger")
            setChatComment("")
        })
    }

    const fetchChatResponse = async () => {
        mainStore.getChatResponse(state?.item?.requestNumber, (status, response) => {
  
            if (response?.meta?.status === "success") {
                setChat(response?.data?.chats)
            } else {
                notify(response?.meta?.message, "danger")
            }
        }, (error) => {
            notify(error?.response?.meta?.message, "danger")
        })
    }


    const handleCreditReportdownload = async (item) => {
        let payload = {
            filename: item
        }
        callDownloadApi(payload)
    }
    const handleProformaDownload = async (item) => {
        let payload = {
            filename: item
        }
        callDownloadApi(payload)
    }
    const handlePurchaseOrderDowmload = async (item) => {
        let payload = {
            filename: item
        }
        callDownloadApi(payload)
    }
    const handleLcDraftDownload = async (item) => {
        let payload = {
            filename: item
        }
        callDownloadApi(payload)
    }


    const callDownloadApi = async (payload) => {
        setIsLoading(true);
        await mainStore.downloadImage(payload, (response) => {
            if (response?.data?.meta?.status === "success") {
                notify(response?.data?.meta?.message);
                setIsLoading(false);
                const base64File = response?.data?.data?.base64File;
                const mimeType = response?.data?.data?.mimeType;
                const fileNameParts = response?.data?.data?.filename?.split("-");
                const fileName = fileNameParts.slice(1).join("-");
                if (base64File && mimeType) {
                    const downloadLink = document.createElement('a');
                    downloadLink.href = `data:${mimeType};base64,${base64File}`;
                    downloadLink.download = fileName;
                    document.body.appendChild(downloadLink);
                    downloadLink.click();
                    document.body.removeChild(downloadLink);
                } else {
                    notify("Download link or File type not found", "danger");
                }
            } else {
                notify(response?.data?.meta?.message, "danger");
                setIsLoading(false);
            }
        },
            (error) => {
                notify(error?.response?.data?.meta?.message, "danger");
                setIsLoading(false);
            });
    };



    const submitApproval = async () => {
        setShowApproveModal(true)
    }

    const rejectApproval = async () => {
        setShowCancellationModal(true)

    }

    const handleAttachmentDownload = async (item) => {
        let payload = {
            filename: item
        }
        callDownloadApi(payload)
    }


    const handleCancellationDownload = async (item) => {
        let payload = {
            filename: item
        }
        callDownloadApi(payload)
    }

    const getFormattedDate = (dateString) => {
        const date = new Date(dateString);
        const year = date.getFullYear();
        const month = (date.getMonth() + 1).toString().padStart(2, "0");
        const day = date.getDate().toString().padStart(2, "0");
        return `${year}-${month}-${day}`;
      };

    return (
        <div className="main-container">
            {isLoading && <Loader />}
            <div className="right-view">
                <Header title={" LC > Issue"} />
                <div className="row">
                    <div className="col-md-12">
                        <CustomCard
                            cardtitle={"Requester"}
                            cardboldtitle={"Details"}
                            cardbody={
                                <div className="row">
                                    <div className="col-md-3">
                                        <CustomInput
                                            label="Request Number"
                                            value={requestNumber}
                                            placeholder="Request Number"
                                            readOnly={true}
                                        />
                                    </div>
                                    <div className="col-md-3">
                                        <CustomInput
                                            require={true}
                                            readOnly={true}
                                            label="Requester Name"
                                            value={requestName}
                                            placeholder="Requester Name"
                                        />
                                    </div>
                                    <div className="col-md-3">
                                        <CustomInput
                                            require={true}
                                            readOnly={true}
                                            label="Requester Mail ID"
                                            value={requesterMail}
                                            placeholder="Requester mail ID"
                                        />
                                    </div>
                                    <div className="col-md-3">
                                        <CustomInput
                                            require={true}
                                            label="Entity"
                                            readOnly={true}
                                            value={requestEntity}
                                            placeholder="Entity"
                                        />
                                    </div>
                                    <div className="col-md-3">
                                        <CustomInput
                                            require={true}
                                            label="Unit"
                                            readOnly={true}
                                            value={requestUnit}
                                            placeholder="Unit"
                                        />
                                    </div>
                                    <div className="col-md-3 ">
                                        <CustomInput
                                            label="Line Of Service"
                                            placeholder="Line Of Service"
                                            value={division}
                                            readOnly={true}
                                        />
                                    </div>
                                    <div className="col-md-3">
                                        <CustomInput
                                            label="Comment"
                                            value={requesterDetailComments}
                                            readOnly={true}
                                            placeholder="Comment"
                                        />
                                    </div>
                                </div>
                            }
                        />
                    </div>
                    <div className="col-md-12">
                        <CustomCard
                            cardtitle={"Approver"}
                            cardboldtitle={"Details"}
                            cardbody={
                                <div>
                                    {approverInfo.map((approver, index) => (
                                        <div key={index} className="row">
                                            <div className="col-md-4">
                                                <CustomInput

                                                    label={index === 0 ? "Approver Purchase" : "Approver Finance"}
                                                    value={approver.approverName}
                                                    readOnly={true}
                                                    placeholder="Approver Name"
                                                />
                                            </div>
                                            <div className="col-md-4">
                                                <CustomInput
                                                    label="Department"
                                                    value={approver.approverDepartment}
                                                    readOnly={true}
                                                    placeholder="Department"
                                                />
                                            </div>
                                            <div className="col-md-4">
                                                <CustomInput

                                                    label="Mail ID"
                                                    value={approver.approverEmail}
                                                    placeholder="Mail ID"
                                                    readOnly={true}
                                                />
                                            </div>
                                        </div>
                                    ))}
                                    <div className="col-md-4">
                                        <CustomInput
                                            label="Comment"
                                            value={approverDetailsComment}
                                            readOnly={true}
                                            placeholder="Comment"
                                        />
                                    </div>
                                </div>
                            }
                        />
                    </div>

                    <div className="col-md-12">
                        <CustomCard
                            cardtitle={"Bank "}
                            cardboldtitle={"Details"}
                            cardbody={
                                <div className="row">
                                    <div className="col-md-3">
                                        <CustomInput
                                            readOnly={true}
                                            label="Bank Name"
                                            value={bankName}
                                            placeholder="Bank Name"
                                        />
                                    </div>
                                    <div className="col-md-3">
                                        <CustomInput
                                            readOnly={true}
                                            label="Bank Account Number"
                                            value={bankAccountNumber}
                                            placeholder="Bank Account Number"
                                        />
                                    </div>
                                    <div className="col-md-3">
                                        <CustomInput
                                            readOnly={true}
                                            label="Bank GL Code"
                                            value={bankGlCode}
                                            placeholder="Bank GL Code"
                                        />
                                    </div>
                                    <div className="col-md-3">
                                        <CustomInput
                                            label="Comment"
                                            value={bankDetailComment}
                                            readOnly={true}
                                            placeholder="Comment"
                                        />
                                    </div>
                                </div>
                            }
                        />
                    </div>

                    <div className="col-md-12">
                        <CustomCard
                            cardtitle={"Letter of Credit"}
                            cardboldtitle={"Details"}
                            cardbody={
                                <div className="row">
                                    <div className="col-md-3">
                                        <CustomInput
                                            label="Reference Number"
                                            readOnly={true}
                                            value={refrenceNumber}
                                            placeholder="Reference Number"
                                        />
                                    </div>
                                    <div className="col-md-3">
                                        <CustomInput
                                            require={true}
                                            readOnly={true}
                                            label="Type of LC"
                                            value={typeOfLc}
                                            placeholder="Type of LC"
                                        />
                                    </div>
                                    <div className="col-md-3">
                                        <CustomInput
                                            label="Issuance Date"
                                            value={ inssuanceDate && getFormattedDate(inssuanceDate)}
                                            readOnly={true}
                                            placeholder="Issuance Date"
                                        />
                                    </div>

                                    <div className="col-md-3">
                                        <CustomInput
                                            require={true}
                                            readOnly={true}
                                            type="date"
                                            label="Date of Shipment/dispatch"
                                            value={dispatchDate && getFormattedDate(dispatchDate)}
                                            placeholder="Date of Shipment/dispatch"
                                        />
                                    </div>
                                    <div className="col-md-3">
                                        <CustomInput
                                            require={true}
                                            readOnly={true}
                                            type="date"
                                            label="Expiry Date"
                                            value={ expiryDate && getFormattedDate(expiryDate)}
                                            placeholder="Expiry Date"
                                        />
                                    </div>
                                    <div className="col-md-3">
                                        <CustomInput
                                            require={true}
                                            readOnly={true}
                                            label="Place of Expiry"
                                            value={placeOfExpiry}
                                            placeholder="Place of Expiry"
                                        />
                                    </div>
                                    <div className="col-md-3">
                                        <CustomInput
                                            require={true}
                                            readOnly={true}
                                            label="Currency"
                                            value={currency}
                                            placeholder="Currency"
                                        />
                                    </div>
                                    <div className="col-md-3">
                                        <CustomInput
                                            require={true}
                                            label="Amount"
                                            readOnly={true}
                                            value={ammount}
                                            placeholder="Amount"
                                        />
                                    </div>
                                    <div className="col-md-3">
                                        <CustomInput
                                            require={true}
                                            readOnly={true}
                                            label="Name of Applicant"
                                            value={nameOfApplicant}
                                            placeholder="Name of Applicant"
                                        />
                                    </div>
                                    <div className="col-md-3">
                                        <CustomInput
                                            require={true}
                                            readOnly={true}
                                            label="Address of Applicant"
                                            value={applicantAddress}
                                            placeholder="Address of Applicant"
                                        />
                                    </div>
                                    <div className="col-md-3">
                                        <CustomInput
                                            require={true}
                                            readOnly={true}
                                            label="Name of Beneficiary"
                                            value={beneficiaryName}
                                            placeholder="Name of Beneficiary"
                                        />
                                    </div>
                                    <div className="col-md-3">
                                        <CustomInput
                                            require={true}
                                            readOnly={true}
                                            label="Address of Beneficiary"
                                            value={beneficiaryLocation}
                                            placeholder="Address of Beneficiary"
                                        />
                                    </div>
                                    <div className="col-md-3">
                                        <CustomInput
                                            require={true}
                                            readOnly={true}
                                            label="Purchase Order No."
                                            value={purchaseOrderNumber}
                                            placeholder="Purchase Order No."
                                        />
                                    </div>
                                    <div className="col-md-3">
                                        <CustomInput
                                            require={true}
                                            readOnly={true}
                                            label="Purchase Order Date"
                                            type="date"
                                            value={purchaseOrderDate && getFormattedDate(purchaseOrderDate)}
                                            placeholder="Purchase Order Date"
                                        />
                                    </div>
                                    <div className="col-md-3">
                                        <CustomInput
                                            require={true}
                                            readOnly={true}
                                            label="Beneficiary Bank Name"
                                            value={beneficiaryBankName}
                                            placeholder="Beneficiary Bank Name"
                                        />
                                    </div>
                                    <div className="col-md-3">
                                        <CustomInput
                                            require={true}
                                            readOnly={true}
                                            label="Beneficiary Bank Address"
                                            value={beneficiaryBankAddress}
                                            placeholder="Beneficiary Bank Address"
                                        />
                                    </div>
                                    <div className="col-md-3">
                                        <CustomInput
                                            require={true}
                                            readOnly={true}
                                            label="Beneficiary Bank Swift Code"
                                            value={beneficiaryBankSwiftCode}
                                            placeholder="Beneficiary Bank Swift Code"
                                        />
                                    </div>
                                    <div className="col-md-3">
                                        <CustomInput
                                            require={true}
                                            readOnly={true}
                                            label="Mode of Shipment"
                                            value={modeOfShipment}
                                            placeholder="Mode of Shipment"
                                        />
                                    </div>
                                    <div className="col-md-3">
                                        <CustomInput
                                            require={true}
                                            readOnly={true}
                                            label="Quantity Tolerance"
                                            value={quantityTolerance}
                                            placeholder="Quantity Tolerance"
                                        />
                                    </div>
                                    <div className="col-md-3">
                                        <CustomInput
                                            require={true}
                                            readOnly={true}
                                            label="Value Tolerance"
                                            value={valueTolerance}
                                            placeholder="Value Tolerance"
                                        />
                                    </div>
                                    <div className="col-md-3">
                                        <CustomInput
                                            require={true}
                                            label="Drafts At"
                                            value={draftsAt}
                                            readOnly={true}
                                            placeholder="Drafts At"
                                        />
                                    </div>
                                    <div className="col-md-3">
                                        <CustomInput
                                            require={true}
                                            label="Dispatch From"
                                            value={dispatchFrom}
                                            readOnly={true}
                                            placeholder="Dispatch From"
                                        />
                                    </div>
                                    <div className="col-md-3">
                                        <CustomInput
                                            require={true}
                                            readOnly={true}
                                            label="Description of Goods"
                                            value={descriptionOfGood}
                                            placeholder="Description of Goods"
                                        />
                                    </div>
                                    <div className="col-md-3">
                                        <CustomInput
                                            require={true}
                                            readOnly={true}
                                            label="HSN Code"
                                            value={hsnCode}
                                            placeholder="HSN Code"
                                        />
                                    </div>
                                    <div className="col-md-3">
                                        <CustomInput
                                            readOnly={true}
                                            label="IBAN Code"
                                            value={ibanCode}
                                            placeholder="IBAN Code"
                                        />
                                    </div>
                                    <div className="col-md-3">
                                        <CustomInput
                                            label="Comment"
                                            value={letterOfCreditDetailComment}
                                            readOnly={true}
                                            placeholder="Comment"
                                        />
                                    </div>
                                </div>
                            }
                        />
                    </div>

                    <div className="col-md-12">
                        <CustomCard
                            cardtitle={"Bank Charges"}
                            cardboldtitle={"Details"}
                            cardbody={
                                <div className="row">
                                    <div className="col-md-3">
                                        <CustomInput
                                            label="LC Charges"
                                            readOnly={true}
                                            value={bgCharges}
                                            placeholder="BG Charges"
                                        />
                                    </div>
                                    <div className="col-md-3">
                                        <CustomInput
                                            label="GST"
                                            value={gst}
                                            readOnly={true}
                                            placeholder="GST"
                                        />
                                    </div>
                                    <div className="col-md-3">
                                        <CustomInput
                                            readOnly={true}
                                            label="Bank Charges GL Code"
                                            value={bgChargesGlCode}
                                            placeholder="Bank Charges GL Code"
                                        />
                                    </div>
                                    <div className="col-md-3">
                                        <CustomInput
                                            readOnly={true}
                                            label="GST GL Code"
                                            value={gstGlCode}
                                            placeholder="GST GL Code"
                                        />
                                    </div>
                                    <div className="col-md-3">
                                        <CustomInput
                                            label="Comment"
                                            value={bankChargesComment}
                                            readOnly={true}
                                            placeholder="Comment"
                                        />
                                    </div>
                                </div>
                            }
                        />
                    </div>

                    <div className="col-md-12">
                        <CustomCard
                            cardboldtitle={"Attachments"}
                            cardbody={
                                <div className="row">
                                    <div className="col-md-3">
                                        <CustomInput
                                            label="LC Draft"
                                            readOnly={true}
                                            placeholder="Browse File"
                                        />
                                        <div className="uploaded-images">
                                            {LcDraft.map((item, index) => (
                                                <div key={index} style={{ cursor: "pointer", color: "#65a6fa", marginBottom: '8px' }} onClick={() => handleLcDraftDownload(item)} className="url-download">
                                                    {item}
                                                </div>
                                            ))}
                                        </div>
                                    </div>
                                    <div className="col-md-3">
                                        <CustomInput
                                            label="Purchase Order"
                                            readOnly={true}
                                            placeholder="Browse File"
                                        />
                                        <div className="uploaded-images">
                                            {purchaseOrder.map((item, index) => (
                                                <div key={index} style={{ cursor: "pointer", color: "#65a6fa", marginBottom: '8px' }} onClick={() => handlePurchaseOrderDowmload(item)} className="url-download">
                                                    {item}
                                                </div>
                                            ))}
                                        </div>
                                    </div>
                                    <div className="col-md-3">
                                        <CustomInput
                                            label="Proforma Invoice"
                                            placeholder="Browse File"
                                            readOnly={true}
                                        />
                                        <div className="uploaded-images">
                                            {proformaInvoice.map((item, index) => (
                                                <div key={index} style={{ cursor: "pointer", color: "#65a6fa", marginBottom: '8px' }} onClick={() => handleProformaDownload(item)} className="url-download">
                                                    {item}
                                                </div>
                                            ))}
                                        </div>
                                    </div>
                                    <div className="col-md-3">
                                        <CustomInput
                                            label="Credit Report"
                                            readOnly={true}
                                            placeholder="Browse File"
                                        />
                                        <div className="uploaded-images">
                                            {creditReport.map((item, index) => (
                                                <div key={index} style={{ cursor: "pointer", color: "#65a6fa", marginBottom: '8px' }} onClick={() => handleCreditReportdownload(item)} className="url-download">
                                                    {item}
                                                </div>
                                            ))}
                                        </div>
                                    </div>
                                    <div className="col-md-3">
                                        <CustomInput
                                            label="Comment"
                                            value={attachmentComment}
                                            readOnly={true}
                                            placeholder="Comment"
                                        />
                                    </div>
                                </div>
                            }
                        />
                    </div>


                    {lcrequestAmendment?.length > 0 &&
                        <div className="col-md-12">
                            {lcrequestAmendment.map((amendment, index) => {
                                let ammendmentData;
                                if (amendment?.attachments) {
                                    ammendmentData = JSON.parse(amendment?.attachments)
                                }
                                return <>
                                    <CustomCard

                                        cardboldtitle={`Amendment ${index + 1}`}
                                        cardbody={
                                            <div className="row">

                                                <div className="col-md-3">
                                                    <CustomInput
                                                        readOnly={true}
                                                        label="Expiry Date"
                                                        placeholder="Expiry Date"
                                                        type="date"
                                                        value={amendment?.expiryDate?.split?.("T")[0]}
                                                    />
                                                </div>
                                                <div className="col-md-3">
                                                    <CustomInput
                                                        readOnly={true}
                                                        label="Claim Expiry Date"
                                                        placeholder="Claim Expiry Date"
                                                        type="date"
                                                        value={amendment?.claimExpiryDate?.split?.("T")[0]}
                                                    />
                                                </div>
                                                <div className="col-md-3">
                                                    <CustomInput
                                                        readOnly={true}
                                                        label="Clause"
                                                        placeholder="Clause"
                                                        value={amendment?.clause}
                                                    />
                                                </div>
                                                <div className="col-md-3">
                                                    <CustomInput
                                                        readOnly={true}
                                                        label="Others"
                                                        placeholder="Others"
                                                        value={amendment?.others}
                                                    />
                                                </div>
                                                <div className="col-md-3">
                                                    <CustomInput
                                                        label="Attachment"
                                                        placeholder="Browse file"
                                                        readOnly={true}
                                                    />
                                                    {ammendmentData && ammendmentData.map((item) => {
                                                        return <div style={{ cursor: "pointer", color: "#65a6fa" }} onClick={() => handleAttachmentDownload(item)} className="url-download">
                                                            {item}
                                                        </div>
                                                    })}
                                                </div>
                                                <div className="col-md-3">
                                                    <CustomInput
                                                        readOnly={true}
                                                        label="LC Charges"
                                                        value={(amendment?.lcCharge)}
                                                        placeholder="LC Charges"
                                                    />
                                                </div>
                                                <div className="col-md-3">
                                                    <CustomInput label="GST" placeholder="GST"
                                                        readOnly={true}
                                                        value={(amendment?.gst)}

                                                    />
                                                </div>
                                                <div className="col-md-3">
                                                    <CustomInput
                                                        readOnly={true}
                                                        label="Bank Charges GL Code"
                                                        value={(amendment?.bankChargesGlCode)}
                                                        placeholder="Bank Charges GL Code"

                                                    />
                                                </div>
                                                <div className="col-md-3">
                                                    <CustomInput
                                                        readOnly={true}
                                                        label="GST GL Code"
                                                        value={(amendment?.gstGlCode)}
                                                        placeholder="GST GL Code"

                                                    />
                                                </div>
                                                <div className="col-md-3">
                                                    <CustomInput
                                                        label="Comment"
                                                        value={amendment?.comment}
                                                        readOnly={true}
                                                        placeholder="Comment"
                                                    />
                                                </div>
                                            </div>
                                        }

                                    />
                                </>
                            })}

                        </div>}

                    {lcrequestCancellation?.length > 0 &&
                        <div className="col-md-12">
                            {lcrequestCancellation.map((cancellation, index) => {
                                let cancellationData;
                                if (cancellation?.attachments) {
                                    cancellationData = JSON.parse(cancellation?.attachments)
                                }
                                return <CustomCard

                                    cardboldtitle={`Cancellation ${index + 1}`}
                                    cardbody={
                                        <div className="row">
                                            <div className="col-md-3">
                                                <CustomInput
                                                    readOnly={true}
                                                    label="Cancellation Date"
                                                    placeholder="Cancellation Date"
                                                    type="date"
                                                    value={cancellation?.cancellationDate?.split?.("T")[0]}
                                                />
                                            </div>
                                            <div className="col-md-3">
                                                <CustomInput
                                                    label="Attachment"
                                                    placeholder="Browse file"
                                                    readOnly={true}

                                                />
                                                {cancellationData && cancellationData.map((item) => {
                                                    return <div style={{ cursor: "pointer", color: "#65a6fa" }} onClick={() => handleCancellationDownload(item)} className="url-download">
                                                        {item}
                                                    </div>
                                                })}
                                            </div>
                                            <div className="col-md-3">
                                                <CustomInput
                                                    readOnly={true}
                                                    label="LC Charges"
                                                    value={cancellation?.lcCharge}
                                                    placeholder="LC Charges"
                                                />
                                            </div>
                                            <div className="col-md-3">
                                                <CustomInput label="GST" placeholder="GST"
                                                    readOnly={true}
                                                    value={cancellation?.gst}

                                                />
                                            </div>
                                            <div className="col-md-3">
                                                <CustomInput
                                                    readOnly={true}
                                                    label="Bank Charges GL Code"
                                                    value={cancellation?.bankChargesGlCode}
                                                    placeholder="Bank Charges GL Code"

                                                />
                                            </div>
                                            <div className="col-md-3">
                                                <CustomInput
                                                    readOnly={true}
                                                    label="GST GL Code"
                                                    value={cancellation?.gstGlCode}
                                                    placeholder="GST GL Code"

                                                />
                                            </div>

                                            <div className="col-md-3">
                                                <CustomInput
                                                    label="Comment"
                                                    value={cancellation?.comment}
                                                    readOnly={true}
                                                    placeholder="Comment"
                                                />
                                            </div>
                                        </div>
                                    }
                                />
                            })}
                        </div>}


                    <div className="col-md-12">
                        <CustomCard
                            cardtitle={"Audit"}
                            cardboldtitle={" trail"}
                            toprightview={
                                <button className="export-btn">
                                    <ExcelFile element={<div> <img width={20} className="me-2" src={require("../../assets/images/excel.png")} />Export</div>}>
                                        <ExcelSheet data={getAuditDetail?.map((item, i) => ({
                                            ...item, index: i + 1, 
                                            role:item?.role,
                                            created_at: item?.created_at && `${item.created_at?.split("T")[0]} ${item.created_at?.split("T")[1]?.split(".")[0].substring(0, 5)}`,
                                        }))} name="Sheet">
                                            <ExcelColumn label="Status Date & Time" value="created_at" />
                                            <ExcelColumn label="Name" value="userName" />
                                            <ExcelColumn label="Role" value="role" />
                                            <ExcelColumn label="Action Required" value="status" />
                                        </ExcelSheet>
                                    </ExcelFile>
                                </button>
                            }
                            cardbody={
                                <React.Fragment>
                                    <div className="table-responsive">
                                        <table className="table">
                                            <thead className="table-customlight">
                                                <tr>
                                                    <th className="auditTrail">Status Date & Time</th>
                                                    <th className="auditTrail">Name</th>
                                                    <th className="auditTrail">Role</th>
                                                    <th className="auditTrail">Action Required</th>
                                                </tr>
                                            </thead>
                                            {getAuditDetail?.length > 0 && (
                                                <tbody>
                                                    {getAuditDetail?.map((item, i) => {
                                                        return (
                                                            <tr key={item?.requestNumber}>
                                                                <td scope="row"> <FormatDate dateString={item?.created_at}/> </td>
                                                                <td>{item?.userName}</td>
                                                                <td>{item?.role}</td>
                                                                <td className="status-box"><span className="status"></span>{item?.status}</td>

                                                            </tr>)
                                                    })}
                                                </tbody>)}
                                        </table>
                                    </div>
                                </React.Fragment>
                            }
                        />
                    </div>

                    <div className="col-md-12">
                        <CustomCard
                            cardtitle={""}
                            cardboldtitle={"Chat"}
                            cardbody={
                                <div className="row">
                                    {chat?.length > 0 && (
                                        <>
                                            {chat?.map((item, i) => (
                                                <div key={i} className="col-md-12 chat-left">
                                                    <div className="chat-container">
                                                        <h4 className="chat-username">{item.userName}({item?.userRole})</h4>
                                                        <div className="chat-username-box">
                                                            <p className="chat-massege">{item?.message}</p>
                                                            <p className="chat-time">
                                                                <FormatDate dateString={item?.created_at} />
                                                            </p>
                                                        </div>
                                                    </div>
                                                </div>
                                            ))}
                                            <div className="col-md-6 " style={{ width: "44%" }}>
                                                <CustomInput
                                                    label="Comment Box"
                                                    value={chatComment}
                                                    onChange={(e) => setChatComment(e.target.value)}
                                                    placeholder="Comment Box"
                                                />
                                            </div>
                                            <div className="col-md-2 chat-button">
                                                <CustomButton
                                                    style={"btn--outline me-3"}
                                                    children={"Send"}
                                                    onClick={handleSendMassege}
                                                />
                                            </div>
                                        </>
                                    )}
                                </div>
                            }
                        />
                    </div>



                    {showButton &&<div className="col-md-12 text-end">
                        <CustomButton style={"btn--outline me-3"} children={"Rejected"} onClick={rejectApproval} />
                        <CustomButton children={"Approved"} style={"btn--approved--solid"} onClick={submitApproval} />
                    </div>}
                </div>
            </div>
            <ApproveLcRequest
                show={showApproveModal}
                hide={() => {
                    setShowApproveModal(false)
                }}
                requestNumber={state?.item?.requestNumber}
            />
            <RejectLcRequest
                show={showCancellationModal}
                hide={() => {
                    setShowCancellationModal(false)
                }}
                requestNumber={state?.item?.requestNumber}
            />
        </div>
    );
};

export default ApproverLcIssuance;
